import type { Shell } from 'repluggable';

interface ReduxAction {
  type: string;
  args: unknown[];
  reducerName: string;
}

export type Reducer<S> = (state: S, action: ReduxAction) => S;

export type ReduxStore<S, Api> = (data: { reducerName: string }) => {
  reducer: Reducer<S>;
  apiFactory: (shell: Shell) => Api;
};

export function createReduxStore<ST, SEL, AC extends Record<string, any[]>>({
  getInitialState,
  selectors,
  actions,
}: {
  getInitialState: () => ST;
  selectors: { [K in keyof SEL]: (state: ST) => SEL[K] };
  actions: { [K in keyof AC]: (state: ST, ...args: AC[K]) => ST };
}): ReduxStore<
  ST,
  {
    [K in keyof AC]: (...args: AC[K]) => void;
  } & {
    [K in keyof SEL]: () => SEL[K];
  }
> {
  if (process.env.NODE_ENV === 'development') {
    for (const key of Object.keys(selectors)) {
      if (actions[key]) {
        throw new Error(`${key} key exists both in actions and selectors`);
      }
    }
  }

  return ({ reducerName }) => {
    const reducer: Reducer<ST> = (state = getInitialState(), action) => {
      if (action.reducerName === reducerName) {
        const fn = actions[action.type];
        if (fn) {
          return fn(state, ...(action.args as any));
        }
      }

      return state;
    };

    const apiFactory = (shell: Shell) => {
      const store = shell.getStore();

      const actionsApi = Object.fromEntries(
        Object.keys(actions).map((k) => {
          const boundedAction = (...args: any[]): void => {
            const action: ReduxAction = { type: k, args, reducerName };
            store.dispatch(action);
          };
          return [k, boundedAction];
        }),
      );

      const selectorsApi = Object.fromEntries(
        Object.entries(selectors).map(([k, getter]) => [
          k,
          () => (getter as any)(store.getState()[reducerName as keyof ST]),
        ]),
      );

      return { ...actionsApi, ...selectorsApi } as any;
    };
    return { reducer, apiFactory };
  };
}
