import { isOnEditorX, isOnWixStudio } from '@wix/santa-editor-utils';
import type { DSRead } from 'types/documentServices';

const getFreeDomainUrlPart = (dsRead: DSRead) => {
  if (isOnWixStudio()) {
    return 'wixstudio.io';
  }

  if (isOnEditorX()) {
    return 'editorx.io';
  }

  if (dsRead.generalInfo.urlFormat.isSlash()) {
    return 'wixsite.com';
  }

  return 'wix.com';
};

export const isDomainConnected = (dsRead: DSRead, domain: string): boolean => {
  const freeDomainUrlPart = getFreeDomainUrlPart(dsRead);
  return Boolean(domain && !domain.includes(freeDomainUrlPart));
};
