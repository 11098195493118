let lastGeneratedId: number | undefined;
let counter = 0;

export function getUniqueId(prefix: string, prefixDelimiter: string): string {
  prefix = prefix || '';
  prefixDelimiter = prefixDelimiter || '';
  const value = Date.now();
  if (value === lastGeneratedId) {
    counter++;
  } else {
    lastGeneratedId = value;
    counter = 0;
  }
  return (
    prefix +
    prefixDelimiter +
    Number(lastGeneratedId).toString(36) +
    (counter ? counter : '')
  );
}

export function getGUID(): string {
  if (window.crypto.randomUUID) {
    return window.crypto.randomUUID();
  }
  //https://stackoverflow.com/a/2117523
  return (<any>[1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (
      c ^
      (window.crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
}
