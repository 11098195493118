import { HTML_TAG_CLOSING } from './consts';

const splitTextByHtmlTags = (text: string) => {
  return text.split(/<[^>]+>/).filter((item) => !!item);
};

const getTagsListLocatedAfterText = (text: string) => {
  const textWithoutHtmlTagsSplit = splitTextByHtmlTags(text);
  const tagsAfterTextList = text.split(
    textWithoutHtmlTagsSplit[textWithoutHtmlTagsSplit.length - 1],
  );
  return tagsAfterTextList[tagsAfterTextList.length - 1];
};

const getTagsListLocatedBeforeText = (text: string) => {
  const [firstTextWithoutHtmlTags] = splitTextByHtmlTags(text);
  const htmlTags = text.split(`${HTML_TAG_CLOSING}${firstTextWithoutHtmlTags}`);
  return `${htmlTags[0]}${HTML_TAG_CLOSING}`;
};

const hasTextContent = (text: string): boolean => {
  const onlyTextElements = splitTextByHtmlTags(text);
  return onlyTextElements.length > 0;
};

const hasTags = (text: string): boolean => {
  const tagsBeforeText = getTagsListLocatedBeforeText(text);
  const tagsAfterText = getTagsListLocatedAfterText(text);
  return !!(tagsBeforeText && tagsAfterText);
};

const getHtmlTagsListFromString = (text: string) => {
  return text
    .replace(/(><)/g, '>_SPLIT_PLACEHOLDER_<')
    .split('_SPLIT_PLACEHOLDER_');
};

const isSameTagsTypeWrappingText = (
  lastTagBeforeText: string,
  firstTagAfterText: string,
) => {
  const firstTagAfterTextWithoutSlash =
    firstTagAfterText.slice(0, 1) + firstTagAfterText.slice(2);
  const firstTagAfterTextType = firstTagAfterTextWithoutSlash.substring(
    1,
    firstTagAfterTextWithoutSlash.indexOf('>'),
  );
  const lastTagBeforeTextType = lastTagBeforeText.substring(
    1,
    firstTagAfterTextType.length + 1,
  );
  return firstTagAfterTextType === lastTagBeforeTextType;
};

const attachSpareInlineStyleTagIfNeeded = (
  text: string,
  tagBeforeText: string,
  tagAfterText: string,
): string => {
  const tagListLocatedBeforeText = getHtmlTagsListFromString(tagBeforeText);
  const tagListLocatedAfterText = getHtmlTagsListFromString(tagAfterText);
  const lastTagBeforeText =
    tagListLocatedBeforeText[tagListLocatedBeforeText.length - 1];
  const firstTagAfterText = tagListLocatedAfterText[0];

  if (tagListLocatedBeforeText.length > tagListLocatedAfterText.length) {
    return lastTagBeforeText + text;
  } else if (tagListLocatedBeforeText.length < tagListLocatedAfterText.length) {
    return text + firstTagAfterText;
  } else if (
    !isSameTagsTypeWrappingText(lastTagBeforeText, firstTagAfterText)
  ) {
    return (
      tagListLocatedBeforeText[tagListLocatedBeforeText.length - 1] +
      text +
      tagListLocatedAfterText[0]
    );
  }
  return text;
};

const separateTextToReplaceFromHtmlTags = (
  text: string,
  tagsBeforeText: string,
  tagsAfterText: string,
) => {
  const textWithoutHtmlTagsSplit = splitTextByHtmlTags(text);
  const maybeTextBeforeClosingTags =
    textWithoutHtmlTagsSplit[textWithoutHtmlTagsSplit.length - 1];
  const maybeTextBeforeClosingTagsIndex = text.indexOf(
    maybeTextBeforeClosingTags,
  );
  return text.substring(
    tagsBeforeText.length,
    text.indexOf(tagsAfterText, maybeTextBeforeClosingTagsIndex),
  );
};

const getTextToReplaceConsideringHtmlTags = (text: string): string => {
  const tagsBeforeText = getTagsListLocatedBeforeText(text);
  const tagsAfterText = getTagsListLocatedAfterText(text);
  const textToReplace = separateTextToReplaceFromHtmlTags(
    text,
    tagsBeforeText,
    tagsAfterText,
  );
  return attachSpareInlineStyleTagIfNeeded(
    textToReplace,
    tagsBeforeText,
    tagsAfterText,
  );
};

export const getInnerTextString = (text: string): string => {
  if (!hasTextContent(text)) {
    return '';
  }
  if (!hasTags(text)) {
    return text;
  }
  return getTextToReplaceConsideringHtmlTags(text);
};
