import React from 'react';
import ReactDOM from 'react-dom';

interface OuterClickProps {
  onOuterClick(event: MouseEvent): void;
  outerClickShouldStopPropagation?: boolean;
}

export class OuterClick extends React.Component<OuterClickProps> {
  displayName: 'outerClick';

  componentDidMount() {
    window.document.addEventListener(
      'mousedown',
      this.handleClickOutside,
      true,
    );
  }

  componentWillUnmount() {
    window.document.removeEventListener(
      'mousedown',
      this.handleClickOutside,
      true,
    );
  }

  handleClickOutside = (event: MouseEvent) => {
    const { onOuterClick, outerClickShouldStopPropagation } = this.props;
    if (outerClickShouldStopPropagation) {
      event.stopPropagation();
    }
    const domNode = ReactDOM.findDOMNode(this);

    if (typeof onOuterClick !== 'function') {
      // Catch errors like https://sentry.wixpress.com/sentry/santa-editor/issues/47377105/
      throw new Error(
        `OuterClick -> onOuterClick is not a function. onOuterClick:\n  typeof:${typeof onOuterClick}\n  stringify:${JSON.stringify(
          onOuterClick,
        )}`,
      );
    }

    if (domNode && !domNode.contains(event.target as Node)) {
      onOuterClick(event);
    }
  };

  render() {
    return React.Children.only(this.props.children);
  }
}
