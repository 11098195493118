import React from 'react';
import type { MouseEvent } from 'react';
import _ from 'lodash';

const DELAY_BETWEEN_CLICKS = 1000;

export interface WithDoubleClickPrevention {
  setClickTimeStamp: (event: MouseEvent) => void;
  isAnotherMouseActionAllowed: (event: MouseEvent) => boolean;
}

const withDoubleClickPrevention = <P>(
  Component: React.ComponentType<P & WithDoubleClickPrevention>,
): React.ComponentType<P> => {
  class DoubleClickPrevention extends React.Component<P> {
    constructor(props: AnyFixMe) {
      super(props);

      this.clickTimestamp = null;
      this.setClickTimeStamp = this.setClickTimeStamp.bind(this);
      this.isAnotherMouseActionAllowed =
        this.isAnotherMouseActionAllowed.bind(this);
    }

    private clickTimestamp: number;

    setClickTimeStamp(e: AnyFixMe) {
      this.clickTimestamp = e.timeStamp;
    }

    isAnotherMouseActionAllowed(e: AnyFixMe) {
      return (
        !this.clickTimestamp ||
        e.timeStamp - this.clickTimestamp > DELAY_BETWEEN_CLICKS
      );
    }

    render() {
      const { props } = this;

      return React.createElement(
        Component,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/assign
        _.assign({}, props, {
          setClickTimeStamp: this.setClickTimeStamp,
          isAnotherMouseActionAllowed: this.isAnotherMouseActionAllowed,
        }),
      );
    }
  }

  return DoubleClickPrevention;
};

export default withDoubleClickPrevention;
