// @ts-nocheck
import _ from 'lodash';
import santaCoreUtils from '@wix/santa-core-utils';
import * as platformEvents from 'platformEvents';

const { eventsMap } = santaCoreUtils;

const isMediaBoxRoot = ({ componentType }) =>
  componentType === 'wysiwyg.viewer.components.MediaBox';
const isAppWidget = ({ componentType }) =>
  componentType === 'platform.components.AppWidget';
const isRefHost = ({ componentType }) =>
  componentType === 'wysiwyg.viewer.components.RefComponent';
const getOriginalControllerDataId = (masterStructure) =>
  isAppWidget(masterStructure) ? masterStructure?.data?.id : undefined;

const getPrimaryConnectionItem = (connectionItems) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  _.find(connectionItems, { isPrimary: true });
const isWixCodeBehaviorItem = (behaviorItem) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  _.includes(['runCode', 'runAppCode'], behaviorItem?.behavior?.name);
const getWixCodeConnectionItem = ({ connections }) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  _.find(connections.items, { type: 'WixCodeConnectionItem' });
const findConnectionByControllerId = (controllerId, connectionItems) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  _.find(connectionItems, { controllerId }) || {};

const findConnectionInDataOverrides = (controllerId, compDef) => {
  const { overriddenData } = compDef?.custom ?? {};
  if (_.isEmpty(overriddenData)) {
    return;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const connectionsItem = _.find(overriddenData, { itemType: 'connections' });
  return findConnectionByControllerId(
    controllerId,
    connectionsItem.dataItem.items,
  );
};

const getConnectionByControllerId = (controllerId, compDef) => {
  if (isRefHost(compDef)) {
    return findConnectionInDataOverrides(controllerId, compDef);
  }

  return findConnectionByControllerId(controllerId, compDef.connections?.items);
};

const isAffectedByModes = (compDescriptor) =>
  !_.isEmpty(compDescriptor.modesVisibility);
const getVisibleModes = (modesVisibility) =>
  _(modesVisibility).pickBy().keys().value();
const buildModeReplaceMap = (oldModeDefinitions, newModeDefinitions) =>
  _.mapValues(
    oldModeDefinitions,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/find
    (modeType) => _.find(newModeDefinitions, { type: modeType })?.modeId,
  );
const getModeOverridesWithFixedIds = (overrides, modesReplaceMap) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  _.map(overrides, (override) =>
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign({}, override, {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/map
      modeIds: _.map(override.modeIds, (id) => modesReplaceMap[id]),
    }),
  );

const getMissingComponentDefinitions = (
  dsRead,
  masterStructure,
  controllerRef,
) => {
  const existingConnectionsMap = getExistingConnectionsMap(
    dsRead,
    controllerRef,
  );
  const getConnection = getFindNicknameConnectionFu(masterStructure);
  const getRemovedComponentsCore = (compDef, removedComps, isRoot = false) => {
    const { role } = getConnection(compDef);
    if (isRoot || isAppWidget(compDef) || existingConnectionsMap[role]) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(compDef.components, (childComp) =>
        getRemovedComponentsCore(childComp, removedComps),
      );
    } else {
      removedComps.push(compDef);
    }
  };

  const result = [];
  getRemovedComponentsCore(masterStructure, result, true);

  return result;
};

const getExistingConnectionsMap = (dsRead, controllerRef) => {
  const currentConnections =
    dsRead.platform.controllers.connections.getControllerConnections(
      controllerRef,
    );
  return _.keyBy(currentConnections, 'connection.role');
};

const buildComponentsDescriptorMap = (widgetStructure) => {
  const parentsMap = {};
  const getConnection = getFindNicknameConnectionFu(widgetStructure);
  return reduceByKey(
    widgetStructure,
    'components',
    (descriptorMap, component) => {
      const { role } = getConnection(component);

      const modesStructure = getModesDefinitionsAndVisibility(
        component,
        role,
        parentsMap,
        descriptorMap,
      );

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      descriptorMap[role] = _.assign(
        {
          role,
          parent: parentsMap[role] || null,
        },
        modesStructure,
      );

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(component.components, (childComp) => {
        const childRole = getConnection(childComp).role;
        parentsMap[childRole] = role;
      });

      return descriptorMap;
    },
  );
};

const fixModeOverridesAndBehaviors = (
  dsRead,
  dsActions,
  compStructure,
  controllerRef,
  componentRole,
  containerRef,
  componentsDescriptorsMap,
) => {
  let shouldApplyToAllModes = false;

  const compDescriptor = componentsDescriptorsMap[componentRole];

  const { modesContainerRef, modesReplaceMap } =
    getAncestorModesContainerAndModeIdReplaceMap(
      dsRead,
      controllerRef,
      componentRole,
      compDescriptor,
      componentsDescriptorsMap,
    );
  if (modesContainerRef && modesReplaceMap) {
    compStructure.behaviors.items = getBehaviorsItemsWithFixedModeIds(
      compStructure,
      modesReplaceMap,
    );
    compStructure.modes.overrides = getModeOverridesWithFixedIds(
      compStructure.modes.overrides,
      modesReplaceMap,
    );

    const visibleModes = getVisibleModes(compDescriptor.modesVisibility);
    shouldApplyToAllModes = visibleModes.length > 1;

    const firstVisibleModeId = modesReplaceMap[_.head(visibleModes)];

    return {
      preAction: () => {
        if (modesContainerRef && firstVisibleModeId) {
          dsActions.components.modes.activateComponentMode(
            modesContainerRef,
            firstVisibleModeId,
          );
        }
      },
      compStructure,
      postAction: (addedCompRef) => {
        if (shouldApplyToAllModes) {
          dsActions.components.modes.applyCurrentToAllModes(addedCompRef);
        }
      },
    };
  }

  return {
    preAction: _.noop,
    compStructure,
    postAction: _.noop,
  };
};

const fixConnectedComponentDefinition = (
  originalControllerDataId,
  dsRead,
  dsActions,
  componentStructure,
  controllerRef,
) => {
  const { id: controllerDataId } = dsRead.components.data.get(controllerRef);
  const { componentDefinition } = getStructureAndBehaviors(
    componentStructure,
    controllerDataId,
    originalControllerDataId,
  );

  return {
    preAction: _.noop,
    compStructure: componentDefinition,
    postAction: _.noop,
  };
};

const fixLayout = (
  dsRead,
  dsActions,
  compStructure,
  controllerRef,
  componentRole,
  containerRef,
) => {
  const containerLayout = dsRead.components.layout.get(containerRef);

  return {
    preAction: _.noop,
    compStructure: constrainLayoutToContainer(compStructure, containerLayout),
    postAction: _.noop,
  };
};

const getStructureAndParentByRole = (
  dsRead,
  role,
  controllerRef,
  widgetStructure,
  container = null,
  getConnections,
) => {
  if (widgetStructure) {
    if (getConnections(widgetStructure).role === role) {
      const containerRef = getCompRefFromRole(
        dsRead,
        controllerRef,
        getConnections(container).role,
      );
      return { compStructure: widgetStructure, containerRef };
    }

    if (widgetStructure.components) {
      for (let i = 0; i < widgetStructure.components.length; i++) {
        const result = getStructureAndParentByRole(
          dsRead,
          role,
          controllerRef,
          widgetStructure.components[i],
          widgetStructure,
          getConnections,
        );
        if (result) {
          return result;
        }
      }
    }
  }
  return null;
};

const runAddComponentPlugins = (
  dsRead,
  dsActions,
  plugins,
  origCompStructure,
  controllerRef,
  componentRole,
  containerRef,
  componentsDescriptorsMap,
) => {
  const preAddActions = [];
  const postAddActions = [];

  let compStructure = _.cloneDeep(origCompStructure);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(plugins, (plugin) => {
    const {
      preAction,
      postAction,
      compStructure: fixedStructure,
    } = plugin(
      dsRead,
      dsActions,
      compStructure,
      controllerRef,
      componentRole,
      containerRef,
      componentsDescriptorsMap,
    );
    compStructure = fixedStructure;
    preAddActions.push(preAction);
    postAddActions.push(postAction);
  });

  return {
    preAddActions,
    compStructure,
    postAddActions,
  };
};

const runActions = (actionsArray, ...args) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(actionsArray, (action) => {
    action(...args);
  });
};

const getFindNicknameConnectionFu = (masterStructure) => {
  if (isAppWidget(masterStructure)) {
    const controllerDataId = masterStructure?.data?.id;
    return _.partial(getConnectionByControllerId, controllerDataId);
  }
  return getWixCodeConnectionItem;
};

const reduceByKey = (root = {}, key, iteratee, accumulator = {}) => {
  const items = [...(root[key] || [])];

  while (items.length > 0) {
    const currentItem = items.shift();

    items.push(...(currentItem[key] || []));
    accumulator = iteratee(accumulator, currentItem);
  }

  return accumulator;
};

const getModesDefinitionsAndVisibility = (
  component,
  role,
  parentsMap,
  descriptorMap,
) => {
  let modeDefinitions;
  let modesVisibility;

  if (component.modes) {
    if (!_.isEmpty(component.modes.definitions)) {
      modeDefinitions = _(component.modes.definitions)
        .keyBy('modeId')
        .mapValues('type')
        .value();
    }

    const visibleByDefault = !component.modes.isHiddenByModes;

    const ancestorModes = getAncestorModesIfExists(
      parentsMap[role],
      parentsMap,
      descriptorMap,
    );

    if (!_.isEmpty(ancestorModes)) {
      modesVisibility = _.mapValues(ancestorModes, () => visibleByDefault);

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
      _.forEach(component.modes.overrides, (override) => {
        const visibleInMode = !override.isHiddenByModes;

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
        _.forEach(override.modeIds, (modeId) => {
          modesVisibility[modeId] = visibleInMode;
        });
      });
    }
  }

  return {
    modeDefinitions,
    modesVisibility,
  };
};

const getAncestorModesContainerAndModeIdReplaceMap = (
  dsRead,
  controllerRef,
  componentRole,
  compDescriptor,
  componentsDescriptorsMap,
) => {
  if (isAffectedByModes(compDescriptor)) {
    const ancestorModesContainerDescriptor =
      getAncestorModesContainerDescriptor(
        componentRole,
        componentsDescriptorsMap,
      );

    if (ancestorModesContainerDescriptor) {
      const modesContainerRef = getCompRefFromRole(
        dsRead,
        controllerRef,
        ancestorModesContainerDescriptor.role,
      );

      if (!modesContainerRef) {
        return {};
      }

      const modes = dsRead.components.modes.getModes(modesContainerRef);

      if (modesContainerRef && !_.isEmpty(modes)) {
        const modesReplaceMap = buildModeReplaceMap(
          ancestorModesContainerDescriptor.modeDefinitions,
          modes,
        );

        return {
          modesContainerRef,
          modesReplaceMap,
        };
      }
    }
  }

  return {};
};

const getBehaviorsItemsWithFixedModeIds = (compStructure, modesReplaceMap) => {
  const { behaviors } = compStructure;

  if (behaviors.items) {
    const parsedItems = JSON.parse(behaviors.items);

    parsedItems.forEach((item) => {
      if (item.params?.modeIds) {
        item.params.modeIds = item.params.modeIds.map(
          (id) => modesReplaceMap[id],
        );
      }
    });

    return JSON.stringify(parsedItems);
  }
};

const updateOverriddenConnectionItemsControllerId = (
  connectionItems,
  controllerDataId,
  originalControllerDataId,
) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(connectionItems, (connectionItem) => {
    if (connectionItem.controllerId === originalControllerDataId) {
      connectionItem.controllerId = controllerDataId;
    }
  });

const updateConnectionOverrides = (
  overriddenData,
  controllerDataId,
  originalControllerDataId,
) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(overriddenData, (overrideItem) => {
    if (overrideItem.itemType === 'connections') {
      updateOverriddenConnectionItemsControllerId(
        overrideItem.dataItem.items,
        controllerDataId,
        originalControllerDataId,
      );
    }
  });

const getStructureAndBehaviors = (
  originalStructure,
  controllerDataId,
  originalControllerDataId,
) => {
  const behaviors = [];
  const componentDefinition = _.cloneDeep(originalStructure);
  const widgetStructureRoot = getWidgetStructureRoot(componentDefinition);

  if (componentDefinition.layout?.docked) {
    delete componentDefinition.layout.docked;
  }

  if (componentDefinition?.mobileStructure?.layout?.docked) {
    delete componentDefinition.mobileStructure.layout.docked;
  }

  if (componentDefinition?.mobileStructure?.layout) {
    componentDefinition.mobileStructure.layout.x = 0;
    componentDefinition.mobileStructure.layout.y = 0;
  }

  if (isMediaBoxRoot(widgetStructureRoot)) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    widgetStructureRoot.props = _.assign({}, widgetStructureRoot.props, {
      type: 'MediaBoxProperties',
      allowOverflowContent: true,
    });
    widgetStructureRoot.style =
      widgetStructureRoot.style === 'mc1' ? 'mb1' : widgetStructureRoot.style;
  }

  const createConnectionItem = ({ role }) => ({
    role,
    controllerId: controllerDataId,
    type: 'ConnectionItem',
    isPrimary: true,
  });
  const setCompsConnectionsAndBehaviors = (comp) => {
    let wixCodeConnectionItem;
    if (comp?.components) {
      comp.components.forEach(setCompsConnectionsAndBehaviors);
    }

    let role;
    if (comp?.connections) {
      wixCodeConnectionItem = getWixCodeConnectionItem(comp);
      if (originalControllerDataId) {
        role = getConnectionByControllerId(originalControllerDataId, comp).role;
        comp.connections.items = fixCompConnections(
          comp.connections.items,
          originalControllerDataId,
          controllerDataId,
        );
        if (wixCodeConnectionItem) {
          comp.connections.items = _.reject(
            comp.connections.items,
            wixCodeConnectionItem,
          );
        }
      } else if (wixCodeConnectionItem) {
        role = wixCodeConnectionItem.role;
        comp.connections.items = _.reject(
          comp.connections.items,
          wixCodeConnectionItem,
        ).concat(createConnectionItem(wixCodeConnectionItem));
      }
    }

    if (comp?.behaviors) {
      const [codeItems, otherItems] = _.partition(
        JSON.parse(comp.behaviors.items),
        isWixCodeBehaviorItem,
      );
      behaviors.push(
        ...codeItems.map(({ action, behavior }) => ({
          role,
          event: eventsMap[action.name],
          callbackId: behavior.params.callbackId,
        })),
      );

      if (otherItems.length) {
        comp.behaviors.items = JSON.stringify(otherItems);
      } else {
        delete comp.behaviors;
      }
    }

    if (isRefHost(comp)) {
      const dataOverrides = comp?.custom?.overriddenData;
      updateConnectionOverrides(
        dataOverrides,
        controllerDataId,
        originalControllerDataId,
      );
    }
  };

  setCompsConnectionsAndBehaviors(componentDefinition);

  return {
    behaviors,
    componentDefinition,
  };
};

const constrainLayoutToContainer = (componentDefinition, containerLayout) => {
  const compLayout = componentDefinition.layout;
  if (
    compLayout.x + compLayout.width > containerLayout.width ||
    compLayout.y + compLayout.height > containerLayout.height
  ) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    const constrainedCompLayout = _.assign({}, compLayout, { x: 0, y: 0 });
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign({}, componentDefinition, { layout: constrainedCompLayout });
  }

  return componentDefinition;
};

const getCompRefFromRole = (dsRead, componentRef, role) => {
  const isRoleExists = (compRef) => {
    const connections = dsRead.platform.controllers.connections.get(compRef);
    return getPrimaryConnectionItem(connections)?.role === role;
  };

  if (isRoleExists(componentRef)) {
    return componentRef;
  }
  const childrenRefs =
    dsRead.deprecatedOldBadPerformanceApis.components.getChildren(
      componentRef,
      true,
    );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  return _.find(childrenRefs, (childRef) => isRoleExists(childRef));
};

const getAncestorModesIfExists = (role, parents, descriptorMap) => {
  if (!role) {
    return null;
  }

  if (descriptorMap[role].modeDefinitions) {
    return descriptorMap[role].modeDefinitions;
  }

  return getAncestorModesIfExists(parents[role], parents, descriptorMap);
};

const getAncestorModesContainerDescriptor = (role, componentsMetaDataMap) => {
  const visibleModes = getVisibleModes(
    componentsMetaDataMap[role].modesVisibility,
  );
  const modeIdInOverride = _.head(visibleModes);

  if (!modeIdInOverride) {
    return null;
  }

  let currComp = componentsMetaDataMap[componentsMetaDataMap[role].parent];

  while (currComp) {
    if (currComp?.modeDefinitions?.[modeIdInOverride]) {
      return currComp;
    }
    currComp = componentsMetaDataMap[currComp.parent];
  }

  return null;
};

const getWidgetStructureRoot = (componentDefinition) => {
  if (isAppWidget(componentDefinition)) {
    return _.head(componentDefinition.components);
  }
  return componentDefinition;
};

const fixCompConnections = (
  connectionItems,
  originalControllerDataId,
  controllerDataId,
) =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  _.map(connectionItems, (item) => {
    if (item.controllerId === originalControllerDataId) {
      item.controllerId = controllerDataId;
    }
    return item;
  });

const getAddableComponents = (dsRead, controllerRef, masterStructure) => {
  if (!masterStructure) {
    return [];
  }
  const removedStructures = getMissingComponentDefinitions(
    dsRead,
    masterStructure,
    controllerRef,
  );
  const getConnection = getFindNicknameConnectionFu(masterStructure);

  return removedStructures.map((structure) => getConnection(structure).role);
};

const getGhostRefComponents = (dsRead, componentPointer) => {
  const refHostCompPointer =
    dsRead.components.refComponents.getRefHostCompPointer(componentPointer);
  const allGhostConnections =
    dsRead.components.refComponents.getAllGhostRefComponentsPrimaryConnection(
      refHostCompPointer,
    );
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/map
  return _.map(allGhostConnections, (connectionItem, key) => ({
    role: connectionItem.role,
    compPointer: dsRead.components.get.byId(key),
  }));
};

const addComponent = (
  dsRead,
  dsActions,
  controllerRef,
  componentRole,
  masterStructure,
) => {
  const addableComponents = getAddableComponents(
    dsRead,
    controllerRef,
    masterStructure,
  );
  const componentsDescriptorsMap =
    buildComponentsDescriptorMap(masterStructure);
  const masterControllerDataId = getOriginalControllerDataId(masterStructure);
  const structurePreperationsPlugins = [
    fixModeOverridesAndBehaviors,
    _.partial(fixConnectedComponentDefinition, masterControllerDataId),
    fixLayout,
  ];

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  if (_.includes(addableComponents, componentRole)) {
    const { compStructure: origCompStructure, containerRef } =
      getStructureAndParentByRole(
        dsRead,
        componentRole,
        controllerRef,
        masterStructure,
        null,
        getFindNicknameConnectionFu(masterStructure),
      );

    const { preAddActions, compStructure, postAddActions } =
      runAddComponentPlugins(
        dsRead,
        dsActions,
        structurePreperationsPlugins,
        origCompStructure,
        controllerRef,
        componentRole,
        containerRef,
        componentsDescriptorsMap,
      );

    runActions(preAddActions);
    const addedCompRef = dsActions.components.add(containerRef, compStructure);
    runActions(postAddActions, addedCompRef);

    dsActions.history.add('Element added', { isAddingComponent: true });
  }
};

const addGhostComponent = (dsRead, dsActions, controllerRef, componentPtr) => {
  dsActions.components.refComponents.unGhostifyComponent(componentPtr);

  dsActions.history.add('Element added', { isAddingComponent: true });
};

const shouldWidgetHaveReset = (dsRead, controllerRef) => {
  const appDefinitionId =
    dsRead.components.data.get(controllerRef)?.applicationId;
  const appData = dsRead.tpa.app.getDataByAppDefId(appDefinitionId);

  return _.has(appData, ['appFields', 'platform', 'studio']);
};

const notifyAppOnAddComponent = (editorAPI, applicationId, widgetRef, comp) => {
  editorAPI.dsActions.platform.notifyApplication(
    applicationId,
    platformEvents.factory.addElementsCompClicked({
      label: comp.label,
      widgetRef,
      isEssential: comp.isEssential,
    }),
  );
};

const notifyAppOnAddAllComponent = (editorAPI, applicationId, widgetRef) => {
  editorAPI.dsActions.platform.notifyApplication(
    applicationId,
    platformEvents.factory.addElementsAllCompsClicked({
      widgetRef,
    }),
  );
};

const notifyAppOnReset = (editorAPI, applicationId, widgetRef) => {
  editorAPI.dsActions.platform.notifyApplication(
    applicationId,
    platformEvents.factory.addElementsResetClicked({
      widgetRef,
    }),
  );
};

export {
  constrainLayoutToContainer,
  buildComponentsDescriptorMap,
  getStructureAndBehaviors,
  getMissingComponentDefinitions,
  getAddableComponents,
  addComponent,
  shouldWidgetHaveReset,
  getGhostRefComponents,
  addGhostComponent,
  notifyAppOnAddComponent,
  notifyAppOnAddAllComponent,
  notifyAppOnReset,
};
