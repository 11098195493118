import type { BaseEntryScope } from './scope';
import * as reactRedux from 'react-redux';
import { memo } from './utils';
import React from 'react';
import { hoc, createPropsDeepEqual } from '@wix/santa-editor-utils';

export const StoreContext =
  React.createContext<reactRedux.ReactReduxContextValue | null>(null);

interface ConnectFn {
  <SC, P, ST extends Partial<P> = {}, DT extends Partial<P> = {}>(
    getScopeFactory: () => new () => SC,
    Component: React.ComponentType<P>,
    mapStateToProps: MapStateToProps<SC, ST>,
    mapDispatchToProps: MapDispatchToProps<SC, DT>,
    options?: {
      rerenderOnMouseOps: boolean;
    },
  ): React.ComponentType<Omit<P, keyof ST | keyof DT>>;
}

type MapStateToProps<SC, R> = (scope: SC, ownProps?: any) => R;
type MapDispatchToProps<SC, R> = (scope: SC, ownProps?: any) => R;

export const ScopeRefMapping = new WeakMap<
  new () => BaseEntryScope,
  BaseEntryScope
>();

function makeMapper(originalMapper: Function, getScope: () => BaseEntryScope) {
  if (!originalMapper) {
    return;
  }
  if (originalMapper.length === 2) {
    return function (_store: AnyFixMe, ownProps: AnyFixMe) {
      return originalMapper(getScope(), ownProps);
    };
  }

  return function (_store: AnyFixMe) {
    return originalMapper(getScope());
  };
}

export const DragInProgressPath = {
  EntrypointName: 'EditorApi',
  EntrypointReducer: 'editor',
  subreducer: 'mouseActions',
  property: 'dragInProgress',
} as const;

export const connectWithScope: ConnectFn = (
  getScopeFactory: () => new () => any,
  Component: React.ComponentType<any>,
  _mapStateToProps?: any,
  _mapDispatchToProps?: any,
  options?: {
    rerenderOnMouseOps: boolean;
  },
) => {
  const propsDeepEqual = createPropsDeepEqual(Component.displayName);

  const getScope = memo(() => {
    const factory = getScopeFactory();
    if (!factory) {
      throw new Error(
        '() => Scope returns no scope, mb due to circular execution',
      );
    }
    const scope = ScopeRefMapping.get(factory);
    return scope;
  });

  const ConnectedComponent = reactRedux.connect(
    makeMapper(_mapStateToProps, getScope),
    makeMapper(_mapDispatchToProps, getScope),
    null,
    {
      areStatesEqual(next, prev) {
        if (options?.rerenderOnMouseOps === true) {
          return next === prev;
        }

        const nextIsDrag =
          next?.[DragInProgressPath.EntrypointName]?.[
            DragInProgressPath.EntrypointReducer
          ]?.[DragInProgressPath.subreducer]?.[DragInProgressPath.property];
        const prevIsDrag =
          prev?.[DragInProgressPath.EntrypointName]?.[
            DragInProgressPath.EntrypointReducer
          ]?.[DragInProgressPath.subreducer]?.[DragInProgressPath.property];

        if (nextIsDrag && prevIsDrag) {
          return true;
        }

        return next === prev;
      },
      context: StoreContext,
      areStatePropsEqual: propsDeepEqual,
      areOwnPropsEqual: propsDeepEqual,
      forwardRef: true,
    },
  )(Component);

  return hoc.errorBoundaryComponent(ConnectedComponent) as any;
};

export type InferComponentProps<
  S extends MapStateToProps<any, any>,
  D extends MapDispatchToProps<any, any>,
  O,
> = ReturnType<S> & ReturnType<D> & O;
