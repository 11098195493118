import { UserData } from './types';
import { DEFAULT_INDUSTRY_ID, DEFAULT_STRUCTURE_ID } from './caas/caasConsts';

export enum Providers {
  CAAS = 'caas',
}

export const TEMP_USER_DATA: UserData = {
  businessName: 'businessName',
  businessTypeName: 'businessTypeName',
  industryId: DEFAULT_INDUSTRY_ID,
  structureId: DEFAULT_STRUCTURE_ID,
  email: 'my@email.com',
  address: {
    googleFormattedAddress:
      '500 Terry Francois Street, 6th Floor. San Francisco, CA 94158',
  },
  phoneNumber: '123-456-7890',
  socialLinks: [],
};

export enum ContentKind {
  TEXT = 'text',
  MEDIA = 'media',
  ALL = 'all',
}

export const languageCodeToLanguageName: { [languageCode: string]: string } = {
  de: 'German',
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  it: 'Italian',
  ja: 'Japanese',
  ko: 'Korean',
  pl: 'Polish',
  ru: 'Russian',
  nl: 'Dutch',
  tr: 'Turkish',
  sv: 'Swedish',
  pt: 'Portuguese',
  no: 'Norwegian',
  da: 'Danish',
  hi: 'Hindi',
  zh: 'Chinese',
  cs: 'Czech',
  th: 'Thai',
  uk: 'Ukrainian',
  vi: 'Vietnamese',
  id: 'Indonesian',
};
