export default {
  ADD_MENU_CATEGORY_CLICK: {
    evid: 102,
    fields: {
      category: 'string',
      category_under_scroll: 'boolean',
      isScrollable: 'boolean',
      window_width: 'numeric',
      window_height: 'numeric',
    },
  },
  ADD_MENU_CATEGORY_SELECT: {
    evid: 715,
    fields: {
      category: 'string',
      origin: 'string',
      is_vertical_installed: 'boolean',
      category_under_scroll: 'boolean',
      isScrollable: 'boolean',
      window_width: 'numeric',
      window_height: 'numeric',
    },
  },
  ADD_MENU_HELP_CATEGORY_CLICK: {
    evid: 104,
    fields: {
      category: 'string',
    },
  },
  ADD_MENU_MAX_CATEGORY_SCROLL: {
    evid: 111,
    fields: {
      max_hover_item: 'numeric',
      section: 'string',
      category: 'string',
    },
  },
  ADD_MENU_MORE_CLICK: {
    evid: 112,
    fields: {
      section: 'string',
      category: 'string',
    },
  },
  ADD_MENU_SOCIAL_SETTINGS_CLICK: {
    evid: 114,
    fields: {},
  },
  ADD_MENU_INNER_NAVIGATION_OPENED_CLICK: {
    evid: 117,
    fields: {
      category: 'string',
    },
  },
  ADD_MENU_INNER_NAVIGATION_OPENED_AUTOMATICALLY: {
    evid: 118,
    fields: {
      category: 'string',
    },
  },
  ADD_MENU_INNER_NAVIGATION_CLOSE_CLICK: {
    evid: 119,
    fields: {
      category: 'string',
    },
  },
  ADD_MENU_INNER_NAVIGATION_CHOOSE_SECTION_CLICK: {
    evid: 121,
    fields: {
      section: 'string',
      category: 'string',
    },
  },
  ADD_MENU_DRAG_COMPONENT: {
    evid: 122,
    fields: {
      section: 'string',
      category: 'string',
    },
  },
  COMPONENT_ADDED_TO_STAGE: {
    evid: 123,
    fields: {
      app_id: 'guid',
      origin: 'string',
      component_type: 'string',
      component_id: 'string',
      component_name: 'string',
      adding_method: 'string',
      preset_data_skin: 'string',
      preset_data_tags: 'string',
      section: 'string',
      category: 'string',
      page_id: 'string',
      target_component: 'string',
      is_target_repeated: 'boolean',
      properties: 'string',
      json_components_presets: 'string',
      non_page_top_parent_component_id: 'string',
      non_page_top_parent_component_type: 'string',
      target_component_id: 'string',
      app_list: 'string',
    },
  },
  ADD_MENU_IMAGE_SECTION_BUTTON_CLICK: {
    evid: 125,
    fields: {
      section: 'string',
      sub_section: 'string',
    },
  },
  SITE_APP_INSTALL_AGREE_CLICK: {
    evid: 193,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_id: 'string',
      app_name: 'string',
      origin: 'string',
    },
  },
  INSTALL_FOUND_OUT_MORE_CLICK: {
    evid: 195,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_id: 'string',
      app_name: 'string',
    },
  },
  DATA_MODE_ON_ACTIVATION_CLICK: {
    evid: 818,
    src: 38,
    fields: {
      origin: 'string',
      status: 'boolean',
      entry_point: 'string',
    },
  },
  HOVER_SECTION_INFO: {
    evid: 674,
    src: 38,
    fields: {
      section: 'string',
      category: 'string',
    },
  },
  ADD_MENU_SUB_CATEGORY_SELECT: {
    evid: 955,
    src: 38,
    fields: {
      category: 'string',
      sub_category: 'string',
    },
  },
  ADD_MENU_SUB_CATEGORY_CLICK: {
    evid: 953,
    src: 38,
    fields: {
      category: 'string',
      sub_category: 'string',
    },
  },
  DEV_MODE_TOGGLE: {
    evid: 1075,
    src: 38,
    fields: {
      status: 'boolean',
      editor_view_mode: 'string',
      origin: 'string',
      is_data_on: 'boolean',
      is_first: 'boolean',
    },
  },
  WIDGET_INSTALLED_AFTER_COMPONENT_ADDED_TO_STAGE: {
    evid: 1114,
    fields: {
      app_id: 'guid',
      origin: 'string',
      component_type: 'string',
      component_id: 'string',
      adding_method: 'string',
      preset_data_skin: 'string',
      preset_data_tags: 'string',
      section: 'string',
      category: 'string',
      page_id: 'string',
      target_component: 'string',
      is_target_repeated: 'boolean',
      properties: 'string',
    },
  },
} as const;
