import { createApiCallsDebugger } from '@wix/api-debug-tools';
import styles from './dsDebugger.scss';

export function createDSDebugger() {
  const container = Object.assign(document.createElement('div'), {
    className: styles.container,
  });

  document.body.appendChild(container);

  return createApiCallsDebugger({
    uiRoot: container,
  });
}
