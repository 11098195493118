export enum AddPanelSectionTypes {
  THEME = 'SECTIONS_TYPES_THEME',
  PERSONALIZED = 'SECTIONS_TYPES_PERSONALIZED',
  PRESET = 'SECTIONS_TYPES_PRESET',
  PRESET_LIST = 'SECTIONS_TYPES_PRESET_LIST',
  IMAGE = 'SECTIONS_TYPES_IMAGE',
  DATA = 'SECTIONS_TYPES_DATA',
  GENERIC_LIST_SECTION = 'SECTIONS_TYPES_GENERIC_LIST_SECTION',
  GENERAL = 'SECTIONS_TYPES_GENERAL',
  WELCOME = 'SECTIONS_TYPES_WELCOME',
  PAGE = 'ADD_PAGES_SECTION',
  RESPONSIVE_PRESET_MANAGER = 'RESPONSIVE_PRESET_MANAGER',
  DYNAMIC_MEDIA_SECTIONS_BOX = 'DYNAMIC_MEDIA_SECTIONS_BOX',
  DESIGN_DATA_PRESET = 'SECTIONS_TYPES_DESIGN_DATA_PRESET',
  DESIGN_DATA_THEME_PRESET = 'SECTIONS_TYPES_DESIGN_DATA_THEME_PRESET',
  PERSONALIZED_DESIGN = 'SECTIONS_TYPES_PERSONALIZED_DESIGN',
  ACCOUNT_SETTINGS_LIST = 'ACCOUNT_SETTINGS_LIST',
  TOGGLES_LIST = 'TOGGLES_LIST',
}
