import {
  Accordion,
  Dropdown,
  Search,
  Repeater,
  Paypal,
  AppMarket,
  ImageGallery,
  FullWidthSlider,
  Lightbox,
  Close,
  Table,
  HorizontalMenu,
  VerticalMenu,
  SocialBar,
  ESignature,
  Section,
  Text,
  Button,
  ImageButton,
  UploadButton,
  Music,
  File,
  SlideShow,
  Shape,
  HorizontalLine,
  VerticalLine,
  VideoBox,
  HoverBox,
  MultiStateBox,
  Tabs,
  ProgressBar,
  Pagination,
  Strip,
  Column,
  Video,
  Sound,
  Anchor,
  MapPin,
  Html,
  Input,
  TextBox,
  RichTextBox,
  RadioButton,
  CheckboxList,
  Checkbox,
  Tag,
  Calendar,
  TimeInput,
  Slider,
  Star,
  AddressInput,
  Switch,
  Captcha,
  PageBody,
  Header,
  Footer,
  Lottie,
  Globe,
  Border,
  Facebook,
  Pinterest,
  LineShare,
  Youtube,
  Twitter,
  Box,
  PageAdd,
  MenuFolder,
  UrlLink,
  PageDynamic,
  Page,
  Menu,
} from '@wix/wix-ui-icons-common/classic-editor';
import { WidgetCanvas } from '@wix/wix-ui-icons-common/responsive-editor';
import type React from 'react';

const wixBaseUiIcons: { [key: string]: React.FC } = {
  Accordion,
  Dropdown,
  Search,
  Repeater,
  Paypal,
  AppMarket,
  ImageGallery,
  FullWidthSlider,
  Lightbox,
  Close,
  Table,
  HorizontalMenu,
  VerticalMenu,
  SocialBar,
  ESignature,
  Section,
  VideoBox,
  HoverBox,
  MultiStateBox,
  Tabs,
  ProgressBar,
  Pagination,
  Strip,
  Column,
  Video,
  Sound,
  Anchor,
  MapPin,
  Html,
  Input,
  TextBox,
  RichTextBox,
  RadioButton,
  CheckboxList,
  Checkbox,
  Tag,
  Calendar,
  TimeInput,
  Slider,
  Star,
  AddressInput,
  Switch,
  Captcha,
  PageBody,
  Header,
  Footer,
  Lottie,
  Globe,
  Text,
  Button,
  ImageButton,
  UploadButton,
  Music,
  File,
  SlideShow,
  Shape,
  HorizontalLine,
  VerticalLine,
  Border,
  Facebook,
  Pinterest,
  LineShare,
  Youtube,
  Twitter,
  Box,
  PageAdd,
  MenuFolder,
  UrlLink,
  PageDynamic,
  Page,
  Menu,
};
const wixBaseUiResponsiveIcons: { [key: string]: React.FC } = { WidgetCanvas };

const WBU = 'WBU_';
const WBUR = 'WBUR_';

const getWBUIcon = (nameWithPrefix: string) => {
  if (nameWithPrefix.startsWith(WBU)) {
    return wixBaseUiIcons[nameWithPrefix.replace(WBU, '')];
  } else if (nameWithPrefix.startsWith(WBUR)) {
    return wixBaseUiResponsiveIcons[nameWithPrefix.replace(WBUR, '')];
  }
  return null;
};

const toWBU = (name: string) => {
  return `${WBU}${name}`;
};
const toWBUR = (name: string) => {
  return `${WBUR}${name}`;
};

export { getWBUIcon, toWBU, toWBUR };
