[
  {
    "isDefault": true,
    "value": {
      "fixedPosition": false,
      "scrollType": "stays_on_top"
    },
    "desktop": {
      "label": "desktop_headerscroll_panel_scrolls_site_label",
      "sources": {
        "paused": "desktop-header-scroll-paused.gif",
        "playing": "desktop-header-scroll.gif",
        "selectedPaused": "desktop-header-scroll-selected-paused.gif",
        "selectedPlaying": "desktop-header-scroll-selected.gif"
      }
    },
    "mobile": {
      "label": "mobile_headerscroll_panel_scrolls_site_label",
      "sources": {
        "paused": "mobile-header-scroll-paused.gif",
        "playing": "mobile-header-scroll.gif",
        "selectedPaused": "mobile-header-scroll-selected-paused.gif",
        "selectedPlaying": "mobile-header-scroll-selected.gif"
      }
    }
  },
  {
    "value": {
      "fixedPosition": true,
      "scrollType": "freezes"
    },
    "desktop": {
      "label": "desktop_headerscroll_panel_freezes_label",
      "sources": {
        "paused": "desktop-header-fixed-paused.gif",
        "playing": "desktop-header-fixed.gif",
        "selectedPaused": "desktop-header-fixed-selected-paused.gif",
        "selectedPlaying": "desktop-header-fixed-selected.gif"
      }
    },
    "mobile": {
      "label": "mobile_headerscroll_panel_freezes_label",
      "sources": {
        "paused": "mobile-header-fixed-paused.gif",
        "playing": "mobile-header-fixed.gif",
        "selectedPaused": "mobile-header-fixed-selected-paused.gif",
        "selectedPlaying": "mobile-header-fixed-selected.gif"
      }
    }
  },
  {
    "value": {
      "fixedPosition": true,
      "animationName": "HeaderHideToTop",
      "scrollType": "disappears"
    },
    "desktop": {
      "behaviorName": "AnimateAfterScroll",
      "label": "desktop_headerscroll_panel_disappears_label",
      "sources": {
        "paused": "desktop-header-hide-on-scroll-paused.gif",
        "playing": "desktop-header-hide-on-scroll.gif",
        "selectedPaused": "desktop-header-hide-on-scroll-selected-paused.gif",
        "selectedPlaying": "desktop-header-hide-on-scroll-selected.gif"
      }
    },
    "mobile": {
      "behaviorName": "ScrubAnimation",
      "label": "mobile_headerscroll_panel_disappears_label",
      "sources": {
        "paused": "mobile-header-hide-on-scroll-paused.gif",
        "playing": "mobile-header-hide-on-scroll.gif",
        "selectedPaused": "mobile-header-hide-on-scroll-selected-paused.gif",
        "selectedPlaying": "mobile-header-hide-on-scroll-selected.gif"
      }
    }
  },
  {
    "value": {
      "fixedPosition": true,
      "animationName": "HeaderFadeOut",
      "scrollType": "fade_out"
    },
    "desktop": {
      "behaviorName": "AnimateAfterScroll",
      "label": "desktop_headerscroll_panel_fades_out_label",
      "sources": {
        "paused": "desktop-header-fade-on-scroll-paused.gif",
        "playing": "desktop-header-fade-on-scroll.gif",
        "selectedPaused": "desktop-header-fade-on-scroll-selected-paused.gif",
        "selectedPlaying": "desktop-header-fade-on-scroll-selected.gif"
      }
    },
    "mobile": {
      "behaviorName": "ScrubAnimation",
      "label": "mobile_headerscroll_panel_fades_out_label",
      "sources": {
        "paused": "mobile-header-fade-on-scroll-paused.gif",
        "playing": "mobile-header-fade-on-scroll.gif",
        "selectedPaused": "mobile-header-fade-on-scroll-selected-paused.gif",
        "selectedPlaying": "mobile-header-fade-on-scroll-selected.gif"
      }
    }
  }
]
