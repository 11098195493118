import React from 'react';
import type { AppHost } from 'repluggable';
import { AppMainView as BaseAppMainView } from 'repluggable';
import * as reactRedux from 'react-redux';
import { StoreContext } from './connect';

export const AppMainView = (props: { host: AppHost }) => {
  return (
    <reactRedux.Provider store={props.host.getStore()} context={StoreContext}>
      <BaseAppMainView host={props.host} />
    </reactRedux.Provider>
  );
};
