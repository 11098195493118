export default {
  FIRST_TIME_INFO_BOX: {
    LEARN_MORE: {
      evid: 347,
      fields: {
        panel_name: 'string',
      },
    },
    CLOSE: {
      evid: 348,
      fields: {
        panel_name: 'string',
      },
    },
  },
  Tooltip_OpenClose: {
    evid: 343,
    fields: {
      panel_name: 'string',
      control_name: 'string',
      section_name: 'string',
      state: 'string',
    },
  },
  Tooltip_LearnMore_Click: {
    evid: 344,
    fields: {
      panel_name: 'string',
      control_name: 'string',
      value: 'string',
    },
  },
  Panel_Header_Close: {
    evid: 345,
    fields: {
      panel_name: 'string',
      control_name: 'string',
      origin: 'string',
      status: 'boolean',
    },
  },
  Panel_Header_HelpClick: {
    evid: 346,
    fields: {
      panel_name: 'string',
      control_name: 'string',
    },
  },
  Panel_FirstTime_LearnMoreClick: {
    evid: 347,
    fields: {
      panel_name: 'string',
      control_name: 'string',
    },
  },
  Toggle_Click: {
    evid: 361,
    fields: {
      control_name: 'string',
      panel_name: 'string',
      section_name: 'string',
      state: 'string',
    },
  },
  DropDown_Selection: {
    evid: 355,
    fields: {
      control_name: 'string',
      panel_name: 'string',
      section_name: 'string',
      selection: 'string',
    },
  },
  Slider_ValueChange: {
    evid: 365,
    fields: {
      control_name: 'string',
      panel_name: 'string',
      section_name: 'string',
      selected_value: 'string',
      value_change_origin: 'string',
    },
  },
  RadioButtonsList_Selection: {
    evid: 359,
    fields: {
      control_name: 'string',
      panel_name: 'string',
      section_name: 'string',
      selection: 'string',
    },
  },
  Thumbnails_Selection_List: {
    evid: 353,
    fields: {
      control_name: 'string',
      panel_name: 'string',
      section_name: 'string',
      selection: 'string',
    },
  },
  CheckboxWithButton_Toggle: {
    evid: 656,
    fields: {
      control_name: 'string',
      panel_name: 'string',
      section_name: 'string',
      state: 'string',
    },
  },
  CheckboxWithButton_DesignClicked: {
    evid: 657,
    fields: {
      cardId: 'string',
    },
  },
  dont_show_again_click: {
    evid: 798,
    fields: {
      panel_name: 'string',
      toggle: 'boolean',
    },
  },
} as const;
