export const LEFTBAR = {
  APP_MANAGER_LABEL: 'App_Manager_Button_Text',

  BACKGROUND_LABEL: 'LEFTBAR_BACKGROUND_LABEL',
  MENUS_AND_PAGES_LABEL: 'LEFTBAR_PAGES_LABEL',
  ADD_SECTION_LABEL: 'LEFTBAR_ADD_SECTION_LABEL',
  ADD_LABEL: 'LEFTBAR_ADD_LABEL',
  ADD_ELEMENT_LABEL: 'LEFTBAR_ADD_ELEMENT_LABEL',
  ADD_MOBILE_LABEL: 'mobile_add_panel_title',
  ADD_APPS_LABEL: 'app_market_button_text_experiment_add_apps',
  MY_UPLOADS_LABEL: 'LEFTBAR_MY_UPLOADS_LABEL',
  MEDIA_LABEL: 'LEFTBAR_MEDIA_NEW_LABEL',
  MOBILE_TOOLS_LABEL: 'mobile_features_section_title_mobile_tools',
  HIDDEN_ELEMENTS_LABEL: 'mobile_hidden_items_panel_title',
  PAGE_LAYOUT_OPTIMIZER_LABEL: 'mobile_layoutoptimizer_title',
  SECTIONS_PANEL_LABEL: 'Sections Panel Title',
  MORE_OPTIONS_LABEL: 'LEFTBAR_lite_editor_full_editor_entry_point',

  BACKGROUND_PANEL_NAME: 'rEditor.panels.backgroundPanel',
  APP_MANAGER_PANEL_NAME: 'appManager.appManagerPanel',
  BLOG_PANEL_NAME: 'blog.menuPanel',
  ADD_PANEL_NAME: 'addPanelInfra.addPanel',
  NEW_ADD_PANEL_NAME: 'addPanelInfra.newAddPanel',
  ADD_APPS_PANEL_NAME: 'tpa.compPanels.appMarketPanel',
  MENUS_AND_PAGES_PANEL_NAME: 'pages.pagesPanel',
  ADD_SECTION_PANEL_NAME: 'addPreset.panels.addSectionPanel',
  MOBILE_TOOLS_PANEL_NAME: 'mobileEditor.mobileElementsPanel',
  HIDDEN_ELEMENTS_PANEL_NAME: 'mobileEditor.hiddenItemsPanel',
  HIDDEN_VARIANTS_ELEMENTS_PANEL_NAME:
    'interactions.panels.interactionHiddenComponents',
  PAGE_LAYOUT_OPTIMIZER_PANEL_NAME: 'mobileEditor.layoutOptimizerPanel',
  MEDIA_MANAGER_PANEL_NAME: 'rEditor.panels.mediaManagerPanel',
  WIX_DATA_PANEL_NAME: 'wixData.panel',
  WIX_DATA_PANEL_ID: 'LEFT_BAR_WIX_DATA',
  SECTIONS_PANEL_NAME: 'sectionsPanel.SectionsPanel',
  MORE_OPTIONS_PANEL_NAME: 'rEditor.panels.workspaceModesLeftPanel',

  BACKGROUND_ID: 'LEFTBAR_BACKGROUND_LABEL',
  MENUS_AND_PAGES_ID: 'LEFTBAR_PAGES_LABEL',
  ADD_SECTION: 'ADD_SECTION',
  ADD_ID: 'LEFTBAR_ADD_LABEL',
  INTERACRIONS_HIDDEN_COMPONENTS: 'interactions_hidden_panel',
  ADD_APPS_ID: 'app_market_button_text',
  MEDIA_MANAGER_ID: 'rEditor.panels.mediaManagerPanel',
  PAGES_ID: 'LEFTBAR_PAGES_PANEL_LABEL',
  PAGES_LABEL: 'LEFTBAR_PAGES_PANEL_LABEL',
  INTERACRIONS_ADD_PANEL: 'interactions_add_panel',
  MOBILE_TOOLS_ID: 'LEFT_BAR_MOBILE_TOOLS',
  HIDDEN_ELEMENTS_ID: 'LEFT_BAR_HIDDEN_ELEMENTS',
  PAGE_LAYOUT_OPTIMIZER_ID: 'PAGE_LAYOUT_OPTIMIZER',
  SECTIONS_PANEL_ID: 'SECTIONS_PANEL',
  MORE_OPTIONS_ID: 'MORE_OPTIONS',

  DESIGN_PANEL_ID: 'LEFTBAR_DESIGN_PANEL',
  DESIGN_PANEL_NAME: 'newDesignPanel.designPanel',
  DESIGN_PANEL_LABEL: 'LeftBar_DesignPanel_Title',

  OPEN_ORIGIN: 'left_bar',

  LEFT_BAR_WIDTH: 60,
  LEFT_BAR_WIDTH_FIXED_STAGE: 54,
  ADD_SECTION_PANEL_WIDTH: 509,
  DESIGN_PANEL_WIDTH: 321,
  MENU_AND_PAGES_PANEL_WIDTH: 452,

  ADVANCED_MENU_PAGES_LABEL: 'LEFTBAR_SITE_PAGES_LABEL',
} as const;
