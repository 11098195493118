import _ from 'lodash';

const waitForAnimationEnd = <TAnimatedProps>(
  getAnimatedProps: () => TAnimatedProps,
  framesToWait: number = 3,
): Promise<TAnimatedProps> =>
  new Promise((resolve) => {
    let animationFramesToWait = framesToWait;
    let props = getAnimatedProps();

    const checkAnimationFinished = () => {
      const nextProps = getAnimatedProps();

      if (_.isEqual(props, nextProps)) {
        animationFramesToWait--;
      } else {
        props = nextProps;
        animationFramesToWait = framesToWait;
      }

      if (animationFramesToWait > 0) {
        requestAnimationFrame(checkAnimationFinished);
      } else {
        resolve(nextProps);
      }
    };

    requestAnimationFrame(checkAnimationFinished);
  });

export { waitForAnimationEnd };
