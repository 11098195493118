import { recompose } from '@wix/santa-editor-utils';
import createReactClass from 'create-react-class';
import _ from 'lodash';
import React from 'react';

/**
 * @deprecated Try to remove usage of this HOC
 */
function renderWhenMutated(Component: React.ComponentType<any>) {
  // eslint-disable-next-line react/prefer-es6-class
  const RenderWhenMutated = createReactClass<Record<string, any>>({
    displayName: recompose.wrapDisplayName(Component, 'RenderWhenMutated'),
    shouldComponentUpdate(nextProps) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/keys
      const propKeys = _.keys(Component.propTypes);
      const currentProps = this.props;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/some
      return _.some(propKeys, (key) => {
        const currentValue = currentProps[key];
        const nextValue = nextProps[key];

        if (key === 'children') {
          if (_.isFunction(currentValue)) {
            return true;
          }
          return nextValue !== currentValue;
        }

        if (_.isFunction(currentValue) && _.isFunction(nextValue)) {
          return false;
        }

        return !_.isEqual(currentValue, nextValue);
      });
    },

    render() {
      return React.createElement(Component, this.props);
    },
  });

  return RenderWhenMutated as AnyFixMe;
}

export default renderWhenMutated;
