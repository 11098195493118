// @ts-nocheck
import _ from 'lodash';
import { translate } from '#packages/i18n';

import type {
  Link,
  PageLink,
  AnchorLink,
  ExternalLink,
  DocumentLink,
  EmailLink,
  PhoneLink,
  DynamicPageLink,
  SectionLink,
  AddressLink,
} from 'types/documentServices';
import * as utils from '@wix/santa-editor-utils';

import type { EditorAPI } from '#packages/editorAPI';
import type { AnchorData } from '#packages/panels';

export interface Anchor {
  anchorName: string;
  pageId: string;
  anchorDataId: string;
  y: number;
}

const LinkTypes = {
  NO_LINK: 'NoLink',
  PAGE_LINK: 'PageLink',
  ANCHOR_LINK: 'AnchorLink',
  EXTERNAL_LINK: 'ExternalLink',
  DOCUMENT_LINK: 'DocumentLink',
  PHONE_LINK: 'PhoneLink',
  WHATSAPP_LINK: 'WhatsAppLink',
  ADDRESS_LINK: 'AddressLink',
  EMAIL_LINK: 'EmailLink',
  LOGIN_TO_WIX_LINK: 'LoginToWixLink',
  DYNAMIC_PAGE_LINK: 'DynamicPageLink',
  EDGE_ANCHOR_LINK: 'EdgeAnchorLinks',
  POPUP_LINK: 'PopupLink',
  FORM_SUBMIT_BUTTON_LINK: 'FormSubmitButtonLink',
};

function sanitizeHash(str) {
  return str.replace('#', '');
}

function getAnchorLinkName(editorAPI: EditorAPI, anchorLink) {
  if (
    anchorLink.anchorDataId === 'SCROLL_TO_TOP' ||
    anchorLink.anchorDataId === 'SCROLL_TO_BOTTOM'
  ) {
    return anchorLink.anchorName;
  }

  const pageId = _.has(anchorLink.pageId, 'id')
    ? anchorLink.pageId.id
    : anchorLink.pageId;
  const anchorDataItem = _.isPlainObject(anchorLink.anchorDataId)
    ? anchorLink.anchorDataId
    : editorAPI.data.getById(sanitizeHash(anchorLink.anchorDataId), pageId);
  if (anchorDataItem) {
    return anchorDataItem.name;
  }

  return getPageTitle(editorAPI, anchorLink.pageId);
}

function getLinkValueAsString(editorAPI: EditorAPI, linkData, addPrefix?) {
  return utils.getLinkValueAsString(editorAPI, linkData, addPrefix, translate);
}

function getPageTitle(editorAPI: EditorAPI, pageId) {
  if (pageId) {
    const pageData = _.isPlainObject(pageId)
      ? pageId
      : editorAPI.dsRead.pages.data.get(sanitizeHash(pageId));
    if (pageData) {
      return pageData.title || '';
    }
  }
  return '';
}

function isScrollTopAnchor(anchor) {
  return anchor.id === 'SCROLL_TO_TOP';
}

function getResponsiveAnchors(editorAPI: EditorAPI): Anchor[] {
  const pagesIds = editorAPI.dsRead.pages.getPagesData().map((page) => page.id);
  const convert = (responsiveAnchor, pageId) => ({
    anchorName: isScrollTopAnchor(responsiveAnchor)
      ? translate('LINK_PANEL_PAGE_ANCHOR_SCROLL_TO_TOP_NAME')
      : responsiveAnchor.name,
    pageId: `#${pageId}`,
    anchorDataId: `#${responsiveAnchor.id}`,
    y: 0,
  });

  return pagesIds.reduce((acc, pageId) => {
    const pageAnchors = editorAPI.dsRead.pages.anchors.getPageAnchors(pageId);
    return acc.concat(pageAnchors.map((anchor) => convert(anchor, pageId)));
  }, []);
}

function getClassicAnchors(editorAPI: EditorAPI): Anchor[] {
  return editorAPI.components.get
    .byType('wysiwyg.common.components.anchor.viewer.Anchor')
    .map((anchorRef) => {
      const data = editorAPI.components.data.get(anchorRef);
      const pageId = editorAPI.components.getPage(anchorRef).id;

      return {
        anchorName: data.name,
        pageId: `#${pageId}`,
        anchorDataId: `#${data.id}`,
        y: editorAPI.components.layout.getRelativeToScreen(anchorRef).y,
      };
    });
}

function getSectionAnchors(editorAPI: EditorAPI): AnchorData[] {
  return editorAPI.sections.getAllSections().map((anchorRef) => {
    const data = editorAPI.components.anchor.get(anchorRef);
    const pageId = editorAPI.components.getPage(anchorRef).id;
    const displayName = editorAPI.components.getDisplayName(anchorRef);

    return {
      anchorName: data.name,
      pageId: `#${pageId}`,
      anchorDataId: `#${data.id}`,
      y: editorAPI.components.layout.getRelativeToScreen(anchorRef).y,
      displayName,
    };
  });
}

function getAnchors(editorAPI: EditorAPI): Anchor[] {
  const retrieveAllAnchors = editorAPI.documentServices.responsiveLayout
    ? getResponsiveAnchors
    : getClassicAnchors;
  return retrieveAllAnchors(editorAPI);
}

function getCleanPageId(pageId: string) {
  return pageId?.startsWith('#') ? pageId.substring(1) : pageId;
}

function getPagesForLinkPanel(editorAPI: EditorAPI): PagesData[] {
  const menuItems = _.flatMap(editorAPI.mainMenu.getMenu(true), (menuItem) => [
    menuItem,
    ...menuItem.items,
  ]);
  const pagesData = editorAPI.dsRead.pages.getPagesData();
  const pagesDataMap = _.keyBy(pagesData, (page) => page.id);

  const pagesSortedByMenuItemsOrder = menuItems
    .filter((item) => item?.link?.type === LinkTypes.PAGE_LINK)
    .reduce((acc, menuItem) => {
      const pageId = getCleanPageId(menuItem.link.pageId);
      const page = pagesDataMap[pageId];
      if (page) {
        acc.push(pagesDataMap[pageId]);
        delete pagesDataMap[pageId];
      }
      return acc;
    }, []);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  const pagesThatAreNotInMenu = _.filter(
    pagesDataMap,
    (page) =>
      !editorAPI.dsRead.tpa.isPageMarkedAsHideFromMenu(
        page.tpaApplicationId,
        page.tpaPageId,
      ),
  );
  return [
    ...pagesSortedByMenuItemsOrder,
    ...pagesThatAreNotInMenu,
  ] as PagesData[];
}

function getLinkPanelProps(props) {
  const mergedProps = _.merge({}, props);

  if (!_.has(mergedProps, 'visibleSections.PhoneLink')) {
    _.set(mergedProps, 'visibleSections.PhoneLink', true);
  }

  return mergedProps;
}

const SECTION_LINK_DATA_ID_PREFIX = '#anchors-';

const linkTypeValidators = {
  isPageLink: (link: Link | undefined): link is PageLink =>
    link?.type === LinkTypes.PAGE_LINK,
  isAnchorLink: (link: Link | undefined): link is AnchorLink =>
    link?.type === LinkTypes.ANCHOR_LINK,
  isExternalLink: (link: Link | undefined): link is ExternalLink =>
    link?.type === LinkTypes.EXTERNAL_LINK,
  isEmailLink: (link: Link | undefined): link is EmailLink =>
    link?.type === LinkTypes.EMAIL_LINK,
  isPhoneLink: (link: Link | undefined): link is PhoneLink =>
    link?.type === LinkTypes.PHONE_LINK,
  isDocumentLink: (link: Link | undefined): link is DocumentLink =>
    link?.type === LinkTypes.DOCUMENT_LINK,
  isDynamicPageLink: (link: Link | undefined): link is DynamicPageLink =>
    link?.type === LinkTypes.DYNAMIC_PAGE_LINK,
  isAddressLink: (link: Link | undefined): link is AddressLink =>
    link?.type === LinkTypes.ADDRESS_LINK,
  isWhatsAppLink: (link: Link | undefined): link is PhoneLink =>
    link?.type === LinkTypes.WHATSAPP_LINK,
  isSectionLink: (link: Link | undefined): link is SectionLink => {
    if (
      linkTypeValidators.isAnchorLink(link) ||
      linkTypeValidators.isDynamicPageLink(link)
    ) {
      return link?.anchorDataId?.startsWith(SECTION_LINK_DATA_ID_PREFIX);
    }
    return false;
  },
};

export {
  LinkTypes,
  linkTypeValidators,
  getLinkPanelProps,
  getAnchors,
  getSectionAnchors,
  getAnchorLinkName,
  getLinkValueAsString,
  getPagesForLinkPanel,
};
