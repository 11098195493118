import {
  hasComponentScopeChildren,
  getComponentScopeChildren,
  getComponentScopeOwner,
  getRefComponentCodeNickname,
} from './componentScopeSelectors';
import { isRefComponent } from './componentTypeSelectors';
import type { DSRead, CompRef } from 'types/documentServices';

export function hasComponentChildren(dsRead: DSRead, compRef: CompRef) {
  return dsRead.components.getChildren(compRef).length > 0;
}

export function getComponentChildrenOrScopedChildren(
  dsRead: DSRead,
  compRef: CompRef,
): CompRef[] {
  const children = dsRead.components.getChildren(compRef);

  return children.length === 0
    ? getComponentScopeChildren(dsRead, compRef)
    : children;
}

export function hasComponentChildrenOrScopedChildren(
  dsRead: DSRead,
  compRef: CompRef,
) {
  return (
    hasComponentChildren(dsRead, compRef) ||
    hasComponentScopeChildren(dsRead, compRef)
  );
}

/**
 * Get component nickname (with RefComponent children nickname resolving)
 */
export function getComponentCodeNickname(
  dsRead: DSRead,
  compRef: CompRef,
): string {
  const nickname = isRefComponent(dsRead, compRef)
    ? getRefComponentCodeNickname(dsRead, compRef)
    : undefined;

  if (nickname) {
    return nickname;
  }

  return dsRead.components.code.getNickname(compRef);
}

export function isComponentAncestorOfComp(
  dsRead: DSRead,
  ancestorRef: CompRef,
  descendantRef: CompRef,
) {
  return dsRead.components.isDescendantOfComp(descendantRef, ancestorRef);
}

export function isComponentAncestorOfCompOrCompScope(
  dsRead: DSRead,
  ancestorRef: CompRef,
  descendantRef: CompRef,
) {
  if (isComponentAncestorOfComp(dsRead, ancestorRef, descendantRef)) {
    return true;
  }

  const descendantScopeOwnerRef = getComponentScopeOwner(dsRead, descendantRef);
  return (
    descendantScopeOwnerRef &&
    isComponentAncestorOfComp(dsRead, ancestorRef, descendantScopeOwnerRef)
  );
}

export function areComponentsIncludes(
  dsRead: DSRead,
  compRefs: CompRef[],
  compRef: CompRef,
) {
  return compRefs.some((c) => dsRead.utils.isSameRef(c, compRef));
}

export function areComponentsSiblings(dsRead: DSRead, compRefs: CompRef[]) {
  if (!compRefs || compRefs.length === 0) {
    return false;
  }

  if (compRefs.length === 1) {
    return true;
  }

  const [firstCompRef, ...restOfCompRefs] = compRefs;
  const firstCompSiblings = dsRead.components.getSiblings(firstCompRef);

  return restOfCompRefs.every((compRef) =>
    areComponentsIncludes(dsRead, firstCompSiblings, compRef),
  );
}

export function areComponentsOfSamePage(dsRead: DSRead, compRefs: CompRef[]) {
  if (!compRefs || compRefs.length === 0) {
    return false;
  }

  if (compRefs.length === 1) {
    return true;
  }

  const [firstCompRef, ...restOfCompRefs] = compRefs;
  const firstCompPageRef = dsRead.components.getPage(firstCompRef);

  return restOfCompRefs.every((compRef) =>
    dsRead.utils.isSameRef(
      dsRead.components.getPage(compRef),
      firstCompPageRef,
    ),
  );
}

export function getComponentsContainer(
  dsRead: DSRead,
  compRefs: CompRef[],
): CompRef {
  if (!areComponentsSiblings(dsRead, compRefs)) {
    return null;
  }

  const onOfSiblings = compRefs[0];
  return dsRead.components.getContainer(onOfSiblings);
}

export function getComponentsContainer_DEPRECATED_BAD_PERFORMANCE(
  dsRead: DSRead,
  compRefs: CompRef[],
): CompRef {
  if (!areComponentsSiblings(dsRead, compRefs)) {
    return null;
  }

  const onOfSiblings = compRefs[0];
  return dsRead.deprecatedOldBadPerformanceApis.components.getContainer(
    onOfSiblings,
  );
}

export function getComponentsContainerOrScopeOwner(
  dsRead: DSRead,
  compRefs: CompRef[],
): CompRef {
  if (!areComponentsSiblings(dsRead, compRefs)) {
    return null;
  }

  const onOfSiblings = compRefs[0];
  return (
    dsRead.components.getContainer(onOfSiblings) ||
    getComponentScopeOwner(dsRead, onOfSiblings)
  );
}

export function getOneComponentIfSiblings(
  dsRead: DSRead,
  compRefOrRefs: CompRef | CompRef[],
) {
  if (!Array.isArray(compRefOrRefs)) {
    return compRefOrRefs;
  }

  if (areComponentsSiblings(dsRead, compRefOrRefs)) {
    return compRefOrRefs[0];
  }

  return null;
}

interface TraverseMatchesConditionOptions {
  includeSelf?: boolean;
}

export function traverseMatchesCondition(
  compRef: CompRef,
  getNext: (compRef: CompRef) => CompRef,
  condition: (candidateRef: CompRef, compRef: CompRef) => boolean,
  { includeSelf }: TraverseMatchesConditionOptions = {},
): CompRef | null {
  if (!compRef) {
    return null;
  }

  if (includeSelf && condition(compRef, compRef)) {
    return compRef;
  }

  let nextCompRef = getNext(compRef);

  while (nextCompRef && !condition(nextCompRef, compRef)) {
    nextCompRef = getNext(nextCompRef);
  }

  return nextCompRef ?? null;
}

export interface FindComponentAncestorMatchesConditionOptions
  extends TraverseMatchesConditionOptions {
  includeScopeOwner?: boolean;
}

export function findComponentAncestorMatchesCondition(
  dsRead: DSRead,
  compRef: CompRef,
  condition: (candidateRef: CompRef, compRef: CompRef) => boolean,
  {
    includeScopeOwner,
    ...options
  }: FindComponentAncestorMatchesConditionOptions = {},
) {
  return traverseMatchesCondition(
    compRef,
    (compRef) =>
      dsRead.components.getContainer(compRef) ??
      (includeScopeOwner ? getComponentScopeOwner(dsRead, compRef) : null),
    condition,
    options,
  );
}

export function someComponentAncestorMatchesCondition(
  dsRead: DSRead,
  compRef: CompRef,
  condition: (candidateRef: CompRef, compRef: CompRef) => boolean,
  options: FindComponentAncestorMatchesConditionOptions = {},
) {
  return !!findComponentAncestorMatchesCondition(
    dsRead,
    compRef,
    condition,
    options,
  );
}

export function groupComponentsByContainer(
  dsRead: DSRead,
  compRefs: CompRef[],
) {
  const groupedComponents = new Map<
    string,
    {
      containerRef: CompRef;
      compRefs: CompRef[];
    }
  >();

  compRefs.forEach((compRef) => {
    const containerRef = dsRead.components.getContainer(compRef);
    const containerKey = `${containerRef.id}|${containerRef.type}`;

    if (!groupedComponents.has(containerKey)) {
      groupedComponents.set(containerKey, {
        containerRef,
        compRefs: [],
      });
    }

    groupedComponents.get(containerKey).compRefs.push(compRef);
  });

  return Array.from(groupedComponents.values());
}

export function hasComponentAncestorsInArray(
  dsRead: DSRead,
  compRef: CompRef,
  possibleAncestorRefs: CompRef[],
) {
  return possibleAncestorRefs.some((possibleAncestorRef) =>
    dsRead.components.isDescendantOfComp(compRef, possibleAncestorRef),
  );
}

export function getComponentsWithoutAncestorsInArray(
  dsRead: DSRead,
  compRefs: CompRef[],
) {
  return compRefs.filter(
    (compRef) => !hasComponentAncestorsInArray(dsRead, compRef, compRefs),
  );
}
