// @ts-nocheck
import * as utils from '@wix/santa-editor-utils';
import experiment from 'experiment';
import _ from 'lodash';
import type { DSRead } from 'types/documentServices';
import serviceTopology from '../serviceTopology/serviceTopology';
import UrlBuilder from '../url/UrlBuilder';
import { translate } from '#packages/i18n';

const controllerTypes = [
  'platform.components.AppWidget',
  'platform.components.AppController',
];

export const httpReferrerOptions = {
  AppImportPanel: 'AppImportPanel',
  LeftTree: 'LeftTree',
  PrivateAppPanel: 'PrivateAppPanel',
};

const getInitialAppStudioModel = () => window.appStudioModel;

/**
 * @deprecated use `shell.getAPI(EditorParamsApi).isInsideAppStudio`
 */
const isAppStudio = () => getInitialAppStudioModel() !== undefined;

const getAppDefIdIfExists = () => getInitialAppStudioModel()?.devSiteAppDefId;

const getNamespace = () => getInitialAppStudioModel()?.namespace;

const getAppVersionOnLoad = () => getInitialAppStudioModel()?.appVersion;
const isAppPublishedOnLoad = () => {
  if (isAppStudio()) {
    const appVersion = getInitialAppStudioModel()?.appVersion;
    if (appVersion) {
      return (
        getVersion(appVersion).major > 0 || getVersion(appVersion).minor > 0
      );
    }
  }
  return false;
};

const getApplicationNameOnLoad = () => getInitialAppStudioModel()?.appName;

const getVersion = (version) => {
  if (!version) {
    return {
      major: 0,
      minor: 0,
    };
  }
  const splitVersion = version.split('.').map((v) => parseInt(v, 10));
  return {
    major: splitVersion[0],
    minor: splitVersion[1],
  };
};

const formatVersion = (version: string) => {
  if (version === 'latest') {
    return translate(
      'blocks-private-apps.AppDiscovery_App_Details_Latest_Version_Label',
    );
  }
  const { major, minor } = getVersion(version);
  return `v${major}.${minor}`;
};

const paramsToPassToAppStudio = [
  'ReactSource',
  'EditorSource',
  'debug',
  'debugViewer',
  'WixCodeRuntimeSource',
];

export const blocksNewServerBaseUrl = `${serviceTopology.editorRootUrl}_api/wix-blocks-service/web/v1`;
const blocksNewAppCreatorUrl =
  'https://blocks.wix.com/wix-blocks-new-app-creator';
const welcomeScreen = 'https://www.wix.com/blocks/new';

const createEditAppURL = (app, httpReferrer) => {
  httpReferrer += getHttpReferrer();
  return appendCurrentUrlParams(
    `${blocksNewServerBaseUrl}/apps/${app.appDefinitionId}/open`,
    {
      http_referrer: httpReferrer,
    },
  );
};

const isAppMajorVersionBuilt = (latestVersion = '0.0.0') => {
  const { minor, major } = getVersion(latestVersion);
  return !(major === 0 && minor === 0);
};

export enum AppType {
  Widgets = 'widgets',
  CodePackage = 'code_package',
  Combined = 'combined',
}

export const getAppType = (app) => {
  if (app.compTypes.includes('CODE_PACKAGE')) {
    if (app.compTypes.includes('STUDIO_WIDGET')) {
      return AppType.Combined;
    }

    return AppType.CodePackage;
  }

  return AppType.Widgets;
};

export const isBlocksApp = (app) => app.compTypes.includes('STUDIO');

export const isAppNotEmpty = (app) => {
  return hasStudioWidget(app) || hasDashboardPage(app) || hasCodePackage(app);
};

const hasStudioWidget = (app) => app.compTypes.includes('STUDIO_WIDGET');
const hasDashboardPage = (app) => app.compTypes.includes('BACK_OFFICE_PAGE');
const hasCodePackage = (app) => app.compTypes.includes('CODE_PACKAGE');

const isResponsiveBlocksWidget = ({ dsRead }, compRef) => {
  const compData = dsRead.components.data.get(compRef);
  if (compData?.type === 'WidgetRef') {
    const appData = dsRead.platform.getAppDataByAppDefId(
      compData.appDefinitionId,
    );
    const widgetData = appData?.components?.find?.(
      (comp) => comp.componentId === compData.widgetId,
    );
    return isResponsiveBlocksVersion(widgetData?.data?.blocksVersion);
  }
  return false;
};

const isResponsiveBlocksVersion = (blocksVersion) =>
  Boolean(blocksVersion?.startsWith('2.'));

const hasMajorVersion = (version) => {
  const { major } = getVersion(version);
  return major > 0;
};

const versionToInstall = ({ appVersion, blocksVersion }) => {
  // can't use semver ^ for apps with no major version
  if (isResponsiveBlocksVersion(blocksVersion) && hasMajorVersion(appVersion)) {
    return formatSemver(appVersion);
  }

  if (!utils.isResponsiveEditor()) {
    return appVersion;
  }
};

const formatSemver = (version: string, isSemver = true) =>
  `${isSemver ? '^' : ''}${version}`;

const getBlocksVersion = (appData) => {
  const studioWidgetComponent = appData?.components?.find(
    ({ type }) => type === 'STUDIO_WIDGET',
  );

  if (!studioWidgetComponent) {
    // only Responsive Blocks (2.0.0) supports apps with CODE_PACKAGE or BACK_OFFICE_PAGE component
    const isResponsiveBlocks = appData?.components?.some(({ type }) =>
      ['CODE_PACKAGE', 'BACK_OFFICE_PAGE'].includes(type),
    );

    if (isResponsiveBlocks) return '2.0.0';
  }

  return studioWidgetComponent?.data?.blocksVersion;
};

const canImport = (app) => {
  const { blocksVersion, latestVersion } = app;

  if (!isAppMajorVersionBuilt(latestVersion)) {
    return false;
  }

  const isResponsiveApp =
    isResponsiveBlocksVersion(blocksVersion) ||
    getAppType(app) !== AppType.Widgets;

  if (utils.isResponsiveEditor()) {
    return isResponsiveApp;
  }

  return true;
};

export enum DisplayMode {
  OnlyWidgets = 'only_widgets',
  OnlyCodePackages = 'only_code_packages',
  All = 'all',
}

const filterAppsByDisplayMode = (
  apps,
  displayMode: DisplayMode = DisplayMode.All,
) => {
  return apps.filter((app) => {
    switch (displayMode) {
      case DisplayMode.OnlyWidgets:
        return getAppType(app) === AppType.Widgets;
      case DisplayMode.OnlyCodePackages:
        return getAppType(app) === AppType.CodePackage;
      case DisplayMode.All:
      default:
        return true;
    }
  });
};

const appendCurrentUrlParams = (url, params) => {
  const currentUrl = window.document.location.href;
  const editorQuery = utils.url.parseUrl(currentUrl).query;
  const builder = new UrlBuilder(url)
    .paramsObj(_.pick(editorQuery, paramsToPassToAppStudio))
    .paramsObj(params);

  return builder.url();
};

const getNewCodeReuseBlocksURL = (httpReferrer) => {
  return appendCurrentUrlParams(blocksNewAppCreatorUrl, {
    editorType: 'RESPONSIVE',
    http_referrer: httpReferrer,
  });
};

const getNewAppStudioURL = (isWixUser: boolean, httpReferrer?: string) => {
  const shouldOpenResponsiveBlocks =
    utils.isResponsiveEditor() ||
    isWixUser ||
    experiment.isOpen('se_openNewResponsiveBlocksFromEditor') ||
    experiment.isOpen('se_privateAppsPanel');

  return appendCurrentUrlParams(
    blocksNewAppCreatorUrl,
    shouldOpenResponsiveBlocks
      ? {
          editorType: 'RESPONSIVE',
          withInitialWidget: true,
          http_referrer: httpReferrer,
        }
      : { editorType: 'CLASSIC', http_referrer: httpReferrer },
  );
};

export enum AppStudioMode {
  App = 'app',
  Package = 'package',
}

const getHttpReferrer = () => {
  if (utils.isOnWixStudio()) {
    return '_wixStudio';
  }

  if (utils.isOnEditorX()) {
    return '_editorx';
  }

  return '_wixEditor';
};

const getBlocksUrl = (
  mode: AppStudioMode = AppStudioMode.App,
  isWixUser,
  httpReferrer,
  isAppStudioAccount,
) => {
  httpReferrer += getHttpReferrer();

  if (experiment.isOpen('se_blocksWelcomeScreen') || isAppStudioAccount) {
    return `${welcomeScreen}?http_referrer=${httpReferrer}`;
  }

  switch (mode) {
    case AppStudioMode.Package:
      return getNewCodeReuseBlocksURL(httpReferrer);
    case AppStudioMode.App:
    default:
      return getNewAppStudioURL(isWixUser, httpReferrer);
  }
};

interface OpenBlocksParams {
  mode: AppStudioMode;
  isWixUser: boolean;
  httpReferrer: string;
  isStudioAccount: boolean;
}
const openBlocks = ({
  mode = AppStudioMode.App,
  isWixUser,
  httpReferrer,
  isStudioAccount,
}: OpenBlocksParams) => {
  const url = getBlocksUrl(mode, isWixUser, httpReferrer, isStudioAccount);
  window.open(url, '_blank');
};

const isWidgetController = (editorAPI, compRefs) => {
  compRefs = _.castArray(compRefs);

  if (
    compRefs.length > 1 ||
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    !_.includes(controllerTypes, editorAPI.components.getType(compRefs))
  ) {
    return false;
  }

  const controllerData = editorAPI.dsRead.components.data.get(compRefs[0]);
  const { type: widgetType } =
    editorAPI.dsRead.platform.controllers.settings.get(compRefs[0]);
  const appData = editorAPI.dsRead.platform.getAppDataByAppDefId(
    controllerData.applicationId,
  );
  const widgets = appData?.widgets ?? {};
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const widget = _.find(
    widgets,
    (w) => w?.componentFields?.appStudioFields?.id === widgetType,
  );

  return !!widget;
};

const getWidgetSchema = (editorAPI, controllerRef) => {
  const controllerData = editorAPI.dsRead.components.data.get(controllerRef);
  if (!controllerData) {
    return [];
  }

  const { type: widgetType } =
    editorAPI.dsRead.platform.controllers.settings.get(controllerRef);

  const appId = controllerData.applicationId;
  const manifest = editorAPI.platform.getAppManifest(appId);
  const widgets = manifest?.widgets;

  return {
    propertiesSchemas: widgets?.[widgetType]?.propertiesSchema,
  };
};

const isAppWidget = (editorAPI, comp) =>
  editorAPI.components.getType(comp) === 'platform.components.AppWidget';

const getRootAppWidget = (editorAPI) => {
  const currentPage = editorAPI.pages.getCurrentPage();
  return editorAPI.dsRead.appStudio.widgets.getRootWidgetByPage(currentPage);
};

const getWidgetRoot = (editorAPI) => {
  const rootAppWidget = getRootAppWidget(editorAPI);
  return _.head(
    editorAPI.components.getChildren_DEPRECATED_BAD_PERFORMANCE(rootAppWidget),
  );
};

const getCustomDefinitions = (editorAPI, controllerRef) => {
  const controllerData = editorAPI.dsRead.components.data.get(controllerRef);
  if (!controllerData) {
    return {};
  }
  const appId = controllerData.applicationId;
  const manifest = editorAPI.platform.getAppManifest(appId);
  return manifest?.customDefinitions ?? {};
};

const isAppController = (editorAPI, compRefs) => {
  compRefs = _.castArray(compRefs);
  return (
    compRefs.length <= 1 &&
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    _.includes(controllerTypes, editorAPI.components.getType(compRefs))
  );
};

const isMajorUpdate = (oldVersion, newVersion) => {
  return getVersion(oldVersion)?.major !== getVersion(newVersion)?.major;
};

const isWixUser = (dsRead: DSRead) =>
  dsRead.generalInfo.getUserInfo().email?.endsWith('@wix.com');

export {
  isAppWidget,
  isWixUser,
  createEditAppURL,
  isAppPublishedOnLoad,
  isAppStudio,
  isResponsiveBlocksVersion,
  getAppDefIdIfExists,
  isWidgetController,
  getNewAppStudioURL,
  getWidgetRoot,
  getRootAppWidget,
  getWidgetSchema,
  getCustomDefinitions,
  getApplicationNameOnLoad,
  getAppVersionOnLoad,
  isAppController,
  getNamespace,
  filterAppsByDisplayMode,
  isAppMajorVersionBuilt,
  getNewCodeReuseBlocksURL,
  openBlocks,
  versionToInstall,
  getBlocksVersion,
  isResponsiveBlocksWidget,
  isMajorUpdate,
  canImport,
  formatVersion,
  formatSemver,
  getVersion,
};
