import { isSectionsEnabled } from '../sections';

import type { MouseEvent as ReactMouseEvent } from 'react';
import type { Box, ScrollPosition } from 'types/core';
import type { PreviewPosition, StageLayout } from '#packages/stateManagement';

function isInsideRect(stageLayout: StageLayout, x: number, y: number): boolean {
  return (
    x >= stageLayout.left &&
    x <= stageLayout.right &&
    y >= stageLayout.top &&
    y <= stageLayout.bottom
  );
}

export interface ViewerMouseCoordinates {
  pageX: number;
  pageY: number;
  clientX: number;
  clientY: number;

  originalPageX: number;
  originalPageY: number;
  originalClientX: number;
  originalClientY: number;
}

export function fitMouseCoordinatesToViewer<
  Event extends MouseEvent | ReactMouseEvent,
>(
  siteScale: number = 1,
  stageLayout: StageLayout,
  editorScroll: ScrollPosition,
  previewPosition: PreviewPosition,
  e: Event,
): ViewerMouseCoordinates {
  const xOffset = (stageLayout.width * (1 - siteScale)) / 2;
  const isSections = isSectionsEnabled();

  const { pageX, pageY, clientX, clientY } = e;

  const event = {
    pageX,
    pageY,
    clientX,
    clientY,
    originalPageX: pageX,
    originalPageY: pageY,
    originalClientX: clientX,
    originalClientY: clientY,
  };

  // TODO: this is a hack to support moving the editor scroll from the body element to editor stage.
  // Need to remove it once we finish refactoring all code that relies on pageX / pageY
  event.pageX += editorScroll.scrollLeft;
  event.pageY += editorScroll.scrollTop;

  event.clientX -= previewPosition.left;
  event.clientY -= previewPosition.top;
  event.pageX -= previewPosition.left;
  event.pageY -= previewPosition.top;

  if (siteScale !== 1) {
    if (isSections) {
      const xOffset = stageLayout.left - previewPosition.left;
      event.pageX -= xOffset;
      event.clientX -= xOffset;

      const YOffset = stageLayout.top - previewPosition.top;
      event.pageY -= YOffset;
      event.clientY -= YOffset;
    } else if (isInsideRect(stageLayout, event.clientX, event.clientY)) {
      event.pageX = (event.pageX - xOffset) / siteScale;
      event.pageY /= siteScale;

      event.clientX = (event.clientX - xOffset) / siteScale;
      event.clientY /= siteScale;
    }
  }

  return event;
}

export function translatePositionToViewerCoordinates(
  siteScale: number,
  stageLayout: StageLayout,
  editorScroll: ScrollPosition,
  previewPosition: PreviewPosition,
  position: Box,
): Box {
  const newPosition = { ...position };
  const xOffset = (stageLayout.width * (1 - siteScale)) / 2;

  newPosition.x += editorScroll.scrollLeft;
  newPosition.y += editorScroll.scrollTop;

  newPosition.x -= previewPosition.left;
  newPosition.y -= previewPosition.top;

  if (
    siteScale &&
    siteScale !== 1 &&
    isInsideRect(stageLayout, newPosition.x, newPosition.y)
  ) {
    newPosition.x = (newPosition.x - xOffset) / siteScale;
    newPosition.y /= siteScale;

    newPosition.x = (newPosition.x - xOffset) / siteScale;
    newPosition.y /= siteScale;
  }

  return newPosition;
}
