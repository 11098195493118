/**
 * @file Use custom hooks to prevent singleClick action from firing on doubleClick event
 * Referenced from here:
 * https://medium.com/trabe/prevent-click-events-on-double-click-with-react-with-and-without-hooks-6bf3697abc40
 */
import { useRef } from 'react';

export const delay = (n: number) =>
  new Promise((resolve) => setTimeout(resolve, n));

interface PendingPromise {
  promise: Promise<unknown>;
  cancel: () => boolean;
}

/**
 * promise that can be cancelled
 * @returns {Object}
 */
export const cancellablePromise = (promise: Promise<any>) => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      (value) => (isCanceled ? reject({ isCanceled, value }) : resolve(value)),
      (error) => reject({ isCanceled, error }),
    );
  });

  return {
    promise: wrappedPromise,
    cancel: () => (isCanceled = true),
  };
};

/**
 * hook to handle cancellable promises
 * @returns {Object}
 */
const useCancellablePromises = () => {
  const pendingPromises = useRef([]);

  const appendPendingPromise = (promise: PendingPromise) =>
    (pendingPromises.current = [...pendingPromises.current, promise]);

  const removePendingPromise = (promise: PendingPromise) =>
    (pendingPromises.current = pendingPromises.current.filter(
      (p) => p !== promise,
    ));

  const clearPendingPromises = () =>
    pendingPromises.current.map((p) => p.cancel());

  return {
    appendPendingPromise,
    removePendingPromise,
    clearPendingPromises,
  };
};

/**
 * hook to redefine onClick & onDoubleClick handlers - for use in functional components
 * @param onClick - function to perform on singleClick event
 * @param onDoubleClick - function to perform on doubleClick event
 * @returns {Array<Function>}
 */
export const useClickPreventionOnDoubleClick = (
  onClick: () => void,
  onDoubleClick: () => void,
) => {
  const { appendPendingPromise, removePendingPromise, clearPendingPromises } =
    useCancellablePromises();

  const handleClick = () => {
    clearPendingPromises();
    const waitForClick = cancellablePromise(delay(300));
    appendPendingPromise(waitForClick);

    return waitForClick.promise
      .then(() => {
        removePendingPromise(waitForClick);
        onClick();
      })
      .catch((errorInfo) => {
        removePendingPromise(waitForClick);
        if (!errorInfo.isCanceled) {
          throw errorInfo.error;
        }
      });
  };

  const handleDoubleClick = () => {
    clearPendingPromises();
    onDoubleClick();
  };

  return [handleClick, handleDoubleClick];
};
