// @ts-nocheck
import translationUtil from './translationUtil';
import PropTypes from 'prop-types';
import _ from 'lodash';

const createTranslationMixin = ({
  translationUtil: translationUtilOvr,
} = {}) => {
  const translationUtilDep = translationUtilOvr || translationUtil;
  return {
    propTypes: {
      shouldTranslate: PropTypes.bool,
    },
    getDefaultProps() {
      return {
        shouldTranslate: true,
      };
    },
    translateIfNeeded(key) {
      return this.props.shouldTranslate
        ? translationUtilDep.translate(key)
        : key;
    },
    translateTemplate(key, params, editorAPI) {
      const reportOnError = _.isFunction(this.props.biError)
        ? this.props.biError
        : editorAPI?.dsActions?.bi?.error;

      return translationUtilDep.translateTemplate(
        key,
        params,
        reportOnError,
        !this.props.shouldTranslate,
      );
    },
  };
};

// allow tests to inject dependencies overrides, despite the use of globals
const translationMixin = createTranslationMixin();
translationMixin.create = createTranslationMixin;

export default translationMixin;
