function register() {
  Math.sign =
    Math.sign ||
    function (x) {
      x = +x; // convert to a number
      if (x === 0 || isNaN(x)) {
        return x;
      }
      return x > 0 ? 1 : -1;
    };
}
export { register };
