export default {
  TOOLBAR_POSITION_CHANGE: {
    evid: 1394,
    xCoordinate_old: 'number',
    xCoordinate_new: 'number',
    yCoordinate_old: 'number',
    yCoordinate_new: 'number',
    component_id: 'string',
    component_type: 'string',
    parent_component_id: 'string',
    parent_component_type: 'string',
    entry_type: 'string',
    isChanged: 'boolean',
  },
  TOOL_BAR_ALIGNMENT_CLICK: {
    evid: 261,
    fields: {},
  },
  TOOL_BAR_ALIGNMENT_MENU_CLICK: {
    evid: 262,
    fields: {
      category: 'string',
    },
  },
  TOOL_BAR_DISTRIBUTION_CLICK: {
    evid: 263,
    fields: {},
  },
  TOOL_BAR_DISTRIBUTION_MENU_CLICK: {
    evid: 264,
    fields: {
      category: 'string',
    },
  },
  TOOL_BAR_MATCH_SIZE_CLICK: {
    evid: 265,
    fields: {},
  },
  TOOL_BAR_MATCH_SIZE_MENU_CLICK: {
    evid: 266,
    fields: {
      category: 'string',
    },
  },
  TOOL_BAR_CLOSE_CLICK: {
    evid: 876,
    fields: {
      origin: 'string',
    },
  },
  TOOL_BAR_PANEL_OPENED: {
    evid: 309,
    src: 38,
    fields: {
      panel_name: 'string',
    },
  },
  TOOL_BAR_RESIZED: {
    evid: 1485,
    src: 38,
    fields: {
      panel_name: 'string',
      component_type: 'string',
      target: 'string',
      entry_type: 'string',
      isChanged: 'boolean',
      valueOrigin: 'number',
      value: 'number | string',
      component_id: 'string',
    },
  },
} as const;
