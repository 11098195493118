// @ts-nocheck
import PropTypes from 'prop-types';
import _ from 'lodash';

const getValueFromProps = (props) =>
  props.valueLink !== undefined ? props.valueLink.value : props.value;

const getOverrideValuesFromProps = (props) =>
  props.valueLink !== undefined ? props.valueLink.overrideValues : {};

const callOnChangeIfExists = (props, newVal, processedValueData?) => {
  const onChange = props.valueLink
    ? props.valueLink.requestChange
    : props.onChange;
  if (onChange) {
    onChange(
      newVal,
      !_.isObject(processedValueData) ? undefined : processedValueData,
    );
  }
};

const ERROR_DUPLICATE_VALUE_PROP = 'Cannot provide a valueLink and a value.';
const ERROR_DUPLICATE_ON_CHANGE_PROP =
  'Cannot provide a valueLink and an onChange event.';

function valueLinkPropType(props) {
  if (props.valueLink !== undefined) {
    const valueLinkShapeError = PropTypes.shape({
      value: PropTypes.any,
      requestChange: PropTypes.func,
    }).apply(this, arguments);

    if (valueLinkShapeError instanceof Error) {
      return valueLinkShapeError;
    }
    if (props.value !== undefined) {
      return new Error(ERROR_DUPLICATE_VALUE_PROP);
    }
    if (props.onChange !== undefined) {
      return new Error(ERROR_DUPLICATE_ON_CHANGE_PROP);
    }
  }
}

export {
  getValueFromProps,
  getOverrideValuesFromProps,
  callOnChangeIfExists,
  valueLinkPropType,
};
