const hasOwn = {}.hasOwnProperty;

type Classes = string | { [key: string]: boolean } | Classes[];

function classNames(...args: Classes[]): string {
  const classes: string[] = [];

  for (const arg of args) {
    if (!arg) {
      continue;
    }

    if (typeof arg === 'string' || typeof arg === 'number') {
      classes.push(arg);
    } else if (Array.isArray(arg) && arg.length) {
      const inner = classNames.apply(null, arg);
      if (inner) {
        classes.push(inner);
      }
    } else if (typeof arg === 'object') {
      for (const key in arg) {
        if (hasOwn.call(arg, key) && (arg as AnyFixMe)[key]) {
          classes.push(key);
        }
      }
    }
  }

  return classes.join(' ');
}

export default classNames;
