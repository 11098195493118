// @ts-nocheck
import * as util from '#packages/util';

function fetchJson(url, onSuccess, onError) {
  util.http.fetchJson(url).then(onSuccess).catch(onError);
}

function getVideoUrl(videoId) {
  return `http://dailymotion.com/${videoId}`;
}

function getVideoIdFromUrl(url) {
  const urlRegex = /dailymotion\.com\/(?:video|playlist)\/([^_/]+)/gi;
  const match = urlRegex.exec(url);
  if (match?.length && match[1]) {
    //if there is a match, the second group is the id we want
    return match[1];
  }
}

function verifyVideoUrl(url, onSuccess, onError) {
  const videoId = getVideoIdFromUrl(url);
  if (!videoId) {
    onError();
  }
  const dataUrl = `https://api.dailymotion.com/video/${videoId}`;
  fetchJson(dataUrl, onSuccess, onError);
}

export { verifyVideoUrl, getVideoUrl, getVideoIdFromUrl };
