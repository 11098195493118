export default {
  CONCURRENT_USERS_POPUP_OK_CLICK: {
    evid: 494,
    src: 38,
    fields: {
      user: 'string',
      count: 'numeric',
      is_first: 'boolean',
    },
  },
  DUPLICATE_TAB_OPENED_NAVIGATE_AWAY: {
    evid: 1472,
    src: 38,
    fields: {
      esi: 'guid',
    },
  },
  CONCURRENT_USER_EDITING: {
    src: 38,
    evid: 1116,
    fields: {
      isSameUser: 'bool',
    },
  },
} as const;
