import coreUtilsLib from '@wix/santa-core-utils';

// 5xx server error status
export const isServerError = (status: number) => status >= 500 && status < 600;

export const fetchJson = coreUtilsLib.requestsUtil.fetchJson;
export const fetch = coreUtilsLib.requestsUtil.fetch;

export const fetchRetry = (
  url: string,
  fetchOptions = {},
  tries: number = 2,
): Promise<any> =>
  fetch(url, fetchOptions).catch((error: AnyFixMe) => {
    const triesLeft = tries - 1;

    if (triesLeft === 0 || !isServerError(error?.status)) {
      throw error;
    }

    return fetchRetry(url, fetchOptions, triesLeft);
  });
