// @ts-nocheck
import * as util from '#packages/util';
import constants from '#packages/constants';
import type { DSRead } from 'types/documentServices';

export interface TranslatedItem {
  translation: string;
  originalIndex: number;
  status: 'SUCCESS' | 'ITEM_TOO_LONG';
}

interface TranslationV3Response {
  translatedContent: {
    id: string;
    format: string;
    plainTextContent?: string;
    htmlContent?: string;
  };
}

export interface Translatable {
  text: string;
  text_format: string;
}
type TranslatableText = Translatable | Array<Translatable>;

const createTranslatableContent = (translatable: Translatable) => {
  switch (translatable.text_format) {
    case 'TEXT':
      return {
        format: 'PLAIN_TEXT',
        plainTextContent: translatable.text,
      };
    case 'HTML':
      return {
        format: 'HTML',
        htmlContent: translatable.text,
      };
  }
};

const translate = <T extends TranslatableText>(
  dsRead: DSRead,
  sourceLanguage: string,
  targetLanguage: string,
  text: T,
): Promise<T extends string ? TranslatedItem : TranslatedItem[]> => {
  const { instance } = dsRead.tpa.app.getDataByAppDefId(
    constants.MULTILINGUAL.linguistDefId,
  );

  return util.http
    .fetch('/_api/machine-translation/v3/machine-translate', {
      credentials: 'same-origin',
      method: 'POST',
      body: JSON.stringify({
        sourceLanguage,
        targetLanguage,
        contentToTranslate: createTranslatableContent(text),
      }),
      headers: new Headers({ Authorization: instance }),
    })
    .then((response) => response.json())
    .then((response: TranslationV3Response) => {
      return {
        translation:
          response.translatedContent.plainTextContent ||
          response.translatedContent.htmlContent,
        status: 'SUCCESS',
        originalIndex: 0,
      } as TranslatedItem;
    })
    .catch((err) => {
      if (err.json().message === 'Text exceeds the allowed string length.') {
        return {
          status: 'ITEM_TOO_LONG',
        };
      }
      throw err;
    });
};

interface TranslateAllSIteParams {
  originalLanguageId: string;
  currentLanguageId: string;
  currentLanguageFlag: string;
  instance: string;
}

const autoTranslateSite = ({
  originalLanguageId,
  currentLanguageId,
  currentLanguageFlag,
  instance,
}: TranslateAllSIteParams): Promise<void> => {
  return util.http.fetch('/_api/site-translator/v2/translate', {
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify({
      mainLanguage: { language: originalLanguageId },
      translated_language: {
        language: currentLanguageId,
        flag: currentLanguageFlag,
      },
    }),
    headers: new Headers({ Authorization: instance }),
  }) as unknown as Promise<void>;
};

export { translate, autoTranslateSite };
