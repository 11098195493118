import _ from 'lodash';
import type React from 'react';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompLayout, Docking } from 'types/documentServices';
import type { PinDockingDirection } from '#packages/pinModeUtils';

const OFFSET = 20;

const dockMap: Record<PinDockingDirection, Docking> = {
  TOP_LEFT: { top: { px: OFFSET }, left: { px: OFFSET } },
  TOP: { top: { px: OFFSET }, hCenter: { px: 0 } },
  TOP_RIGHT: { top: { px: OFFSET }, right: { px: OFFSET } },
  LEFT: { left: { px: OFFSET }, vCenter: { px: 0 } },
  MIDDLE: { hCenter: { px: 0 }, vCenter: { px: 0 } },
  RIGHT: { right: { px: OFFSET }, vCenter: { px: 0 } },
  BOTTOM_LEFT: { bottom: { px: OFFSET }, left: { px: OFFSET } },
  BOTTOM: { bottom: { px: OFFSET }, hCenter: { px: 0 } },
  BOTTOM_RIGHT: { bottom: { px: OFFSET }, right: { px: OFFSET } },
} as const;

/**
 * @deprecated use `PinDockingDirection` from `@/pinModeUtils` instead
 */
export type DockingDirection = PinDockingDirection;

/**
 * @deprecated use `getPinDockingDirection` from `@/pinModeUtils` instead
 */
function getDockedDirection(dockData: Docking): PinDockingDirection {
  if (!dockData) {
    return null;
  }

  return _.findKey(dockMap, (dockVal, dockKey) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/keys
    if (_.isEqual(_.keys(dockVal), _.keys(dockData))) {
      return dockKey;
    }
  }) as PinDockingDirection;
}

/**
 * @deprecated
 */
function getDiffBetweenBoundingAndOriginalLayout(layout: CompLayout) {
  const newWidth = (layout.bounding.width - layout.width) / 2;
  const newHeight = (layout.bounding.height - layout.height) / 2;
  return {
    additionalWidth: newWidth,
    additionalHeight: newHeight,
  };
}

/**
 * @deprecated
 */
function getAdditionOfDiffBoundingAndOriginalLayout(
  originalDock: Docking,
  additionalMargins: {
    additionalWidth: number;
    additionalHeight: number;
  },
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(originalDock.bottom)) {
    originalDock.bottom.px += additionalMargins.additionalHeight;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  } else if (!_.isUndefined(originalDock.top)) {
    originalDock.top.px += additionalMargins.additionalHeight;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(originalDock.right)) {
    originalDock.right.px += additionalMargins.additionalWidth;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  } else if (!_.isUndefined(originalDock.left)) {
    originalDock.left.px += additionalMargins.additionalWidth;
  }
  return originalDock;
}

/**
 * @deprecated
 */
function getDockPreviewStyle(
  editorAPI: EditorAPI,
  compLayout: CompLayout,
): React.CSSProperties {
  const additionalMargins = getDiffBetweenBoundingAndOriginalLayout(compLayout);
  const originalDock = _.cloneDeep(compLayout);
  originalDock.docked = getAdditionOfDiffBoundingAndOriginalLayout(
    originalDock.docked,
    additionalMargins,
  );

  const dockedPreviewStyle: React.CSSProperties =
    // @ts-expect-error
    editorAPI.components.layout.getDockedStyle(originalDock) || {};

  if (originalDock.rotationInDegrees) {
    dockedPreviewStyle.transform = `rotate(${originalDock.rotationInDegrees}deg)`;
  }
  dockedPreviewStyle.opacity = 0.5;
  dockedPreviewStyle.transitionProperty = 'opacity';
  dockedPreviewStyle.transitionDuration = '0.2s';

  return dockedPreviewStyle;
}

/**
 * @deprecated
 */
function getHorizontalOffset(dockedLayout: Docking) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(dockedLayout.left)) {
    return dockedLayout.left.px;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(dockedLayout.right)) {
    return dockedLayout.right.px;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(dockedLayout.hCenter)) {
    return dockedLayout.hCenter.px;
  }
}

/**
 * @deprecated
 */
function getVerticalOffset(dockedLayout: Docking) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(dockedLayout.top)) {
    return dockedLayout.top.px;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(dockedLayout.bottom)) {
    return dockedLayout.bottom.px;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(dockedLayout.vCenter)) {
    return dockedLayout.vCenter.px;
  }
}

/**
 * @deprecated
 */
function updateVerticalOffset(
  dockedLayout: Docking,
  newOffset: number,
  extraBounding = 0,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(dockedLayout.top)) {
    dockedLayout.top.px = newOffset + extraBounding;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(dockedLayout.bottom)) {
    dockedLayout.bottom.px = newOffset + extraBounding;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(dockedLayout.vCenter)) {
    dockedLayout.vCenter.px = newOffset;
  }

  return dockedLayout;
}

/**
 * @deprecated
 */
function updateHorizontalOffset(
  dockedLayout: Docking,
  newOffset: number,
  extraBounding = 0,
) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(dockedLayout.left)) {
    dockedLayout.left.px = newOffset + extraBounding;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(dockedLayout.right)) {
    dockedLayout.right.px = newOffset + extraBounding;
  }
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  if (!_.isUndefined(dockedLayout.hCenter)) {
    dockedLayout.hCenter.px = newOffset;
  }

  return dockedLayout;
}

export {
  OFFSET,
  dockMap,
  getDockedDirection,
  getDockPreviewStyle,
  getDiffBetweenBoundingAndOriginalLayout,
  getAdditionOfDiffBoundingAndOriginalLayout,
  getHorizontalOffset,
  getVerticalOffset,
  updateVerticalOffset,
  updateHorizontalOffset,
};
