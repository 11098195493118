//import { isNewWorkspaceEnabled } from '../workspace';

const isWelcomeTourEnabled = () => {
  return false;
};

const isNewWorkspaceTourEnabled = () => {
  // Until we qa'ed it
  return false;
  //return isNewWorkspaceEnabled();
};

export { isWelcomeTourEnabled, isNewWorkspaceTourEnabled };
