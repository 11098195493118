export const PANEL_SECTIONS = {
  'compPanels.panels.TextInput.settingsPanel': {
    INPUT_TYPE: 'inputType',
    LABEL: 'label',
    PLACEHOLDER: 'placeholder',
    INITIAL_VALUE: 'value',
    REQUIRED: 'required',
    READ_ONLY: 'readOnly',
    MAX_LENGTH: 'maxLength',
    MAX_NUMBER: 'max',
    MIN_NUMBER: 'min',
    PATTERN_VALIDATION: 'patternValidation',
    PHONE_FORMAT: 'phoneFormat',
  },
  'compPanels.panels.TextAreaInput.settingsPanel': {
    LABEL: 'label',
    PLACEHOLDER: 'placeholder',
    INITIAL_VALUE: 'value',
    REQUIRED: 'required',
    READ_ONLY: 'readOnly',
    MAX_LENGTH: 'maxLength',
  },
  'compPanels.panels.RadioGroup.settingsPanel': {
    LABEL: 'label',
    INITIAL_VALUE: 'value',
    REQUIRED: 'required',
    OPTIONS: 'options',
  },
  'compPanels.panels.ComboBoxInput.settingsPanel': {
    LABEL: 'label',
    PLACEHOLDER: 'placeholder',
    INITIAL_VALUE: 'value',
    REQUIRED: 'required',
    DROPDOWN_LIST_TYPE: 'dropdownListType',
  },
  'compPanels.panels.ComboBoxInput.managePanel': {
    SETTINGS: 'settings',
  },
  'compPanels.panels.Checkbox.settingsPanel': {
    LABEL: 'label',
    VALUE: 'value',
    REQUIRED: 'required',
    CHECKED: 'checked',
    LINK: 'link',
  },
  'compPanels.panels.DatePicker.settingsPanel': {
    INPUT_LABEL: 'label',
    INPUT_INITIAL_VALUE: 'value',
    INPUT_PLACEHOLDER: 'placeholder',
    INPUT_REQUIRED: 'required',
    INPUT_READ_ONLY: 'readOnly',
    INPUT_FORMAT: 'format',
    CALENDAR_DISABLED_DAYS: 'disabledDays',
    CALENDAR_WEEK_STARTS_ON: 'weekStartsOn',
    CALENDAR_DISABLED_DAYS_OF_WEEK: 'disabledDaysOfWeek',
    CALENDAR_TIME_SLOTS: 'timeSlots',
  },
  'compPanels.panels.FileUploader.settingsPanel': {
    VIEW_FILES: 'viewFiles',
    FILE_TYPE: 'fileType',
    MULTI_FILES_UPLOAD: 'multiFilesUpload',
    LABEL: 'label',
    BUTTON_LABEL: 'buttonLabel',
    PLACEHOLDER: 'placeholder',
    REQUIRED: 'required',
  },
  'compPanels.panels.TimePicker.settingsPanel': {
    LABEL: 'label',
    USE_AM_PM_FORMAT: 'useAmPmFormat',
    INPUT_OPTION: 'inputOption',
    INITIAL_TIME: 'initialTime',
    INITIAL_TEXT_NOTHING: 'initialTextNothing',
    INITIAL_TEXT_PLACEHOLDER: 'initialTextPlaceholder',
    INITIAL_TEXT_CURRENT_TIME: 'initialTextCurrentTime',
    INITIAL_TEXT_SPECIFIC_TIME: 'initialTextSpecificTime',
    STEP: 'step',
    REQUIRED: 'required',
    READ_ONLY: 'readOnly',
    DISABLED_TIMES: 'disabledTimes',
  },
  'compPanels.panels.SiteButton.settingsPanel': {
    LABEL: 'label',
    LINK: 'link',
  },
  'compPanels.panels.WPhoto.settingsPanel': {
    IMAGE_CHANGE_ADJUST: 'changeAdjust',
    IMAGE_BEHAVIORS: 'behaviors',
    ALT_TEXT: 'altText',
    TOOLTIP: 'tooltip',
  },
  'compPanels.panels.SlideShowGallery.settingsPanel': {
    CHANGE_IMAGES: 'changeImages',
    LINK: 'link',
  },
  'compPanels.panels.PaginatedGridGallery.settingsPanel': {
    CHANGE_IMAGES: 'changeImages',
    LINK: 'link',
  },
  'compPanels.panels.MatrixGallery.settingsPanel': {
    CHANGE_IMAGES: 'changeImages',
    LINK: 'link',
  },
  'compPanels.panels.SliderGallery.settingsPanel': {
    CHANGE_IMAGES: 'changeImages',
    LINK: 'link',
  },
  'compPanels.panels.VideoPlayer.settingsPanel': {
    CHANGE_VIDEO: 'changeVideo',
    VIDEO_DESCRIPTION: 'videoDescription',
    PLAYBACK: 'playback',
    WIX_VIDEO_APP_MARKET_LINK: 'wixVideoAppMarketLink',
  },
  'compPanels.panels.ToggleSwitch.settingsPanel': {
    TOGGLE_ON: 'toggleOn',
  },
  'compPanels.panels.AddressInput.settingsPanel': {
    PLACEHOLDER: 'placeholder',
    READ_ONLY: 'readOnly',
    REQUIRED: 'required',
    LABEL: 'label',
  },
  'compPanels.panels.MusicPlayer.settingsPanel': {
    UPLOAD_IMAGE: 'uploadImage',
    CHOOSE_TRACK: 'chooseTrack',
    TRACK_DETAILS: 'trackDetails',
  },
  'compPanels.panels.MediaPlayer.settingsPanel': {
    CHANGE_VIDEO: 'changeVideo',
    RESET_VIDEO: 'resetVideo',
    VIDEO_DESCRIPTION: 'videoDescription',
  },
  'compPanels.panels.VectorImage.settingsPanel': {
    LINK: 'link',
    DESCRIPTION: 'description',
    RESIZING: 'resizing',
  },
  'compPanels.panels.PopupContainer.settingsPanel': {
    NAME: 'name',
    DISPLAY: 'display',
    DELAY: 'delay',
    PAGES: 'pages',
    LINKS: 'links',
    X_ICON_TOGGLE: 'xIconToggle',
    CLOSE_BUTTON: 'closeButton',
  },
} as const;
