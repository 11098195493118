import experiment from 'experiment';
import { siteGeneration } from '@wix/santa-editor-utils';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

import type { EditorAPI } from '#packages/editorAPI';

export const isNewAnimationsEnabled = (editorAPI: EditorAPI) => {
  if (isResponsiveEditor()) {
    return false;
  }

  return (
    editorAPI.components.effects?.usesNewAnimations() ||
    experiment.isOpen('se_newAnimationPanel') ||
    siteGeneration.isSiteGenerationTemplate()
  );
};
