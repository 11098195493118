export const pickBy = (
  object: Record<string, unknown>,
  predicate: (value: unknown, key: string) => boolean,
) =>
  Object.fromEntries(
    Object.entries(object).filter(([key, value]) => predicate(value, key)),
  );

const replaceValue1 = `###REPLACE_VALUE_1###`;
const replaceValue2 = `###REPLACE_VALUE_2###`;
export function invertObjectByEntries<T>(
  object: T,
  entriesToInvert: string[][],
): T {
  let stringifiedObject = JSON.stringify(object);
  entriesToInvert.forEach(([str1, str2]) => {
    const str1Reg = new RegExp(str1, 'g');
    const str2Reg = new RegExp(str2, 'g');
    stringifiedObject = stringifiedObject.replace(str1Reg, replaceValue1);
    stringifiedObject = stringifiedObject.replace(str2Reg, replaceValue2);

    stringifiedObject = stringifiedObject.replace(
      new RegExp(replaceValue1, 'g'),
      str2,
    );
    stringifiedObject = stringifiedObject.replace(
      new RegExp(replaceValue2, 'g'),
      str1,
    );
  });
  return JSON.parse(stringifiedObject);
}

/**
 * Compared to "invertObjectByEntries" function in this file, invertObjectByKeyToValueEntries handles cases when entries can be repeated in the given array.
 * for example:
 * switching numbers:  1->3,  3->4, 4->1
 * This example wouldn't work in above "invertObjectByEntries" because it goes over all object once, and will end with unexpected results since repeated entries will get the last replace call.
 * Of course, this function has to go over the object twice, after providing a unique string to each entry.
 *
 * @param object - any object
 * @param keyToValueEntries key to value object to invert
 */
export function invertObjectByKeyToValueEntries<T>(
  object: T,
  keyToValueEntries: Record<string, string>,
): T {
  function getEntryUniqueExpression(entry: string) {
    return `#####${entry}#####`;
  }
  let stringifiedObject = JSON.stringify(object);

  Object.entries(keyToValueEntries).forEach(([key]) => {
    const keyReg = new RegExp(key, 'g');
    stringifiedObject = stringifiedObject.replace(
      keyReg,
      getEntryUniqueExpression(key),
    );
  });

  Object.entries(keyToValueEntries).forEach(([key, value]) => {
    const keyUniqueReg = new RegExp(getEntryUniqueExpression(key), 'g');
    stringifiedObject = stringifiedObject.replace(keyUniqueReg, value);
  });

  return JSON.parse(stringifiedObject);
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const objectToEntries = <T extends {}>(obj: T) => {
  return Object.entries(obj) as Entries<T>;
};
