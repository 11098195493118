import constants from '#packages/constants';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from '@wix/document-services-types';
import type { CSSProperties } from 'react';

const componentsWithCss: Record<
  string,
  (
    editorAPI: EditorAPI,
    compType: string,
    compRef: CompRef,
  ) => CSSProperties | undefined
> = {
  [constants.COMP_TYPES.CONTAINER]: (editorAPI, compType, compRef) => {
    const bg = editorAPI.components.style.get(compRef).style.properties.bg;
    return {
      fill: bg ? editorAPI.theme.colors.get(bg) : 'transparent',
    };
  },
};
export const getStyleForSvg = (
  editorAPI: EditorAPI,
  compType: string,
  compRef: CompRef,
) => {
  return componentsWithCss[compType]?.(editorAPI, compType, compRef);
};
