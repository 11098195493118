// @ts-nocheck
import _ from 'lodash';

const COLLECTION_NAMESPACE_PREFIX = 'collection-namespace=';

function createPrefixForCollectionNamespace(collectionNamespace) {
  return `${COLLECTION_NAMESPACE_PREFIX}${collectionNamespace}`;
}

function isCollectionNamespacePrefix(prefix) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/starts-with
  return _.startsWith(prefix, COLLECTION_NAMESPACE_PREFIX);
}

function getCollectionNamespaceFromPrefix(prefix) {
  return prefix.substring(COLLECTION_NAMESPACE_PREFIX.length);
}

const COLLECTION_ID_PREFIX = 'collection-id=';

function createPrefixForCollectionId(collectionId) {
  return `${COLLECTION_ID_PREFIX}${collectionId}`;
}

function isCollectionIdPrefix(prefix) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/starts-with
  return _.startsWith(prefix, COLLECTION_ID_PREFIX);
}

function getCollectionIdFromPrefix(prefix) {
  return prefix.substring(COLLECTION_ID_PREFIX.length);
}

function isValidPrefix(prefix) {
  return isCollectionNamespacePrefix(prefix) || isCollectionIdPrefix(prefix);
}

function getAddPageEventPayloadByPrefix(prefix) {
  if (isCollectionNamespacePrefix(prefix)) {
    return { namespace: getCollectionNamespaceFromPrefix(prefix) };
  }

  if (isCollectionIdPrefix(prefix)) {
    return { collectionName: getCollectionIdFromPrefix(prefix) };
  }
}

export {
  createPrefixForCollectionNamespace,
  createPrefixForCollectionId,
  isValidPrefix,
  getAddPageEventPayloadByPrefix,
};
