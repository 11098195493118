import { VALIDATOR_ERROR_MESSAGES } from './errorMessages';
import type { ValidatorWithConfig } from './types';

interface MinLengthValidatorErordata {
  minLength: number;
  length: number;
}
type MinLengthValidator = ValidatorWithConfig<
  string,
  number,
  MinLengthValidatorErordata
>;
export const minLengthValidator: MinLengthValidator =
  (minLength) => (value) => {
    if (value.length < minLength) {
      return {
        message: VALIDATOR_ERROR_MESSAGES.MIN_LENGHT_ERROR,
        additionalData: {
          minLength,
          length: value.length,
        },
      };
    }
    return null;
  };

interface MaxLengthValidatorErordata {
  maxLength: number;
  length: number;
}
type MaxLengthValidator = ValidatorWithConfig<
  string,
  number,
  MaxLengthValidatorErordata
>;
export const maxLengthValidator: MaxLengthValidator =
  (maxLength) => (value) => {
    if (value.length > maxLength) {
      return {
        message: VALIDATOR_ERROR_MESSAGES.MAX_LENGTH_ERROR,
        additionalData: {
          maxLength,
          length: value.length,
        },
      };
    }
    return null;
  };

export type RequiredValidatorErrorData = undefined;
export const requiredValidator: ValidatorWithConfig<
  string,
  null,
  RequiredValidatorErrorData
> = () => (value) => {
  if (!value) {
    return {
      message: VALIDATOR_ERROR_MESSAGES.REQUIRED_ERROR,
    };
  }
  return null;
};
