// @ts-nocheck
import _ from 'lodash';
import media from '../media/media';
import headerScrollTypesData from './headerScrollTypesData.json';

const viewModePath = (viewMode) =>
  viewMode === 'MOBILE' ? 'mobile' : 'desktop';
const getBehaviorName = (option, viewMode) =>
  option[viewModePath(viewMode)].behaviorName;
const formatImageSrc = (src) =>
  media.getMediaUrl(`compPanels/headerContainer/${src}`);

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line you-dont-need-lodash-underscore/map
const headerScrollTypesModel = _.map(headerScrollTypesData, (option) => {
  option.desktop.sources = _.mapValues(option.desktop.sources, formatImageSrc);
  option.mobile.sources = _.mapValues(option.mobile.sources, formatImageSrc);
  return option;
});

const getDefaultHeaderType = () =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  _.find(headerScrollTypesModel, { isDefault: true });

const getSelectedHeaderType = (
  fixedPosition: boolean | undefined,
  compBehaviors = [],
  viewMode,
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const behavior = _.find(compBehaviors, { type: 'scrub', viewMode });

  const headerScrollTypesModelForViewMode = headerScrollTypesModel.map(
    (option) => {
      const behaviorName = getBehaviorName(option, viewMode);

      Object.assign(option.value, { behaviorName });

      return option;
    },
  );

  return headerScrollTypesModelForViewMode.find((option) => {
    const isFixed = option.value.fixedPosition === (fixedPosition ?? false);

    if (behavior) {
      const sameBehavior =
        behavior.name === option?.[viewModePath(viewMode)]?.behaviorName;
      const behaviorAnimation = _.head(behavior?.params?.animations ?? []);
      const sameAnimation =
        behaviorAnimation?.name === option.value.animationName;
      return isFixed && sameBehavior && behaviorAnimation && sameAnimation;
    }

    return isFixed;
  });
};

const getHeaderTypeThumbnailImage = (
  option,
  selectedHeaderType,
  hoveredHeaderType,
  viewMode,
) => {
  const isHovered = _.isEqual(option.value.scrollType, hoveredHeaderType);
  const isSelected = _.isEqual(option.value.scrollType, selectedHeaderType);

  let currentSource;

  if (isSelected) {
    const noThumbnailIsHovered = !hoveredHeaderType;
    currentSource =
      isHovered || noThumbnailIsHovered ? 'selectedPlaying' : 'selectedPaused';
  } else {
    currentSource = isHovered ? 'playing' : 'paused';
  }

  return option[viewModePath(viewMode)].sources[currentSource];
};

export {
  headerScrollTypesModel,
  getBehaviorName,
  getDefaultHeaderType,
  getSelectedHeaderType,
  getHeaderTypeThumbnailImage,
};
