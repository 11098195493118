import type { CompClusterGroupingConfig } from './types';

export const DEFAULT_GROUPING_CONFIG: CompClusterGroupingConfig = {
  minimumSectionHeight: 240,
  blockComponentWidth: 700,
  mergeSectionsThreshold: -3,
  blockCompMergeThreshold: -3,
  smallCompMergeThreshold: 30,
  sectionsMergeMaxDistance: 30,
  smallAndBlockMergeThreshold: 0.5,
};
