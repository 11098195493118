import _ from 'lodash';
import type { CompStructure } from '@wix/document-services-types';
import { ESocialPlatform } from '@wix/adi-core-types';
import { platformDataMap, urlValidation } from './socialLinksConsts';
import type { UserData } from '@wix/editor-content-provider';
import type { SocialLink } from './types';

export const isURLInvalid = (url: string) => {
  if (!url) {
    return false;
  }
  return !urlValidation.test(url);
};

const replaceSocialLinksData = (
  compStructure: CompStructure,
  socialLinks: Array<Partial<SocialLink>>,
) => {
  const [firstSocialLinkItem] = compStructure.data.items;
  const socialLinkDataItem = { ...firstSocialLinkItem };
  compStructure.data.items = socialLinks.map((socialLink) => ({
    ...socialLinkDataItem,
    title: socialLink.Title,
    id: socialLink.id,
    uri: socialLink.relativeUri,
    link: { ...socialLinkDataItem.link, url: socialLink.url },
  }));
  return compStructure;
};

export const getPlatformByURL = (url: string): ESocialPlatform => {
  return Object.keys(platformDataMap).find((platformName) =>
    url.toLowerCase().match(platformDataMap[platformName].regex),
  ) as ESocialPlatform;
};

export const injectSocialLinks = (
  singleCompStructure: CompStructure,
  userData: UserData,
) => {
  const getRelativeUri = (platform: ESocialPlatform) =>
    platformDataMap[platform].relativeUriLight;

  const socialLinks = userData.socialLinks.map((url) => {
    const platform = getPlatformByURL(url) || ESocialPlatform.VALID_CUSTOM_LINK;
    return {
      url,
      platform,
      Title: platformDataMap[platform].Title,
      icon: platformDataMap[platform].icon,
      relativeUri: getRelativeUri(platform),
    };
  });

  replaceSocialLinksData(singleCompStructure, socialLinks);
};

export const getIsSocialLinksBar = (compStructure: CompStructure) => {
  return compStructure.componentType === 'wysiwyg.viewer.components.LinkBar'; // TODO
};

export function getDefaultLink(): SocialLink {
  return {
    id: _.uniqueId(),
    url: '',
    Title: platformDataMap[ESocialPlatform.VALID_CUSTOM_LINK].Title,
    relativeUri:
      platformDataMap[ESocialPlatform.VALID_CUSTOM_LINK].relativeUriLight,
    platform: ESocialPlatform.VALID_CUSTOM_LINK,
  };
}
