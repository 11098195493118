// @ts-nocheck
import _ from 'lodash';
import * as editorSessionUtil from '../editorSessionUtil/editorSessionUtil';

const EDITOR_TOP_BAR_BI_TRIGGER = 'Editor_top_bar';

const initUnifiedChatWidget = (appData, metaSiteId, initUnifiedChatState) => {
  if (isAnswerWidgetInitialized()) {
    return;
  }
  const doc = window.document;
  const answerScriptElement = doc.createElement('script');
  answerScriptElement.type = 'text/javascript';
  answerScriptElement.async = true;
  answerScriptElement.src =
    'https://apps.wix.com/support-chat-widget/script.js';

  answerScriptElement.addEventListener('load', () => {
    window.WixSupportChatSDK.answersScriptLoaded.then(() => {
      window.WixSupportChatSDK._init({
        authorizationToken: appData?.instance,
        origin: 'Editor',
        horizontalOffset: 12,
        verticalOffset: 102,
        msid: metaSiteId,
        sessionId: editorSessionUtil.editorSessionId,
      }).then((res) => initUnifiedChatState(res?.initResult?.visible ?? false));
    });
  });
  const scriptElement = _.head(doc.getElementsByTagName('script'));
  if (scriptElement) {
    scriptElement.parentNode.insertBefore(answerScriptElement, scriptElement);
  }
};

const isAnswerWidgetInitialized = () => !!window.AnswersWidget;

const isUnifiedAnswerWidgetInitialized = () =>
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/is-undefined
  _.isUndefined(window.WixSupportChatSDK?._init);

const showChat = () =>
  _.invoke(window.WixSupportChatSDK, 'show', EDITOR_TOP_BAR_BI_TRIGGER);

const hideChat = () =>
  _.invoke(window.WixSupportChatSDK, 'hide', EDITOR_TOP_BAR_BI_TRIGGER);

export {
  initUnifiedChatWidget,
  isAnswerWidgetInitialized,
  isUnifiedAnswerWidgetInitialized,
  showChat,
  hideChat,
};
