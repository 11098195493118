import { getSectionsBoundaries } from './getSectionsBoundaries';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

// https://github.com/wix-private/santa-editor/blob/0d7e37061e4b60969e056c3cc0552693a1d62d33/santa-editor/packages/rEditor/rootComps/anchor/anchor.scss#L68
const ANCHOR_HEIGHT = 24;

export function getAnchorPositionBoundaries(
  editorAPI: EditorAPI,
  {
    pageRef,
    anchorHeight = ANCHOR_HEIGHT,
    useRelativeToStructureLayout,
  }: {
    pageRef: CompRef;
    anchorHeight?: number;
    useRelativeToStructureLayout?: boolean;
  },
): { topY: number; bottomY: number } {
  const { topY: sectionsTopY, bottomY: sectionsBottomY } =
    getSectionsBoundaries(editorAPI, { pageRef, useRelativeToStructureLayout });

  // NOTE: `topY` of anchor editor UI control positioned
  // - not at the viewer component `Y` (start of anchor component !== start of anchor UI control)
  // - but on the `Y - ANCHOR_HEIGHT / 2` (start of anchor component === center of anchor UI control)
  const anchorTopYBoundary = sectionsTopY;
  const anchorBottomYBoundary = sectionsBottomY - anchorHeight / 2;

  return {
    topY: anchorTopYBoundary,
    bottomY: anchorBottomYBoundary,
  };
}
