import _ from 'lodash';
import constants from '#packages/constants';
import browserDetection from '@wix/santa-browser-detection';
import { UAParser } from 'ua-parser-js';
import type { DEVICE, IDevice } from 'ua-parser-js';
import { isDebugMode } from '../utils/debugMode';
import translate from '../translation/translate';

import type { MouseEvent } from 'react';

const detected = browserDetection(getUserAgent());

function getEditorTitleBySiteName(siteName: string) {
  return `${translate(constants.TAB_TITLE_KEYS.EDITOR)} | ${siteName}`;
}

function setTabTitle(newTitle: string) {
  window.document.title = getEditorTitleBySiteName(newTitle);
}

function getUserAgent() {
  return window.navigator?.userAgent;
}

function isMacintosh() {
  return !!detected.os.mac;
}

function isChrome() {
  return !!detected.browser.chrome;
}

function isFirefox() {
  return !!detected.browser.firefox;
}

/**
 * Most code taken from http://forums.mozillazine.org/viewtopic.php?f=25&t=2268557
 * in Firefox, document.getSelection().toString() does not return selected text.
 *
 * returns the browser's selected string.
 */
function getBrowserSelectedText(): string {
  let userSelection, el;
  if (window.getSelection && document.activeElement) {
    if (
      document.activeElement.nodeName == 'TEXTAREA' ||
      (document.activeElement.nodeName == 'INPUT' &&
        document.activeElement.getAttribute('type').toLowerCase() == 'text')
    ) {
      el = document.activeElement as AnyFixMe;
      userSelection = el.value.substring(el.selectionStart, el.selectionEnd);
    } else {
      userSelection = window.getSelection();
    }
    return userSelection.toString();
  }
  return document.getSelection().toString();
}

function isEdge() {
  return !!detected.browser.edge;
}

function isSafari() {
  return !!detected.browser.safari;
}

function isDeprecatedBrowser() {
  return !!getDeprecatedBrowser();
}

function getDeprecatedBrowser() {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/split
  const version = _.head(_.split(detected.browser.version, '.'));

  // Before dropping support of old browsers, and updating babel-preset-env(.browserslistrc),
  // we show a deprecation message to the users of these browsers (for a week or more before stopping support).

  if (isChrome() && parseInt(version, 10) < 75) {
    return 'chrome';
  }
}

function isSpecialKeyPressed(event: AnyFixMe) {
  if (isMacintosh()) {
    return event.metaKey;
  }

  return event.ctrlKey;
}

function getDevicePixelRatio() {
  return window.devicePixelRatio;
}

function isRetina() {
  return getDevicePixelRatio() >= 2;
}

const getKeyboardSpecialKey = () => ({
  label: isMacintosh() ? '⌘' : translate('Keyboard_Shortcut_Ctrl'),
  value: isMacintosh() ? 'command' : 'ctrl',
});

const getAltSpecialKey = () => ({
  label: isMacintosh() ? '⌥' : 'Alt',
  value: isMacintosh() ? 'option' : 'alt',
});

const getShiftKey = () => ({
  label: translate('Keyboard_Shortcut_Shift'),
  value: 'shift',
});

const preventDefaultRightClick = (e: AnyFixMe) => {
  const contextMenuEnabled = e.ctrlKey && isDebugMode();

  if (!contextMenuEnabled) {
    e.preventDefault();
  }
};

const isLeftClick = (e: MouseEvent) => e.button === 0;
const isRightClick = (e: MouseEvent) => e.button === 2;

type GetDeviceKeys = keyof IDevice;
type UAParserDeviceType = ValueOf<Omit<DEVICE, Uppercase<GetDeviceKeys>>>;

function getDeviceType(): 'desktop' | 'tablet' | 'mobile' | 'other' {
  const parserInstance = new UAParser();

  const parsedValue = parserInstance.getDevice().type as UAParserDeviceType;

  if (parsedValue === null || parsedValue === undefined) return 'desktop';
  if (parsedValue === 'tablet') return 'tablet';
  if (parsedValue === 'mobile') return 'mobile';

  return 'other';
}

function getScreenOrientation(): 'portrait' | 'landscape' {
  if (window.matchMedia('(orientation: portrait)').matches) {
    return 'portrait';
  }

  if (window.matchMedia('(orientation: landscape)').matches) {
    return 'landscape';
  }

  return undefined;
}

function isTouchSupported() {
  return matchMedia('(hover: none), (pointer: coarse)').matches;
}

export {
  setTabTitle,
  getDeprecatedBrowser,
  getUserAgent,
  getDevicePixelRatio,
  getKeyboardSpecialKey,
  getEditorTitleBySiteName,
  getAltSpecialKey,
  getShiftKey,
  getBrowserSelectedText,
  isMacintosh,
  isChrome,
  isEdge,
  isSafari,
  isFirefox,
  isDeprecatedBrowser,
  isRetina,
  isSpecialKeyPressed,
  isLeftClick,
  isRightClick,
  preventDefaultRightClick,
  getDeviceType,
  getScreenOrientation,
  isTouchSupported,
};
