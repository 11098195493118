import { SerializedCompStructure } from '@wix/document-services-types';
import {
  getImgObj,
  getVideoObj,
  isImageBackground,
  isMediaPlayer,
  isSupportedVideoPlayer,
  isTransparentVideo,
  isVideoBackground,
} from './mediaUtils';
import { getRepeaterItems } from './utils';
import { getInnerTextString } from './textUtils';
import { replaceComponentInnerText } from './injectors';
import _ from 'lodash';
import { StructureByIdEntry } from './types';
import { TEXT_MASK_DATA_TYPE } from './consts';

const getTextFromStructure = (
  structure: SerializedCompStructure,
  itemKey?: string,
): string | undefined => {
  if (itemKey) {
    return structure.data.overrides[itemKey]?.text;
  }
  return structure.data.text;
};
const injectTextFromComponentToComponent = (
  source: SerializedCompStructure,
  target: SerializedCompStructure,
  itemKey?: string,
): void => {
  const sourceText = getTextFromStructure(source, itemKey);
  const targetText = getTextFromStructure(target, itemKey);

  if (!sourceText || !targetText) {
    return;
  }

  const desktopInnerText = getInnerTextString(targetText);
  const injectedText =
    source.data.type === TEXT_MASK_DATA_TYPE
      ? targetText
      : replaceComponentInnerText(sourceText, desktopInnerText);
  if (itemKey && source.data.overrides[itemKey]) {
    source.data.overrides[itemKey].text = injectedText;
    return;
  }

  if (source.data.text) {
    source.data.text = injectedText;
  }
};

function injectAlignType(
  target: SerializedCompStructure,
  source: SerializedCompStructure,
  itemKey?: string,
) {
  if (itemKey && source.design?.overrides?.[itemKey]?.background?.alignType) {
    source.design.overrides[itemKey].background.alignType =
      target.design?.overrides?.[itemKey]?.background?.alignType;
    return;
  }

  if (target.design?.background?.alignType) {
    source.design.background.alignType = target.design.background.alignType;
    return;
  }
}

const injectImageFromComponentToComponent = (
  source: SerializedCompStructure,
  target: SerializedCompStructure,
  itemKey?: string,
): void => {
  const sourceImageObj = getImgObj(source, itemKey);
  const targetImageObj = getImgObj(target, itemKey);

  if (!sourceImageObj || !targetImageObj) {
    return;
  }

  if (isImageBackground(target)) {
    injectAlignType(target, source, itemKey);
  }

  if (targetImageObj.crop) {
    sourceImageObj.crop = targetImageObj.crop;
  }
  sourceImageObj.uri = targetImageObj.uri;
  sourceImageObj.width = targetImageObj.width;
  sourceImageObj.height = targetImageObj.height;
  sourceImageObj.alt = targetImageObj.alt;
  sourceImageObj.title = targetImageObj.title;
  if (targetImageObj.hasAnimation) {
    sourceImageObj.hasAnimation = targetImageObj.hasAnimation;
  }
  if (targetImageObj.description) {
    sourceImageObj.description = targetImageObj.description;
  }
};

const injectVideoFromComponentToComponent = (
  source: SerializedCompStructure,
  target: SerializedCompStructure,
  itemKey?: string,
): void => {
  const isSupported =
    isMediaPlayer(target) ||
    isSupportedVideoPlayer(target, itemKey) ||
    isVideoBackground(target);

  if (!isSupported || isTransparentVideo(target)) {
    return;
  }

  const sourceVideoObj = getVideoObj(source, itemKey);
  const targetVideoObj = getVideoObj(target, itemKey);

  if (!sourceVideoObj || !targetVideoObj) {
    return;
  }

  sourceVideoObj.duration = targetVideoObj.duration;
  sourceVideoObj.videoId = targetVideoObj.videoId;
  sourceVideoObj.title = targetVideoObj.title;
  sourceVideoObj.posterImageRef.description =
    targetVideoObj.posterImageRef.description;
  sourceVideoObj.posterImageRef.uri = targetVideoObj.posterImageRef.uri;
  sourceVideoObj.adaptiveVideo = targetVideoObj.adaptiveVideo;

  sourceVideoObj.qualities = _.cloneDeep(targetVideoObj.qualities);
  if (sourceVideoObj.generatedPosters && targetVideoObj.generatedPosters) {
    sourceVideoObj.generatedPosters = _.cloneDeep(
      targetVideoObj.generatedPosters,
    );
  }

  sourceVideoObj.fps = targetVideoObj.fps;
  sourceVideoObj.hasAudio = targetVideoObj.hasAudio;

  if (isSupportedVideoPlayer(target, itemKey)) {
    sourceVideoObj.posterImageRef.height = targetVideoObj.posterImageRef.height;
    sourceVideoObj.posterImageRef.width = targetVideoObj.posterImageRef.width;
  }

  if (isMediaPlayer(target) || isVideoBackground(target)) {
    sourceVideoObj.artist.id = targetVideoObj.artist.id;
    sourceVideoObj.artist.name = targetVideoObj.artist.name;
  }
};

const injectBackgroundFromComponentToComponent = (
  source: SerializedCompStructure,
  target: SerializedCompStructure,
  itemKey?: string,
): void => {
  if (isImageBackground(target)) {
    injectImageFromComponentToComponent(source, target, itemKey);
    return;
  }

  if (isVideoBackground(target)) {
    injectVideoFromComponentToComponent(source, target, itemKey);
  }
};

const injectVectorImageFromComponentToComponent = (
  source: SerializedCompStructure,
  target: SerializedCompStructure,
): void => {
  if (!source.data.svgId || !target.data.svgId) {
    return;
  }
  source.data.svgId = target.data.svgId;
  source.data.title = target.data.title;
};

const componentTypeToInjector = {
  'wysiwyg.viewer.components.WRichText': injectTextFromComponentToComponent,
  'wixui.TextMask': injectTextFromComponentToComponent,
  'wysiwyg.viewer.components.WPhoto': injectImageFromComponentToComponent,
  'wysiwyg.viewer.components.MediaPlayer': injectVideoFromComponentToComponent,
  'wixui.VideoPlayer': injectVideoFromComponentToComponent,
  'wysiwyg.viewer.components.ClassicSection':
    injectBackgroundFromComponentToComponent,
  'wysiwyg.viewer.components.StripColumnsContainer':
    injectBackgroundFromComponentToComponent,
  'wysiwyg.viewer.components.Column': injectBackgroundFromComponentToComponent,
  'wysiwyg.viewer.components.MediaContainer':
    injectBackgroundFromComponentToComponent,
  'wysiwyg.viewer.components.StripContainerSlideShowSlide':
    injectBackgroundFromComponentToComponent,
  'wysiwyg.viewer.components.VectorImage':
    injectVectorImageFromComponentToComponent,
};

const injectContentFromComponentToComponent = (
  source: SerializedCompStructure,
  target: SerializedCompStructure,
  itemKey?: string,
): void => {
  if (!source.componentType || source.componentType !== target.componentType) {
    return;
  }

  componentTypeToInjector[source.componentType]?.(source, target, itemKey);
};

const mapStructureByIdRecursive = (
  presetStructure: SerializedCompStructure,
  structureByIdEntry: StructureByIdEntry,
): void => {
  structureByIdEntry[presetStructure.id!] = presetStructure;
  presetStructure.components?.forEach((childStructure) => {
    mapStructureByIdRecursive(childStructure, structureByIdEntry);
  });
};
const mapStructureById = (
  presetStructure: SerializedCompStructure,
): StructureByIdEntry => {
  const structureByCompTypeEntry: StructureByIdEntry = {};
  mapStructureByIdRecursive(presetStructure, structureByCompTypeEntry);
  return structureByCompTypeEntry;
};

export const injectContentFromStructureToStructure = (
  source: SerializedCompStructure,
  target: SerializedCompStructure,
): void => {
  const compIdToDesktopStructureMap = mapStructureById(target);
  injectContentFromStructureToStructureRecoursivly(
    source,
    compIdToDesktopStructureMap,
  );
};

function injectContentFromStructureToStructureRecoursivly(
  source: SerializedCompStructure,
  compIdToTargetStructureMap: StructureByIdEntry,
) {
  if (source?.components && source?.components?.length > 0) {
    source.components.forEach((childComponentStructure) => {
      injectContentFromStructureToStructureRecoursivly(
        childComponentStructure,
        compIdToTargetStructureMap,
      );
    });
  }
  if (!source?.id || !compIdToTargetStructureMap[source?.id]) {
    return;
  }

  const target = compIdToTargetStructureMap[source?.id];

  const overrides = getRepeaterItems(source);
  if (overrides) {
    Object.keys(overrides).forEach((key) => {
      injectContentFromComponentToComponent(source, target, key);
    });
  }

  injectContentFromComponentToComponent(source, target);
}
