class PromiseTimeoutError extends Error {
  public timeout: number;

  constructor(message: string, timeout: number) {
    super(message);

    this.name = this.constructor.name;
    this.timeout = timeout;
  }
}

export function isPromiseTimeoutError(
  error: Error,
): error is PromiseTimeoutError {
  return error instanceof PromiseTimeoutError;
}

export function withPromiseTimeout<TValue>(
  promise: Promise<TValue>,
  timeout: number,
) {
  return Promise.race<TValue>([
    promise,
    new Promise<TValue>((resolve, reject) => {
      setTimeout(
        () =>
          reject(
            new PromiseTimeoutError(`promise timeout has passed`, timeout),
          ),
        timeout,
      );
    }),
  ]);
}

export async function waitFor(ms: number) {
  return new Promise((res) => setTimeout(res, ms));
}
