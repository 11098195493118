import type { CompWithLayout } from './types';

export const isBlockComponent = (
  comp: CompWithLayout,
  threshold: number,
): boolean => {
  return comp.layout.width >= threshold;
};

export const sortByLayoutY = (
  compA: CompWithLayout,
  compB: CompWithLayout,
): number => (compA.layout.y > compB.layout.y ? 1 : -1);
