import type { PublicApiKey } from '#packages/apilib';
import type { createMenuApi } from './menuWrapper';
import type { createAdvancedMenu } from './API/createAdvancedMenu';

export const MenuApiKey: PublicApiKey<typeof createMenuApi> = {
  name: 'MenuApi',
  public: true,
};

export const AdvancedMenuApiKey: PublicApiKey<typeof createAdvancedMenu> = {
  name: 'AdvancedMenuApi',
  public: true,
};
