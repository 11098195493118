export const SUPER_APPS = {
  BLOG_PANEL_NAME: 'blog.menuPanel',
  NEW_BLOG_PANEL_NAME: 'blog.newMenuPanel',
  SHOUTOUT_PANEL_NAME: 'savePublish.panels.publish.shoutOut',
  WIX_STORE_PANEL_NAME: 'wixStore.panels.wixStorePanel',
  BOOKINGS_PANEL_NAME: 'wixBookings.panels.bookingsPanel',
  WIX_DATA_PANEL_NAME: 'wixData.panel',
  ASCEND_PANEL_NAME: 'ascend.panel',
  PROMOTE_PANEL_NAME: 'promote.panel',
  MENU_PANEL_TAB_NAMES: {
    MANAGE: 'Manage',
    PAGES: 'Pages',
    ADD: 'Add',
    LEARN_MORE: 'LearnMore',
  },
} as const;
