const defaultStylesMap = {
  h1: {
    seoTag: 'h1',
    label: 'FONT_0_LABEL',
    tag: 'h1',
    displayName: 'text_editor_theme_new_1',
    cssClass: 'font_0',
  },
  h2: {
    seoTag: 'h2',
    label: 'FONT_2_LABEL',
    tag: 'h2',
    displayName: 'text_editor_theme_new_2',
    cssClass: 'font_2',
  },
  h3: {
    seoTag: 'h3',
    label: 'FONT_3_LABEL',
    tag: 'h3',
    displayName: 'text_editor_theme_new_3',
    cssClass: 'font_3',
  },
  h4: {
    seoTag: 'h4',
    label: 'FONT_4_LABEL',
    tag: 'h4',
    displayName: 'text_editor_theme_new_4',
    cssClass: 'font_4',
  },
  h5: {
    seoTag: 'h5',
    label: 'FONT_5_LABEL',
    tag: 'h5',
    displayName: 'text_editor_theme_new_5',
    cssClass: 'font_5',
  },
  h6: {
    seoTag: 'h6',
    label: 'FONT_6_LABEL',
    tag: 'h6',
    displayName: 'text_editor_theme_new_6',
    cssClass: 'font_6',
  },
  p: {
    seoTag: 'p',
    label: 'FONT_7_LABEL',
    tag: 'p',
    displayName: 'text_editor_theme_7',
    cssClass: 'font_7',
  },
  div: {
    seoTag: 'p',
    label: 'FONT_8_LABEL',
    tag: 'div',
    displayName: 'text_editor_theme_8',
    cssClass: 'font_8',
  },
  address: {
    seoTag: 'p',
    label: 'FONT_9_LABEL',
    tag: 'address',
    displayName: 'text_editor_theme_9',
    cssClass: 'font_9',
  },
  blockquote: {
    seoTag: 'blockquote',
    label: 'FONT_7_LABEL',
    tag: 'blockquote',
    displayName: 'text_editor_theme_7',
    cssClass: 'font_7',
  },
};

type StylesMap = typeof defaultStylesMap;

const textThemesExcludedOptions = ['blockquote'];

const getTextThemesStylesMap = (): StylesMap => {
  const filtered = Object.entries(defaultStylesMap).filter(
    ([key]) => !textThemesExcludedOptions.includes(key),
  );
  return Object.fromEntries(filtered) as StylesMap;
};

const STYLE_COMMANDS = [
  'bold',
  'fontSize',
  'fontFamily',
  'foreColor',
  'italic',
  'letterSpacing',
  'lineHeight',
] as const;

export type CkOff = 2;

export default {
  MAX_TEXT_LENGTH: 60000,
  CK_OFF: 2,
  CK_ON: 1,
  CK_DISABLED: 0,
  IMAGE_WHITE_LIST_ATTRIBUTES: ['src', 'style', 'wix-comp'],
  FORBIDDEN_TAGS: ['script', 'iframe', 'embed', 'object', 'meta'],
  SENSITIVE_ATTRIBUTES: ['href', 'src', 'style'],
  FORBIDDEN_TAGS_AND_ATTRIBUTES: [
    'script',
    'iframe',
    'embed',
    'object',
    'meta',
    'expression',
    'id',
    'comp',
    'dataquery',
    'propertyquery',
    'styleid',
    'skin',
    'skinpart',
    'y',
    'x',
    'scale',
    'angle',
    'idprefix',
    'state',
    'container',
    'listposition',
    'hasproxy',
    'vcfield',
    'vcview',
    'vctype',
    'pos',
    'onAbort',
    'onActivate',
    'onAfterPrint',
    'onAfterUpdate',
    'onBeforeActivate',
    'onBeforeCopy',
    'onBeforeCut',
    'onBeforeDeactivate',
    'onBeforeEditFocus',
    'onBeforePaste',
    'onBeforePrint',
    'onBeforeUnload',
    'onBeforeUpdate',
    'onBegin',
    'onBlur',
    'onBounce',
    'onCellChange',
    'onChange',
    'onClick',
    'onContextMenu',
    'onControlSelect',
    'onCopy',
    'onCut',
    'onDataAvailable',
    'onDataSetChanged',
    'onDataSetComplete',
    'onDblClick',
    'onDeactivate',
    'onDrag',
    'onDragEnd',
    'onDragLeave',
    'onDragEnter',
    'onDragOver',
    'onDragDrop',
    'onDragStart',
    'onDrop',
    'onEnd',
    'onError',
    'onErrorUpdate',
    'onFilterChange',
    'onFinish',
    'onFocus',
    'onFocusIn',
    'onFocusOut',
    'onHashChange',
    'onHelp',
    'onInput',
    'onKeyDown',
    'onKeyPress',
    'onKeyUp',
    'onLayoutComplete',
    'onLoad',
    'onLoseCapture',
    'onMediaComplete',
    'onMediaError',
    'onMessage',
    'onMouseDown',
    'onMouseEnter',
    'onMouseLeave',
    'onMouseMove',
    'onMouseOut',
    'onMouseOver',
    'onMouseUp',
    'onMouseWheel',
    'onMove',
    'onMoveEnd',
    'onMoveStart',
    'onOffline',
    'onOnline',
    'onOutOfSync',
    'onPaste',
    'onPause',
    'onPopState',
    'onProgress',
    'onPropertyChange',
    'onReadyStateChange',
    'onRedo',
    'onRepeat',
    'onReset',
    'onResize',
    'onResizeEnd',
    'onResizeStart',
    'onResume',
    'onReverse',
    'onRowsEnter',
    'onRowExit',
    'onRowDelete',
    'onRowInserted',
    'onScroll',
    'onSeek',
    'onSelect',
    'onSelectionChange',
    'onSelectStart',
    'onStart',
    'onStop',
    'onStorage',
    'onSyncRestored',
    'onSubmit',
    'onTimeError',
    'onTrackChange',
    'onUndo',
    'onUnload',
    'onURLFlip',
    'seekSegmentTime',
  ],
  DEFAULT_STYLES_MAP: defaultStylesMap,
  TEXT_THEMES_STYLES_MAP: getTextThemesStylesMap(),
  CLASS_TO_FONT: {
    font_0: {
      tag: 'h1',
      class: 'font_0',
    },
    font_2: {
      tag: 'h2',
      class: 'font_2',
    },
    font_3: {
      tag: 'h3',
      class: 'font_3',
    },
    font_4: {
      tag: 'h4',
      class: 'font_4',
    },
    font_5: {
      tag: 'h5',
      class: 'font_5',
    },
    font_6: {
      tag: 'h6',
      class: 'font_6',
    },
    font_7: {
      tag: 'p',
      class: 'font_7',
    },
    font_8: {
      tag: 'p',
      class: 'font_8',
    },
    font_9: {
      tag: 'p',
      class: 'font_9',
    },
  },
  TEXT_LIST_TYPES: {
    textListNone: 'nonelist',
    textListNumbers: 'numberedlist',
    textListBullets: 'bulletedlist',
  },
  TEXT_BACK_COLOR_TYPES: {
    textListNone: 'noColor',
  },
  TEXT_LIST_TYPES_RTL: {
    textListNone: 'nonelist',
    textListNumbersRight: 'numberedlist',
    textListBulletsRight: 'bulletedlist',
  },
  ALIGNMENT_TYPES: {
    textAlignLeft: 'justifyleft',
    textAlignCenter: 'justifycenter',
    textAlignRight: 'justifyright',
    textAlignBlock: 'justifyblock',
  },
  FONT_SIZE: {
    MIN: 6,
    MAX: 215,
  },
  //used by the blog
  //todo - fix the blog to use better data
  BLOG_CK_EDITOR_FONT_STYLES: {
    //CK_EDITOR_FONT_STYLES
    type: 'map',
    metaData: {
      isPreset: true,
      isHidden: false,
      description: '',
      isPersistent: false,
    },
    items: {
      font_0: {
        seoTag: 'h1',
        label: 'FONT_0_LABEL',
      },
      font_1: {
        seoTag: 'h2',
        label: 'FONT_1_LABEL',
      },
      font_2: {
        seoTag: 'h2',
        label: 'FONT_2_LABEL',
      },
      font_3: {
        seoTag: 'h3',
        label: 'FONT_3_LABEL',
      },
      font_4: {
        seoTag: 'h4',
        label: 'FONT_4_LABEL',
      },
      font_5: {
        seoTag: 'h5',
        label: 'FONT_5_LABEL',
      },
      font_6: {
        seoTag: 'h6',
        label: 'FONT_6_LABEL',
      },
      font_7: {
        seoTag: 'p',
        label: 'FONT_7_LABEL',
      },
      font_8: {
        seoTag: 'p',
        label: 'FONT_8_LABEL',
      },
      font_9: {
        seoTag: 'p',
        label: 'FONT_9_LABEL',
      },
      font_10: {
        seoTag: 'p',
        label: 'FONT_10_LABEL',
      },
    },
    id: 'CK_EDITOR_FONT_STYLES',
  },
  BLOG_EDITOR_STYLES: {
    //WIXAPPS_EDITOR_STYLES
    type: 'map',
    metaData: {
      isPersistent: false,
    },
    items: {
      h1: {
        tag: 'h1',
        cssClass: 'font_3',
      },
      h2: {
        tag: 'h2',
        cssClass: 'font_4',
      },
      h3: {
        tag: 'h3',
        cssClass: 'font_5',
      },
      h4: {
        tag: 'h4',
        cssClass: 'font_6',
      },
      h5: {
        tag: 'h5',
        cssClass: 'font_7',
      },
      h6: {
        tag: 'h6',
        cssClass: 'font_8',
      },
      p: {
        tag: 'p',
        cssClass: 'font_9',
      },
      div: {
        tag: 'div',
        cssClass: 'font_8',
        label: 'default',
        seoTag: 'hatul',
      },
    },
    id: 'WIXAPPS_EDITOR_STYLES',
  },
  DEFAULT_STYLE_CLASS: 'font_100',
  FALLBACK_STYLE_CLASS: 'font_8',
  STYLE_COMMANDS,
  DEFAULT_LINE_HEIGHT: 'normal',
  DEFAULT_LETTER_SPACING: 'normal',
  DEFAULT_CUSTOM_LINE_HEIGHT: 1.2,
  DEFAULT_THEME_LINE_HEIGHT: '1.4em',
  DEFAULT_THEME_LETTER_SPACING: '0em',
  DEFAULT_THEME_FONT_VARIANT: 'normal',
  DEFAULT_FONT_WEIGHT_VALUE: 'bold',
  DEFAULT_THEME_NORMAL_VALUE: 'normal',
  DEFAULT_FONT_STYLE_VALUE: 'italic',
  DEFAULT_THEME_COLOR: '#000000',
  MAX_VALUE_SLIDER_LINE_HEIGHT: 3,
  MIN_VALUE_SLIDER_LINE_HEIGHT: 0.5,
  MAX_VALUE_INPUT_LINE_HEIGHT: 9,
  MIN_VALUE_INPUT_LINE_HEIGHT: 0.5,
  MAX_VALUE_SLIDER_LETTER_SPACING: 0.7,
  MIN_VALUE_SLIDER_LETTER_SPACING: -0.1,
  MAX_VALUE_INPUT_LETTER_SPACING: 3,
  MIN_VALUE_INPUT_LETTER_SPACING: -0.4,
  DEFAULT_STEP_INPUT_LETTER_SPACING: 0.01,
  DEFAULT_STEP_INPUT_LINE_HEIGHT: 0.01,
  DEFAULT_STEP_SLIDER_LINE_HEIGHT: 0.1,
  DEFAULT_STEP_SLIDER: 0.05,
  TEXT_BLOCKS: Object.keys(defaultStylesMap).concat(['ul', 'ol']),
  FONT_GROUPS: {
    'my-uploads': 'text_editor_font_dropdown_my_fonts',
    latin: 'text_editor_font_dropdown_all_fonts',
    'latin-ext': 'add_languages_eastern_european',
    cyrillic: 'add_languages_cyrillic',
    japanese: 'add_languages_japanese',
    korean: 'add_languages_korean',
    arabic: 'add_languages_arabic',
    hebrew: 'add_languages_hebrew',
  },
  WRICH_TEXT_NEW_SKIN: 'wysiwyg.viewer.skins.WRichTextNewSkin',
} as const;
