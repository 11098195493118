// @ts-nocheck
import _ from 'lodash';

export default {
  UNSAFE_componentWillMount() {
    this.handlers = {};
  },
  componentWillUnmount() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      this.handlers,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (handler, name) {
        this.clearPastMessageHandlerNamed(name);
      }, this),
    );
  },
  setPostMessageHandler(id, callback) {
    this.setPostMessageHandlerNamed('default', id, callback);
  },
  setPostMessageHandlerNamed(name, id, callback) {
    if (this.handlers.hasOwnProperty(name)) {
      this.clearPastMessageHandlerNamed(name);
    }
    const handlerWrap = function (msg) {
      let msgData;

      try {
        // error handling for good JSON
        if (typeof msg.data === 'string') {
          msgData = JSON.parse(msg.data);
        }
      } catch (ee) {
        return;
      }

      if (!msgData) {
        return;
      }

      if (msgData.id === id || (id === null && msgData.id === undefined)) {
        callback(msgData);
      }
    };

    window.addEventListener('message', handlerWrap, false);

    this.handlers[name] = handlerWrap;
  },
  clearPastMessageHandlerNamed(name) {
    if (this.handlers[name]) {
      window.removeEventListener('message', this.handlers[name], false);
      delete this.handlers[name];
    }
  },
};
