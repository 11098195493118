import { useReducer, type Reducer } from 'react';
import type { ValidatorFn } from '../validators/types';
import type { FormFieldState } from './types';

enum FormReducerActionTypes {
  SET_VALUE = 'SET_VALUE',
  PATCH_VALUE = 'PATCH_VALUE',
  RESET_FORM = 'RESET_FORM',
  SET_DIRTY = 'SET_DIRTY',
  SET_CLEAN = 'SET_CLEAN',
}

interface FormReducerAction<ValueType> {
  type: string;
  validators?: ValidatorFn<ValueType>[];
  newValue?: ValueType;
}

const getInitialState = <ValueType>(
  initialValue?: ValueType,
  validators?: ValidatorFn<ValueType>[],
): FormFieldState<ValueType> => {
  const value = initialValue ?? null;
  const errors = validators ? validate(initialValue, validators) : [];

  return {
    value,
    isDirty: false,
    errors,
    isValid: errors.length === 0,
  };
};

const validate = <ValueType>(
  value: ValueType,
  validators: ValidatorFn<ValueType>[],
) => validators.map((validator) => validator(value)).filter(Boolean);

const formReducer = <ValueType>(
  currentState: FormFieldState<ValueType>,
  action: FormReducerAction<ValueType>,
): FormFieldState<ValueType> => {
  const { newValue, validators } = action;
  const errors = validators
    ? validate(newValue, validators)
    : currentState.errors;

  switch (action.type) {
    case FormReducerActionTypes.SET_VALUE:
      return {
        value: newValue,
        isDirty: true,
        errors,
        isValid: errors.length === 0,
      };
    case FormReducerActionTypes.PATCH_VALUE:
      return {
        ...currentState,
        value: newValue,
      };
    case FormReducerActionTypes.RESET_FORM:
      return {
        ...getInitialState(newValue, validators),
      };
    case FormReducerActionTypes.SET_DIRTY:
      return {
        ...currentState,
        isDirty: true,
      };
    case FormReducerActionTypes.SET_CLEAN:
      return {
        ...currentState,
        isDirty: false,
      };
    default:
      return currentState;
  }
};

export const useSmartField = <ValueType>(
  initialValue: ValueType,
  validators: ValidatorFn<ValueType>[],
) => {
  const initialState = getInitialState<ValueType>(initialValue, validators);

  const [state, dispatch] = useReducer<
    Reducer<FormFieldState<ValueType>, FormReducerAction<ValueType>>
  >(formReducer, initialState);

  const setValue = (newValue: ValueType) =>
    dispatch({
      type: FormReducerActionTypes.SET_VALUE,
      validators,
      newValue,
    });

  const patchValue = (newValue: ValueType) =>
    dispatch({
      type: FormReducerActionTypes.PATCH_VALUE,
      newValue,
    });

  const resetForm = (newInitialValue: ValueType) =>
    dispatch({
      type: FormReducerActionTypes.RESET_FORM,
      validators,
      newValue: newInitialValue,
    });

  const setDirty = () =>
    dispatch({
      type: FormReducerActionTypes.SET_DIRTY,
    });

  const setClean = () =>
    dispatch({
      type: FormReducerActionTypes.SET_CLEAN,
    });

  return {
    ...state,
    setValue,
    patchValue,
    resetForm,
    setDirty,
    setClean,
  };
};
