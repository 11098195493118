import constants from '#packages/constants';
import experiment from 'experiment';
import type { CompRef, CompStructure } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { Rect } from 'types/documentServices';

// NOTE: import of isMeshLayoutEnabled creates a circular dependency, so use experiment.isOpen directly
// import { isMeshLayoutEnabled } from '#packages/layout';
const isMeshLayoutEnabled = () => experiment.isOpen('se_meshLayout');

export const hasFWE = (editorAPI: EditorAPI, compDefs: CompStructure[]) =>
  compDefs.some((compDef) =>
    editorAPI.addPanelInfra.addPanelUtils.isStretchedComp(editorAPI, compDef),
  );

export const hasAnchor = (compDefs: CompStructure[]) =>
  compDefs.some(
    (compDef) => compDef?.componentType === constants.COMP_TYPES.ANCHOR,
  );

const isVisibleInViewport = (layout: Rect, previewHeight: number) => {
  return layout.y + layout.height > 0 && layout.y < previewHeight;
};

export function createSectionToPasteUtils(
  editorAPI: EditorAPI,
  components: CompStructure[],
  allowSectionsOnly = false,
) {
  const isAllowedSectionLike =
    allowSectionsOnly || hasAnchor(components)
      ? editorAPI.sections.isSection
      : editorAPI.sections.isSectionLike;

  const getPreviewPosition = () => {
    // not a selector because we can't import state management in @/util package
    return editorAPI.store.getState().domMeasurements.previewPosition;
  };

  return {
    getByYPosition({ y }: { y: number }): CompRef {
      if (isMeshLayoutEnabled()) {
        return editorAPI.components.get
          .byXYFromDom(0, y)
          .find(isAllowedSectionLike);
      }

      return editorAPI.components.get
        .byXYRelativeToStructure(1, y)
        .find(isAllowedSectionLike);
    },
    getVisibleInViewportAndFocused(): CompRef {
      const sectionLikeRef = editorAPI.sections.getFocusedSectionLike();

      if (sectionLikeRef && isAllowedSectionLike(sectionLikeRef)) {
        const sectionLayout =
          editorAPI.components.layout.getRelativeToScreenConsideringScroll(
            sectionLikeRef,
          );

        const previewPosition = getPreviewPosition();
        if (isVisibleInViewport(sectionLayout, previewPosition.height)) {
          return sectionLikeRef;
        }
      }
    },
    getVisibleInViewport(): CompRef {
      // NOTE: overrider `isAllowedSectionLike` to follow rules of original code:
      // https://github.com/wix-private/santa-editor/pull/41870/files#diff-212294ac816666ef133625de06bc8343998514d624633cff34003491f800bb77R91-R93
      const isAllowedSectionLike =
        hasFWE(editorAPI, components) && !hasAnchor(components)
          ? editorAPI.sections.isSectionLike
          : editorAPI.sections.isSection;

      const previewPosition = getPreviewPosition();

      const previewCenter: [x: number, y: number] = [
        previewPosition.width / 2,
        previewPosition.height / 2,
      ];
      const compsInMiddle = editorAPI.selection.getComponentsByXY(
        ...previewCenter,
      );

      return compsInMiddle.find(isAllowedSectionLike);
    },
    getClosestToViewport() {
      const pageSections = editorAPI.sections
        .getPageSectionsWithLayout(editorAPI.pages.getFocusedPage(), true)
        .filter((section) => isAllowedSectionLike(section.ref));

      if (!pageSections.length) {
        return null;
      }

      const previewPosition = getPreviewPosition();

      const previewY = previewPosition.height / 2;
      const previewYConsideringScroll =
        previewY + editorAPI.scroll.get().scrollTop;

      const closestSection = pageSections.reduce((prev, curr) =>
        Math.abs(curr.layout.y - previewYConsideringScroll) <
        Math.abs(prev.layout.y - previewYConsideringScroll)
          ? curr
          : prev,
      );

      return closestSection.ref;
    },
  };
}
