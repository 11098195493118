export const initialDesignItemBackground = {
  type: 'BackgroundMedia',
  colorLayers: [
    {
      type: 'SolidColorLayer',
      opacity: 0,
      fill: {
        type: 'SolidColor',
        color: 'color_11',
      },
    },
  ],
  colorOverlay: '',
};
