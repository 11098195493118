export function bindObjectToScope<
  SC,
  T extends {
    [key: string]: (scope: SC, ...args: any[]) => any;
  },
>(
  scope: SC,
  object: T,
): {
  [K in keyof T]: T[K] extends (scope: SC, ...args: infer A) => infer R
    ? (...args: A) => R
    : never;
} {
  return Object.fromEntries(
    Object.entries(object).map(([k, v]) => {
      return [k, v.bind(null, scope)];
    }),
  ) as any;
}

export const memo = <T>(cb: () => T): (() => T) => {
  let called = false;
  let val: T;

  return () => {
    if (!called) {
      val = cb();
      called = true;
    }
    return val;
  };
};
