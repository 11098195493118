// @ts-nocheck
import * as util from '@wix/santa-editor-utils';

const getLocalModeServerPort = () =>
  util.url.getParameterByName('localServerPort');

const isLocalModeEnabled = () => !!getLocalModeServerPort();

const getCorvidSessionId = () => util.url.getParameterByName('corvidSessionId');

const getLocalModeFieldsExtraBiFields = () =>
  isLocalModeEnabled()
    ? {
        builder_environment: 'local',
        corvidSessionId: getCorvidSessionId(),
      }
    : {};

const switchToOnlineEditor = ({ _blank = false } = {}) => {
  const { path, query: urlParams } = util.url.parseUrl(window.location.href);
  const baseUrl = util.url.baseUrl(window.location.href);

  delete urlParams.localServerPort;

  const editorOnlineURL = util.url.setUrlParams(`${baseUrl}${path}`, urlParams);
  if (_blank) {
    window.open(editorOnlineURL);
  } else {
    util.url.forceChangeEditorUrlTo(editorOnlineURL);
  }
};

export {
  isLocalModeEnabled,
  switchToOnlineEditor,
  getLocalModeFieldsExtraBiFields,
};
