//@ts-nocheck
import _ from 'lodash';
import coreUtilsLib from '@wix/santa-core-utils';

/**
 *
 * @param overrideName
 * @param value - 'old' or 'new'
 */
function setUserPetriOverrideCookie(overrideName, value) {
  const oppositeValue = value === 'old' ? 'new' : 'old';
  let cookieValue = coreUtilsLib.cookieUtils.getCookie('petri_ovr');

  if (!cookieValue) {
    cookieValue = `specs.${overrideName}#${value}`;
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
  } else if (_.includes(cookieValue, `specs.${overrideName}`)) {
    cookieValue = cookieValue.replace(
      `specs.${overrideName}#${oppositeValue}`,
      `specs.${overrideName}#${value}`,
    );
  } else {
    cookieValue += `|specs.${overrideName}#${value}`;
  }
  const time = new Date(new Date().setYear(new Date().getFullYear() + 1));
  coreUtilsLib.cookieUtils.setCookie('petri_ovr', cookieValue, time, 'wix.com');
}

const { parseCookieString, setCookie, getCookie, deleteCookie } =
  coreUtilsLib.cookieUtils;

export {
  parseCookieString,
  setCookie,
  getCookie,
  deleteCookie,
  setUserPetriOverrideCookie,
};
