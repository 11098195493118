import $ from 'zepto';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

const trimLabel = (text: string) => {
  if (text.length > 25) {
    return `${text.substring(0, 22)}...`;
  }
  return text;
};

const getRichTextLabel = (editorAPI: EditorAPI, compRef: CompRef) =>
  trimLabel($(editorAPI.components.data.get(compRef).text).text());

const getSiteButtonLabel = (editorAPI: EditorAPI, compRef: CompRef) =>
  trimLabel(editorAPI.components.data.get(compRef).label);

const getSingleTabLabel = (editorAPI: EditorAPI, compRef: CompRef) =>
  trimLabel(editorAPI.components.data.get(compRef).label);

const getAnchorLabel = (editorAPI: EditorAPI, compRef: CompRef) =>
  trimLabel(editorAPI.components.data.get(compRef).name);

const getComponentLabelByType = new Map<
  string,
  (editorAPI: EditorAPI, compRef: CompRef) => string | undefined
>([
  ['wysiwyg.viewer.components.WRichText', getRichTextLabel],
  ['wysiwyg.viewer.components.SiteButton', getSiteButtonLabel],
  ['wixui.StylableButton', getSiteButtonLabel],
  ['wixui.SingleTab', getSingleTabLabel],
  ['wysiwyg.common.components.anchor.viewer.Anchor', getAnchorLabel],
]);

export const getComponentLabel = (
  editorAPI: EditorAPI,
  compRef: CompRef,
): string | undefined => {
  const componentType = editorAPI.dsRead.components.getType(compRef);
  if (getComponentLabelByType.has(componentType)) {
    return getComponentLabelByType.get(componentType)!(editorAPI, compRef);
  }
};
