import _ from 'lodash';
import type { CompRef } from 'types/documentServices';

function swap<T>(arr: T[], i: number, j: number): T[] {
  const itemI = arr[i];
  const itemJ = arr[j];

  if (
    !Array.isArray(arr) ||
    typeof itemI === 'undefined' ||
    typeof itemJ === 'undefined'
  ) {
    throw new Error('Invalid arguments');
  }

  return arr.map((val, index) => {
    if (index === i) {
      return itemJ;
    }
    if (index === j) {
      return itemI;
    }
    return val;
  });
}

const asArray = <T>(items: T[] | T): T[] => {
  if (!items) {
    return [];
  }

  if (Array.isArray(items)) {
    return items.filter(Boolean);
  }

  return [items];
};

function asSingle<T>(
  itemOrItems: T | T[],
  {
    throwIfMultiple,
    throwActionName,
  }: {
    throwIfMultiple?: boolean;
    throwActionName?: string;
  } = {},
): T {
  if (!Array.isArray(itemOrItems)) {
    return itemOrItems;
  }

  if (throwIfMultiple && itemOrItems.length > 1) {
    throw new Error(
      `${
        throwActionName ?? 'method'
      } is not supported for multiple components at once`,
    );
  }

  return itemOrItems[0];
}

function asSingleThrowIfMultiple<T>(
  itemOrItems: T | T[],
  { throwActionName }: { throwActionName?: string } = {},
): T {
  return asSingle(itemOrItems, {
    throwIfMultiple: true,
    throwActionName,
  });
}

/**
 * Applies action on all components in compRefs, using .forEach
 * @param action
 * @param compRefs
 * @returns {*}
 */
const applyForAll = _.curry(
  (
    action: (compRef: CompRef, index: number, array: CompRef[]) => void,
    compRefs: CompRef | CompRef[],
  ) => {
    const compsArr = asArray(compRefs);
    compsArr.forEach(action);
  },
);

/**
 * Applies action on all components in compRefs, returning a promise
 */
const applyForAllAsync = _.curry(
  async (
    action: (compRef: CompRef) => Promise<void>,
    compRefs: CompRef | CompRef[],
  ) => {
    const compsArr = asArray(compRefs);
    return Promise.allSettled(compsArr.map((compRef) => action(compRef)));
  },
);

/**
 * Applies action on the first component in compRefs
 * @param action
 * @param compRefs
 * @returns {*}
 */
const applyForFirst = _.curry((action: AnyFixMe, compRefs: AnyFixMe) => {
  const compsArr = asArray(compRefs);
  return action(compsArr[0]);
});

/**
 * Throws if compRefs is multi-component
 * Will apply action on the first comp in compRefs, only if compRefs is a single comp.
 * @param actionName
 * @param action
 * @param compRefs
 * @returns {*}
 */
const applyForFirstAndThrowIfMulti = _.curry(
  (
    actionName: string,
    action: (compRef: CompRef) => any,
    compRefOrRefs: CompRef | CompRef[],
  ) => {
    const compRef = asSingleThrowIfMultiple(compRefOrRefs, {
      throwActionName: actionName,
    });

    return action(compRef);
  },
);

const isMultiselect = (compRefs: CompRef | CompRef[]): boolean => {
  const compsArr = asArray(compRefs);
  return compsArr.length > 1;
};

/**
 * Check predicate is truthy for all compRefs
 * @param predicate
 * @param compRefs
 */
const validateForAll = (predicate: AnyFixMe, compRefs: AnyFixMe) => {
  const compsArr = asArray(compRefs);
  return compsArr.every(predicate);
};

/**
 * Check predicate is truthy for some of compRefs
 * @param predicate
 * @param compRefs
 */
const validateForSome = (predicate: AnyFixMe, compRefs: AnyFixMe) => {
  const compsArr = asArray(compRefs);
  return compsArr.some(predicate);
};

/**
 * Check that compRefs only contain a single item, and validate predicate on that comp.
 * Returns false if compRefs is multicomponents
 * @param predicate
 * @param compRefs
 * @returns {boolean|*}
 */
const checkSingleAndValidate = <T = any>(
  predicate: (compRef: CompRef) => T,
  compRefs: CompRef | CompRef[],
): false | T => {
  const compsArr = asArray(compRefs);
  return compsArr.length === 1 && predicate(compsArr[0]);
};

function immutableSplice<T = any>(
  arr: T[],
  start: number,
  deleteCount: number,
  ...items: T[]
): T[] {
  start = start >= 0 ? start : Math.max(0, arr.length + start);

  const end = start + deleteCount;

  return [...arr.slice(0, start), ...items, ...arr.slice(end)];
}

/**
 * Splits array into two arrays. In first array it puts items that satisfy predicate, in second - those who don't
 * Returns tuple of two arrays
 * @param array
 * @param predicate
 * @returns {[Array, Array]}
 */
export function partition<T = any>(
  array: T[],
  predicate: (item: T, index: number) => boolean,
): [T[], T[]] {
  return array.reduce(
    (acc, item, index) => {
      const indexToPush = predicate(item, index) ? 0 : 1;
      acc[indexToPush].push(item);
      return acc;
    },
    [[], []],
  );
}

/**
 * Sorts array without mutating original array
 * Returns new sorted arrays
 * @param array
 * @param direction
 * @returns {Array}
 */
export function sort<T = any>(
  array: T[],
  direction: 'asc' | 'desc' = 'asc',
): T[] {
  return array.slice().sort((a, b) => {
    const directionMultiplier = direction === 'desc' ? -1 : 1;

    if (a > b) {
      return 1 * directionMultiplier;
    } else if (a < b) {
      return -1 * directionMultiplier;
    }
    return 0;
  });
}

/**
 * Sorts array by item prop without mutating original array
 * Returns new sorted arrays
 * @param array
 * @param propName
 * @param direction
 * @returns {Array}
 */
export function sortByProp<T = any>(
  array: T[],
  propName: string,
  direction: 'asc' | 'desc' = 'asc',
): T[] {
  return array.slice().sort((a: AnyFixMe, b: AnyFixMe) => {
    const aValue = a[propName];
    const bValue = b[propName];

    const directionMultiplier = direction === 'desc' ? -1 : 1;

    if (aValue > bValue) {
      return 1 * directionMultiplier;
    } else if (aValue < bValue) {
      return -1 * directionMultiplier;
    }
    return 0;
  });
}

const uniq = <T>(array: T[]) => Array.from(new Set(array));

type Nested<T, K extends string> = Omit<T, K> & { [P in K]?: Nested<T, K>[] };

const flattenBy = <K extends string, T>(
  arr: (T & Nested<T, K>)[],
  key: K,
): T[] => {
  return arr.reduce((acc, current) => {
    acc.push(current);
    if (!current[key]) return acc;
    const flattened = flattenBy(current[key] as any, key);

    return acc.concat(flattened);
  }, []);
};

export {
  swap,
  uniq,
  flattenBy,
  asArray,
  asSingle,
  asSingleThrowIfMultiple,
  isMultiselect,
  applyForAll,
  applyForAllAsync,
  applyForFirst,
  applyForFirstAndThrowIfMulti,
  validateForAll,
  validateForSome,
  checkSingleAndValidate,
  immutableSplice,
};
