export default {
  MIGRATION_ANCHORS_CHECK: {
    evid: 1396,
    fields: {
      flow: 'string',
      revision: 'number',
    },
  },
  MIGRATION_START: {
    evid: 1307,
    fields: {
      template_id: 'string',
      revision: 'number',
      origin: 'string',
      flow: 'string',
      is_anchor_in_structure: 'boolean',
      is_section_in_structure: 'boolean',
    },
  },
  MIGRATION_SUCCESS: {
    evid: 1246,
    fields: {
      template_id: 'string',
      origin: 'string',
      flow: 'string',
      duration: 'number',
      pages_list: 'string',
    },
  },
  MIGRATION_ERROR: {
    evid: 1318,
    fields: {
      template_id: 'string',
      errorMessage: 'string',
      origin: 'string',
      flow: 'string',
    },
  },
  MIGRATION_FALLBACK_ERROR: {
    evid: 1400,
    fields: {
      revision: 'number',
      origin: 'string',
      flow: 'string',
      sections_number: 'number',
      undo_label: 'string',
    },
  },
  MIGRATION_NOT_POSSIBLE: {
    evid: 1291,
    fields: {
      template_id: 'string',
      reason: 'string',
    },
  },
  MIGRATION_SKIPPED_THIRD_TIME: {
    evid: 1484,
    fields: {
      flow: 'string',
    },
  },
  SECTION_Z_INDEX_OVERLAPPING: {
    evid: 1296,
    fields: {
      template_id: 'string',
      pageId: 'string',
      section_name: 'string',
      flow: 'string',
    },
  },
  COMPONENT_MIGRATION_ERROR: {
    evid: 1248,
    fields: {
      template_id: 'string',
      flow: 'string',
      pageId: 'string',
      component_id: 'string',
      component_type: 'string',
      errorMessage: 'string',
    },
  },
  COMPONENT_IS_NOT_CONTAINABLE: {
    evid: 1297,
    fields: {
      template_id: 'string',
      flow: 'string',
      pageId: 'string',
      component_id: 'string',
      component_type: 'string',
    },
  },
  SITE_STRUCTURE_ERROR: {
    evid: 1365,
    fields: {
      template_id: 'string',
      flow: 'string',
      origin: 'string',
      invalidData: 'string',
      levelAmount: 'number',
      layoutAmount: 'number',
      visibilityAmount: 'number',
      commonAmount: 'number',
    },
  },
  PREDICTION_ERROR: {
    evid: 1380,
    fields: {
      flow: 'string',
      revision: 'number',
      errorMessage: 'string',
    },
  },
} as const;
