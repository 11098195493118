import experiment from 'experiment';
import { ErrorReporter } from '@wix/editor-error-reporter';
import * as appStudioUtils from '../appStudio/appStudioUtils';
import { isResponsiveEditor } from '@wix/santa-editor-utils';

export const isNewPanelEnabled = (): boolean =>
  !isResponsiveEditor() && !appStudioUtils.isAppStudio();

export const isAddPanelRegistrySectionEnabled = (): boolean =>
  isNewPanelEnabled() && experiment.isOpen('apd_elementsRegistryDev');

export function deprecateOldAddPanel<T>(obj: T, kind: string): T {
  if (!isNewPanelEnabled()) {
    return obj;
  }
  const deprecate = experiment.getValue('se_deprecateOldAddPanel') as
    | 'false'
    | 'log'
    | 'throw';
  if (deprecate !== 'log' && deprecate !== 'throw') {
    return obj;
  }
  let errorWasSent = false;
  let errToThrow: null | any = null;

  const sendError = (kind2: string | symbol | undefined) => {
    if (!errorWasSent) {
      errorWasSent = true;
      const errorStack = new Error('deprecateOldAddPanel');
      ErrorReporter.captureException(errorStack, {
        tags: { deprecateOldAddPanel: true },
        extra: {
          kind,
          kind2,
        },
      });
      if (deprecate === 'throw') {
        console.log('deprecateOldAddPanel', kind, kind2);
        errToThrow = errorStack;
      }
    }
    if (errToThrow) {
      throw errToThrow;
    }
  };
  if (typeof obj === 'function') {
    return function (...args: any[]) {
      sendError(args[0]);
      return obj(...args);
    } as any;
  }

  if (typeof obj === 'object' && obj !== null) {
    return new Proxy(obj, {
      get(target, prop) {
        sendError(prop);
        return Reflect.get(target, prop);
      },
      set(target, prop, value) {
        sendError(prop);
        return Reflect.set(target, prop, value);
      },
    });
  }
  throw new Error('deprecateOldAddPanel: invalid argument');
}

export const isOldAddPanelRegistryDisabled = () =>
  isNewPanelEnabled() &&
  (experiment.getValue('se_deprecateOldAddPanel') === 'throw' ||
    experiment.getValue('se_deprecateOldAddPanel') === 'log');
