/**
 * Removes all closing and opening html/xml tags from a string, returning only plain text
 * @param str
 * @returns {string}
 */
export function removeHtmlTagsFromString(str: string) {
  return str.replace(/<[^<]*>/g, '');
}

/**
 * Removes newline and line feed characters from a string, returning a one line string
 * @param str
 * @returns {string}
 */
export function removeBreakLinesFromString(str: string) {
  return str.replace(/[\n\r]/g, ' ');
}

/**
 * Removes 0x2028 and 0x2029 characters from a string, replacing with empty character
 * @param str
 * @returns {string}
 */
export function removeNonSupportedCharacters(str: string) {
  return str.replace(/[\u2028-\u2029]/g, '');
}

export function htmlDecode(text: string): string {
  const e = window.document.createElement('div');
  e.innerHTML = text;
  return e.childNodes.length === 0 ? '' : e.childNodes[0].nodeValue;
}

export const isTrue = (str: string) => str === 'true';
