import type { EditorLoggers } from 'types/core';
import { ErrorReporter } from '@wix/editor-error-reporter';

if (process.env.NODE_ENV === 'test') {
  require('./mockBiLoggerForTests');
}

if (!window.__editor_bi_loggers__) {
  ErrorReporter.captureMessage(
    'window.__editor_bi_loggers__ is undefined, failed to load',
    {
      tags: {
        failedToLoadEditor: true,
      },
    },
  );
}
const {
  logger: biLogger,
  loggerLegacy: biLoggerLegacy,
  updateDefaults: biLoggerUpdateDefaults,
} = window.__editor_bi_loggers__ as EditorLoggers['bi'];

delete window.__editor_bi_loggers__;

export { biLogger, biLoggerLegacy, biLoggerUpdateDefaults };
