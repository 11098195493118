export const switchLayout = {
  switchLayoutWithListSessionInfo: {
    evid: 1280,
    src: 38,
    fields: {
      error_info: 'string',
      extra: 'string',
      is_success: 'boolean',
      is_list: 'boolean',
      list_type: 'string',
      section_correlation_id: 'string',
      section_id: 'string',
    },
  },
  switchLayoutHelpClick: {
    evid: 254,
    src: 38,
    fields: {
      panel_name: 'string',
      action: 'string',
      component_id: 'string',
      section_correlation_id: 'string',
      panel_correlation_id: 'string',
      origin: 'string',
      layout_type: 'string',
    },
  },
  switchLayoutPanelOpened: {
    evid: 309,
    src: 38,
    fields: {
      panel_name: 'string',
      origin: 'string',
      component_id: 'string',
      section_correlation_id: 'string',
      panel_correlation_id: 'string',
    },
  },
  switchLayoutSwitchBetweenSections: {
    evid: 1293,
    src: 38,
    fields: {
      component_id: 'string',
      section_correlation_id: 'string',
      panel_correlation_id: 'string',
      panel_name: 'string',
      origin: 'string',
    },
  },
  switchLayoutPanelClosed: {
    evid: 345,
    fields: {
      panel_name: 'string',
      component_id: 'string',
      section_correlation_id: 'string',
      panel_correlation_id: 'string',
      time_spent: 'number',
      origin: 'string',
    },
  },
  switchLayoutSectionNotSupported: {
    evid: 1301,
    src: 38,
    fields: {
      is_list: 'boolean',
      component_id: 'string',
      section_correlation_id: 'string',
      panel_correlation_id: 'string',
      origin: 'string',
      components_amount: 'numeric',
      inputJson: 'string',
      section_name_technical: 'string',
      section_name_personal: 'string',
    },
  },
  switchLayoutSendGetLayoutIdeas: {
    evid: 1271,
    src: 38,
    fields: {
      is_list: 'boolean',
      component_id: 'string',
      section_correlation_id: 'string',
      panel_correlation_id: 'string',
      origin: 'string',
      components_amount: 'numeric',
      inputJson: 'string',
      section_name_technical: 'string',
      section_name_personal: 'string',
    },
  },
  switchLayoutRecievedLayoutIdeas: {
    evid: 1272,
    src: 38,
    fields: {
      is_list: 'boolean',
      component_id: 'string',
      section_correlation_id: 'string',
      panel_correlation_id: 'string',
      origin: 'string',
      components_amount: 'numeric',
      result: 'string',
      section_name_technical: 'string',
      section_name_personal: 'string',
      duration: 'number',
      engine_type: 'string',
    },
  },
  switchLayoutSelectedLayout: {
    evid: 1273,
    src: 38,
    fields: {
      component_id: 'string',
      components_amount: 'numeric',
      layout_type: 'string',
      section_correlation_id: 'string',
      panel_correlation_id: 'string',
      origin: 'string',
      layout_id: 'string',
      layout_index: 'numeric',
    },
  },
  switchLayoutLayoutApplied: {
    evid: 1274,
    src: 38,
    fields: {
      component_id: 'string',
      components_amount: 'numeric',
      layout_type: 'string',
      section_correlation_id: 'string',
      panel_correlation_id: 'string',
      origin: 'string',
      layout_id: 'string',
      layout_index: 'numeric',
    },
  },
  switchLayoutInfoTooltip: {
    evid: 1275,
    src: 38,
    fields: {
      action: 'string',
      component_id: 'string',
      section_correlation_id: 'string',
      panel_correlation_id: 'string',
      origin: 'string',
      layout_type: 'string',
    },
  },
  switchLayoutExploreMoreDesigns: {
    evid: 1276,
    src: 38,
    fields: {
      component_id: 'string',
      section_correlation_id: 'string',
      panel_correlation_id: 'string',
      origin: 'string',
    },
  },
  switchLayoutScrollDesigns: {
    evid: 1277,
    src: 38,
    fields: {
      component_id: 'string',
      correlation_type: 'string',
      section_correlation_id: 'string',
      panel_correlation_id: 'string',
      origin: 'string',
      scrollTop: 'number',
    },
  },
} as const;
