export enum RCM_ORIGIN {
  STAGE = 'stage',
  ACTIONS_MORE_ACTION = 'action_bar_more_actions',
  STAGE_SIDE_AREA = 'stageSideArea',
  PAGES_MENU = 'pagesMenu',
  PAGES_MENU_ITEM = 'pagesMenuItem',
  RULERS_HORIZONTAL = 'rulers horizontal',
  RULERS_VERTICAL = 'rulers vertical',
  GUIDE_HORIZONTAL = 'guide horizontal',
  GUIDE_VERTICAL = 'guide vertical',
}
