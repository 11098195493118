import type { PublicApiKey } from '#packages/apilib';
import type { createBaseResizeAndPushApi } from '../../utils/mouseMoveActions/baseResizeAndPush';
import type { createBaseDragApi } from '../../utils/mouseMoveActions/baseDrag/baseDragApi';
import type { createPluginServiceApi } from '../pluginService';
import type { createHomePageApi } from './homePage/homePageApi';
import type { createLayoutersApi } from './layoutersWrapper';
import type { createMediaApi } from './mediaWrapper';
import type { createMouseActionsApi } from './mouseActionsWrapper';
import type { createScrollApi } from './scroll/scrollApi';
import type { createSelectedComponentApi } from './selectedComponentWrapper';
import type { createTabIndicationApi } from './tabIndicationState';
import type { createWixCodeApi } from './wixCode';

export const MediaApiKey: PublicApiKey<typeof createMediaApi> = {
  name: 'MediaApi',
  public: true,
};

export const HomePageApiKey: PublicApiKey<typeof createHomePageApi> = {
  name: 'HomePageApi',
  public: true,
};

export const ScrollApiKey: PublicApiKey<typeof createScrollApi> = {
  name: 'ScrollApi',
  public: true,
};

export const LayoutersApiKey: PublicApiKey<typeof createLayoutersApi> = {
  name: 'LayoutersApi',
  public: true,
};

export const MouseActionsApiKey: PublicApiKey<typeof createMouseActionsApi> = {
  name: 'MouseActionsApi',
  public: true,
};

export const SelectedComponentApiKey: PublicApiKey<
  typeof createSelectedComponentApi
> = {
  name: 'SelectedComponentApi',
  public: true,
};

export const TabIndicationApiKey: PublicApiKey<typeof createTabIndicationApi> =
  {
    name: 'TabIndicationApi',
    public: true,
  };

export type WixCodeApi = typeof createWixCodeApi;

export const WixCodeApiKey: PublicApiKey<WixCodeApi> = {
  name: 'WixCodeApi',
  public: true,
};

export const PluginServiceApiKey: PublicApiKey<typeof createPluginServiceApi> =
  {
    name: 'PluginServiceApi',
    public: true,
  };

export const BaseDragApiKey: PublicApiKey<typeof createBaseDragApi> = {
  name: 'BaseDragApi',
  public: true,
};

export const BaseResizeAndPushApiKey: PublicApiKey<
  typeof createBaseResizeAndPushApi
> = {
  name: 'BaseResizeAndPushApi',
  public: true,
};
