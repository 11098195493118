export interface CancelablePromise<T> {
  promise: Promise<T>;
  cancel(): void;
}

const CANCEL_ERROR_MESSAGE = 'Promise is canceled';

export const makeCancelablePromise = <T>(
  promise: Promise<T>,
): CancelablePromise<T> => {
  let hasCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      (val) =>
        hasCanceled ? reject(new Error(CANCEL_ERROR_MESSAGE)) : resolve(val),
      (error) =>
        hasCanceled ? reject(new Error(CANCEL_ERROR_MESSAGE)) : reject(error),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    },
  };
};

export const isCancelablePromiseCancelError = (
  error: Error | unknown,
): error is Error => {
  return error instanceof Error && error.message === CANCEL_ERROR_MESSAGE;
};
