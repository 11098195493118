import _ from 'lodash';
import * as utils from '@wix/santa-editor-utils';

const rectUtil = utils.math.rect;

const FIX_DIRECTIONS: AnyFixMe = {
  left: { x: -1 },
  top: { y: -1 },
  right: { x: 1 },
  bottom: { y: 1 },
};

function scale(imageData: AnyFixMe, ratio: AnyFixMe) {
  const scaledImage = _.clone(imageData);

  scaledImage.height *= ratio;
  scaledImage.width *= ratio;

  return scaledImage;
}

function reverseScale(scaledImage: AnyFixMe, originalImage: AnyFixMe) {
  return scaledImage.height / originalImage.height;
}

function autoCrop(image: AnyFixMe, newDimensions: AnyFixMe) {
  const imageAspectRatio = rectUtil.getAspectRatio(image);
  const diff = { x: 0, y: 0 };

  const minHeightToKeepAspectRatio = newDimensions.width * imageAspectRatio;

  if (minHeightToKeepAspectRatio < newDimensions.height) {
    diff.x = (image.width - newDimensions.width) / 2;
  } else if (minHeightToKeepAspectRatio > newDimensions.height) {
    diff.y = (image.height - newDimensions.height) / 2;
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/assign
  return _.assign({}, image, {
    x: image.x + diff.x,
    y: image.y + diff.y,
    width: newDimensions.width,
    height: newDimensions.height,
  });
}

function reverseAutoCrop(autoCroppedImage: AnyFixMe, originalImage: AnyFixMe) {
  const originalAspectRatio = rectUtil.getAspectRatio(originalImage);
  const diff = { width: 0, height: 0 };

  const minHeightToKeepAspectRatio =
    autoCroppedImage.width * originalAspectRatio;
  const minWidthToKeepAspectRatio =
    autoCroppedImage.height / originalAspectRatio;

  if (minHeightToKeepAspectRatio < autoCroppedImage.height) {
    diff.width = minWidthToKeepAspectRatio - autoCroppedImage.width;
  } else if (minHeightToKeepAspectRatio > autoCroppedImage.height) {
    diff.height = minHeightToKeepAspectRatio - autoCroppedImage.height;
  } else {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    return _.assign({}, autoCroppedImage, {
      width: minWidthToKeepAspectRatio,
      height: minHeightToKeepAspectRatio,
    });
  }

  return {
    width: autoCroppedImage.width + diff.width,
    x: autoCroppedImage.x - diff.width / 2,
    height: autoCroppedImage.height + diff.height,
    y: autoCroppedImage.y - diff.height / 2,
  };
}

function manualCrop(
  scaledImage: AnyFixMe,
  cropData: AnyFixMe,
  originalImage: AnyFixMe,
) {
  const resizeRatio = {
    width: scaledImage.width / originalImage.width,
    height: scaledImage.height / originalImage.height,
  };

  return {
    x: cropData.x * resizeRatio.width,
    y: cropData.y * resizeRatio.height,
    width: cropData.width * resizeRatio.width,
    height: cropData.height * resizeRatio.height,
  };
}

function reverseManualCrop(
  manuallyCroppedImage: AnyFixMe,
  cropData: AnyFixMe,
  originalImage: AnyFixMe,
) {
  const resizeRatio = {
    width: manuallyCroppedImage.width / cropData.width,
    height: manuallyCroppedImage.height / cropData.height,
  };

  return {
    x: manuallyCroppedImage.x - cropData.x * resizeRatio.width,
    y: manuallyCroppedImage.y - cropData.y * resizeRatio.height,
    width: originalImage.width * resizeRatio.width,
    height: originalImage.height * resizeRatio.height,
  };
}

function scaleWithinDimensions(
  newScaledImage: AnyFixMe,
  cropArea: AnyFixMe,
  minDimensions: AnyFixMe,
) {
  const ensuredScaledImage = _.clone(newScaledImage);

  if (ensuredScaledImage.height < cropArea.height) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(ensuredScaledImage, {
      height: cropArea.height,
      width: minDimensions.width,
    });
  }
  if (ensuredScaledImage.width < cropArea.width) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/assign
    _.assign(ensuredScaledImage, {
      width: cropArea.width,
      height: minDimensions.height,
    });
  }

  return ensuredScaledImage;
}

function areaIsFullyContained(scaledImage: AnyFixMe, cropArea: AnyFixMe) {
  const newCropOriginArea = _.clone(scaledImage);
  const newCropArea = _.clone(cropArea);

  const exceedingDiffs = {
    left: -newCropArea.x,
    top: -newCropArea.y,
    right: rectUtil.getRight(newCropArea) - newCropOriginArea.width,
    bottom: rectUtil.getBottom(newCropArea) - newCropOriginArea.height,
  };

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(exceedingDiffs, function (diff, edge) {
    if (diff > 0) {
      const multiplier = FIX_DIRECTIONS[edge];
      let totalDiff = 0;

      if (multiplier.x) {
        totalDiff = multiplier.x * diff;
        newCropOriginArea.x += totalDiff;
        newCropArea.x -= totalDiff;
      } else {
        totalDiff = multiplier.y * diff;
        newCropOriginArea.y += totalDiff;
        newCropArea.y -= totalDiff;
      }
    }
  });

  return {
    originArea: newCropOriginArea,
    area: newCropArea,
  };
}

function areaWithinViewport(viewport: AnyFixMe, cropState: AnyFixMe) {
  const newCropArea = _.clone(cropState.cropArea);
  const { cropOriginArea } = cropState;

  newCropArea.x = Math.max(
    0,
    viewport.x - cropOriginArea.x,
    cropState.cropArea.x,
  );
  const cropAreaRightBoundary = Math.min(
    rectUtil.getRight(cropOriginArea),
    rectUtil.getRight(viewport),
  );
  const cropAreaRight = Math.min(
    cropAreaRightBoundary - cropOriginArea.x,
    rectUtil.getRight(cropState.cropArea),
  );
  newCropArea.width = cropAreaRight - newCropArea.x;

  newCropArea.y = Math.max(0, -cropOriginArea.y, newCropArea.y);
  // current assumption is that image cannot exceed site bottom
  const cropAreaBottomBoundary = rectUtil.getBottom(cropOriginArea);
  const cropAreaBottom = Math.min(
    cropAreaBottomBoundary - cropOriginArea.y,
    rectUtil.getBottom(cropState.cropArea),
  );
  newCropArea.height = cropAreaBottom - newCropArea.y;

  return newCropArea;
}

const ensure = {
  scaleWithinDimensions,
  areaIsFullyContained,
  areaWithinViewport,
};

export {
  scale,
  reverseScale,
  autoCrop,
  reverseAutoCrop,
  manualCrop,
  reverseManualCrop,
  ensure,
};
