import type { GradientMesh, GradientData } from '@wix/css-gradient-generator';
import { type ColorLayerData } from '#packages/util';
import _ from 'lodash';

type Gradient = GradientData | GradientMesh;
interface BackgroundData {
  type: 'BackgroundMedia';
  colorLayers?: ColorLayerData[];
  colorOverlay?: string;
  colorOverlayOpacity?: number;
  color?: string;
  colorOpacity?: number;
}

export interface ColorPickerValues {
  color: string;
  alpha: number;
}

export const getOpacityValue = (data: BackgroundData): number => {
  return data.colorLayers?.[0]?.opacity ?? data.colorOpacity ?? 1;
};

export const getColorValue = (data: BackgroundData) => {
  const fill = data.colorLayers?.[0].fill || data.color;
  if (typeof fill === 'string') {
    return fill;
  }
  return fill?.type === 'SolidColor' ? fill.color : fill;
};

export const getBackgroundWithColorLayers = (
  data: BackgroundData,
  fill: ColorLayerData['fill'],
  opacity: number = 1,
): BackgroundData => {
  const newData = _.cloneDeep(data);
  const type = `${fill.type}Layer` as ColorLayerData['type'];
  const colorLayer = {
    type,
    opacity,
    fill,
  };

  newData.colorLayers = [colorLayer];

  return newData;
};

export const getUpdatedDesignData = (
  data: BackgroundData,
  { color, alpha: opacity }: ColorPickerValues,
) => {
  opacity = opacity ?? getOpacityValue(data);

  if (typeof color === 'string') {
    return getBackgroundWithColorLayers(
      data,
      { type: 'SolidColor', color: color ?? '#FFFFFF' },
      opacity,
    );
  }

  if ((color as Gradient)?.type) {
    return getBackgroundWithColorLayers(data, color as Gradient, opacity);
  }

  if (Array.isArray(color)) {
    return getBackgroundWithColorLayers(
      data,
      {
        type: 'GradientMesh',
        gradients: color as GradientMesh['gradients'],
      },
      opacity,
    );
  }

  return data;
};
