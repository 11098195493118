const SEPARATOR_BETWEEN_NAMESPACE_AND_COLLECTION_NAME = '/';

function doesCollectionIdHaveNamespace(collectionId: AnyFixMe) {
  return collectionId.includes(SEPARATOR_BETWEEN_NAMESPACE_AND_COLLECTION_NAME);
}

function getNamespaceFromCollectionId(collectionId: AnyFixMe) {
  return collectionId.split(SEPARATOR_BETWEEN_NAMESPACE_AND_COLLECTION_NAME)[0];
}

export { doesCollectionIdHaveNamespace, getNamespaceFromCollectionId };
