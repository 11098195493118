import { getEcomAppDefId, getStoresAppDefId } from '../tpa/getAppDefId';

const TPA_APP_IDS = {
  ECOM: getEcomAppDefId(),
  STORES: getStoresAppDefId(),
  BOOKING: '13d21c63-b5ec-5912-8397-c3a5ddb27a97',
  FORUM: '14724f35-6794-cd1a-0244-25fd138f9242',
  BLOG: '14bcded7-0066-7c35-14d7-466cb3f09103',
  HOTELS: '135aad86-9125-6074-7346-29dc6a3c9bcf',
  RESTAURANTS_MENUS_: '13c1402c-27f2-d4ab-7463-ee7c89e07578',
  RESTAURANTS_ORDERS_: '13e8d036-5516-6104-b456-c8466db39542',
  SEARCH_RESULTS: '1484cb44-49cd-5b39-9681-75188ab429de',
};

const TPA_SYMBOLS_MAP = {
  [TPA_APP_IDS.ECOM]: 'storesPageType',
  [TPA_APP_IDS.STORES]: 'storesPageType',
  [TPA_APP_IDS.BOOKING]: 'bookingPageType',
  [TPA_APP_IDS.FORUM]: 'forumPageType',
  [TPA_APP_IDS.BLOG]: 'blogPageType',
  [TPA_APP_IDS.HOTELS]: 'hotelsPageType',
  [TPA_APP_IDS.RESTAURANTS_MENUS_]: 'restaurantsPageType',
  [TPA_APP_IDS.RESTAURANTS_ORDERS_]: 'restaurantsPageType',
  [TPA_APP_IDS.SEARCH_RESULTS]: 'searchPageType',
};

const DEFAULT_TPA_SYMBOL = 'tpaPageType';

export const getTpaSymbolName = (appDefinitionId?: string) =>
  TPA_SYMBOLS_MAP[appDefinitionId] || DEFAULT_TPA_SYMBOL;
