import { backgroundUtils } from '@wix/santa-platform-utils';
import { url, imageTransform } from '@wix/santa-editor-utils';

import constants from '#packages/constants';
import { staticAssetsMap } from '#packages/staticAssets';

import serviceTopology from '../serviceTopology/serviceTopology';
import translate from '../translation/translate';

import type { ServiceTopology } from 'types/core';

const SHAPE_SVG_TYPE = 'shape';
const NO_VIDEO = 'NO_VIDEO';
const PDF = /\.pdf$/i;

function getLinkComps(editorAPI: AnyFixMe) {
  return [
    ...editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
      constants.COMP_TYPES.SITE_BUTTON,
    ),
    ...editorAPI.components.get.byType_DEPRECATED_BAD_PERFORMANCE(
      constants.COMP_TYPES.DOCUMENT_MEDIA,
    ),
  ];
}

const getResizedImage = (
  src: string,
  width: number,
  height: number = width,
) => {
  const id = src.split('/').pop();

  const resizeImageData = imageTransform.getData(
    imageTransform.fittingTypes.SCALE_TO_FIT,
    { id, width, height },
    { width, height },
  );

  return `${serviceTopology.staticMediaUrl}/${resizeImageData.uri}`;
};

export const createMediaUtils = ({
  serviceTopology,
  assetsMap,
}: {
  serviceTopology: ServiceTopology;
  assetsMap: Record<string, string>;
}) => ({
  isSvgVectorArt(svgInfo: AnyFixMe) {
    return svgInfo?.svgType !== SHAPE_SVG_TYPE;
  },

  getQabUploadedSvgUrl(svgId: AnyFixMe) {
    return `${serviceTopology.mediaRootUrl}shapes/${svgId}.svg`;
  },

  getMediaUrl(mediaPath: string, fallback?: string): string {
    const baseUrl = url.joinURL(
      serviceTopology.scriptsDomainUrl,
      'services',
      'santa-resources',
      'dist',
      'editor',
    );
    let assetPath =
      assetsMap[mediaPath as keyof typeof assetsMap] ||
      assetsMap[fallback as keyof typeof assetsMap] ||
      fallback ||
      mediaPath;

    if (assetPath && assetPath[0] === '/') {
      assetPath = assetPath.substr(1);
    }
    return [baseUrl, assetPath].join('/');
  },

  getResizedImage,

  getVideoIfAvailable(sectionKey: AnyFixMe, videoIdKey: AnyFixMe) {
    const untranslatedVideoIdKey = `!${videoIdKey}!`;
    const videoUrl = translate(videoIdKey);
    if (videoUrl === NO_VIDEO || videoUrl === untranslatedVideoIdKey) {
      return false;
    }
    return videoUrl;
  },

  isPdfAlreadyMarkedIndexable(editorAPI: AnyFixMe, docId: AnyFixMe) {
    const linkComps = getLinkComps(editorAPI);
    const linkDatas = linkComps.map(
      (comp) => editorAPI.components.data.get(comp).link,
    );

    return linkDatas.some(
      (data) => data && data.docId === docId && data.indexable === true,
    );
  },

  isFilePDF(docId: AnyFixMe) {
    return PDF.test(docId || '');
  },

  setLinksAsIndexableForDoc(editorAPI: AnyFixMe, docId: AnyFixMe) {
    const linkComps = getLinkComps(editorAPI);

    linkComps.forEach((comp) => {
      const data = editorAPI.components.data.get(comp);

      if (data.link?.docId === docId && !data.link.indexable) {
        data.link.indexable = true;
        editorAPI.components.data.update(comp, data);
      }
    });
  },

  fixMediaTitleLength: backgroundUtils.fixMediaTitleLength,
});

export default createMediaUtils({
  serviceTopology,
  assetsMap: staticAssetsMap,
});
