export default {
  quick_navigation_clicked: {
    evid: 736,
    fields: {
      origin: 'string',
    },
  },
  click_on_pages_panel_open: {
    evid: 703,
    fields: {
      origin: 'string',
    },
  },
  navigate_to_page: {
    evid: 454,
    fields: {
      page_id: 'string',
      category: 'string',
      panel_name: 'string',
      isDynamicPage: 'string',
    },
  },
  navigate_login_settings: {
    evid: 1192,
    src: 5,
    endpoint: 'site-members',
    fields: {
      msid: 'guid',
    },
  },
  login_type_selection: {
    evid: 1193,
    src: 5,
    endpoint: 'site-members',
    fields: {
      msid: 'guid',
      selection: 'string',
      has_custom_signup: 'boolean',
    },
  },
  login_edit_clicked: {
    evid: 1194,
    src: 5,
    endpoint: 'site-members',
    fields: {
      msid: 'guid',
      origin: 'string',
    },
  },
  toggle_what_auth_presenting_first: {
    evid: 1201,
    src: 5,
    endpoint: 'site-members',
    fields: {
      msid: 'guid',
      first_option: 'string',
    },
  },
  add_page_from_add_panel: {
    evid: 796,
    fields: {
      type: 'string',
    },
  },
  add_dashboard_page_from_add_panel: {
    evid: 1042,
    fields: {
      multilingual: 'string',
    },
  },
  cant_add_page_tooltip_shown: {
    evid: 964,
    fields: {
      origin: 'string',
    },
  },
  pagesPanel: {
    ssr_cache_timeframe_dropdown_open: {
      evid: 1175,
      fields: {
        origin: 'string',
        pageId: 'string',
      },
    },
    static_page_deleted: {
      evid: 97,
      fields: {
        page_id: 'string',
      },
    },
    category_click: {
      evid: 704,
      fields: {},
    },
    category_view: {
      evid: 1005,
      fields: {
        category: 'string',
      },
    },
    add_page_selection_click: {
      evid: 412,
      fields: {
        origin: 'string',
        category: 'string',
        page_id: 'string',
        source: 'string',
        button: 'string',
      },
    },
    page_transition_selected_click: {
      evid: 415,
      fields: {
        category: 'string',
      },
    },
    too_many_pages_info_click: {
      evid: 962,
      fields: {
        origin: 'string',
        pages_count: 'numeric',
      },
    },
    too_many_pages_banner_shown: {
      evid: 963,
      fields: {
        pages_count: 'numeric',
      },
    },
    custom_menus_first_time_shown: {
      evid: 1048,
      fields: {
        message: 'string',
      },
    },
    custom_menus_first_time_close: {
      evid: 1052,
      fields: {
        message: 'string',
        button_name: 'string',
      },
    },
    dynamic_pages_suggestion_action_click: {
      evid: 965,
      fields: {
        pages_count: 'numeric',
      },
    },
    site_menu_opened: {
      evid: 966,
      fields: {
        pages_count: 'numeric',
      },
    },
    editor_loaded_with_more_than_max_static_pages: {
      evid: 969,
      fields: {
        pages_count: 'numeric',
      },
    },
    page_actions_click: {
      evid: 707,
      fields: {
        page_id: 'string',
        source: 'string',
        type: 'string',
      },
    },
    layout_toggle_landing: {
      evid: 92,
      fields: {
        category: 'string',
        pageId: 'string',
      },
    },
    quick_actions_menu_select: {
      evid: 421,
      fields: {
        page_id: 'string',
        category: 'string',
        source: 'string',
        type: 'string',
        is_opened_page: 'boolean',
      },
    },
    tab_clicked_on_settings: {
      evid: 705,
      fields: {
        category: 'string',
        source: 'string',
      },
    },
    tab_clicked_an_item_at_settings_menu: {
      evid: 706,
      fields: {
        category: 'string',
        source: 'string',
      },
    },
    settings_layout_choose: {
      evid: 1078,
      fields: {
        target: 'string',
      },
    },
    quick_action_hide_show_mobile_click: {
      evid: 436,
      fields: {
        category: 'string',
        source: 'string',
        page_id: 'string',
      },
    },
    convert_to_dynamic_on_duplicate_modal: {
      evid: 361,
      src: 79,
      endpoint: 'platform',
      fields: {
        action_name: 'string',
        origin: 'string',
        is_first_data_modal: 'boolean',
      },
    },
    hover_on_item_in_context_menu: {
      src: 38,
      evid: 1536,
      fields: {
        target: 'string',
        category: 'string',
        origin: 'string',
        source: 'string',
        page_id: 'string',
      },
    },
  },
  addPage: {
    page_added: {
      evid: 1072,
      fields: {
        origin: 'string',
        source: 'string',
        category: 'string',
        name: 'string',
        target: 'string',
        app_list: 'string',
        page_id: 'string',
        button: 'string',
        business_type: 'string',
        is_injected_content: 'boolean',
      },
    },
    add_new_page_click: {
      evid: 1079,
      fields: {
        origin: 'string',
        source: 'string',
        category: 'string',
        name: 'string',
        target: 'string',
        app_list: 'string',
        business_type: 'string',
        is_injected_content: 'boolean',
      },
    },
    panel_content_scrolled: {
      evid: 1080,
      fields: {
        scrollTop: 'numeric',
        target: 'string',
        business_type: 'string',
      },
    },
    thumbnails_load_finished: {
      evid: 1082,
      fields: {
        category: 'string',
        phaseName: 'string',
        duration: 'numeric',
        business_type: 'string',
        is_injected_content: 'boolean',
      },
    },
    tab_click: {
      evid: 1073,
      fields: {
        target: 'string',
        is_installed: 'boolean',
        business_type: 'string',
      },
    },
    page_preview_click: {
      evid: 1105,
      fields: {
        target: 'string',
        name: 'string',
        category: 'string',
        origin: 'string',
        is_installed: 'boolean',
        business_type: 'string',
      },
    },
    addAppClick: {
      evid: 1129,
      fields: {
        app_id: 'string',
        app_name: 'string',
      },
    },
    postInstallAppClick: {
      evid: 1130,
      fields: {
        app_id: 'string',
        app_name: 'string',
      },
    },
    appCarouselArrowClick: {
      evid: 1137,
      fields: {
        direction: 'string',
        target: 'string',
      },
    },
    replace_page_click: {
      evid: 1143,
      fields: {
        name: 'string',
        origin: 'string',
        page_id: 'string',
      },
    },
  },
} as const;
