import type { PublicApiKey, SlotKey } from '#packages/apilib';
import type { createAutosaveManagerApi } from './managers/autosaveManagerApi';
import type { createPublishManagerApi } from './managers/publishManagerApi';
import type { createSaveManagerApi } from './managers/saveManagerApi';
import type { createSavePublishApi } from './savePublishApi';

export const SaveManagerApiKey: SlotKey<
  ReturnType<typeof createSaveManagerApi>
> = {
  name: 'SaveManagerApiApi',
  public: true,
};

export const AutosaveManagerApiKey: PublicApiKey<
  typeof createAutosaveManagerApi
> = {
  name: 'AutosaveManagerApi',
  public: true,
};

export const PublishManagerApiKey: PublicApiKey<
  typeof createPublishManagerApi
> = {
  name: 'PublishManagerApiApi',
  public: true,
};

export const SavePublishApiKey: PublicApiKey<typeof createSavePublishApi> = {
  name: 'SavePublishApi',
  public: true,
};
