import { ESocialPlatform } from '@wix/adi-core-types';
import type { PlatformDataMapValue } from './types';

export const urlValidation =
  /^(https?:\/\/)?[^\s\./$?#]+\.[^\s\./$?#]{2,}[^\s]*$/i;
const urlPrefix = '^(?:https?://)?(www.)?';
const urlInternationalPrefix = '^(?:https?://)?([a-z]{1,3}.)?';
const urlSuffix = '/.+$';

export const platformDataMap: {
  [socialPlatformName: string]: PlatformDataMapValue;
} = {
  // TODO: map new platforms - https://jira.wixpress.com/browse/WEED-29327
  [ESocialPlatform.FACEBOOK]: {
    regex:
      /^(?:https?:\/\/)?((www|business)\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile\.php\?id=(?=\d.*))?([\w\-]*)?/,
    icon: 'FacebookIcon',
    relativeUriLight: '64a918_d9471de294d741f48b2e2b25d078e7a8~mv2.png',
    relativeUriDark: '64a918_ecce71321e844673b977c572d915fa2b~mv2.png',
    Title: 'Facebook',
  },
  [ESocialPlatform.TWITTER]: {
    regex:
      /^(?:https?:\/\/)?(www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/,
    icon: 'TwitterIcon',
    relativeUriLight: 'c7d035ba85f6486680c2facedecdcf4d.png',
    relativeUriDark: '64a918_6164e7ff95a147d2aa22c1a98c3ac43e~mv2.png',
    Title: 'Twitter',
  },
  [ESocialPlatform.INSTAGRAM]: {
    regex:
      /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_]+)/,
    icon: 'InstagramIcon',
    relativeUriLight: '01c3aff52f2a4dffa526d7a9843d46ea.png',
    relativeUriDark: '64a918_b802763ffe1b4e9292c9d7f1605587fb~mv2.png',
    Title: 'Instagram',
  },
  [ESocialPlatform.PINTEREST]: {
    regex: new RegExp(
      `${urlInternationalPrefix}pinterest((.[a-zA-Z]+)+)${urlSuffix}`,
    ),
    icon: 'PinterestIcon',
    relativeUriLight: '64a918_104dba51473a4b7d86c00631922b8976~mv2.png',
    relativeUriDark: '64a918_003a4aab954140a8b21361b8fea8ff39~mv2.png',
    Title: 'Pinterest',
  },
  [ESocialPlatform.YOUTUBE]: {
    regex: new RegExp(`${urlPrefix}(youtube.com|youtu.be)${urlSuffix}`),
    icon: 'YoutubeIcon',
    relativeUriLight: '64a918_e9386698aada4a6fa2cf9a27ec97a6ea~mv2.png',
    relativeUriDark: '64a918_cc1ee9fddadd4aa58c57ecdd7a1373e2~mv2.png',
    Title: 'Youtube',
  },
  [ESocialPlatform.BLOGGER]: {
    regex: new RegExp(`${urlPrefix}(\\w+.)?(blogger|blogspot)(\\.\\w+)+`),
    icon: 'BloggerIcon',
    relativeUriLight: '64a918_1e999cf0bb4c4b9dbc049aee397a6929~mv2.png',
    relativeUriDark: '64a918_fd594f44c24e4ba789bd3c25174ccb10~mv2.png',
    Title: 'Blogger',
  },
  [ESocialPlatform.FLICKR]: {
    regex: new RegExp(`${urlPrefix}(flickr.com|flic.kr)${urlSuffix}`),
    icon: 'FlickrIcon',
    relativeUriLight: '64a918_54c6730207e14472948dc6fa2904025c~mv2.png',
    relativeUriDark: '64a918_56cf02785e704230906611b4ac1456b2~mv2.png',
    Title: 'Flickr',
  },
  [ESocialPlatform.LINKEDIN]: {
    regex: new RegExp(`${urlInternationalPrefix}linkedin.com${urlSuffix}`),
    icon: 'LinkedinIcon',
    relativeUriLight: '64a918_ceec6f59c38341979111a1c3233aa7b8~mv2.png',
    relativeUriDark: '64a918_a50379c60382427393ddd2bbf493172c~mv2.png',
    Title: 'LinkedIn',
  },
  [ESocialPlatform.SOUNDCLOUD]: {
    regex: new RegExp(`${urlPrefix}(soundcloud.com|snd.sc)${urlSuffix}`),
    icon: 'SoundcloudIcon',
    relativeUriLight: '64a918_7b77aebeb7da4786a8080d2e50e15df5~mv2.png',
    relativeUriDark: '64a918_dc07bdca4b86483dba9723e4bac02187~mv2.png',
    Title: 'Soundcloud',
  },
  [ESocialPlatform.TUMBLR]: {
    regex: new RegExp(`${urlPrefix}([a-zA-Z0-9-]+.)?tumblr.com`),
    icon: 'TumblrIcon',
    relativeUriLight: '64a918_242d80933b104cb09beecb85e38152b6~mv2.png',
    relativeUriDark: '64a918_06cf831ed7924e2b8de82b73dff83a03~mv2.png',
    Title: 'Tumblr',
  },
  [ESocialPlatform.VIMEO]: {
    regex: new RegExp(`${urlPrefix}vimeo.com${urlSuffix}`),
    icon: 'VimeoIcon',
    relativeUriLight: '64a918_2409096cb17344a691ebb0b99bd37e70~mv2.png',
    relativeUriDark: '64a918_9ac63b90565b474c88ac4f89f1162e2f~mv2.png',
    Title: 'Vimeo',
  },
  [ESocialPlatform.VKONTAKTE]: {
    regex: new RegExp(`${urlPrefix}(\\w+.)?vk.com${urlSuffix}`),
    icon: 'VkontakteIcon',
    relativeUriLight: '64a918_72f115f5c9454ae4b903809071903562~mv2.png',
    relativeUriDark: '64a918_1502f8d04dfa4729881a649f6de5b25d~mv2.png',
    Title: 'Vkontakte',
  },
  [ESocialPlatform.ODNOKLASSNIKI]: {
    regex: new RegExp(`${urlPrefix}ok.ru${urlSuffix}`),
    icon: 'OdnoklassnikiIcon',
    relativeUriLight: '982336b31261453582d50aa2496a582b.png',
    relativeUriDark: '64a918_fb514979798346918ae173385e3a016b~mv2.png',
    Title: 'Odnoklassniki',
  },
  [ESocialPlatform.YELP]: {
    regex: new RegExp(`${urlPrefix}yelp.com${urlSuffix}`),
    icon: 'YelpIcon',
    relativeUriLight: '64a918_d09691df1db747e8aa25514d25d113eb~mv2.png',
    relativeUriDark: '64a918_deab5985f1d54d2e9d74c64683c57cf5~mv2.png',
    Title: 'Yelp',
  },
  [ESocialPlatform.GOOGLE_PLACES]: {
    regex: new RegExp(
      `${urlPrefix}(google(.[a-z]+){1,2}/maps|maps.google.[^s]+)${urlSuffix}`,
    ),
    icon: 'GooglePlacesIcon',
    relativeUriLight: '390db90c2cec42d58bd3203fc21fe12e.png',
    relativeUriDark: '64a918_fb514979798346918ae173385e3a016b~mv2.png',
    Title: 'Google Places',
  },
  [ESocialPlatform.MYSPACE]: {
    regex: new RegExp(`${urlPrefix}myspace.com${urlSuffix}`),
    icon: 'MyspaceIcon',
    relativeUriLight: '393712e1bfea435eb4ccb505a4fb494c.png',
    relativeUriDark: '64a918_fb514979798346918ae173385e3a016b~mv2.png',
    Title: 'Houzz',
  },
  [ESocialPlatform.HOUZZ]: {
    regex: new RegExp(`${urlPrefix}houzz.com${urlSuffix}`),
    icon: 'HouzzIcon',
    relativeUriLight: '393712e1bfea435eb4ccb505a4fb494c.png',
    relativeUriDark: '64a918_fb514979798346918ae173385e3a016b~mv2.png',
    Title: 'Houzz',
  },
  [ESocialPlatform.GOOGLE_PLUS]: {
    regex: /^(?:https?:\/\/)?(www\.)?plus\.google\.com\/.?\/?.?\/?([0-9]*)/,
    icon: 'GooglePlusIcon',
    relativeUriLight: '05546d0902004bfabba0396e21f33f7d.png',
    relativeUriDark: '64a918_fb514979798346918ae173385e3a016b~mv2.png',
    Title: 'Google Plus',
  },
  [ESocialPlatform.TRIP_ADVISOR]: {
    regex: new RegExp(`${urlPrefix}tripadvisor.com${urlSuffix}`),
    icon: 'TripAdvisorIcon',
    relativeUriLight: '17cf82e0d3fc4c11a370a8eedfefa0fb.png',
    relativeUriDark: '64a918_fb514979798346918ae173385e3a016b~mv2.png',
    Title: 'Trip Advisor',
  },
  [ESocialPlatform.VALID_CUSTOM_LINK]: {
    regex: urlValidation,
    icon: 'urlLink',
    relativeUriLight: '64a918_547aefbf5bbf48169e18ca7048ccdb02~mv2.png',
    relativeUriDark: '64a918_fb514979798346918ae173385e3a016b~mv2.png',
    Title: 'Link',
  },
};
