import type Ajv from 'ajv';
import type { Schema } from 'ajv';

async function loadAjwWithJSONSchemas() {
  const [
    { default: Ajv },
    commonSchema,
    commonSchemasSchema,
    layoutDataSchema,
    structureSchema,
  ] = await Promise.all([
    import('ajv'),
    import('@wix/document-services-json-schemas/dist/schemas/common.json'),
    import(
      '@wix/document-services-json-schemas/dist/schemas/commonSchemas.json'
    ),
    import('@wix/document-services-json-schemas/dist/schemas/layout_data.json'),
    import('@wix/document-services-json-schemas/dist/schemas/structure.json'),
  ]);

  const ajv = new Ajv({
    strict: false,
  });

  ajv.addSchema(commonSchema, 'common.json');
  ajv.addSchema(commonSchemasSchema, 'commonSchemas.json');
  ajv.addSchema(layoutDataSchema, 'layout_data.json');
  ajv.addSchema(structureSchema, 'structure.json');

  const CompRefSchema = {
    type: 'object',
    properties: {
      id: { type: 'string' },
      type: { type: 'string' },
    },
    required: ['id', 'type'],
  };

  return {
    ajv,
    schemas: {
      CompRef: CompRefSchema,
      SingleLayoutData: {
        $ref: 'layout_data.json#/SingleLayoutData',
      },
      LayoutObjectForComponents: {
        $ref: 'structure.json#/LayoutObjectForComponents',
      },
      DockingDefinition: {
        $ref: 'structure.json#/DockingDefinition',
      },
    } as const,
  };
}

function defineArgumensSchemas(
  schemas: Awaited<ReturnType<typeof loadAjwWithJSONSchemas>>['schemas'],
) {
  return new Map<string, Schema[]>([
    [
      'dsActions.components.layout.update',
      [schemas.CompRef, schemas.LayoutObjectForComponents],
    ],
    [
      'dsActions.components.layout.setDock',
      [schemas.CompRef, schemas.DockingDefinition],
    ],
    [
      'dsActions.components.layout.updateDock',
      [schemas.CompRef, schemas.DockingDefinition],
    ],
    ['dsActions.components.layout.unDock', [schemas.CompRef]],
    [
      'dsActions.components.responsiveLayout.update',
      [schemas.CompRef, schemas.SingleLayoutData],
    ],
    [
      'dsActions.components.responsiveLayout.pin',
      [schemas.CompRef, schemas.SingleLayoutData],
    ],
  ]);
}

export function createValidateDSActionsMethodArguments() {
  let _argumentsSchemasMap: Map<string, Schema[]>;
  let _ajv: Ajv;

  loadAjwWithJSONSchemas()
    .then(({ ajv, schemas }) => {
      _ajv = ajv;
      _argumentsSchemasMap = defineArgumensSchemas(schemas);
    })
    .catch((error) => {
      console.error(error);
    });

  function validateArguments(methodKey: string, args: IArguments) {
    const errors = _argumentsSchemasMap
      ?.get(methodKey)
      ?.map((argumentSchema, index) => {
        if (!_ajv.validate(argumentSchema, args[index])) {
          return _ajv.errors;
        }
        return null;
      });

    return errors?.some(Boolean) ? errors : null;
  }

  return validateArguments;
}
