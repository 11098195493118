import experiment from 'experiment';
import type { EditorAPI } from '#packages/editorAPI';
import type {
  CompLayout,
  CompLayoutUpdate,
  CompRef,
} from 'types/documentServices';
import constants from '#packages/constants';
import { fixedStage } from '#packages/util';

const DEFAULT_SNAP_TRESHOLD = 5;
const BORDER_SNAP_TRESHOLD = 6;
const MAGNET_SNAP_TRESHOLD = BORDER_SNAP_TRESHOLD + 1;
const FULL_WIDTH_THRESHOLD = 15;
const SECTION_VERTICAL_THRESHOLD = 60;

const isNewStageGuidesEnabled = () => {
  return (
    experiment.isOpen('se_newStageGuides') && fixedStage.isFixedStageEnabled()
  );
};

interface ContainerMargins {
  left: number;
  right: number;
  top: number;
  bottom: number;
}

function getContainerMargins(
  compLayout: CompLayout,
  containerLayout: CompLayout,
) {
  const left = Math.round(compLayout.x - containerLayout.x);
  const top = Math.round(compLayout.y - containerLayout.y);
  const right = Math.round(
    containerLayout.x + containerLayout.width - compLayout.x - compLayout.width,
  );
  const bottom = Math.round(
    containerLayout.y +
      containerLayout.height -
      compLayout.y -
      compLayout.height,
  );

  return { left, top, right, bottom };
}

const getIsCloseEnough = (margins: ContainerMargins, distance: number) => {
  const { right, left, top, bottom } = margins;

  const updateDirections = {
    left: left >= 0 && left <= distance,
    right: right >= 0 && right <= distance,
    top: top >= 0 && top <= distance,
    bottom: bottom >= 0 && bottom <= distance,
  };

  return updateDirections;
};

function getLayoutUpdatesOnResize(
  layout: CompLayout,
  containerLayout: CompLayout,
) {
  const compLayoutUpdates: CompLayoutUpdate = {};
  const containerMargins = getContainerMargins(layout, containerLayout);
  const updateDirections = getIsCloseEnough(
    containerMargins,
    MAGNET_SNAP_TRESHOLD,
  );
  const { left, right, top, bottom } = containerMargins;

  if (updateDirections.left) {
    Object.assign(compLayoutUpdates, {
      x: containerLayout.x,
      width: layout.width + left,
    });
  }

  if (updateDirections.right) {
    Object.assign(compLayoutUpdates, {
      width: layout.width + right,
    });
  }

  if (updateDirections.top) {
    Object.assign(compLayoutUpdates, {
      y: containerLayout.y,
      height: layout.height + top,
    });
  }

  if (updateDirections.bottom) {
    Object.assign(compLayoutUpdates, {
      height: layout.height + bottom,
    });
  }

  return compLayoutUpdates;
}

function getLayoutUpdatesOnDrag(
  layout: CompLayout,
  containerLayout: CompLayout,
) {
  const compLayoutUpdates: CompLayoutUpdate = {};

  const containerMargins = getContainerMargins(layout, containerLayout);
  const updateDirections = getIsCloseEnough(
    containerMargins,
    MAGNET_SNAP_TRESHOLD,
  );
  const { right, bottom } = containerMargins;

  if (updateDirections.left) {
    Object.assign(compLayoutUpdates, {
      x: containerLayout.x,
    });
  }

  if (updateDirections.right) {
    Object.assign(compLayoutUpdates, { x: layout.x + right });
  }

  if (updateDirections.top) {
    Object.assign(compLayoutUpdates, {
      y: containerLayout.y,
    });
  }

  if (updateDirections.bottom) {
    Object.assign(compLayoutUpdates, { y: layout.y + bottom });
  }

  return compLayoutUpdates;
}

function snapCloseToBorder(
  editorAPI: EditorAPI,
  selectedComps: CompRef[],
  snapType:
    | typeof constants.MOUSE_ACTION_TYPES.RESIZE
    | typeof constants.MOUSE_ACTION_TYPES.DRAG,
) {
  if (selectedComps.length !== 1) {
    return;
  }

  const layout = editorAPI.components.layout.getRelativeToScreen(selectedComps);
  const [selectedCompRef] = selectedComps;
  const containerCompRef = editorAPI.components.getContainer(selectedCompRef);
  const containerLayout =
    editorAPI.components.layout.getRelativeToScreen(containerCompRef);

  if (!editorAPI.components.is.fullWidth(containerCompRef)) {
    return;
  }

  const isResize = snapType === constants.MOUSE_ACTION_TYPES.RESIZE;
  const compLayoutUpdates = isResize
    ? getLayoutUpdatesOnResize(layout, containerLayout)
    : getLayoutUpdatesOnDrag(layout, containerLayout);

  if (Object.keys(compLayoutUpdates).length > 0) {
    editorAPI.components.layout.updateRelativeToScreen(
      selectedCompRef,
      compLayoutUpdates,
    );
  }
}

const isSnappedToContainerHorizontally = (
  editorAPI: EditorAPI,
  comp: CompRef,
) => {
  const { snappedToLeft, snappedToRigth } = getHorizontalSnapData(
    editorAPI,
    comp,
  );

  return snappedToLeft || snappedToRigth;
};

const getHorizontalSnapData = (editorAPI: EditorAPI, comp: CompRef) => {
  const parent = editorAPI.components.getContainerOrScopeOwner(comp);
  const compLayout = editorAPI.components.layout.getRelativeToScreen(comp);
  const parentLayout = editorAPI.components.layout.getRelativeToScreen(parent);

  return {
    snappedToLeft:
      compLayout.x + compLayout.width === parentLayout.x + parentLayout.width,
    snappedToRigth: compLayout.x === parentLayout.x,
  };
};

export {
  DEFAULT_SNAP_TRESHOLD,
  BORDER_SNAP_TRESHOLD,
  MAGNET_SNAP_TRESHOLD,
  FULL_WIDTH_THRESHOLD,
  SECTION_VERTICAL_THRESHOLD,
  snapCloseToBorder,
  getIsCloseEnough,
  getContainerMargins,
  isNewStageGuidesEnabled,
  isSnappedToContainerHorizontally,
  getHorizontalSnapData,
};
