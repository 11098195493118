export enum LEGACY_WIX_APPS_NAMES {
  FAQ = 'f2c4fc13-e24d-4e99-aadf-4cff71092b88',
  RESTAURANTS_MENUS = '1660c5f3-b183-4e6c-a873-5d6bbd918224',
  NEWS_A = '045dd836-ef5d-11e1-ace3-c0dd6188709b',
  NEWS_B = '63631b64-a981-40c3-8772-40238db5aff6',
}

export const LEGACY_WIX_APPS_NAMES_TO_HELP_ID_MAP: Record<
  LEGACY_WIX_APPS_NAMES,
  string
> = {
  [LEGACY_WIX_APPS_NAMES.FAQ]: '5f892cd8-b07c-4549-827e-682753e98241',
  [LEGACY_WIX_APPS_NAMES.RESTAURANTS_MENUS]:
    '43b70343-3448-4de7-953d-e813e9d1f9ed',
  [LEGACY_WIX_APPS_NAMES.NEWS_A]: 'f450a9f1-da3a-4573-9a10-c752c2aae341',
  [LEGACY_WIX_APPS_NAMES.NEWS_B]: 'f450a9f1-da3a-4573-9a10-c752c2aae341',
};
