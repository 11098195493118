import baseConstants from './base/baseConstants';
import overriddenConstants from './overridden/overriddenConstants';
import appStudioConstants from './appStudio/appStudioConstants';
import { wixDataConstants } from './wixData/wixDataConstants';
import textControlsConstants from './textControls/textControlsConstants';

export const { SELECTABLE_CONTAINER } = baseConstants.COMP_TYPES;

export type {
  GridLinesType,
  Feature,
  ProgressStatus,
  MediaTypes,
  BackgroundData,
  VideoData,
  ImageData,
  DesktopSuperApps,
  PanelType,
  PanelSection,
  GfppActions,
  MatchSizeValue,
} from './base/baseConstants';

export {
  LEGACY_WIX_APPS_NAMES,
  LEGACY_WIX_APPS_NAMES_TO_HELP_ID_MAP,
} from './legacyWixApps/legacyWixApps';

export { RCM_ORIGIN } from './rightClickMenu/rightClickMenuConstants';
export {
  WORKSPACE_RIGHT_PANEL_NAMES,
  WORKSPACE_RIGHT_PANEL_TAB_GROUP_NAMES,
  WORKSPACE_RIGHT_PANEL_TAB_GROUP_TITLES,
  WORKSPACE_RIGHT_PANEL_GROUPS_TAB_ORDER,
  WORKSPACE_RIGHT_DRILL_IN_PANEL_NAMES,
} from './workspaceRightPanel/workspaceRightPanelConstants';

export default Object.assign(baseConstants, {
  OVERRIDDEN: overriddenConstants,
  APP_STUDIO: appStudioConstants,
  WIX_DATA: wixDataConstants,
  TEXT_CONTROLS: textControlsConstants,
});

export { TagValue, TextEditorLocation } from './text/textConstants';
export { SiteAccessPermission } from './siteAccess';
