export default {
  SAVE_ERROR: {
    errorName: 'SAVE_ERROR',
    errorCode: 504,
    severity: 'fatal',
    params: {
      p1: 'failedServices',
      p2: 'errorTypes',
      p3: 'errorCodes',
    },
  },
  FIRST_SAVE_ERROR: {
    errorName: 'FIRST_SAVE_ERROR',
    errorCode: 505,
    severity: 'fatal',
    params: {
      p1: 'failedServices',
      p2: 'errorTypes',
      p3: 'errorCodes',
    },
  },
  FULL_SAVE_ERROR: {
    errorName: 'FULL_SAVE_ERROR',
    errorCode: 506,
    severity: 'fatal',
    params: {
      p1: 'failedServices',
      p2: 'errorTypes',
      p3: 'errorCodes',
    },
  },
  DS_SAVE_TIMEOUT_ERROR: {
    errorName: 'DS_SAVE_TIMEOUT_ERROR',
    severity: 'warning',
    errorCode: 599,
    desc: 'dsActions.save is running too long',
    params: {
      p1: 'saveOptions',
    },
  },
  SITE_SETTINGS_JSON_PARSE_ERROR: {
    errorName: 'SITE_SETTINGS_JSON_PARSE_ERROR',
    errorCode: 10000,
    severity: 'error',
    params: {
      p1: 'dsc',
      p2: 'errscp',
    },
  },
  BG_MOBILE_DATA_DUPLICATION_ERROR: {
    errorName: 'BG_MOBILE_DATA_DUPLICATION_ERROR',
    errorCode: 3771,
    severity: 'error',
  },
  OLD_TEXT_MIGRATION: {
    errorName: 'OLD_TEXT_MIGRATION',
    errorCode: 58,
    severity: 'error',
  },
} as const;
