import * as utils from '@wix/santa-editor-utils';

export const isRightClickWithCtrlInDebugMode = (e: AnyFixMe) => {
  // added condition for ctrl + right click - hack for development
  const query = utils.url.parseUrlParams(window.location as any);
  return e.ctrlKey && typeof query.debug !== 'undefined';
};

export const preventContextMenu = (e: AnyFixMe) => {
  if (!isRightClickWithCtrlInDebugMode(e)) {
    e.preventDefault();
  }
};
