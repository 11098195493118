export default {
  top_bar_drop_panel_choose_lang: {
    evid: 689,
    src: 38,
    fields: {
      lang: 'string',
      origin: 'string',
      is_primary: 'bool',
      hosting: 'string',
      is_visible: 'boolean',
    },
  },
  top_bar_drop_panel_publish_lang: {
    evid: 203,
    src: 89,
    endpoint: 'linguist',
    fields: {
      lang: 'string',
      origin: 'string',
    },
  },
  top_bar_drop_panel_unpublish_lang: {
    evid: 202,
    src: 89,
    endpoint: 'linguist',
    fields: {
      lang: 'string',
      origin: 'string',
    },
  },
  delete_language_menu_panel_selection: {
    evid: 263,
    src: 89,
    endpoint: 'linguist',
    fields: {
      button_name: 'string',
      primary_lang: 'string',
      current_secondary_lng: 'string',
      page_id: 'string',
      page_name: 'string',
    },
  },
  language_menu_order_changed: {
    evid: 768,
    src: 38,
    fields: {
      lang: 'string',
    },
  },
  manage_languages: {
    evid: 769,
    src: 38,
    fields: {
      source: 'string',
      hosting: 'string',
    },
  },
  styling_dont_show: {
    evid: 770,
    src: 38,
    fields: {
      toggle_state: 'bool',
    },
  },
  language_selector_show_icons_toggle: {
    evid: 788,
    src: 38,
    fields: {
      toggle_status: 'bool',
      source: 'string',
    },
  },
  translate_gfpp_exposure: {
    evid: 79,
    src: 38,
    fields: {
      app_id: 'string',
      component_id: 'string',
      panel_name: 'string',
      panel_type: 'string',
      page_id: 'string',
      page_name: 'string',
    },
  },
  design_layout_panel_selection: {
    evid: 265,
    src: 89,
    endpoint: 'linguist',
    fields: {
      dont_show_again_indication: 'boolean',
      button_name: 'string',
      page_id: 'string',
      page_name: 'string',
    },
  },
  language_picker_opened: {
    evid: 266,
    src: 89,
    endpoint: 'linguist',
    fields: {
      origin: 'string',
      hosting: 'string',
    },
  },
  language_picker_closed: {
    evid: 267,
    src: 89,
    endpoint: 'linguist',
    fields: {
      origin: 'string',
      hosting: 'string',
    },
  },
  dashboard_start_open: {
    evid: 268,
    src: 89,
    endpoint: 'linguist',
    fields: {
      lang: 'string',
      origin: 'string',
    },
  },
  multilingual_mode_opened: {
    evid: 269,
    src: 89,
    endpoint: 'linguist',
    fields: {
      lang: 'string',
      origin: 'string',
    },
  },
  multilingual_mode_closed: {
    evid: 270,
    src: 89,
    endpoint: 'linguist',
    fields: {
      lang: 'string',
      origin: 'string',
    },
  },
  multilingual_mode_exit_click: {
    evid: 264,
    src: 89,
    endpoint: 'linguist',
    fields: {
      page_id: 'string',
      page_name: 'string',
      primary_lang: 'string',
      current_secondary_lng: 'string',
    },
  },
  mode_bar_auto_translate_opened: {
    evid: 275,
    src: 89,
    endpoint: 'linguist',
    fields: {
      main_language: 'string',
      lang: 'string',
      content_type: 'string',
      words_in_credit: 'number',
      words_translated: 'number',
      words_remain: 'number',
      is_credit_enough: 'boolean',
      words_needed: 'number',
    },
  },
  translate_content_manually_clicked: {
    evid: 366,
    src: 89,
    endpoint: 'linguist',
    fields: {
      words_in_credit: 'number',
      words_translated: 'number',
      words_remain: 'number',
      is_credit_enough: 'boolean',
      words_needed: 'number',
    },
  },
  gt_limit_learn_more_clicked: {
    evid: 367,
    src: 89,
    endpoint: 'linguist',
    fields: {
      words_in_credit: 'number',
      is_credit_enough: 'boolean',
    },
  },
  get_more_credit_clicked: {
    evid: 363,
    src: 89,
    endpoint: 'linguist',
    fields: {
      words_in_credit: 'number',
      words_translated: 'number',
      origin: 'string',
      is_credit_enough: 'boolean',
      words_remain: 'number',
      words_needed: 'number',
    },
  },
  auto_translate_confirm_clicked: {
    evid: 375,
    src: 89,
    endpoint: 'linguist',
    fields: {
      words_in_credit: 'number',
      words_translated: 'number',
      words_remain: 'number',
      is_credit_enough: 'boolean',
      words_needed: 'number',
      origin: 'string',
    },
  },

  dont_show_this_again_clicked: {
    evid: 365,
    src: 89,
    endpoint: 'linguist',
    fields: {
      words_in_credit: 'number',
      words_translated: 'number',
      words_remain: 'number',
      is_credit_enough: 'boolean',
      words_needed: 'number',
      origin: 'string',
      dont_show_this_again: 'boolean',
    },
  },
  google_translate_cancel_modal_clicked: {
    evid: 364,
    src: 89,
    endpoint: 'linguist',
    fields: {
      words_in_credit: 'number',
      words_translated: 'number',
      words_remain: 'number',
      is_credit_enough: 'boolean',
      words_needed: 'number',
      origin: 'string',
      type: 'string',
    },
  },
  mode_bar_auto_translate_opened_by_click: {
    evid: 377,
    src: 89,
    endpoint: 'linguist',
    fields: {
      main_language: 'string',
      lang: 'string',
      content_type: 'string',
      is_credit_enough: 'string',
      origin: 'string',
      words_in_credit: 'number',
      words_needed: 'number',
      words_remain: 'number',
      words_translated: 'number',
    },
  },

  mode_bar_auto_translate_closed: {
    evid: 276,
    src: 89,
    endpoint: 'linguist',
    fields: {
      main_language: 'string',
      lang: 'string',
      content_type: 'string',
      words_in_credit: 'number',
      words_translated: 'number',
      words_remain: 'number',
      is_credit_enough: 'boolean',
      words_needed: 'number',
    },
  },
  mode_bar_auto_translate_clicked: {
    evid: 277,
    src: 89,
    endpoint: 'linguist',
    fields: {
      main_language: 'string',
      lang: 'string',
      words_in_credit: 'number',
      words_translated: 'number',
      words_remain: 'number',
      is_credit_enough: 'boolean',
    },
  },
  auto_translation_started: {
    evid: 274,
    src: 89,
    endpoint: 'linguist',
    fields: {
      pages_count: 'number',
      char_count: 'number',
      translations_count: 'number',
      origin: 'string',
      referrer: 'string',
      main_language: 'string',
      lang: 'string',
    },
  },
  auto_translation_finished: {
    evid: 278,
    src: 89,
    endpoint: 'linguist',
    fields: {
      duration: 'number',
      success: 'boolean',
      pages_count: 'number',
      char_count: 'number',
      translations_count: 'number',
      origin: 'string',
      referrer: 'string',
      main_language: 'string',
      lang: 'string',
    },
  },
  language_menu_added_ob: {
    evid: 289,
    src: 89,
    endpoint: 'linguist',
    fields: {
      container_id: 'string',
      main_language: 'string',
      page_id: 'string',
    },
  },
  new_language_check: {
    evid: 302,
    src: 89,
    endpoint: 'linguist',
    fields: {
      languages: 'string',
      primary_lang: 'string',
    },
  },
  language_menu_deleted: {
    evid: 351,
    src: 89,
    endpoint: 'linguist',
    fields: {
      page_id: 'string',
      page_name: 'string',
      primary_lang: 'string',
      current_secondary_lng: 'string',
    },
  },
} as const;

export const googleTranslate = {
  sent: {
    evid: 243,
    src: 89,
    endpoint: 'linguist',
    fields: {
      component_id: 'string',
      component_type: 'string',
      string_length_sent: 'number',
      origin: 'string',
      words_in_credit: 'number',
      words_translated: 'number',
      words_remain: 'number',
      primary_lang: 'string',
      current_secondary_lng: 'string',
    },
  },
  received: {
    evid: 244,
    src: 89,
    endpoint: 'linguist',
    fields: {
      component_id: 'string',
      component_type: 'string',
      string_length_sent: 'number',
      string_length_received: 'number',
      success: 'boolean',
      duration: 'number',
      origin: 'string',
    },
  },
  smthWentWrongPopupDisplayed: {
    evid: 249,
    src: 89,
    endpoint: 'linguist',
    fields: {
      component_id: 'string',
      component_type: 'string',
      origin: 'string',
      error_type: 'string',
      referrer: 'string',
      process_type: 'string',
      lang: 'string',
      panel_name: 'string',
      error_data: 'string',
    },
  },
  smthWentWrongPopupClosed: {
    evid: 250,
    src: 89,
    endpoint: 'linguist',
    fields: {
      component_id: 'string',
      component_type: 'string',
      origin: 'string',
      process_type: 'string',
      button: 'string',
      panel_name: 'string',
    },
  },
} as const;
