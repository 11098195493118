import type { CompLayout, CompRef, Point } from 'types/documentServices';

export enum RectTypes {
  SnappedToBorder,
  BorderMagnet,
  MarginIndicatorsCompBorder,
}

export type SnapDataStyles = Pick<
  React.SVGProps<SVGSVGElement>,
  'stroke' | 'fill'
>;

export interface RectangleDrawData {
  x: number;
  y: number;
  width: number;
  height: number;
  style: SnapDataStyles;
}

export interface AlignmentLine {
  type: 'alignmentLine';
  line?: [Point, Point];
  rectanglesDrawData: RectangleDrawData[];
  style?: SnapDataStyles;
}

export interface SnapTextLabel {
  type: 'textLabel';
  position: Point;
  value: number | string;
  style?: SnapDataStyles;
}

export type SnapData = AlignmentLine | SnapTextLabel;

export enum SnapCandidateType {
  ContainerWidthPercentage = 'ContainerWidthPercentage',
  StageCenter = 'StageCenter',
  MarginIndicator = 'MarginIndicator',
}

export interface SnapCandidate {
  type?: SnapCandidateType;
  comp?: CompRef;
  layout: CompLayout;
  isDynamicLayout: boolean;
  style?: SnapDataStyles;
}

export interface Margin {
  top: number;
  bottom: number;
  left: number;
  right: number;
}
