import { useEffect, useState, useCallback } from 'react';

import fedopsLogger from '../utils/integrations/fedopsLogger';

import type { AmbassadorHTTPError } from '@wix/ambassador/runtime/http';
import type { EditorInteractionName } from '../../../types/fedops';

interface UseRequestDataOptions<T, K extends EditorInteractionName> {
  initialData?: T;
  fedopsInteractionName: K;
  enabled?: boolean;
}

export const useRequest = <
  T,
  K extends EditorInteractionName,
  E extends Error = AmbassadorHTTPError,
>(
  request: () => Promise<T>,
  options?: UseRequestDataOptions<T, K>,
) => {
  const shouldBeCalled = options?.enabled ?? true;

  const [attempts, setAttempts] = useState(1);
  const retry = useCallback(() => {
    setAttempts((current) => current + 1);
  }, [setAttempts]);
  const [isLoading, setLoading] = useState(!!shouldBeCalled);
  const [error, setError] = useState<E>(null);
  const [data, setData] = useState<T>(options?.initialData || null);

  useEffect(
    () => {
      if (!shouldBeCalled) {
        return;
      }

      let isAborted = false;

      if (options?.fedopsInteractionName) {
        fedopsLogger.interactionStarted(options.fedopsInteractionName);
      }

      request()
        .then((data) => {
          if (options?.fedopsInteractionName) {
            fedopsLogger.interactionEnded(options.fedopsInteractionName);
          }
          if (isAborted) {
            return;
          }
          setData(data);
          setLoading(false);
          setError(null);
        })
        .catch((error: E) => {
          if (isAborted) {
            return;
          }
          setData(null);
          setError(error);
          setLoading(false);
        });

      return () => {
        isAborted = true;
      };
    }, // TODO: Fix this the next time the file is edited. request is usually arrow function
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attempts, shouldBeCalled, options.fedopsInteractionName],
  );

  return [data, { isLoading, error, retry }] as const;
};
