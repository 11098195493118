export default {
  link_panel_done_click: {
    evid: 160,
    fields: {
      category: 'string',
      anchor_where_on_page: 'string',
      open_method: 'string',
      urlAddress: 'string',
      targetPage: 'string',
      appDefinitionId: 'string',
      component_id: 'string',
      component_type: 'string',
      is_changed: 'boolean',
      selection: 'string',
    },
  },
  link_panel_radio_button_click: {
    evid: 1231,
    fields: {
      category: 'string',
    },
  },
  link_panel_drop_down_open: {
    evid: 1232,
    fields: {
      category: 'string',
      subcategory: 'string',
    },
  },
  link_panel_drop_down_click: {
    evid: 1346,
    fields: {
      category: 'string',
      rel_name: 'string',
      rel_value: 'string',
    },
  },
  LINK_PANEL_ADD_CLICK_TO_ACTION: {
    evid: 627,
    fields: {
      action_type: 'string',
      component_type: 'string',
      field_type: 'string',
      data: 'string',
      site_id: 'guid',
      i1: 'string',
    },
  },
  LINK_PANEL_CLICK_BUSINESS_NUMBERS_LINK: {
    evid: 1061,
    src: 38,
    fields: {
      type: 'string',
      component_type: 'string',
      component_id: 'string',
    },
  },
  LINK_PANEL_PAGE_NAME_TYPE_START: {
    evid: 990,
    src: 38,
    fields: {},
  },
  LINK_PANEL_LIGHTBOX_ADDED_TO_STAGE: {
    evid: 1234,
    src: 38,
    fields: {
      app_id: 'guid',
      origin: 'string',
      component_type: 'string',
      component_id: 'string',
      component_name: 'string',
      adding_method: 'string',
      preset_data_skin: 'string',
      preset_data_tags: 'string',
      section: 'string',
      category: 'string',
      page_id: 'string',
      target_component: 'string',
      is_target_repeated: 'boolean',
      properties: 'string',
      json_components_presets: 'string',
    },
  },
  CHANGE_REL_VALUES: {
    category: 'string',
    rel_name: 'string',
    rel_value: 'boolean',
  },
} as const;
