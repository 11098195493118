export default {
  PRESET_OR_CATEGORY_SELECTED: {
    evid: 109,
    src: 164,
    endpoint: 'editor-platform',
    fields: {
      target_type: 'string',
      target: 'string',
      previous_value: 'string',
      app_id: 'string',
      host_app_id: 'string',
      component_id: 'string',
      origin: 'string',
    },
  },
} as const;
