import _ from 'lodash';

const fromSectionNameToOverrideObject = (sectionName: string) => ({
  sectionName,
  sectionFooter: null as any,
});

const addItemInPosition = (arr: AnyFixMe, pos: AnyFixMe, item: AnyFixMe) => [
  ...arr.slice(0, pos),
  item,
  ...arr.slice(pos),
];

const applyOverrides = (
  defaultItems: AnyFixMe,
  overrides: AnyFixMe,
  identifierName = 'key',
) => {
  if (!overrides) {
    return defaultItems;
  }

  const defaultsMap = _.keyBy(defaultItems, identifierName);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/filter
  return _(overrides)
    .filter(
      (override) =>
        defaultsMap[override[identifierName]] || override?.__isExtraItem,
    )
    .map((override) => {
      if (override[identifierName] && defaultsMap[override[identifierName]]) {
        const original = defaultsMap[override[identifierName]];
        if (!_.isEqual(override, original)) {
          /*
           * at the time of a writing (2020-02-20),
           * `override` and `original` are often big _and_ (always?) equal,
           * so doing `_.defaultsDeep` on them is both expensive _and_ unnecessary,
           * so deep comparing them speeds up `applyOverrides` _a lot_
           * (for comparison, see https://github.com/wix-private/santa-editor/pull/26430/)
           *
           * if you think that you should delete that `_.isEqual`,
           * please make sure that it won't make `applyOverrides` awfully slow
           *
           * or, if you know for sure that `override` and `original` are _always_ equal,
           * consider removing `applyOverrides` function calls altogether
           */
          return _.defaultsDeep({}, override, original);
        }
      }
      return override;
    })
    .value();
};

const mapOverrideConfigToCategoriesSchema = (categoryDefinition: AnyFixMe) =>
  categoryDefinition.map((sectionsDef: AnyFixMe) => ({
    id: sectionsDef.categoryId,

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/map
    overriddenSections: _.map(
      sectionsDef.sectionName,
      fromSectionNameToOverrideObject,
    ),
  }));

const mapOverrideConfigToCategoriesGroupSchema = (
  categoriesDefinition: AnyFixMe,
) =>
  categoriesDefinition.map((definition: AnyFixMe) => ({
    id: definition.categoryId,

    groups: definition.groups.map((group: AnyFixMe) => ({
      sections: group.sections.map(fromSectionNameToOverrideObject),
      title: group.title,
    })),
  }));

const addExtraSection = (
  categories: AnyFixMe,
  categoryId: AnyFixMe,
  sectionName: AnyFixMe,
  index: AnyFixMe,
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const categoryToOverride = _.find(categories, { id: categoryId });
  categoryToOverride.overriddenSections = addItemInPosition(
    categoryToOverride.overriddenSections,
    index,
    {
      sectionName,
      __isRef: true,
      __isExtraItem: true,
      id: `categoryId${index}`,
    },
  );

  return categories;
};

const addExtraSectionByGroup = (
  categories: AnyFixMe,
  categoryId: AnyFixMe,
  groupIndex: AnyFixMe,
  sectionName: AnyFixMe,
  index: AnyFixMe,
) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/find
  const categoryToOverride = _.find(categories, { id: categoryId });
  categoryToOverride.groups[groupIndex].sections = addItemInPosition(
    categoryToOverride.groups[groupIndex].sections,
    index,
    {
      sectionName,
      __isRef: true,
      __isExtraItem: true,
      id: `categoryId-group-${groupIndex}-index-${index}`,
    },
  );

  return categories;
};

const addPanel = {
  mapOverrideConfigToCategoriesGroupSchema,
  mapOverrideConfigToCategoriesSchema,
  addExtraSectionByGroup,
  addExtraSection,
};

export { applyOverrides, addPanel };
