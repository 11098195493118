import _ from 'lodash';

/**
 * cacheFunction
 *
 * @param {Function} originalFunction - function we want to be called only once
 * @param {string|Array|Function} dynamicCachePath - function returning path of cached value in cache or the path itself
 * @param {Object} [cache] - pass a storage object if you need a direct access to it
 * @returns {any} on first time - proxied function result, otherwise - cached value
 */
function cacheFunction(
  originalFunction: AnyFixMe,
  dynamicCachePath: AnyFixMe,
  cache: AnyFixMe,
) {
  let staticCachePath: AnyFixMe;

  cache = cache || {};

  if (!_.isFunction(dynamicCachePath)) {
    staticCachePath = dynamicCachePath;
  }

  return function cacheWrapper(this: any) {
    const cachePath =
      staticCachePath || dynamicCachePath.apply(this, arguments);
    let result;

    if (!_.has(cache, cachePath)) {
      result = originalFunction.apply(this, arguments);
      _.set(cache, cachePath, result);
    }

    return result || _.get(cache, cachePath);
  };
}

// TODO: extend to recursive cache
/**
 * createWeakMemo - creates a wrapper with WeakMap as a cache storage
 * for now supports only cash by one (first) argument
 * If function is called with more then one argument it will be invoked directly without cache
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/WeakMap
 * @param {Function} func - function we want to be called only once
 * @returns {Function} cached function
 */
const createWeakMemo = <F extends Function>(
  func: F,
  map?: WeakMap<any, any>,
): F => {
  const cache = map || new WeakMap();

  return ((...args: AnyFixMe[]) => {
    if (args.length === 0) {
      const cached = cache.get(func);

      if (cached) {
        return cached;
      }

      const result = func();
      cache.set(func, result);

      return result;
    }

    if (args.length > 1) {
      return func(...args);
    }

    const cached = cache.get(args[0]);

    if (cached) {
      return cached;
    }

    const result = func(...args);

    cache.set(args[0], result);

    return result;
  }) as any as F;
};

/**
 * memoizeOnce - creates a function wrapper that holds only latest computed value for unique arguments
 *
 * @param func - function we want to be called only once
 * @returns memoized function
 */
function memoizeOnce<T extends (...args: any[]) => any>(
  func: T,
  { getArgsKey }: { getArgsKey?: (...args: Parameters<T>) => string } = {},
): T {
  type TArgs = Parameters<T>;
  type TResult = ReturnType<T>;

  let prevArgsKey: string;
  let prevArgs: TArgs;
  let prevResult: TResult;
  let hasComputedResult = false;

  const areArgsEqual = (argsA: TArgs, argsB: TArgs) =>
    argsA.length === argsB.length &&
    argsA.every((arg, index) => argsB[index] === arg);

  return ((...args: TArgs): TResult => {
    const argsKey = getArgsKey?.(...args);

    if (
      !hasComputedResult ||
      (getArgsKey ? argsKey !== prevArgsKey : !areArgsEqual(args, prevArgs))
    ) {
      prevResult = func(...args);
      prevArgs = args;
      prevArgsKey = argsKey;
      hasComputedResult = true;
    }

    return prevResult;
  }) as unknown as T;
}

export { cacheFunction, createWeakMemo, memoizeOnce };
