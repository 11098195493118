import { sections } from '#packages/util';

import type { CompRef, CompLayout } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';

interface CompWithLayout {
  ref: CompRef;
  layout: Pick<CompLayout, 'y' | 'height'>;
}

type CompWithLayoutGroup = CompWithLayout[];

interface GroupLayout {
  y: number;
  height: number;
  bottom: number;
}

const sortByY = (compA: CompWithLayout, compB: CompWithLayout) =>
  compA.layout.y > compB.layout.y ? 1 : -1;

const MIN_Y = 0;
const normalizeYPosition = (y: number) => Math.max(MIN_Y, y);

const head = <T extends unknown>(arr: T[]): T => arr[0];
const tail = <T extends unknown>(arr: T[]): T => arr[arr.length - 1];

const getSortedSiblingsWithLayout = (
  editorAPI: EditorAPI,
  containerRef: CompRef,
  component: CompRef,
): CompWithLayout[] => {
  const toCompWithLayout = (comp: CompRef): CompWithLayout => ({
    ref: comp,
    layout: editorAPI.components.layout.get_rect(comp),
  });
  const excludeComp = (comp: CompRef) =>
    !editorAPI.utils.isSameRef(comp, component);

  return editorAPI.components
    .getChildren_DEPRECATED_BAD_PERFORMANCE(containerRef)
    .filter(excludeComp)
    .map(toCompWithLayout)
    .sort(sortByY);
};

const composeComponentsToGroups = (
  components: CompWithLayout[],
): CompWithLayoutGroup[] =>
  components.reduce<CompWithLayoutGroup[]>((groups, currentComponent) => {
    const lastGroup = tail(groups);

    if (!lastGroup) {
      const newGroup = [currentComponent];

      return [newGroup];
    }
    const lastComponent = tail(lastGroup);

    const lastComponentBottom =
      lastComponent.layout.y + lastComponent.layout.height;
    const isOverlappingPreviousComponent =
      currentComponent.layout.y < lastComponentBottom;

    if (isOverlappingPreviousComponent) {
      lastGroup.push(currentComponent);
    } else {
      const newGroup = [currentComponent];
      groups.push(newGroup);
    }

    return groups;
  }, []);

const getGroupLayout = (group: CompWithLayoutGroup): GroupLayout => {
  const first = head(group);
  const last = tail(group);

  const y = first.layout.y;
  const bottom = last.layout.y + last.layout.height;
  const height = bottom - y;

  return {
    y,
    height,
    bottom,
  };
};

export const calculateUpdatedY = (
  components: CompWithLayout[],
  { mouseY, componentY }: { mouseY: number; componentY: number },
) => {
  const findYPosition = () => {
    const groups = composeComponentsToGroups(components);
    const groupsLayout = groups.map(getGroupLayout);
    const createIsInsideGroup = (val: number) => (groupLayout: GroupLayout) =>
      val >= groupLayout.y && val <= groupLayout.bottom;

    const groupLayoutByMouse = groupsLayout.find(createIsInsideGroup(mouseY));
    const groupLayoutByY = groupsLayout.find(createIsInsideGroup(componentY));
    const previousGroupsByMouse = groupsLayout.filter(
      (group) => mouseY > group.bottom,
    );
    const previousGroupByMouse = tail(previousGroupsByMouse);

    if (groupLayoutByMouse) {
      const groupMiddle = groupLayoutByMouse.y + groupLayoutByMouse.height / 2;
      const groupBottom = groupLayoutByMouse.bottom;

      const isHoveringBottomPart = mouseY > groupMiddle;

      if (isHoveringBottomPart) {
        return groupBottom;
      }
    }

    if (previousGroupByMouse && componentY < previousGroupByMouse.y) {
      return previousGroupByMouse.bottom;
    }

    return groupLayoutByY?.bottom || componentY;
  };

  const yPosition = findYPosition();

  return normalizeYPosition(yPosition);
};

const findComponentsBelow = (allComponents: CompWithLayout[], y: number) =>
  allComponents.filter((component) => component.layout.y >= y);

export const rearrangeNewSectionElement = async (
  editorAPI: EditorAPI,
  data: {
    container: CompRef;
    mouseY: number;
    component: CompRef;
    shouldGrowSection?: boolean;
  },
) => {
  const { container, mouseY, component, shouldGrowSection = true } = data;

  const containerLayoutConsideringScroll =
    editorAPI.components.layout.getRelativeToScreenConsideringScroll(container);
  const compLayout = editorAPI.components.layout.get_rect(component);
  const mouseYInContainer = mouseY - containerLayoutConsideringScroll.y;

  const siblings = getSortedSiblingsWithLayout(editorAPI, container, component);

  const newY = calculateUpdatedY(siblings, {
    mouseY: mouseYInContainer,
    componentY: compLayout.y,
  });
  const updatedCompBottom = newY + compLayout.height;

  const componentsBelow = findComponentsBelow(siblings, newY);
  const [componentBelow] = componentsBelow;
  const overlapToComponentBelow = componentBelow
    ? updatedCompBottom - componentBelow.layout.y
    : 0;

  // enlarge container
  if (overlapToComponentBelow > 0) {
    if (shouldGrowSection) {
      editorAPI.components.layout.resizeToAndPush(
        container,
        {
          height:
            containerLayoutConsideringScroll.height + overlapToComponentBelow,
        },
        {
          dontAddToUndoRedoStack: true,
        },
      );
    }
  } else if (updatedCompBottom > containerLayoutConsideringScroll.height) {
    editorAPI.components.layout.resizeToAndPush(
      container,
      {
        height: updatedCompBottom,
      },
      {
        dontAddToUndoRedoStack: true,
      },
    );
  }
  await editorAPI.waitForChangesAppliedAsync();

  if (newY !== compLayout.y) {
    // update position of added element
    editorAPI.components.layout.update(component, { y: newY }, true);
  }

  if (overlapToComponentBelow > 0) {
    sections.shiftComponents(
      editorAPI,
      componentsBelow.map((comp) => comp.ref),
      overlapToComponentBelow,
    );
  }

  await editorAPI.waitForChangesAppliedAsync();
};
