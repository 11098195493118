// @ts-nocheck
import _ from 'lodash';
const { curry } = _;

export default curry((requestAnimationFrame, cancelAnimationFrame, render) => {
  let requestId = null;
  return () => {
    if (!requestId) {
      requestId = requestAnimationFrame(() => {
        requestId = null;
        render();
      });
    }
    return () => {
      cancelAnimationFrame(requestId);
      requestId = null;
    };
  };
});
