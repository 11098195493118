import _ from 'lodash';
import * as utils from '@wix/santa-editor-utils';
import * as util from '#packages/util';
import serviceTopology from '../serviceTopology/serviceTopology';
import type { TranslationService } from '../translation/types';
import translate from '../translation/translate';

function adjustResponseToLangs(
  obj: Record<string, string>,
): Record<string, string> {
  return _.transform(obj, function (result, val, key) {
    result[key.toLowerCase()] = val;
  });
}

const addTranslations = _.once((): Promise<Record<string, string>> => {
  const localeScriptUrl =
    serviceTopology.scriptsLocationMap['locale-dataset-data'];
  const editorLanguage = utils.editorModel.languageCode;

  return util.http
    .fetch(`${localeScriptUrl}/translations/messages_${editorLanguage}.json`)
    .then((response) => response.json())
    .then(adjustResponseToLangs)
    .then((localeDateset) => {
      (translate as TranslationService).addLangs(localeDateset);
      return localeDateset;
    });
});

export { addTranslations };
