import {
  convertFontSizeToMobile,
  getSourceFontSizeFromMobileFontSize,
} from '../mobileUtils';
import type { FontThemeObject, FontId } from 'types/documentServices';
import coreUtilsLib from 'coreUtilsLib';

type TextDirection = 'ltr' | 'rtl';

enum TextType {
  PARAGRAPH = 'paragraph',
  TITLE = 'title',
}

/**
 * Gets a text and returns the text without &nbsp; in case there are no spaces around it
 * HELLO&nbsp;HELLO -> HELLO HELLO
 * HELLO &nbsp;HELLO -> no change
 * HELLO&nbsp; HELLO -> no change
 * HELLO &nbsp; HELLO -> no change
 * (For all cases, see cover tests) for this function
 * @param text - string text
 */
function replaceNbspBetweenTextsWithSpace(text: string): string {
  return text.replace(/([^ >])(&nbsp;)([^ <])/g, '$1 $3');
}

const fontSizeStringRegex = /[0-9]+px/;

const headingRegex = /H[1-6]/;

function getFontSizeFromFontString(textStringValue: string): number {
  const fontValueReg = textStringValue?.match(fontSizeStringRegex);
  const fontSizeValue = fontValueReg && fontValueReg[0];
  if (typeof fontSizeValue !== 'string') {
    return null;
  }
  return Number(fontSizeValue.replace('px', ''));
}

function handleMaxLengthKeyDown(ev: AnyFixMe) {
  const allowedKeys = [
    'Backspace',
    'Delete',
    'ArrowRight',
    'ArrowLeft',
    'Home',
  ];
  const { key } = ev;
  if (/[e,\+,\-,\.]/.test(key) && !allowedKeys.includes(key)) {
    ev.preventDefault();
  }
}

function getTextThemeFontSize(
  themeFonts: FontThemeObject,
  element: HTMLElement,
): number | null {
  const textFontKey = Object.keys(themeFonts).find((themeFontKey) =>
    element.classList.contains(themeFontKey),
  ) as FontId;
  if (!textFontKey) {
    return null;
  }
  return getFontSizeFromFontString(themeFonts[textFontKey]);
}

function getFontSizeFromElement(
  themeFonts: FontThemeObject,
  element: HTMLElement,
): number | null {
  if (element.style.fontSize) {
    return Number(element.style.fontSize.replace('px', ''));
  }
  return getTextThemeFontSize(themeFonts, element);
}

function adjustDomElementsToCKMobileEditing(
  themeFonts: FontThemeObject,
  elements: HTMLCollectionOf<HTMLElement>,
  textScale: number,
): void {
  Array.from(elements).forEach((element) => {
    const fontSize = getFontSizeFromElement(themeFonts, element);
    if (fontSize) {
      element.style.fontSize = `${convertFontSizeToMobile(
        fontSize,
        textScale,
      )}px`;
    }
    if (element.children.length > 0) {
      adjustDomElementsToCKMobileEditing(
        themeFonts,
        element.children as HTMLCollectionOf<HTMLElement>,
        textScale,
      );
    }
  });
}
function adjustTextToCKMobileEditing(
  themeFonts: FontThemeObject,
  text: string,
  textScale: number,
): string {
  const container = document.createElement('div');
  container.innerHTML = text;
  adjustDomElementsToCKMobileEditing(
    themeFonts,
    container.children as HTMLCollectionOf<HTMLElement>,
    textScale,
  );
  return container.innerHTML;
}

function adjustDomElementsAddedTextToMobileEditing(
  themeFonts: FontThemeObject,
  elements: HTMLCollectionOf<HTMLElement>,
  textScale: number,
): void {
  Array.from(elements).forEach((element) => {
    if (element.children.length === 0 && headingRegex.test(element.tagName)) {
      const fontSize = getFontSizeFromElement(themeFonts, element);
      if (fontSize) {
        const fontSizeToUpdate = convertFontSizeToMobile(fontSize, textScale);
        element.style.fontSize = `${fontSizeToUpdate}px`;
        element.innerHTML = `<span style="font-size: ${fontSizeToUpdate}px;">${element.innerHTML}</span>`;
        return;
      }
    }
  });
}
function adjustMobileAddedTextToMobileEditing(
  themeFonts: FontThemeObject,
  text: string,
  textScale: number,
): string {
  const container = document.createElement('div');
  container.innerHTML = text;
  adjustDomElementsAddedTextToMobileEditing(
    themeFonts,
    container.children as HTMLCollectionOf<HTMLElement>,
    textScale,
  );
  return container.innerHTML;
}

function adjustDomElementsFromCKMobileToTextData(
  themeFonts: FontThemeObject,
  elements: HTMLCollectionOf<HTMLElement>,
  textScale: number,
): void {
  Array.from(elements).forEach((element) => {
    const elementFontSize = getFontSizeFromElement(themeFonts, element);
    if (elementFontSize) {
      const fontSizeToUpdate = getSourceFontSizeFromMobileFontSize(
        getFontSizeFromFontString(element.style.fontSize),
        textScale,
      );
      element.style.fontSize = `${fontSizeToUpdate}px`;
    }
    if (element.children.length > 0) {
      adjustDomElementsFromCKMobileToTextData(
        themeFonts,
        element.children as HTMLCollectionOf<HTMLElement>,
        textScale,
      );
    }
  });
}

function getTextDataFromCKMobileEditing(
  themeFonts: FontThemeObject,
  text: string,
  textScale: number,
): string {
  const container = document.createElement('div');
  container.innerHTML = text;
  adjustDomElementsFromCKMobileToTextData(
    themeFonts,
    container.children as HTMLCollectionOf<HTMLElement>,
    textScale,
  );
  return container.innerHTML;
}

function decreaseTextFontSizeByRenderedTextRecursively(
  originElements: HTMLCollectionOf<HTMLElement>,
  themeFonts: FontThemeObject,
  elements: HTMLCollectionOf<HTMLElement>,
): void {
  Array.from(originElements).forEach((originElement, index) => {
    const element = elements[index];
    if (originElement.style.fontSize) {
      const originFontSize = getFontSizeFromFontString(
        originElement.style.fontSize,
      );
      const fontSizeToUpdate = getSourceFontSizeFromMobileFontSize(
        originFontSize,
        1,
      );
      element.style.fontSize = `${fontSizeToUpdate}px`;
    }
    if (originElement.children.length > 0) {
      decreaseTextFontSizeByRenderedTextRecursively(
        originElement.children as HTMLCollectionOf<HTMLElement>,
        themeFonts,
        element.children as HTMLCollectionOf<HTMLElement>,
      );
    }
  });
}

function decreaseTextFontSizeByRenderedText(
  originTextComp: HTMLDivElement,
  themeFonts: FontThemeObject,
  text: string,
): string {
  const container = document.createElement('div');
  container.innerHTML = text;
  decreaseTextFontSizeByRenderedTextRecursively(
    originTextComp.children as HTMLCollectionOf<HTMLElement>,
    themeFonts,
    container.children as HTMLCollectionOf<HTMLElement>,
  );
  return container.innerHTML;
}

function decreaseElementFontSizeByScaleRecursively(
  themeFonts: FontThemeObject,
  elements: HTMLCollectionOf<HTMLElement>,
  textScale: number,
  fontSizeToUpdateFromParent?: number,
): void {
  Array.from(elements).forEach((element) => {
    const textFontSize =
      getFontSizeFromFontString(element.style.fontSize) ||
      getTextThemeFontSize(themeFonts, element);
    let fontSizeToUpdate = fontSizeToUpdateFromParent;
    if (textFontSize || fontSizeToUpdateFromParent) {
      const mobileFontSize = convertFontSizeToMobile(textFontSize, textScale);
      fontSizeToUpdate = getSourceFontSizeFromMobileFontSize(mobileFontSize, 1);
      if (fontSizeToUpdateFromParent && !element.style.fontSize) {
        element.style.fontSize = `${fontSizeToUpdateFromParent}px`;
        fontSizeToUpdate = null;
      } else if (element.children.length === 0) {
        element.style.fontSize = `${fontSizeToUpdate}px`;
        fontSizeToUpdate = null;
      }
    }
    if (element.children.length > 0) {
      decreaseElementFontSizeByScaleRecursively(
        themeFonts,
        element.children as HTMLCollectionOf<HTMLElement>,
        textScale,
        fontSizeToUpdate,
      );
    }
  });
}

function decreaseTextFontSizeByScale(
  themeFonts: FontThemeObject,
  text: string,
  textScale: number,
): string {
  const container = document.createElement('div');
  container.innerHTML = text;
  decreaseElementFontSizeByScaleRecursively(
    themeFonts,
    container.children as HTMLCollectionOf<HTMLElement>,
    textScale,
  );
  return container.innerHTML;
}

const copyTextToClipBoard = (text: AnyFixMe) => {
  const node = window.document.createElement('textarea');
  node.style.position = 'absolute';
  node.style.left = '-10000px';
  node.textContent = text;
  window.document.body.appendChild(node);
  node.select();
  window.document.execCommand('copy');
  window.document.body.removeChild(node);
};

const getInnerText = (text: string): string => {
  const e = window.document.createElement('div');
  e.innerHTML = text;
  return e.childNodes.length === 0
    ? ''
    : (e.childNodes[0] as HTMLDivElement).innerText;
};

const getTextDirection = (text: string): TextDirection => {
  const textDirectionRegex = new RegExp(/dir="(rtl|ltr)"/);
  const direction = text.match(textDirectionRegex) as TextDirection[];
  return direction ? direction[1] : 'ltr';
};

const getRootTag = (html: string): TextType => {
  let rootTag: string;

  coreUtilsLib.htmlParser(html, {
    start(tagName: string) {
      rootTag = rootTag ?? tagName[0]; // h1...h6 ->h
    },
  });

  switch (rootTag) {
    case 'p':
      return TextType.PARAGRAPH;
    case 'h':
      return TextType.TITLE;
    default:
      return TextType.PARAGRAPH;
  }
};

export {
  replaceNbspBetweenTextsWithSpace,
  handleMaxLengthKeyDown,
  copyTextToClipBoard,
  getFontSizeFromFontString,
  adjustTextToCKMobileEditing,
  adjustMobileAddedTextToMobileEditing,
  decreaseTextFontSizeByScale,
  decreaseTextFontSizeByRenderedText,
  getTextDataFromCKMobileEditing,
  getInnerText,
  getTextDirection,
  getRootTag,
};
