// @ts-nocheck
import constants from '#packages/constants';
import * as uiUtils from './uiUtils';

import type { CompLayout } from 'types/documentServices';

const { RECOMMENDED_MOBILE_HEADER_HEIGHT } = constants.ROOT_COMPS.MOBILE_EDITOR;
const LINE_LENGTH = constants.ROOT_COMPS.MOBILE_EDITOR.SEPARATOR_LINE_LENGTH;
const { MOBILE_FRAME_WIDTH } = constants.ROOT_COMPS.MOBILE_EDITOR;

function buildRecommendedHeaderLines(
  stageLayout: CompLayout,
  scrollWidth: number,
) {
  const lines = [];
  const marginFromScaledLayout =
    (stageLayout.width - scrollWidth - MOBILE_FRAME_WIDTH) / 2;
  lines.push({
    x1: marginFromScaledLayout - LINE_LENGTH,
    x2: marginFromScaledLayout,
    y: RECOMMENDED_MOBILE_HEADER_HEIGHT,
  });
  lines.push({
    x1: marginFromScaledLayout + MOBILE_FRAME_WIDTH,
    x2: marginFromScaledLayout + MOBILE_FRAME_WIDTH + LINE_LENGTH,
    y: RECOMMENDED_MOBILE_HEADER_HEIGHT,
  });

  return lines;
}

function isMobileHeader(selectedComp) {
  return selectedComp.id === 'SITE_HEADER' && selectedComp.type === 'MOBILE';
}

function getSnapCandidate(getEditingAreaLayout, compRef: CompRef) {
  const lines = buildRecommendedHeaderLines(
    getEditingAreaLayout(),
    uiUtils.getScrollbarWidth(),
  );
  return {
    id: 'recommendedMobileHeaderSize',
    layout: {
      x: lines[0].x1,
      y: lines[0].y,
      height: 0,
      width: lines[1].x2 - lines[0].x1,
    },
    isDynamicLayout: false,
    comp: compRef,
  };
}

export { buildRecommendedHeaderLines, isMobileHeader, getSnapCandidate };
