import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

export function getSectionsBoundaries(
  editorAPI: EditorAPI,
  {
    pageRef,
    useRelativeToStructureLayout,
  }: { pageRef: CompRef; useRelativeToStructureLayout?: boolean },
) {
  const sectionsInStageOrder =
    editorAPI.sections.getPageSectionsWithLayoutSortedByStageOrder(
      pageRef,
      useRelativeToStructureLayout,
    );

  const firstSection = sectionsInStageOrder[0];
  const lastSection = sectionsInStageOrder[sectionsInStageOrder.length - 1];

  const sectionsTopY = firstSection.layout.y;
  const sectionsBottomY = lastSection.layout.y + lastSection.layout.height;

  return {
    topY: sectionsTopY,
    bottomY: sectionsBottomY,
  };
}
