export default {
  add_widget: {
    evid: 848,
    endpoint: 'app-studio',
    fields: {
      widget_name: 'string',
      widget_id: 'string',
      origin: 'string',
    },
  },
  duplicate_widget: {
    evid: 846,
    endpoint: 'app-studio',
    fields: {
      widget_name: 'string',
      widget_id: 'string',
      origin: 'string',
      origin_id: 'string',
    },
  },
  rename_widget: {
    evid: 845,
    endpoint: 'app-studio',
    fields: {
      widget_name: 'string',
      widget_id: 'string',
    },
  },
  delete_widget: {
    evid: 844,
    endpoint: 'app-studio',
    fields: {
      widget_name: 'string',
      widget_id: 'string',
      origin: 'string',
    },
  },
  add_property_click: {
    evid: 854,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
    },
  },
  edit_property_click: {
    evid: 855,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
    },
  },
  add_property: {
    evid: 843,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
    },
  },
  update_property: {
    evid: 850,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
    },
  },
  delete_property: {
    evid: 849,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
      property_name: 'string',
    },
  },
  add_event: {
    evid: 856,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
    },
  },
  delete_event: {
    evid: 857,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
    },
  },
  edit_event: {
    evid: 858,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
    },
  },
  add_event_click: {
    evid: 859,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
    },
  },
  edit_event_click: {
    evid: 860,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
    },
  },
  add_function_click: {
    evid: 864,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
    },
  },
  custom_type_action_click: {
    evid: 1109,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
      target: 'string',
      panel_name: 'string',
    },
  },
  custom_type_values_changed: {
    evid: 1110,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
      panel_name: 'string',
    },
  },
  add_property_to_custom_type_click: {
    evid: 1111,
    endpoint: 'app-studio',
    fields: {
      panel_name: 'string',
    },
  },
  add_property_to_custom_type: {
    evid: 1112,
    endpoint: 'app-studio',
    fields: {
      panel_name: 'string',
      propertyType: 'string',
    },
  },
  create_new_app_clicked: {
    evid: 865,
    endpoint: 'app-studio',
    fields: {},
  },
  edit_imported_app_click: {
    evid: 867,
    endpoint: 'app-studio',
    fields: {
      app_id: 'string',
      appName: 'string',
    },
  },
  add_variation: {
    evid: 923,
    endpoint: 'app-studio',
    fields: {
      widget_id: 'string',
    },
  },
  build: {
    evid: 924,
    endpoint: 'app-studio',
    fields: {
      value: 'string',
    },
  },
} as const;
