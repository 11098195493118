import { BasePublicApi } from '#packages/apilib';
import { DocumentServicesApiKey } from '#packages/apis';
import type { DocumentServicesObject } from 'types/documentServices';
import type { Scope } from './scope';

function setDs(scope: Scope, fullDsObject: DocumentServicesObject) {
  scope.contributeDeferred(DocumentServicesApiKey, () => fullDsObject);
}

export class InitDocumentServicesPublicApi extends BasePublicApi<Scope> {
  setDs = this.bindScope(setDs);
}
