import type { ColorPickerTypes } from '#packages/panels';
import type { PanelMode } from '@wix/color-picker/dist/types/panels/ColorPickerPanel/types';
import type { ColorId, CompRef } from '@wix/document-services-types';
import type {
  GradientLinear,
  GradientCircle,
  GradientEllipse,
  GradientConic,
  GradientMesh,
} from '@wix/css-gradient-generator';
import type { BackgroundData } from '#packages/constants';
import type { ColorPalette } from '#packages/theme';
import type { MediaTypes } from '@wix/video-maker-opener';
import type { BiEventDefinition, BiEventFields } from 'types/bi';

export type ColorOrGradient =
  | string
  | GradientLinear
  | GradientCircle
  | GradientEllipse
  | GradientConic
  | GradientMesh;

// TODO: use from document-services-types
export enum ColorLayerType {
  GradientLinearLayer = 'GradientLinearLayer',
  GradientCircleLayer = 'GradientCircleLayer',
  GradientEllipseLayer = 'GradientEllipseLayer',
  GradientConicLayer = 'GradientConicLayer',
  GradientMeshLayer = 'GradientMeshLayer',
  SolidColorLayer = 'SolidColorLayer',
}

// TODO: use from document-services-types
export interface ColorLayerData {
  type: ColorLayerType;
  opacity?: number;
  fill:
    | GradientLinear
    | GradientCircle
    | GradientEllipse
    | GradientConic
    | GradientMesh
    | { type: 'SolidColor'; color: string };
}
export interface ColorValue {
  color: string;
  alpha: number;
}

export interface MediaInfo {
  path?: string;
}

export interface BackgroundMediaPanelOwnProps {
  selectedComponent: CompRef[];
  getBg: FunctionFixMe;
  allowGradients?: boolean;
  allowDividers?: boolean;
}

export interface BackgroundMediaProps {
  isPopup: boolean;
  hasDividersDesign: boolean;
  bgData: BackgroundData;
  compData: AnyFixMe;
  compDesign: AnyFixMe;
  compBehaviors: AnyFixMe;
  pathFromUserPreferences: AnyFixMe;
  colorsMap: ColorPalette;
  colorResolver: (color: ColorId | string) => string;
  openColorPicker: (
    onChange: (
      value: ColorPickerTypes.ColorPickerValue,
      options?: { isHover?: boolean },
    ) => void,
    value: ColorOrGradient,
    allowPaletteEditing: boolean,
    position: { top: number; left: number },
    previewOnHover: boolean,
    enableHistory: boolean,
    onClose: () => void,
    origin: string,
    panelMode: PanelMode,
  ) => void;
  openMediaManager: (
    categor: string,
    callback: (payload: any[], info: any) => void,
  ) => void;
  updateContainerWithDividersDesign: (
    newData: BackgroundData,
    dontAddToUndoRedoStack: boolean,
  ) => void;
}

export interface BackgroundMediaDispatchProps {
  setPathToUserPref: (mediaType: MediaTypes, info: MediaInfo) => void;
  sendBi: (event: BiEventDefinition, parameters: BiEventFields) => void;
}
