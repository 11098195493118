const initialCode = `// For Wix Code documentation visit http://wix.to/94BuAAs
// To learn about widget code visit https://www.wix.com/app-builder/home/resources

$w.onReady(function () {
	// Initialize your widget here. If your widget has properties, this is a good place to read their values and initialize the widget accordingly.
\t
});

$widget.onPropsChanged((oldProps, newProps) => {
	// If your widget has properties, onPropsChanged is where you should handle changes to their values.
	// Property values can change at runtime by code written on the site, or when you preview your widget here in the App Builder.
\t
});
`;

export default {
  PACKAGE_ERRORS: {
    PARSE_CODE_ERROR: 'PARSE_CODE',
    BUILD_PACKAGE: 'BUILD_PACKAGE',
    PUBLISH: 'PUBLISH',
    COLLECTIONS: 'COLLECTIONS',
    SAVE: 'SAVE',
    SAVE_TO_VFS: 'SAVE_WIDGET_API_TO_VFS',
    PREBUILD: 'PREBUILD',
  },
  NAMESPACE_ERRORS: {
    VALIDATION: 400,
    NO_APP_DEF_ID: 404,
    BLACK_LIST: 403,
    CONFLICT: 409,
  },
  PACKAGE_STEPS: {
    ERROR: 'ERROR',
    FIRST_PUBLISH: 'FIRST_PUBLISH',
    FIRST_PUBLISH_RESULT: 'FIRST_PUBLISH_RESULT',
    PACKAGE_TASKS: 'PACKAGE_TASKS',
    PUBLISH: 'PUBLISH',
  },
  PACKAGE: {
    DURATION_TO_SHOW_RESULT: 1000,
    DURATION_TO_SHOW_FIRST_RESULT: 500,
  },
  BUILD_VERSION_TYPES: {
    MINOR: 'minor',
    MAJOR: 'major',
    BUILD: 'build',
  },
  SETTINGS_PANEL: {
    NAME: 'appStudio.panels.appSettingsPanel',
    OPTIONS: {
      APP_LINK: 'APP_LINK',
      NAMESPACE: 'NAMESPACE',
    },
  },
  INTERNALS_PANEL: {
    NAME: 'appStudio.panels.appInternalsPanel',
    KEY: 'INTERNALS',
    TITLE: 'TOPBAR_Internal_Label',
    ITEMS: [
      {
        onClick: {
          tab: 'authorization',
        },
        params: {
          key: 'PERMISSIONS',
          leftTitle: 'TOPBAR_Settings_Permissions_Label',
          rightTitle: 'TOPBAR_Settings_Permissions_Promotion_Title',
          description: 'TOPBAR_Settings_Permissions_Promotion_Text',
          symbolName: 'menubarRolesAndPermissions',
        },
      },
      {
        onClick: {
          tab: 'membership',
        },
        params: {
          key: 'TEAM_MEMBERS',
          leftTitle: 'TOPBAR_Internal_Add_Team_Members_Title',
          rightTitle: 'TOPBAR_Internal_Add_Team_Members_Title',
          description: 'TOPBAR_Internal_Add_Team_Members_Text',
          symbolName: 'menubarRolesAndPermissions',
        },
      },
      {
        onClick: {
          tab: 'revisions',
        },
        params: {
          key: 'SITE_REVISIONS',
          leftTitle: 'TOPBAR_Site_Site_Revisions',
          rightTitle: 'TOPBAR_SITE_HISTORY_PROMOTION_TITLE',
          description: 'TOPBAR_SITE_HISTORY_PROMOTION_TEXT',
          symbolName: 'menubarSiteHistoryPromotion_v2',
        },
      },
      {
        onClick: {
          panel: 'appStudio.panels.devCenterSettingsPanel',
          action: 'devCenterSettings',
        },
        experiment: 'se_devCenterSettingsInternals',
        params: {
          key: 'DEV_CENTER_SETTINGS',
          leftTitle: 'Dev Center Settings',
          rightTitle: 'Dev Center Settings Data',
          description:
            'Get all the data you need to configure your widget in the dev center',
          symbolName: 'menubarKeyboardShortcuts',
        },
      },
    ],
    DEV_CENTER_OPTIONS: {
      ROBOB: { value: 'ROBOB', label: 'Update my app' },
      BASE_URLS: { value: 'BASE_URLS', label: 'Base urls' },
      WIDGET_DATA: { value: 'WIDGET_DATA', label: 'Widget data' },
    },
  },
  CODE: {
    INITIAL_CONTENT: initialCode,
    EXPORTED_FUNCTION:
      '\n/**\n * @function\n * @description Function description\n * @param {string} myParameter - Parameter description\n * @returns {boolean} Return value description\n * See http://wix.to/VaEzgUn for more information on widget API functions\n */\nexport function myPublicFunction(myParameter){\n\t// Write your public API function code here\n}',
  },
  DEFINITIONS: {
    $widget: `
    /**
 * The '$widget' namespace contains everything you need in order to work with your site's components.
 */
declare namespace $widget {
    /**
	 * Adds an event handler that runs when the widget's properties change
     * [Read more](https://support.wix.com/en/article/app-builder-api-reference)
	 */
	 function onPropsChanged(handler: (oldProps: WidgetProps, newProps: WidgetProps) => void);

    /**
     * Fires an event that you defined in the widget API
     * [Read more](https://support.wix.com/en/article/app-builder-api-reference)
     */
    function fireEvent(eventName: string, data?: Object): void;

    /**
     * Widget defined properties
     * 	[Read more](https://support.wix.com/en/article/app-builder-api-reference)
     */
    const props: WidgetProps;
}
    `,
    definitions: {
      '!name': 'WIDGET API',
      '!define': {
        dateTime: {
          '!proto': 'string',
        },
        image: {
          '!proto': 'string',
        },
        url: {
          '!proto': 'string',
        },
        widget: {
          props: 'props',
          fireEvent: {
            '!type': 'fn(eventName: string, data: object)',
            '!doc': 'Fires an event that you defined in the widget API',
            '!url':
              'https://support.wix.com/en/article/app-builder-api-reference',
          },
          onPropsChanged: {
            '!type': 'fn(handler: fn(oldProps: props, newProps: props))',
            '!doc':
              "Adds an event handler that runs when the widget's properties change",
            '!url':
              'https://support.wix.com/en/article/app-builder-api-reference',
          },
        },
      },
      $widget: 'widget',
    },
  },
  PROP_TYPES: {
    TEXT: 'text',
    NUMBER: 'number',
    BOOLEAN: 'boolean',
    URL: 'url',
    IMAGE: 'image',
    DATE_TIME: 'dateTime',
    OBJECT: 'object',
    ARRAY: 'array',
  },
  APP_BUILDER_PLATFORM_APP_ID: '46b2ad43-5720-41d2-8436-2058979cb53f',
};
