export default {
  click_on_sections_panel_open: {
    evid: 309,
    fields: {
      origin: 'string',
    },
  },
  add_section_panel: {
    category_loaded: {
      evid: 1310,
      fields: {
        category: 'string',
        correlation_id: 'string',
        business_type: 'string',
        is_injected_content: 'boolean',
      },
    },
    sections_loaded_phase: {
      evid: 1311,
      fields: {
        category: 'string',
        duration: 'numeric',
        phaseName: 'string',
        correlation_id: 'string',
        business_type: 'string',
        is_injected_content: 'boolean',
      },
    },
    add_section_start: {
      evid: 1314,
      fields: {
        origin: 'string',
        app_list: 'string',
        category: 'string',
        preset_id: 'string',
        adding_method: 'string',
        component_type: 'string',
        editor_working_mode: 'string',
        is_app_installation_required: 'boolean',
        business_type: 'string',
        is_injected_content: 'boolean',
      },
    },
    add_section_start_after_app_install: {
      evid: 1359,
      fields: {
        category: 'string',
        preset_id: 'string',
        component_type: 'string',
        adding_method: 'string',
        origin: 'string',
        app_list: 'string',
      },
    },
    category_scrolled: {
      evid: 1313,
      fields: {
        category: 'string',
        origin: 'string',
        scroll_top: 'string',
        business_type: 'string',
        is_injected_content: 'boolean',
      },
    },
    tooltip_checked: {
      evid: 1352,
      fields: {
        category: 'string',
        origin: 'string',
        duration: 'numeric',
      },
    },
  },
} as const;
