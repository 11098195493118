import _ from 'lodash';

function isPropChanged<P extends Record<string, any>>(
  props: P,
  nextProps: P,
  key: string | string[],
  deep: boolean = false,
): boolean {
  const keys = Array.isArray(key) ? key : [key];
  const isEqual = deep ? _.isEqual : (a: any, b: any): boolean => a === b;

  return keys.some((key) => !isEqual(props[key], nextProps[key]));
}

export default isPropChanged;
