export type WrapOptions = {
  path?: string[];
  wrap: <TOriginalFunction extends Function>(
    originalFunction: TOriginalFunction,
    path: string[],
  ) => TOriginalFunction;
  wrapIgnorePaths?: string[];
};

function wrap<T>(value: T, path: string[], options: WrapOptions): T {
  if (value === null || value === undefined) {
    return value;
  }

  if (
    typeof value === "function" &&
    typeof options.wrap === "function" &&
    !options.wrapIgnorePaths?.includes(path.join("."))
  ) {
    return options.wrap(value, path);
  }

  if (typeof value === "object") {
    const result: Record<string, any> = {};
    Object.entries(value).forEach(
      ([key, v]) => (result[key] = wrap(v, [...path, key as string], options)),
    );

    return result as T;
  }

  return value;
}

export function wrapAPIFunctions<TValue>(
  value: TValue,
  options: WrapOptions,
): TValue {
  return wrap(value, options.path || [], options);
}
