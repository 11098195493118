import { createUI } from "./ui";
import { getUIDebugInfoMap } from "./getUIDebugInfoMap";
import type { ApiPathDebugInfo } from "./type";

const PINNED_STORAGE_NAME = "__apiCallsDebuggerPinnedPaths";
const pinnedStorage = {
  get() {
    try {
      return localStorage.getItem(PINNED_STORAGE_NAME)?.split(",") ?? [];
    } catch (e) {
      return [];
    }
  },
  set(pinnedPaths: string[]) {
    try {
      localStorage.setItem(PINNED_STORAGE_NAME, pinnedPaths.join(","));
    } catch (e) {
      // ignore
    }
  },
};

export function createApiCallsDebugger({ uiRoot }: { uiRoot: HTMLElement }) {
  const debugInfoMap = new Map<string, ApiPathDebugInfo>();
  const pinnedPaths = new Set<string>(pinnedStorage.get());

  let isEnabled = false;
  let ui: ReturnType<typeof createUI> | undefined;
  const runUI = () => {
    ui.update(getUIDebugInfoMap(debugInfoMap, pinnedPaths));
  };

  const initUI = () => {
    ui =
      ui ||
      createUI({
        root: uiRoot,
        onTogglePinned(pathStr) {
          if (pinnedPaths.has(pathStr)) {
            pinnedPaths.delete(pathStr);
          } else {
            pinnedPaths.add(pathStr);
          }
          // make sure the pinned paths are saved across reloads
          pinnedStorage.set(Array.from(pinnedPaths));
        },
      });
  };

  let uiIntervalHandle: ReturnType<typeof setInterval> | undefined;
  const enable = () => {
    if (isEnabled) {
      return;
    }

    initUI();
    runUI();
    uiIntervalHandle = setInterval(runUI, 300);
    isEnabled = true;
  };

  const disable = () => {
    if (!isEnabled) {
      return;
    }

    clearInterval(uiIntervalHandle);
    isEnabled = false;
  };

  const clear = () => {
    debugInfoMap.clear();
  };

  function reportPathCall(path: string[], { duration }: { duration: number }) {
    if (!isEnabled) {
      return;
    }

    const pathStr = path.join(".");
    let debugInfo = debugInfoMap.get(pathStr);

    if (!debugInfo) {
      debugInfo = {
        callsCount: 0,
        callsTotalDuration: 0,
      };
      debugInfoMap.set(pathStr, debugInfo);
    }

    debugInfo.callsCount += 1;
    debugInfo.callsTotalDuration += duration;
    debugInfo.lastCallTime = Date.now();
  }

  return {
    enable,
    disable,
    clear,
    reportPathCall,
  };
}
