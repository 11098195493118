export default {
  RIGHT_CLICK_MENU_OPEN: {
    evid: 130,
    fields: {
      isScrollable: 'bool',
      category: 'string',
      component: 'string',
      component_id: 'string',
    },
  },
  RCM_SCROLL_BUTTON_HOVERED: {
    evid: 1071,
    fields: {
      component: 'string',
      scroll_direction: 'string',
    },
  },
  CUT: {
    evid: 180,
    fields: {
      app_id: 'guid',
      app_site_id: 'guid',
      component_type: 'string',
      instance_id: 'guid',
      origin: 'string',
    },
  },
  COPY: {
    evid: 181,
    fields: {
      app_id: 'guid',
      app_site_id: 'guid',
      component_type: 'string',
      instance_id: 'guid',
      origin: 'string',
    },
  },
  PASTE: {
    evid: 182,
    fields: {
      app_id: 'guid',
      app_site_id: 'guid',
      component_type: 'string',
      instance_id: 'guid',
      origin: 'string',
    },
  },
  APPLY_MODE_FROM_CLIPBOARD: {
    evid: 633,
    fields: {
      app_id: 'guid',
      app_site_id: 'guid',
      component_type: 'string',
      instance_id: 'guid',
      origin: 'string',
    },
  },
  DUPLICATE: {
    evid: 183,
    fields: {
      app_id: 'guid',
      app_site_id: 'guid',
      component_type: 'string',
      instance_id: 'guid',
      origin: 'string',
    },
  },
  COPY_DESIGN: {
    evid: 135,
    fields: {
      component_type: 'string',
      instance_id: 'guid',
      origin: 'string',
    },
  },
  PASTE_DESIGN: {
    evid: 136,
    fields: {
      component_type: 'string',
      instance_id: 'guid',
      origin: 'string',
    },
  },
  COPY_DESIGN_HOVER: {
    evid: 137,
    fields: {
      instance_id: 'guid',
    },
  },
  PASTE_DESIGN_HOVER: {
    evid: 138,
    fields: {
      instance_id: 'guid',
    },
  },
  MENU_WITH_SUB_ITEMS_HOVER: {
    evid: 967,
    fields: {
      category: 'string',
      component: 'string',
      origin: 'string',
    },
  },
  OVERLAPPING_ITEMS_HOVER: {
    evid: 140,
    fields: {
      origin: 'string',
    },
  },
  OVERLAPPING_ITEM_CLICK: {
    evid: 141,
    fields: {
      origin: 'string',
      component: 'string',
    },
  },
  LOCK: {
    evid: 142,
    fields: {
      origin: 'string',
    },
  },
  UNLOCK: {
    evid: 143,
    fields: {
      origin: 'string',
    },
  },
  HIDE: {
    evid: 145,
    fields: {
      origin: 'string',
    },
  },
  SHOW_ON_ALL_PAGES: {
    evid: 146,
    fields: {
      new_status: 'string',
      origin: 'string',
      component_id: 'string',
      component_type: 'string',
    },
  },
  FIXED_POSITION: {
    evid: 147,
    fields: {
      component: 'string',
      new_status: 'string',
      origin: 'string',
    },
  },
  BRING_TO_FRONT: {
    evid: 148,
    fields: {
      origin: 'string',
    },
  },
  SEND_TO_BACK: {
    evid: 149,
    fields: {
      origin: 'string',
    },
  },
  MOVE_FORWARD: {
    evid: 150,
    fields: {
      origin: 'string',
    },
  },
  MOVE_BACKWARDS: {
    evid: 151,
    fields: {
      origin: 'string',
    },
  },
  RIGHT_CLICK_MENU_ITEM_CLICK: {
    evid: 330,
    fields: {
      component_type: 'string',
      field_selected: 'string',
    },
  },
  RIGHT_CLICK_MENU_CLOSE: {
    evid: 331,
    fields: {
      component: 'string',
      origin: 'string',
    },
  },
  RIGHT_CLICK_MENU_FLIP_CLICK: {
    evid: 1060,
    fields: {
      component_type: 'string',
      actionName: 'string',
    },
  },
  RIGHT_CLICK_MENU_RESET_TO_REGULAR_CLICK: {
    evid: 1099,
    fields: {
      component_type: 'string',
    },
  },
} as const;
