import type { BaseEntryScope } from './scope';

const IsPublicApiFactorySymbol = Symbol('isPublicApiFactoryClass');

export abstract class BasePublicApi<SC extends BaseEntryScope> {
  protected scope: SC;
  //@ts-expect-error
  private static [IsPublicApiFactorySymbol] = true;
  constructor(scope: SC) {
    this.scope = scope;
  }
  protected bindScope<A extends any[], R>(
    fn: (scope: SC, ...args: A) => R,
  ): (...args: A) => R {
    return fn.bind(null, this.scope);
  }
}
export interface PublicApiFactory<SC, T> {
  new (sc: SC): T;
}

export type FunctionApiFactory<SC, T> = (sc: SC) => T;

export function callApiFactory<SC, T>(
  _factory: PublicApiFactory<SC, T> | FunctionApiFactory<SC, T>,
  scope: SC,
): T {
  const factory = _factory as any;
  if (factory[IsPublicApiFactorySymbol] === true) {
    return new factory(scope);
  }
  return factory(scope);
}
