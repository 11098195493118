import React from 'react';
import { ErrorReporter } from '@wix/editor-error-reporter';

export interface ErrorBoundaryProps {
  renderError?: (error: unknown) => JSX.Element;
  onError?: (error: unknown, errorInfo: unknown) => void;
  ravenConfig?: {
    tags: Record<string, string | number | boolean>;
  };
}

export interface ErrorBoundaryState {
  error: unknown;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  static getDerivedStateFromError(error: unknown) {
    return {
      error,
    };
  }

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error: unknown, errorInfo: unknown) {
    const { onError, ravenConfig } = this.props;

    if (onError) {
      onError(error, errorInfo);
    }

    if (ravenConfig) {
      ErrorReporter.captureException(error, ravenConfig);
    }
  }

  render() {
    const { renderError, children } = this.props;
    const { error } = this.state;

    if (error) {
      if (renderError) {
        return renderError(error);
      }

      return null;
    }

    return children;
  }
}
