// @ts-nocheck
import ReactDOM from 'react-dom';
import $ from 'zepto';
import _ from 'lodash';
import * as utils from '@wix/santa-editor-utils';

const { elementUtils, math, animationFrameUtils } = utils;
/**
 * A mixin to allow drag of fixed position components
 * Supports drag limits, and dragging a different element than the root element of the class
 */

const requestAnimationFrame = animationFrameUtils.request;
const cancelAnimationFrame = animationFrameUtils.cancel;

function drag() {
  this.setState({
    isCustomPosition: true,
  });

  const { draggable } = this;
  draggable.pendingFrameRequest = 0;
  const { limits } = draggable;

  let left =
    draggable.event.pageX - window.pageXOffset - draggable.relPosition.x;
  left = limits.lockedX
    ? math.ensureWithinLimits(
        left,
        draggable.nodePosition.x,
        draggable.nodePosition.x,
      )
    : math.ensureWithinLimits(
        left,
        limits.x[0],
        limits.x[1] - draggable.nodeSizes.width,
      );
  let top =
    draggable.event.pageY - window.pageYOffset - draggable.relPosition.y;
  top = limits.lockedY
    ? math.ensureWithinLimits(
        top,
        draggable.nodePosition.y,
        draggable.nodePosition.y,
      )
    : math.ensureWithinLimits(
        top,
        limits.y[0],
        limits.y[1] - draggable.nodeSizes.height,
      );

  draggable.nodePosition.x = left;
  draggable.nodePosition.y = top;

  const draggableOffset = {
    left: draggable.nodePosition.x,
    top: draggable.nodePosition.y,
  };

  if (this.whileDrag) {
    this.whileDrag(draggableOffset);
  } else {
    elementUtils.setOffset(
      draggable.element,
      draggableOffset.top,
      draggableOffset.left,
    );
  }
}

function endDrag() {
  $(window.document).off('.draggable');
  const { draggable } = this;
  cancelAnimationFrame(draggable.pendingFrameRequest);
  draggable.pendingFrameRequest = 0;
  $(draggable.element).css({ transition: '' }); //eslint-disable-line @wix/santa/no-css-manipulation
  if (this.isBeingDragged) {
    this.isBeingDragged = false;
    if (this.onDragEnd) {
      this.onDragEnd({
        left: draggable.nodePosition.x,
        top: draggable.nodePosition.y,
      });
    }
  }
  draggable.element = undefined;
}

export default {
  getInitialState() {
    this.isBeingDragged = false;
    this.draggable = {};
    return {
      isCustomPosition: false,
    };
  },
  startDrag(event, limits, dragElement) {
    const { draggable } = this;
    draggable.limits = {
      x: [],
      y: [],
      lockedX: false,
      lockedY: false,
    };
    draggable.element = $(
      (_.isObject(dragElement) && ReactDOM.findDOMNode(dragElement)) ||
        ReactDOM.findDOMNode(this),
    );
    if (typeof limits !== 'string') {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/assign
      _.assign(draggable.limits, limits);
    }
    const offset = draggable.element.offset();
    draggable.nodePosition = {
      y: offset.top - window.pageYOffset,
      x: offset.left - window.pageXOffset,
    };
    draggable.relPosition = {
      x: event.pageX - window.pageXOffset - draggable.nodePosition.x,
      y: event.pageY - window.pageYOffset - draggable.nodePosition.y,
    };
    draggable.nodeSizes = {
      height: draggable.element.height(),
      width: draggable.element.width(),
    };
    draggable.event = {};
    $(window.document).on({
      'mousemove.draggable': this.onDrag,
      'mouseup.draggable': this.endDrag,
    });
    $(draggable.element).css({ transition: 'none' }); //eslint-disable-line @wix/santa/no-css-manipulation
  },
  onDrag(event) {
    if (!this.isBeingDragged) {
      this.isBeingDragged = true;
      if (this.onDragStart) {
        this.onDragStart();
      }
    }
    const { draggable } = this;
    draggable.event.pageX = event.pageX;
    draggable.event.pageY = event.pageY;
    if (!draggable.pendingFrameRequest) {
      draggable.pendingFrameRequest = requestAnimationFrame(drag.bind(this));
    }
    return false;
  },
  endDrag() {
    endDrag.call(this);
  },
  componentWillUnmount() {
    if (this.draggable.element) {
      endDrag.call(this);
    }
  },
};
