export default {
  settings_panel: {
    LOGIN_LAYOUT_CHANGE: {
      src: 69,
      endpoint: 'members-app-users',
      evid: 861,
      fields: {
        layoutType: 'string',
        instance_id: 'guid',
        appId: 'guid',
      },
    },
  },
  layout_panel: {
    BELL_OR_ARROW_ICON_CHANGE: {
      src: 69,
      endpoint: 'members-app-users',
      evid: 862,
      fields: {
        property: 'string',
        value: 'string',
        instance_id: 'guid',
        appId: 'guid',
      },
    },
  },
} as const;
