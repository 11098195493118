export default {
  BTClick: {
    evid: 1408,
    src: 38,
    fields: {
      business_type: 'string',
      origin: 'string',
    },
  },
  panelOpened: {
    evid: 309,
    src: 38,
    fields: {
      business_type: 'string',
      origin: 'string',
      panel_name: 'string',
    },
  },
  panelClosed: {
    evid: 345,
    src: 38,
    fields: {
      business_type: 'string',
      origin: 'string',
      panel_name: 'string',
    },
  },
  openSuggestions: {
    evid: 1409,
    src: 38,
    fields: {
      business_type: 'string',
      origin: 'string',
      panel_name: 'string',
    },
  },
  clickSuggestion: {
    evid: 1410,
    src: 38,
    fields: {
      business_type: 'string',
      origin: 'string',
      panel_name: 'string',
    },
  },
  changeSubmit: {
    evid: 1411,
    src: 38,
    fields: {
      business_type: 'string',
      origin: 'string',
      panel_name: 'string',
      is_changed: 'bool',
    },
  },
} as const;
