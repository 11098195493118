import experiment from 'experiment';
import * as utils from '@wix/santa-editor-utils';

import * as appStudioUtils from '../appStudio/appStudioUtils';

const isNewWorkspaceEnabled = (): boolean =>
  !appStudioUtils.isAppStudio() && !utils.isResponsiveEditor();

const isNewWorkspaceFTETourEnabled = (): boolean =>
  isNewWorkspaceEnabled() &&
  experiment.isOpen('se_newWorkspace_firstTimeExperienceTour');

export { isNewWorkspaceEnabled, isNewWorkspaceFTETourEnabled };
