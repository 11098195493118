import type { ComponentType } from 'react';
import type { ExtensionItem, ExtensionSlot, Shell } from 'repluggable';

export interface ContributedComponent<T> {
  uniqueId: string;
  contribution: React.ComponentType<T>;
  condition?: () => boolean;
}

export class ComponentSlot<T> {
  #extensionSlot: ExtensionSlot<ComponentType<T>>;
  #shell: Shell;
  constructor(extensionSlot: ExtensionSlot<ComponentType<T>>, shell: Shell) {
    this.#shell = shell;
    this.#extensionSlot = extensionSlot;
  }
  contribute = (item: ComponentType<T>, condition?: () => boolean) => {
    this.#extensionSlot.contribute(this.#shell, item, condition);
  };
  getItems = (forceAll = false): ContributedComponent<T>[] => {
    return this.#extensionSlot.getItems(forceAll).map((item) => ({
      uniqueId: item.uniqueId,
      contribution: item.contribution,
      condition: item.condition,
    }));
  };
  discardBy = (
    predicate: (extensionItem: ExtensionItem<ComponentType<T>>) => boolean,
  ) => this.#extensionSlot.discardBy(predicate);
}
