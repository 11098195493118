export default {
  POP_UP_SETTINGS_PAGE_SELECTOR_OK: {
    evid: 560,
    fields: {
      all_pages_selected: 'boolean',
      num_selected_pages: 'numeric',
      num_unselected_pages: 'numeric',
    },
  },
  pop_up_settings_show_on_pages: {
    evid: 1036,
    fields: {
      component_type: 'string',
      component_id: 'string',
      status: 'boolean',
    },
  },
  pop_up_tooltip_appearance_on_delete_x: {
    evid: 561,
    fields: {},
  },
  pop_up_overlay_clicked: {
    evid: 562,
    fields: {},
  },
  pop_up_layout_changed: {
    evid: 565,
    fields: {
      section: 'string',
    },
  },
  pop_up_pin_position_selected: {
    evid: 529,
    fields: {
      selected_area: 'string',
      component_type: 'string',
    },
  },
  exit_pop_up_mode_clicked: {
    evid: 530,
    fields: {},
  },
  setup_overlay_clicked: {
    evid: 532,
    fields: {},
  },
  pop_up_choose_pages_clicked: {
    evid: 528,
    fields: {},
  },
  pop_up_tooltip_settings_click: {
    evid: 567,
    fields: {
      settings_button: 'string',
    },
  },
  pop_up_tooltip_closed: {
    evid: 566,
    fields: {
      origin: 'string',
    },
  },
  exit_lightbox_mode_click: {
    evid: 1144,
    fields: {
      origin: 'string',
    },
  },
} as const;
