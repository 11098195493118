// @ts-nocheck
import serviceTopology from '../../serviceTopology/serviceTopology';
import languages from '../../translation/languages';
import fedopsLogger from './fedopsLogger';
import { ErrorReporter } from '@wix/editor-error-reporter';

const DEALER_PRE_FETCH_TIMEOUT = 2000;
const DEALER_RENDER_SUCCESS_TIMEOUT = 2000;

export default {
  loadDealerAsset(
    wixDealerClientApi,
    bannerPosition,
    ignoreCache,
    metaSiteId,
    metaSiteInstance,
  ): Promise<{ assetExist(): boolean }> {
    const dealerAssetsLoader = new wixDealerClientApi.DealerAssetsLoader();
    fedopsLogger.interactionStarted(
      fedopsLogger.INTERACTIONS.FETCHING_FROM_DEALER,
    );
    return new Promise((resolve, reject) => {
      const clearTimeoutBeforePromiseHandling = (
        asset,
        timeoutID,
        promiseHandler,
      ) => {
        window.clearTimeout(timeoutID);
        promiseHandler(asset);
      };
      const timeoutID = window.setTimeout(() => {
        const errorMessage = `${bannerPosition} pre fetch timeout`;
        reject({ message: errorMessage });
      }, DEALER_PRE_FETCH_TIMEOUT);

      dealerAssetsLoader
        .loadAsset(
          bannerPosition,
          metaSiteId,
          { ignoreCache: !!ignoreCache },
          metaSiteInstance,
        )
        .then((asset) =>
          clearTimeoutBeforePromiseHandling(asset, timeoutID, resolve),
        )
        .catch((asset) =>
          clearTimeoutBeforePromiseHandling(asset, timeoutID, reject),
        );
    }).then((asset) => {
      fedopsLogger.interactionEnded(
        fedopsLogger.INTERACTIONS.FETCHING_FROM_DEALER,
      );
      return asset;
    });
  },
  loadDealerOffers(wixDealerClientApi, bannerPosition, metaSiteInstance) {
    const dealerAssetsLoader = new wixDealerClientApi.DealerAssetsLoader();
    return dealerAssetsLoader.loadOffers(bannerPosition, metaSiteInstance);
  },
  createDealerRenderSuccessTimeout(fallbackToDefaultFunction) {
    const errorType = 'success timeout';
    return window.setTimeout(() => {
      ErrorReporter.captureMessage(
        `dealer after pre fetch ${errorType} error`,
        'warning',
      );
      fallbackToDefaultFunction(errorType);
    }, DEALER_RENDER_SUCCESS_TIMEOUT);
  },
  clearDealerRenderSuccessTimeout(timeoutId) {
    window.clearTimeout(timeoutId);
  },
  getDealerViewerBaseProps() {
    return {
      translationsUrl: `${
        serviceTopology.scriptsLocationMap?.['dealer-cms-translations'] ?? ''
      }/`,
      locale: languages.getLanguageCode(),
    };
  },
  bannerPositions: {
    SITE_SAVED: 'EDITOR_UPGRADE',
    SITE_NOT_SAVED: 'EDITOR_FIRST_SAVE_UPGRADE',
    POST_PUBLISH: '85299d8a-9b9c-4324-a777-335364a06e1b',
    POST_SAVE: '339f5cad-0997-40a7-9f22-1a0d4bddd1bd',
  },
};
