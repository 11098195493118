import { ErrorReporter } from '@wix/editor-error-reporter';

import type {
  EditorInteractionEndedParams,
  EditorInteractionName,
  EditorInteractionStartedParams,
  EditorLoadingPhaseName,
  FedopsGlobalBiParams,
  FedopsInteractionEndedOptions,
  FedopsInteractionParamsOptions,
  FedopsInteractionStartedOptions,
} from 'types/fedops';
import type { EditorLoggers } from 'types/core';
import { INTERACTIONS, SWITCH_LAYOUT } from './interactions';

interface Hook {
  onStart: (
    name: string,
    interactionOptions: FedopsInteractionStartedOptions | undefined,
  ) => FedopsInteractionStartedOptions | void;
  onEnd: (
    name: string,
    interactionOptions: FedopsInteractionStartedOptions | undefined,
  ) => FedopsInteractionStartedOptions | void;
}

class FedopsHooksManager {
  hooks: Hook[] = [];
  addHook(hook: Hook) {
    this.hooks.push(hook);
  }
}

class EditorFedopsLogger {
  private fedopsLogger: EditorLoggers['fedops']['logger'];
  private fedopsLoggerUpdateDefaults: EditorLoggers['fedops']['updateDefaults'];
  private preInitRequests: {
    interactionName: EditorInteractionName;
    interactionStarted: boolean;
  }[] = [];
  hooks = new FedopsHooksManager();
  private interactionTimeoutQue: Record<string, { timeoutId: number }> = {};

  create(fedops: EditorLoggers['fedops']) {
    this.fedopsLogger = fedops.logger;
    this.fedopsLoggerUpdateDefaults = fedops.updateDefaults;

    this.preInitRequests.forEach(({ interactionName, interactionStarted }) => {
      this.reportInteractionToFedops(interactionName, {}, interactionStarted);
    });

    this.preInitRequests = [];
  }

  private reportInteractionToFedops(
    interactionName: EditorInteractionName,
    interactionOptions?:
      | FedopsInteractionStartedOptions
      | FedopsInteractionEndedOptions,
    interactionStarted?: boolean,
  ) {
    if (this.fedopsLogger) {
      if (interactionStarted) {
        this.interactionTimeoutQue[interactionName] =
          this.fedopsLogger.interactionStarted(
            interactionName,
            interactionOptions,
          );
      } else {
        this.fedopsLogger.interactionEnded(interactionName, {
          ...this.interactionTimeoutQue[interactionName],
          ...interactionOptions,
        });
      }
    } else {
      this.preInitRequests.push({ interactionName, interactionStarted });
    }
  }

  interactionStarted = <TCurrentInteraction extends EditorInteractionName>(
    interactionName: TCurrentInteraction | EditorInteractionName[],
    interactionOptions?: FedopsInteractionStartedOptions<
      TCurrentInteraction extends keyof EditorInteractionStartedParams
        ? EditorInteractionStartedParams[TCurrentInteraction]
        : FedopsInteractionParamsOptions
    >,
  ) => {
    const interactions = Array.isArray(interactionName)
      ? interactionName
      : [interactionName];
    interactions.forEach((interaction) => {
      this.hooks.hooks.forEach((hook) => {
        const newInteractionOptions = hook.onStart(
          interaction,
          interactionOptions,
        );
        if (newInteractionOptions) {
          interactionOptions = newInteractionOptions as any;
        }
      });
      this.reportInteractionToFedops(interaction, interactionOptions, true);
    });
  };

  interactionEnded = <TCurrentInteraction extends EditorInteractionName>(
    interactionName: TCurrentInteraction | EditorInteractionName[],
    interactionOptions?: FedopsInteractionEndedOptions<
      TCurrentInteraction extends keyof EditorInteractionEndedParams
        ? EditorInteractionEndedParams[TCurrentInteraction]
        : FedopsInteractionParamsOptions
    >,
  ) => {
    const interactions = Array.isArray(interactionName)
      ? interactionName
      : [interactionName];

    interactions.forEach((interaction) => {
      this.hooks.hooks.forEach((hook) => {
        const newInteractionOptions = hook.onEnd(
          interaction,
          interactionOptions,
        );
        if (newInteractionOptions) {
          interactionOptions = newInteractionOptions as any;
        }
      });
      this.reportInteractionToFedops(interaction, interactionOptions, false);
    });
  };

  appLoaded() {
    this.fedopsLogger?.appLoaded();
  }

  appLoadingPhaseStart = (phase: EditorLoadingPhaseName) => {
    this.fedopsLogger?.appLoadingPhaseStart(phase);
  };

  appLoadingPhaseFinish = (phase: EditorLoadingPhaseName) => {
    this.fedopsLogger?.appLoadingPhaseFinish(phase);
  };

  mapInteraction = (interaction: EditorInteractionName) => {
    return {
      start: () => this.interactionStarted(interaction),
      end: () => this.interactionEnded(interaction),
    };
  };

  /**
   * update fedops default params. Must not get functions with heavy calculations !
   * @param fedopsGlobalBiParams - global params for fedops
   */
  updateBiGlobalParams(fedopsGlobalBiParams: Partial<FedopsGlobalBiParams>) {
    this.fedopsLoggerUpdateDefaults?.(fedopsGlobalBiParams);
  }

  INTERACTIONS = INTERACTIONS;
  SWITCH_LAYOUT = SWITCH_LAYOUT;
}

const editorFedopsLogger = new EditorFedopsLogger();

editorFedopsLogger.hooks.addHook({
  onStart: (interactionName, interactionOptions) => {
    ErrorReporter.addBreadcrumb({
      category: 'interaction start',
      message: `interaction ${interactionName} started`,
      data: interactionOptions,
    });
  },
  onEnd: (interactionName, interactionOptions) => {
    ErrorReporter.addBreadcrumb({
      category: 'interaction end',
      message: `interaction ${interactionName} ended`,
      data: interactionOptions,
    });
  },
});

export default editorFedopsLogger;
