const ignorePaths = new Set([
  'dsActions.bi.event',
  'dsActions.waitForChangesApplied',
  'dsActions.waitForChangesAppliedAsync',
  'dsActions.history.getUndoLastSnapshotParams',
  'dsActions.history.getRedoLastSnapshotParams',
]);

function stringifyArg(argument: unknown) {
  if (argument === undefined) {
    return 'undefined';
  }
  if (argument === null) {
    return 'null';
  }
  return JSON.stringify(argument, null, 2);
}

const dsDebuggerLogs: string[] = [];
(window as any).__dsDebuggerLogs = dsDebuggerLogs;
export function reportPathCallToConsole(
  path: string[],
  args: IArguments,
  argsErrors: unknown,
) {
  if (ignorePaths.has(path.join('.'))) {
    return;
  }

  const message = `called %c${path.join('.')}(${Array.from(
    args,
    stringifyArg,
  ).join(', ')})`;

  if (argsErrors) {
    //eslint-disable-next-line no-console
    console.error(message, 'color: #bada55', argsErrors);
  } else {
    //eslint-disable-next-line no-console
    console.warn(message, 'color: #bada55');
  }

  dsDebuggerLogs.push(message.replace('%c', ''));
}

export function handlePathCallError(error: MaybeError, path: string[]) {
  throw new Error(
    `Error on DS call "${path.join('.')}()": ${error.message}\n${error.stack}`,
    { cause: error },
  );
}
