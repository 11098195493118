const defaultParams = {
  actionName: 'string',
  component_id: 'string',
  component_type: 'string',
  componentName: 'string',
  userEntry: 'string',
};
const logo_src = 88;
const endpoint = 'logo-builder';

export default {
  DESIGN_LOGO_CLICKED: {
    evid: 168,
    endpoint,
    src: logo_src,
    fields: {
      ...defaultParams,
    },
  },
  UPGRADE_BANNER_CLICKED: {
    evid: 168,
    src: logo_src,
    endpoint,
    fields: {
      ...defaultParams,
    },
    brandGuid: 'string',
    logoId: 'string',
  },
  CLAIM_VOUCHER_CLICKED: {
    evid: 168,
    src: logo_src,
    endpoint,
    fields: {
      ...defaultParams,
      brandGuid: 'string',
      logoId: 'string',
    },
  },
  LOGO_BACKGROUND_TOGGLE: {
    evid: 168,
    src: logo_src,
    endpoint,
    fields: {
      ...defaultParams,
      value: 'string',
      brandGuid: 'string',
      logoId: 'string',
    },
  },
  EDIT_LOGO_CLICKED: {
    evid: 168,
    src: logo_src,
    endpoint,
    fields: {
      ...defaultParams,
      brandGuid: 'string',
      logoId: 'string',
    },
  },
  DOWNLOAD_LOGO_CLICKED: {
    evid: 168,
    src: logo_src,
    endpoint,
    fields: {
      ...defaultParams,
      brandGuid: 'string',
      logoId: 'string',
    },
  },
  CHANGE_LOGO_PRESET: {
    evid: 168,
    src: logo_src,
    endpoint,
    fields: {
      ...defaultParams,
      value: 'string',
      brandGuid: 'string',
      logoId: 'string',
    },
  },
  LOGO_ADDED: {
    evid: 169,
    src: logo_src,
    endpoint,
    fields: {
      actionName: 'string',
      userEntry: 'string',
    },
  },
  LOGO_CONGRATS_MODAL: {
    evid: 169,
    src: logo_src,
    endpoint,
    fields: {
      actionName: 'string',
      userEntry: 'string',
      pageName: 'string',
      brandGuid: 'string',
    },
  },
  LOGO_IFRAME_ACTION_TRIGGERED: {
    evid: 204,
    src: logo_src,
    endpoint,
    fields: {
      actionName: 'string',
      userEntry: 'string',
      brandGuid: 'string',
      logoId: 'string',
    },
  },
} as const;
