const DEFAULT_DELAY = 100;
let lastHoveredItemKey: AnyFixMe;
let setTimeoutId: AnyFixMe;

function enter(
  this: unknown,
  event: AnyFixMe,
  hoveredItem: AnyFixMe,
  hoveredItemKey: AnyFixMe,
  callback: AnyFixMe,
  delay = DEFAULT_DELAY,
) {
  lastHoveredItemKey = null;
  window.clearTimeout(setTimeoutId);

  if (!callback) {
    return;
  }

  setTimeoutId = window.setTimeout(
    function (this: unknown) {
      if (!lastHoveredItemKey) {
        if (callback) {
          callback.call(this, hoveredItem, hoveredItemKey);
        }
      }
    }.bind(this),
    delay,
  );
}

function leave(event: AnyFixMe, hoveredItemKey: AnyFixMe) {
  lastHoveredItemKey = hoveredItemKey;
}

export { enter, leave };
