function wrapFunctionsWithPreventOnSilent<
  Fns extends Record<
    string,
    (...args: any[]) => Promise<any> | Promise<void> | any
  >,
>(functionsToPreventExecutionOnSilent: Fns, isSilentInstall: boolean): Fns {
  const clonedFunctions = { ...functionsToPreventExecutionOnSilent };
  for (const functionName of Object.keys(clonedFunctions)) {
    clonedFunctions[functionName as keyof Fns] = ((...args: any) => {
      if (isSilentInstall) {
        return Promise.resolve();
      }
      return functionsToPreventExecutionOnSilent[functionName](...args);
    }) as Fns[keyof Fns];
  }
  return clonedFunctions;
}

export * as addElementsUtils from './addElementsUtils';
export { wrapFunctionsWithPreventOnSilent };
