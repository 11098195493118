// @ts-nocheck
import _ from 'lodash';

const throwFrozenError = () => {
  throw new Error('Object is frozen');
};

const freezedObjects = new WeakSet();

const deepFreeze = (obj) => {
  if (Object.isFrozen(obj)) {
    return obj;
  }

  if (freezedObjects.has(obj)) {
    // to avoid infinite loops
    return obj;
  }

  freezedObjects.add(obj);

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(Object.getOwnPropertyNames(obj), (prop) => {
    if (
      obj.hasOwnProperty(prop) &&
      obj[prop] !== null &&
      typeof obj[prop] === 'object'
    ) {
      _.set(obj, prop, deepFreeze(obj[prop]));
    }
  });

  Object.freeze(obj);

  const defineProperty = (target, key, descriptor) => {
    const { configurable, writable } = descriptor;

    if (configurable || writable) {
      throwFrozenError();
    } else {
      return Object.defineProperty(target, key, descriptor);
    }
  };

  return new Proxy(obj, {
    set: throwFrozenError,
    deleteProperty: throwFrozenError,
    setPrototypeOf: throwFrozenError,
    defineProperty,
  });
};

export default deepFreeze;
