export const TYPE_OF_CUSTOM_PERMISSIONS = 'CUSTOM';
export const TYPE_OF_PRIVATE_DATA_PERMISSIONS = 'PRIVATE_DATA';

export const translationKeyByTypeOfPermissions = {
  CONTENT_FOR_YOUR_SITE:
    'SiteApp_WixData_DataManager_Tab_Permissions_SiteContent',
  FORM_INPUTS: 'SiteApp_WixData_DataManager_Tab_Permissions_FormSubmit',
  USER_GENERATED_CONTENT:
    'SiteApp_WixData_DataManager_Tab_Permissions_MemberContent',
  CONTENT_FOR_SITE_MEMBERS:
    'SiteApp_WixData_DataManager_Tab_Permissions_MembersOnlyContent',
  FORM_INPUTS_FOR_SITE_MEMBERS:
    'SiteApp_WixData_DataManager_Tab_Permissions_MembersFormSubmit',
  [TYPE_OF_PRIVATE_DATA_PERMISSIONS]:
    'SiteApp_WixData_DataManager_Tab_Permissions_Private',
  [TYPE_OF_CUSTOM_PERMISSIONS]:
    'SiteApp_WixData_DataManager_Tab_Permissions_Custom',
} as const;

export const permissionsByType = {
  CONTENT_FOR_YOUR_SITE: {
    read: 'anyone',
    insert: 'admin',
    update: 'admin',
    remove: 'admin',
  },

  FORM_INPUTS: {
    insert: 'anyone',
    read: 'admin',
    update: 'admin',
    remove: 'admin',
  },

  USER_GENERATED_CONTENT: {
    insert: 'siteMember',
    read: 'anyone',
    update: 'siteMemberAuthor',
    remove: 'siteMemberAuthor',
  },

  CONTENT_FOR_SITE_MEMBERS: {
    insert: 'admin',
    read: 'siteMember',
    update: 'admin',
    remove: 'admin',
  },

  FORM_INPUTS_FOR_SITE_MEMBERS: {
    insert: 'siteMember',
    read: 'admin',
    update: 'admin',
    remove: 'admin',
  },

  [TYPE_OF_PRIVATE_DATA_PERMISSIONS]: {
    read: 'admin',
    insert: 'admin',
    update: 'admin',
    remove: 'admin',
  },
};

export const OLD_FORMAT_KEYS = [
  'anonymous',
  'siteMember',
  'dataOwner',
  'siteOwner',
  'meta',
  'initialData',
];
