export default {
  panel_background_changed: {
    evid: 375,
    fields: {
      origin: 'string',
      page_id: 'string',
      bg_media_type: 'string',
      bg_mobile_detached: 'string',
      bg_media_name: 'string',
      bg_page_id: 'string',
      bg_preset_index: 'number',
      bg_vendor_name: 'string',
      bg_vendor_media_id: 'string',
      bg_color: 'string',
      bg_fitting: 'string',
      bg_positioning: 'string',
      bg_opacity: 'number',
      bg_scroll_with_site: 'string',
      bg_overlay_color: 'string',
      bg_overlay_color_opacity: 'string',
      bg_video_overlay_pattern: 'string',
      bg_video_loop: 'string',
      bg_slideshow_image_fitting: 'string',
      bg_slideshow_transition_type: 'string',
      bg_slideshow_transition_duration: 'number',
      bg_slideshow_transition_direction: 'string',
      bg_slideshow_pausebetweenimages: 'number',
      is_ai_generator: 'boolean',
    },
  },
  panel_apply_to_other_pages_click: {
    evid: 376,
    fields: {},
  },
  panel_apply_to_other_pages_ok: {
    evid: 377,
    fields: {
      all_pages_selected: 'string',
      num_unselected_pages: 'number',
      num_selected_pages: 'string',
    },
  },
  mobile_video_background_toggle: {
    evid: 870,
    fields: {
      pageId: 'string',
      toggle: 'boolean',
    },
  },
  bg_image_settings_click: {
    evid: 1015,
    fields: {
      component_type: 'string',
      component_id: 'string',
      panel_name: 'string',
    },
  },
  bg_image_settings_back_click: {
    evid: 1016,
    fields: {
      component_type: 'string',
      component_id: 'string',
      panel_name: 'string',
    },
  },
  customize_buttons_click: {
    evid: 1184,
    fields: {
      page_id: 'string',
      button_name: 'string',
    },
  },
  bg_apply_to_other_toggle: {
    evid: 1186,
    fields: {
      target_page_id: 'string',
      action: 'string',
      pageId: 'string',
      category: 'string',
    },
  },
} as const;
