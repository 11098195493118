import React from 'react';
import PropTypes from 'prop-types';

type Subtract<T, K> = Omit<T, keyof K>;

export interface WithReportUIChange {
  reportUIChange: (changeEvent: unknown, controlName?: string) => void;
}

interface ReportUIChangeProps {
  instanceId?: string;
  label?: string;
}

const getDisplayName = (WrappedComponent: React.ComponentType<any>) =>
  WrappedComponent.displayName || WrappedComponent.name || 'Component';

const withReportUIChange = <P extends ReportUIChangeProps & WithReportUIChange>(
  Component: React.ComponentType<P>,
): React.ComponentType<Subtract<P, WithReportUIChange>> => {
  class ReportUIChange extends React.Component<
    Subtract<P, WithReportUIChange>
  > {
    static displayName = `withReportUIChange${getDisplayName(Component)}`;

    static propTypes = {
      instanceId: PropTypes.string,
      label: PropTypes.string,
    } as any;

    static contextTypes = {
      reportUIChange: PropTypes.func,
    };

    reportUIChange = (changeEvent: AnyFixMe, controlName: AnyFixMe) => {
      this.context?.reportUIChange?.(
        controlName || Component.displayName,
        this.props.instanceId || this.props.label,
        changeEvent,
      );
    };

    render() {
      return (
        <Component
          {...(this.props as P)}
          reportUIChange={this.reportUIChange}
        />
      );
    }
  }

  return ReportUIChange;
};

export default withReportUIChange;
