import type {
  CompRef,
  DocumentServicesObject,
  Rect,
} from 'types/documentServices';
import type {
  CompClusterGroupingConfig,
  CompWithLayout,
  ComponentsCluster,
  ComponentsClusterLayout,
} from './types';
import { DEFAULT_GROUPING_CONFIG } from './constants';
import { isBlockComponent, sortByLayoutY } from './utils';

export const groupComponentsClusters = (
  documentServices: DocumentServicesObject,
  containerRef: CompRef,
  rootComponents?: CompRef[],
  config?: Partial<CompClusterGroupingConfig>,
): ComponentsCluster[] => {
  const fullConfig: CompClusterGroupingConfig = {
    ...DEFAULT_GROUPING_CONFIG,
    ...config,
  };

  if (!rootComponents)
    rootComponents = documentServices.components.getChildren(containerRef);

  const sortedChildren = getSortedChildrenWithLayout(
    documentServices,
    rootComponents,
  );
  const { blockComps, smallComps } = separateBlockAndSmallComponents(
    sortedChildren,
    fullConfig.blockComponentWidth,
  );
  const blockCompsSections = composeSectionsFromCompClusters(
    blockComps,
    fullConfig.blockCompMergeThreshold,
  );
  const { sections: extendedBlockSections, leftovers } =
    insertSmallCompsIntoSections(
      blockCompsSections,
      smallComps,
      fullConfig.smallAndBlockMergeThreshold,
    );
  const smallCompsSections = composeSectionsFromCompClusters(
    leftovers,
    fullConfig.smallCompMergeThreshold,
  );
  const fullSectionsList = mergeBlockAndSmallSections(
    extendedBlockSections,
    smallCompsSections,
  );
  return mergeAndFilterRedundantSections(fullSectionsList, fullConfig);
};

const getSortedChildrenWithLayout = (
  documentServices: DocumentServicesObject,
  rootComponents: CompRef[],
) => {
  return rootComponents
    .map((childRef) => getComponentWithLayout(documentServices, childRef))
    .sort(sortByLayoutY);
};

const getComponentWithLayout = (
  documentServices: DocumentServicesObject,
  compRef: CompRef,
): CompWithLayout => {
  const layoutObject = documentServices.components.layout.get(compRef);
  const layout = {
    ...(layoutObject.bounding ?? layoutObject),
  };

  return {
    compRef,
    layout,
  };
};

const separateBlockAndSmallComponents = (
  components: CompWithLayout[],
  blockCompWidth: number,
): {
  blockComps: CompWithLayout[];
  smallComps: CompWithLayout[];
} => {
  const blockComps: CompWithLayout[] = [];
  const smallComps: CompWithLayout[] = [];

  for (const comp of components) {
    if (isBlockComponent(comp, blockCompWidth)) {
      blockComps.push(comp);
    } else {
      smallComps.push(comp);
    }
  }

  return { blockComps, smallComps };
};

const composeSectionsFromCompClusters = (
  components: CompWithLayout[],
  threshold: number,
): ComponentsCluster[] => {
  const sections: ComponentsCluster[] = [];

  for (let i = 0; i < components.length; i++) {
    const currComp = components[i];
    const currSection: ComponentsCluster = {
      position: {
        top: currComp.layout.y,
        bottom: currComp.layout.y + currComp.layout.height,
      },
      children: [currComp.compRef],
    };

    let endSection = false;
    while (!endSection) {
      const nextComp = components[i + 1];
      if (
        !nextComp ||
        nextComp.layout.y > currSection.position.bottom + threshold
      ) {
        endSection = true;
      } else {
        currSection.children.push(nextComp.compRef);
        currSection.position.bottom = Math.max(
          currSection.position.bottom,
          nextComp.layout.y + nextComp.layout.height,
        );
        i++;
      }
    }

    sections.push(currSection);
  }

  return sections;
};

const insertSmallCompsIntoSections = (
  sections: ComponentsCluster[],
  comps: CompWithLayout[],
  threshold: number,
): {
  sections: ComponentsCluster[];
  leftovers: CompWithLayout[];
} => {
  const leftovers: CompWithLayout[] = [];

  const clonedSections: ComponentsCluster[] = sections.map((section) => ({
    ...section,
    children: [...section.children],
  }));

  comps.forEach((comp) => {
    const { compRef, layout: compLayout } = comp;
    let overlappingSection;
    let i = 0;
    while (!overlappingSection && i < sections.length) {
      const sectionToCheck = clonedSections[i];
      const isOverlapping = isVerticallyContained(
        sectionToCheck.position,
        compLayout,
        threshold,
      );

      if (isOverlapping) {
        overlappingSection = sectionToCheck;
        overlappingSection.children.push(compRef);
      }

      i++;
    }

    if (!overlappingSection) leftovers.push(comp);
  });

  return {
    sections: clonedSections,
    leftovers,
  };
};

const isVerticallyContained = (
  sectionLayout: ComponentsClusterLayout,
  compLayout: Rect,
  threshold: number = 1,
): boolean => {
  const sectionHeight = sectionLayout.bottom - sectionLayout.top;
  const compBottomY = compLayout.y + compLayout.height;

  const overlappingYWithinOverlapped =
    compLayout.y >= sectionLayout.top && compLayout.y <= sectionLayout.bottom;
  const overlappingBottomYWithinOverlapped =
    compBottomY >= sectionLayout.top && compBottomY <= sectionLayout.bottom;
  const overlappingExceedsOverlappedInBothDirections =
    compLayout.y <= sectionLayout.top && compBottomY >= sectionLayout.bottom;

  return (
    (overlappingYWithinOverlapped &&
      sectionLayout.bottom - compLayout.y >= compLayout.height * threshold) ||
    (overlappingBottomYWithinOverlapped &&
      compBottomY - sectionLayout.top >= compLayout.height * threshold) ||
    (overlappingExceedsOverlappedInBothDirections &&
      sectionHeight >= compLayout.height * threshold)
  );
};

const mergeBlockAndSmallSections = (
  sectionsListA: ComponentsCluster[],
  sectionsListB: ComponentsCluster[],
): ComponentsCluster[] => {
  const mergedList: ComponentsCluster[] = [];

  let countA = 0;
  let countB = 0;

  while (countA < sectionsListA.length || countB < sectionsListB.length) {
    const sectionA = sectionsListA[countA];
    const sectionB = sectionsListB[countB];

    if (
      sectionA &&
      (!sectionB || sectionA.position.top <= sectionB.position.top)
    ) {
      mergedList.push(sectionA);
      countA++;
    } else {
      mergedList.push(sectionB);
      countB++;
    }
  }

  return mergedList;
};

const mergeAndFilterRedundantSections = (
  sections: ComponentsCluster[],
  config: CompClusterGroupingConfig,
): ComponentsCluster[] => {
  const withoutShortSections = mergeShortSections(
    sections,
    config.minimumSectionHeight,
    config.sectionsMergeMaxDistance,
  );
  const withoutOverlappingSections = mergeOverlappingSections(
    withoutShortSections,
    config.mergeSectionsThreshold,
  );
  const completedSectionsList = removeSectionsAboveTheirContainer(
    withoutOverlappingSections,
  );

  return completedSectionsList;
};

const mergeShortSections = (
  sections: ComponentsCluster[],
  minSectionHeight: number,
  mergeThreshold: number,
): ComponentsCluster[] => {
  const clonedSections = [...sections];
  let currSection, nextSection, previousSection, isMerged;

  for (let i = 0; i < clonedSections.length; i++) {
    isMerged = false;
    currSection = sections[i];
    const sectionHeight =
      currSection.position.bottom - currSection.position.top;

    if (sectionHeight < minSectionHeight) {
      previousSection = clonedSections[i - 1];
      nextSection = clonedSections[i + 1];

      const distanceFromPrevious = previousSection
        ? currSection.position.top - previousSection.position.bottom
        : Number.POSITIVE_INFINITY;
      const distanceFromNext = nextSection
        ? nextSection.position.top - currSection.position.bottom
        : Number.POSITIVE_INFINITY;

      if (
        distanceFromPrevious < distanceFromNext &&
        distanceFromPrevious <= mergeThreshold
      ) {
        mergeSections(clonedSections, i, i - 1);
        isMerged = true;
      } else if (
        distanceFromNext < distanceFromPrevious &&
        distanceFromNext <= mergeThreshold
      ) {
        mergeSections(clonedSections, i, i + 1);
        isMerged = true;
      }

      if (isMerged) {
        i--;
      }
    }
  }

  return clonedSections;
};

const mergeSections = (
  sections: ComponentsCluster[],
  mergedSectionIdx: number,
  intoSectionIdx: number,
) => {
  const mergedSection = sections[mergedSectionIdx];
  const intoSection = sections[intoSectionIdx];
  intoSection.children = intoSection.children.concat(mergedSection.children);

  intoSection.position.top = Math.min(
    intoSection.position.top,
    mergedSection.position.top,
  );
  intoSection.position.bottom = Math.max(
    intoSection.position.bottom,
    mergedSection.position.bottom,
  );

  sections.splice(mergedSectionIdx, 1);
};

const mergeOverlappingSections = (
  sections: ComponentsCluster[],
  threshold: number,
): ComponentsCluster[] => {
  const clonedSections = [...sections];
  let i = 0;
  while (i < clonedSections.length) {
    const isOverlapping =
      clonedSections[i + 1] &&
      clonedSections[i + 1].position.top <=
        clonedSections[i].position.bottom + threshold;
    if (isOverlapping) {
      mergeSections(clonedSections, i + 1, i);
    } else {
      i++;
    }
  }

  return clonedSections;
};

const removeSectionsAboveTheirContainer = (
  sections: ComponentsCluster[],
): ComponentsCluster[] => {
  return sections.filter((section) => section.position.bottom >= 0);
};
