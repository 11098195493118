export default {
  LEFT_BAR_NUMBER_OF_BUTTONS: {
    evid: 655,
    fields: {
      count: 'numeric',
      site_id: 'guid',
      blog_bool: 'bool',
      booking_bool: 'bool',
      ecomm_bool: 'bool',
      rest_bool: 'bool',
      ascend_bool: 'bool',
    },
  },
  LEFT_BAR_BUTTON_CLICK: {
    evid: 296,
    fields: {
      category: 'string',
    },
  },
  LEFT_BAR_BUTTON_HOVER: {
    evid: 1177,
    fields: {
      editorMode: 'string',
      element_name: 'string',
    },
  },
} as const;
