const MANAGE_MENU_INTERACTIONS = {
  // panels
  OPEN_PANEL: 'manage_menu_open_panel',
  OPEN_MENU_SETS_PANEL: 'manage_menu_open_menu_sets_panel',
  OPEN_MENU_SETS_RADIO_PANEL: 'manage_menu_open_menu_sets_radio_panel',
  OPEN_DELETE_MENU_SET_PANEL: 'manage_menu_open_delete_menu_set_panel',
  OPEN_CHANGE_PAGES_PANEL: 'manage_menu_open_show_pages_panel',
  // folders/dropdowns
  ADD_DROPDOWN: 'manage_menu_add_dropdown',
  ADD_CONTAINER: 'manage_menu_add_container',
  MOVE_OUT_OF_DROPDOWN: 'manage_menu_move_out_of_dropdown',
  MOVE_INTO_DROPDOWN: 'manage_menu_move_into_dropdown',
  // migration
  MIGRATE: 'manage_menu_migrate_menu_data_structure',
  MIGRATE_DESKTOP: 'manage_menu_migrate_menu_desktop',
  MIGRATE_MOBILE: 'manage_menu_migrate_menu_mobile',
  MIGRATE_PAGES_PANEL: 'manage_menu_migrate_menu_pages_panel',
  // menu sets
  CREATE_MENU_SET: 'manage_menu_create_menu_set',
  DUPLICATE_MENU_SET: 'manage_menu_duplicate_menu_set',
  DELETE_MENU_SET: 'manage_menu_delete_menu_set',
  // menu pages
  CHANGE_MENU_PAGES: 'manage_menu_change_pages',
  // menu items
  RENAME_ITEM: 'manage_menu_rename_item',
  DELETE_MENU_ITEM: 'manage_menu_delete_menu_item',
  SELECT_MENU_SET: 'manage_menu_select_menu_set',
  // link panel
  ADD_OR_CHANGE_FROM_LINK_PANEL: 'manage_menu_add_or_change_from_link_panel',
  // drop
  DND_MOVE_ITEM: 'manage_menu_dnd_move_item',
} as const;

export const INTERACTIONS = {
  INSTALL_APP: 'install_app',
  UNINSTALL_APP: 'uninstall_app',
  ADD_CONTACT_FORM: 'add_contact_form',
  ADD_BLOG_FROM_6TH_BUTTON: 'add_blog_from_6th_button',
  APP_STUDIO_INIT: 'app_studio_init',
  APP_STUDIO_PACKAGE: 'app_studio_package',
  APP_STUDIO_BUILD: 'app_studio_build',
  ADD_TPA_FROM_ADD_PANEL: 'add_tpa_from_add_panel',
  ADD_COMP_FROM_ADD_PANEL: 'add_comp_from_add_panel',
  ADD_COMP_FROM_MEDIA_MANAGER_PANEL: 'add_comp_from_media_manager_panel',
  ADD_PAGE_ADD_PANEL: 'add_page_add_panel',
  ADD_PAGE_ADD_DYNAMIC_PAGE_PRESET: 'add_page_add_dynamic_page_preset',
  ADD_PAGE_AND_CLONE_TPA_DATA: 'add_page_and_clone_tpa_data',
  AUTO_PIN_TO_SCREEN: 'auto_pin_to_screen',
  DELETE_LIGHTBOX_FROM_EDITOR: 'delete_lightbox_from_editor',
  PASTE_FILE_FROM_KEYBOARD: 'paste_file_from_keyboard',
  DRAG_DROP_FILE_FROM_OS: 'drag_drop_file_from_os',
  ADD_PAGE: 'add_page',
  ADD_TPA_SECTION: 'add_tpa_section',
  ADD_TPA_WIDGET: 'add_tpa_widget',
  ADD_TPA_PLATFORM: 'add_tpa_platform',
  REMOVE_PAGE: 'remove_page',
  NAVIGATE_TO_PAGE: 'navigate_to_page',
  PRE_FETCH_ROUTERS_INNER_ROUTES: 'pre_fetch_routers_inner_routes',
  CHANGE_EDITOR_MODE_TO_MOBILE: 'change_editor_mode_to_mobile',
  ADD_BLOG_FROM_EDITOR: 'add_blog_from_editor',
  ADD_MEMBERS_FROM_EDITOR: 'add_members_from_editor',
  CHANGE_EDITOR_MODE_TO_DESKTOP: 'change_editor_mode_to_desktop',
  GO_TO_DESKTOP_PREVIEW: 'go_to_desktop_preview',
  GO_TO_MOBILE_PREVIEW: 'go_to_mobile_preview',
  GO_TO_DESKTOP_EDITOR: 'go_to_desktop_editor',
  GO_TO_MOBILE_EDITOR: 'go_to_mobile_editor',
  PUBLISH_FROM_TOPBAR: 'publish_from_topbar',
  LAZY_COMPONENT_LOAD: 'lazy_component_load',
  ADD_PAGE_PANEL_OPEN: 'add_page_panel_open',
  ADD_PAGE_FROM_ADD_PAGE_PANEL: 'add_page_from_add_page_panel',
  ADD_PAGE_PANEL_INSTALL_FORMS: 'add_page_panel_install_forms',
  ADD_PAGE_PANEL_INSTALL_PRO_GALLERY: 'add_page_panel_install_pro_gallery',
  ADD_SECTION_PANEL_OPEN: 'add_section_panel_open',
  ADD_BLANK_PAGE_FROM_ADD_PAGE_PANEL: 'add_blank_page_from_add_page_panel',
  ADD_SECTION_PANEL_ADD_SECTION: 'add_section_panel_add_section',
  ADD_SECTION_PANEL_ADD_SECTION_BLANK: 'add_section_panel_add_section_blank',
  ADD_SECTION_PANEL_ADD_SECTION_CLICK: 'add_section_panel_add_section_click',
  ADD_SECTION_PANEL_ADD_SECTION_FROM_CONTEXT_MENU:
    'add_section_panel_add_section_from_context_menu',
  ADD_SECTION_PANEL_ADD_SECTION_DRAG: 'add_section_panel_add_section_drag',
  ADD_SECTION_PANEL_INSTALL_FORMS: 'add_section_panel_install_forms',
  ADD_SECTION_PANEL_INSTALL_PRO_GALLERY:
    'add_section_panel_install_pro_gallery',
  ADD_SECTION_PANEL_INSTALL_STORES: 'add_section_panel_install_stores',
  ADD_SECTION_PANEL_INSTALL_BOOKINGS: 'add_section_panel_install_bookings',
  ADD_SECTION_PANEL_INSTALL_EVENTS: 'add_section_panel_install_events',
  USER_PUBLISH_SITE: 'user_publish_site',
  USER_PUBLISH_RC: 'user_publish_rc',
  EXECUTE_PUBLISH: 'execute_publish',
  PUBLISH: 'publish',
  PUBLISH_PIPELINE_PUBLISH_ANYWAY: 'publish_pipeline_publish_anyway',
  SAVE_SITE: 'save_site',
  PREVIEW_SITE: 'preview_site',
  CODE_TOP_BAR_FIRST_INSTALL: 'code_top_bar_first_install',
  CODE_ADD_PANEL_FIRST_INSTALL: 'code_add_panel_first_install',
  CODE_NEW_ADD_PANEL_FIRST_INSTALL: 'code_new_add_panel_first_install',
  CODE_TOP_BAR_TOGGLE: 'code_top_bar_toggle',
  SUCCESSFUL_DOMAIN_SUGGESTION_FETCH: 'successful_domain_suggestion_fetch',
  INIT_MULTILINGUAL: 'multilingual-onboarding-open',
  ONBOARDING_MULTILINGUAL: 'multilingual-onboarding-complete',
  BM_MULTILINGUAL: 'multilingual-dashboard-open',
  OPEN_ADD_PANEL: 'open-add-panel',
  FETCHING_FROM_DEALER: 'fetching_from_dealer',
  BACK_TO_ADI: 'back_to_adi',
  CROP_MODE: 'crop_mode',
  ADD_QUICK_ACTION_BAR: 'add_quick_action_bar',
  ATTACH_QAB_TO_MASTER_PAGE: 'attach_qab_to_master_page',
  DEALER_IN_POST_PUBLISH: 'dealer_in_post_publish',
  MEDIA_MANAGER_PANEL_SEARCH: 'media_manager_panel_search',
  MEDIA_MANAGER_PANEL_CLEAR_SEARCH: 'media_manager_panel_clear_search',
  MEDIA_MANAGER_PANEL_BLUR_SEARCH: 'media_manager_panel_blur_search',
  MEDIA_MANAGER_PANEL_BUY_ITEM: 'media_manager_panel_buy_item',
  MEDIA_MANAGER_PANEL_LOAD_DATA: 'media_manager_panel_load_data',
  MEDIA_MANAGER_PANEL_ADD_MEDIA_CLICK: 'media_manager_panel_add_media_click',
  MEDIA_MANAGER_PANEL_ITEM_CLICK: 'media_manager_panel_item_click',
  MEDIA_MANAGER_PANEL_START_DRAG_ITEM: 'media_manager_panel_start_drag_item',
  SET_DRAG_SHORTCUT_CONTEXT: 'set_drag_shortcut_context',
  SET_ROTATE_SHORTCUT_CONTEXT: 'set_rotate_shortcut_context',
  SET_RESIZE_SHORTCUT_CONTEXT: 'set_resize_shortcut_context',
  BOOKINGS_PANEL_OPENED: 'bookings_panel_opened',
  PAST_COMPONENT_OR_TPA: 'past_component_or_tpa',
  DUPLICATE_COMPONENT_OR_TPA: 'duplicate_component_or_tpa',
  COPY_COMPONENTS: 'copy_components',
  COPY_PAGE: 'copy_page',
  PASTE_COMPONENTS_SAME_SITE: 'paste_components_same_site',
  PASTE_COMPONENTS_CROSS_SITE: 'paste_components_cross_site',
  PASTE_PAGE_SAME_SITE: 'paste_page_same_site',
  PASTE_PAGE_CROSS_SITE: 'paste_page_cross_site',
  PASTE_COPY_MEDIA_FILES: 'paste_copy_media_files',
  REMOVE_COMPONENT: 'remove_component',
  CUT_COMPONENT: 'cut_component',
  HIDE_COMP: 'hide_comp',
  SCROLL_TO_COMP_AND_OPEN_PANEL: 'scroll_to_comp_and_open_panel_on_stage',

  SG_PLAYGROUND_BUILD_PAGE: 'SG_playground_build_page',
  SG_PLAYGROUND_LOAD_SECTIONS: 'SG_playground_get_sections',
  SAVED_COMPONENTS_SAVE: 'saved_components_save',
  SAVED_COMPONENTS_PASTE: 'saved_components_paste',
  SAVED_COMPONENTS_RENAME: 'saved_components_rename',
  SAVED_COMPONENTS_DELETE: 'saved_components_delete',
  SAVED_COMPONENTS_SAVE_PANEL: 'saved_components_save_panel_open',

  SAVED_SECTIONS_RENAME: 'saved_sections_rename',
  SAVED_SECTIONS_DELETE: 'saved_sections_delete',

  SMART_WRITER_OPEN: 'smart_writer_open',
  SMART_WRITER_CLOSE: 'smart_writer_close',
  SMART_WRITER_SUGGEST_BUSINESS_TYPE: 'smart_writer_suggest_business_type',
  SMART_WRITER_STEP_BUSINESS_TYPE: 'smart_writer_step_business_type',
  SMART_WRITER_STEP_TOPIC: 'smart_writer_step_topic',
  SMART_WRITER_STEP_PARAGRAPH: 'smart_writer_step_paragraph',
  SMART_WRITER_INIT_BUSINESS_TYPE: 'smart_writer_init_business_type',
  SMART_WRITER_SET_TOPIC: 'smart_writer_set_topic',
  SMART_WRITER_UPDATE_COMPONENT_TEXT: 'smart_writer_update_component_text',

  AI_TEXTGENERATOR_OPEN: 'ai_text_generator_open',

  FETCH_APPS_DATA_FROM_APP_MARKET: 'fetch_apps_data_from_app_market',

  TOUR_START: 'tour_start',

  HELP_HOME_OPEN: 'help_home_open',

  COMP_MIGRATIONS_SERVICE: {
    MIGRATION_TO_TEXT_THEME_SKIN:
      'migration_to_text_theme_skin_wrichtextthemeskin',
    MIGRATION_TO_TEXT_THEME_SKIN_REVERT:
      'migration_to_text_theme_skin_wrichtextthemeskin_revert',
  },

  MOBILE_DISCOVERABILITY: {
    MODAL_SHOW: 'mobile_discoverability_modal_show',
    MODAL_LOAD_PREVIEW: 'mobile_discoverability_modal_load_preview',
  },

  MANAGE_MENU: MANAGE_MENU_INTERACTIONS,

  PANELS: {
    'wysiwyg.viewer.components.WRichText': {
      'compPanels.panels.WRichText.settingsPanel':
        'open_settings_panel_wrichtext',
    },
    'wysiwyg.viewer.components.SiteButton': {
      'compPanels.panels.SiteButton.settingsPanel':
        'open_settings_panel_SiteButton',
      'designPanel.designPanel': 'open_design_panel_SiteButton',
    },
    'wysiwyg.viewer.components.StripColumnsContainer': {
      'compPanels.panels.StripColumnsContainer.managePanel':
        'open_manage_panel_StripColumnsContainer',
    },

    'menu.panels.MenuManagePanel': MANAGE_MENU_INTERACTIONS.OPEN_PANEL,
  },

  GOOGLE_TRANSLATE: {
    FAILED_POPUP: {
      OPEN: 'google_translate_failed_popup_open',
      CLOSE: 'google_translate_failed_popup_close',
    },
    TRANSLATE: 'google_translate_translate',
  },
  AUTO_TRANSLATE: {
    TRANSLATE: 'auto_translate_translate',
  },
  ADD_PANEL: {
    OPEN_NEW: 'add_panel_open_new',
    NEW_ADD_PANEL_FIRST_PRESET_SECTION_LOAD:
      'new_add_panel_first_preset_section_load',
    OLD_ADD_PANEL_FIRST_PRESET_SECTION_LOAD:
      'old_add_panel_first_preset_section_load',
    DATA_LOAD: 'add_panel_data_load',
    RELATED_APPS_LOAD: 'related_apps_load',
    THEMED_SECTION_LOAD: 'themed_section_load',
    ADD_BLOCKS_WIDGET: 'add_panel_add_blocks_widget',
  },
  A11Y: {
    OPEN_PANEL: 'a11y_open_panel',
  },
  LINK_PANEL: {
    ADD_ANCHOR: 'link_panel_add_anchor_link',
    ADD_SECTION: 'link_panel_add_section_link',
    NAVIGATE_TO_LINKED_COMPONENT:
      'link_panel_navigate_back_to_linked_component',
  },
  STYLABLE: {
    ADD_STYLABLE_BUTTON_FROM_ADD_PANEL: 'add_stylable_button_from_add_panel',
  },
  ADD_SITE_BUTTON_FROM_ADD_PANEL: 'add_site_button_from_add_panel',
  CUSTOM_404_CREATE_PAGE: 'custom_404_create_page',
  CUSTOM_404_OPEN_PAGE: 'custom_404_open_page',
  CUSTOM_404_SETTING_MENU_CLICK: 'custom_404_settings_menu_click',
  CUSTOM_404_OPEN_PANEL: 'custom_404_open_panel',
  CUSTOM_404_CLOSE_PANEL: 'custom_404_close_panel',

  MEDIA_SERVICES: {
    MEDIA_STUDIO: {
      ADJUST_BACKGROUND_IMAGE: 'media_studio_adjust_background_image',
    },
  },
  MULTILINGUAL: {
    COMPONENT_CHANGED_PANEL_CLOSE: 'multilingual_component_changed_panel_close',
    GOOGLE_TRANSLATE_WARNING_PANEL_CLOSE:
      'multilingual_google_translate_warning_panel_close',
  },
  PAGES_PANEL: {
    OPEN_PANEL: 'pages_panel_open_panel',
    CLOSE_PANEL: 'pages_panel_close_panel',
    MAP_STATE_TO_PROPS: 'pages_panel_map_state_to_props',
    NEW_MAP_STATE_TO_PROPS: 'pages_panel_new_map_state_to_props',
  },
  SECTIONS_MIGRATION: {
    BULK_MIGRATION: 'sections_migration_bulk_migration',
    PAGE_MIGRATION: 'sections_migration_page_migration',
    PAGE_PREDICTION: 'sections_migration_page_prediction',
  },
  NEW_COLOR_PALETTE_MIGRATION: {
    GENERAL_FLOW: {
      FLAG_SET: 'new_color_palette_general_flow_flag_set',
    },
    SC_FLOW: {
      PALETTE_MIGRATION: 'new_color_palette_sc_flow_new_palette_migration',
      COLORS_SET: 'new_color_palette_sc_flow_colors_set',
      FLAG_SET: 'new_color_palette_sc_flow_flag_set',
    },
    TEMPLATES_FLOW: {
      PALETTE_MIGRATION:
        'new_color_palette_templates_flow_new_palette_migration',
      FLAG_SET: 'new_color_palette_templates_flow_flag_set',
      ACCENTS_SET: 'new_color_palette_templates_flow_accents_set',
      WIRING_REMOVED: 'new_color_palette_templates_flow_wiring_removed',
    },
    EXISTING_SITES_FLOW: {
      PALETTE_MIGRATION:
        'new_color_palette_existing_sites_flow_new_palette_migration',
      FLAG_SET: 'new_color_palette_existing_sites_flow_flag_set',
      ACCENTS_SET: 'new_color_palette_existing_sites_flow_accents_set',
      WIRING_REMOVED: 'new_color_palette_existing_sites_flow_wiring_removed',
    },
    SYNC_FLOW: 'new_color_palette_sync_flow_palette_migration',
  },
  LIST_CLASSIFICATION: {
    LIST_CLASSIFICATION_PREDICTION: 'list_classification_prediction',
  },
  TEXT_CLASSIFICATION: {
    TEXT_CLASSIFICATION_PREDICTION: 'text_classification_prediction',
  },
  INTERACTIONS_FEATURE: {
    ENTER_INTERACTIONS: 'enter_interactions',
    REMOVE_INTERACTION: 'remove_interaction',
    EDIT_EFFECT: 'interaction_edit_effect',
    OPEN_EDIT_TEXT_PANEL: 'interaction_open_edit_text_panel',
  },
  EDITOR_SEARCH: {
    OPEN_PANEL: 'editor_search_open_panel',
    RESULT_FETCH: 'editor_search_result_fetch',
    RESULT_SHOW: 'editor_search_result_show',
    RESULT_ITEM_FOCUS: 'editor_search_result_item_focus',
    RESULT_ITEM_BLUR: 'editor_search_result_item_blur',
    RESULT_ITEM_SUBMIT: 'editor_search_result_item_submit',
  },
  WIXAPPS: {
    WIXAPPS_MIGRATE_NEWS: 'wixapps_migrate_news',
    WIXAPPS_MIGRATE_FAQ: 'wixapps_migrate_faq',
    WIXAPPS_MIGRATE_MENUS: 'wixapps_migrate_menus',
    WIXAPPS_MIGRATE_LIST_BUILDER: 'wixapps_migrate_list_builder',
  },
  ADI_PAAS: {
    ADI_PAAS_INIT: 'adi_paas_init',
    GET_SECTION_PRSEET: 'get_section_presets',
    LOAD_STORY: 'adi_paas_load_story',
    PRE_SOLUTION_INIT: 'adi_paas_pre_solution_init',
    LOAD_LAYOUTS: 'adi_paas_load_layouts',
    INIT_LAYOUT_SOLVER: 'adi_paas_init_layout_solver',
    INIT_THEME_CHOOSER: 'adi_paas_init_theme_chooser',
    SET_UP_CACHE_BY_KIT: 'adi_paas_set_up_cache_by_kit',
    INIT_RICH_TEXT_SERVICE: 'adi_paas_init_rich_text_service',
    FOOTER: 'adi_paas_footer',
    INITIALIZE_FORMS_PLATFORM_SERVICE: 'initialize_forms_platform_service',
    SET_STATIC_STORY_STYLES: 'set_static_story_styles',
    CREATE_FOOTER_SOLUTIONS: 'create_footer_solutions',
    INIT_LAYOUT_ELEMENTS_FETCHER: 'adi_paas_init_layout_elements_fetcher',
  },
  EDITOR_PAAS_API: {
    PAGE_MOBILE_ADJUSTMENTS: 'editor_paas_api_page_mobile_adjustments',
  },
  SITE_CREATION: {
    GET_PAGE_PRESETS: 'site_creation_get_page_presets',
    ADD_HOMEPAGE: 'site_creation_add_homepage',
    ADD_ADDITIONAL_PAGE: 'site_creation_add_additional_page',
    SET_SITE_NAME: 'site_creation_set_site_name',
    INSTALL_CHOSEN_APPS: 'site_creation_install_chosen_apps',
    SET_FPD_AS_HOMEPAGE: 'site_creation_set_fpd_as_homepage',
    INIT_PRESET_API: 'site_creation_init_preset_api',
    SET_KIT: 'site_creation_set_kit',
    SET_SITE_PROPERTIES: 'site_creation_set_site_properties',
    PERSONALIZE_BOOKINGS: 'site_creation_personalize_bookings',
    ADD_WIDGET_PRESET: 'site_creation_add_widget_preset',
  },
  QUICK_EDIT_PANEL: {
    TEXT_EDITOR_LOAD: 'quick_edit_text_editor_load',
  },
  CUSTOM_MENUS_PAGES_PANEL_REVERT: {
    MIGRATION_FOR_MAIN_MENU:
      'custom_menus_pages_panel_revert_migration_for_main_menu',
    MIGRATION_FOR_MULTILINGUAL_MENU:
      'custom_menus_pages_panel_revert_migration_for_multilingual_menu',
  },
  CUSTOM_MENUS_SETUP: {
    CREATE_MENU_SET_FROM_PAGES_PANEL:
      'custom_menus_setup_create_menu_set_from_pages_panel',
    APPLY_MULTILINGUAL_TO_NEW_MENU:
      'custom_menus_setup_apply_multilingual_to_new_menu',
    FIX_PAGES_MENUS_SUB_ITEMS: 'custom_menus_setup_fix_pages_menus_sub_items',
    FIX_PAGES_MENUS_SUB_ITEMS_MULTILINGUAL:
      'custom_menus_setup_fix_pages_menus_sub_items_multilingual',
    CREATE_MAIN_MENU: 'custom_menus_setup_create_main_menu',
    RECONNECT_MENU_COMPONENT_TO_CUSTOM_MENU:
      'custom_menus_setup_reconnect_menu_component_to_custom_menu',
    NORMALIZE_ITEMS_VISIBILITY: 'custom_menus_setup_normalize_items_visibility',
    DEDUPLICATE_CUSTOM_MENUS: 'custom_menus_setup_deduplicate_custom_menus',
  },
  APP_MANAGER: {
    LOAD: 'app_manager_load',
    FETCH_WIX_APPS: 'app_manager_fetch_wix_apps',
    FETCH_INSTALLED_APPS: 'app_manager_fetch_installed_apps',
    FETCH_DEALER_INFO: 'app_manager_fetch_dealer_info',
    FETCH_DEALER_SEGMENT_APPS: 'app_manager_fetch_dealer_segment_apps',
    FETCH_DEALER_SEGMENT_CONTENT: 'app_manager_fetch_dealer_segment_content',
    INIT_SEGMENTS: 'app_manager_init_segments',
    FETCH_SETUP_STEPS: 'app_manager_fetch_setup_steps',
    FETCH_RECOMMENDED_TOOLS: 'app_manager_fetch_recommended_tools',
  },
  SECTIONS_ON_STAGE: {
    ADD_SECTION: 'sections_on_stage_add_section',
  },

  CUSTOM_MENUS_REVERT: {
    E2E_REVERT_FUNCTION: 'custom_menus_revert_e2e_revert_function',
    REVERT_PAGES_MENU: 'custom_menus_revert_revert_pages_menu',
    CONNECT_MENU_COMPONENTS: 'custom_menus_revert_connect_menu_components',
    REMOVE_CUSTOM_MENUS: 'custom_menus_revert_remove_custom_menus',
  },
  BLOCKS: {
    IMPORT_APP: 'blocks_import_app',
    DELETE_APP: 'blocks_delete_app',
    UPDATE_APP: 'blocks_update_app',
    CREATE_APP_LINK: 'blocks_create_app_link',
    DELETE_APP_LINK: 'blocks_delete_app_link',
    UPDATE_CUSTOM_TYPE: 'blocks_update_custom_type',
    UPDATE_PROPERTIES: 'blocks_update_properties',
  },

  DESIGN_PANEL: {
    OPEN_PANEL: 'design_panel_open',
    CLOSE_PANEL: 'design_panel_close',
    OPEN_BACKGROUND: 'design_panel_background_open',
    OPEN_LOBBY: 'design_panel_lobby_open',
    OPEN_THEME: 'design_panel_theme_open',
    OPEN_TRANSITIONS: 'design_panel_transitions_open',
    OPEN_TEXT: 'design_panel_text_open',
    OPEN_TEXT_THEME: 'design_panel_text_theme_open',
    OPEN_COLOR: 'design_panel_color_open',
    OPEN_COLOR_THEME: 'design_panel_color_theme_open',
  },

  CLASSIC_SECTIONS: {
    BOOTSTRAP: 'classic_section_bootstrap',
    ADD_SECTION: 'classic_section_add_section',
    RESIZE: 'classic_section_resize',
    RENAME: 'classic_section_rename',
    ADD_AND_LINK: 'classic_section_add_and_link',
    MOVE: 'classic_section_move',
    COMP_ATTACHED_TO_SECTION: 'classic_section_comp_attached',
    CREATE_WITH_AI: 'create_section_with_ai',
    RENAME_PANEL: {
      OPEN: 'classic_section_open_rename_panel',
    },
    MORE_ACTIONS: {
      OPEN: 'classic_section_open_more_actions',
    },
  },
  PLATFORM: {
    SILENT_INSTALL_APP: 'silent_install_app',
    SILENT_INSTALL_APPS_PARALLEL: 'silent_install_apps_parallel',
    SILENT_INSTALL_APPS_SERIAL: 'silent_install_apps_serial',
  },

  ZOOM_MODE: {
    ENTER: 'zoom_mode_enter',
    EXIT: 'zoom_mode_exit',
  },

  HELP_UKRAINE: 'help_ukraine',

  ADD_SECTION_PREVIEW_LOAD: {
    ADD_SECTION_PREVIEWER_PREVIEW_LOAD: 'add_section_previewer_preview_load',
    ADD_SECTION_HTML_PREVIEW_LOAD: 'add_section_html_preview_load',
    ADD_SECTION_RAW_HTML_PREVIEW_LOAD: 'add_section_raw_html_preview_load',
    ADD_SECTION_PREVIEWER_FIRST_READY: 'add_section_previewer_first_ready',
    ADD_SECTION_PREVIEWER_ALL_READY: 'add_section_previewer_all_ready',
  },
  ADD_PAGE_PREVIEW_LOAD: {
    ADD_PAGE_PREVIEWER_PREVIEW_LOAD: 'add_page_previewer_preview_load',
    ADD_PAGE_PREVIEWER_FIRST_READY: 'add_page_previewer_first_ready',
    ADD_PAGE_PREVIEWER_ALL_READY: 'add_page_previewer_all_ready',
    ADD_PAGE_HTML_PREVIEW_LOAD: 'add_page_html_preview_load',
  },
  CONTENT_INJECTION: {
    CONTENT_INJECTION_CAAS_INITIALIZATION:
      'content_injection_caas_initialization',
    CONTENT_INJECTION_SECTION_CATEGORY_INJECTION:
      'content_injection_section_category_injection',
    CONTENT_INJECTION_PAGES_CATEGORY_INJECTION:
      'content_injection_pages_category_injection',
  },
  AI_TEXT_CREATOR: {
    GPT_REQUEST: 'gpt_request',
    SITE_INJECTION: 'site_injection',
    STREAM_MESSAGE: 'stream_message',
  },
  PALETTE_FROM_LOGO: {
    GPT_REQUEST: 'palette_from_logo_gpt_request',
    GPT_REQUEST_V2: 'palette_from_logo_gpt_request_v2',
    GENERATE_PALETTES: 'palette_from_logo_generate_palettes',
  },
  PALETTE_FROM_TEXT: {
    GPT_REQUEST: 'palette_from_text_gpt_request',
    GPT_REQUEST_V2: 'palette_from_text_gpt_request_v2',
    GENERATE_PALETTES: 'palette_from_text_generate',
    GENERATE_PALETTES_V2: 'palette_from_text_generate_v2',
  },
  SITE_WIDTH_MIGRATION: {
    SECTIONS_MIGRATION_FLOW: 'site_width_migration_sections_migration_flow',
    SITE_CREATION_FLOW: 'site_width_migration_site_creation_flow',
  },
  AI_WRITER: 'ai_writer',
  SITE_ACCESS: {
    OPEN_PANEL: 'site_access_open_panel',
    SAVE: 'site_access_save',
  },
  MOBILE_TEXT_REPLACE: {
    OPEN_PANEL: 'mobile_text_replace_open_panel',
    EDIT_TEXT_CLICK: 'mobile_text_replace_click',
  },
} as const;

export const SWITCH_LAYOUT = {
  OPEN_PANEL: 'switch_layout_open_panel',
  OPEN_PANEL_FIRST_TIME: 'switch_layout_open_panel_first_time',
  SELECT_NEW_SECTION: 'switch_layout_select_new_section',
  GET_SUGGESTIONS_BRUTE_FORCE: 'switch_layout_get_suggestions_brute_force',
  GET_SUGGESTIONS_PAAS: 'switch_layout_get_suggestions_paas',
  GET_SUGGESTIONS: 'switch_layout_get_suggestions',
  APPLY_ORIGINAL_LAYOUT: 'switch_layout_apply_original_layout',
  APPLY_PAAS_LAYOUT: 'switch_layout_apply_paas_layout',
  SWITCH_LAYOUT_TEXT_CLASSIFFICATION: 'switch_layout_text_classification',
  SWITCH_LAYOUT_LIST_CLASSIFICATION_PROCESS:
    'switch_layout_list_classification_process',
} as const;
