export enum PresetContentFieldRole {
  Title = 'title',
  SubTitle = 'subTitle',
  Paragraph = 'paragraph',
  Address = 'address',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
  BusinessName = 'businessName',
  Logo = 'logo',
  Abstract = 'CROPTYPE_ABSTRACT',
  Landscape = 'CROPTYPE_LANDSCAPE',
  Portrait = 'CROPTYPE_PORTRAIT',
  Icon = 'icon',
  Sticker = 'sticker',
}

export const TRANSPARENT_VIDEO_MEDIA_FEATURE = 'alpha';

export const HTML_TAG_CLOSING = '>';

export enum MediaType {
  Image = 'image',
  Video = 'video',
  Picture = 'picture',
  Shape = 'shape',
  Music = 'music',
  Document = 'document',
  Swf = 'swf',
  Archive = 'archive',
}

export const SmartFocalPointConfig = {
  MinFaceConfidence: 0.98,
  MaxFaceAreaCoverage: 0.8,
  MaxFacesHeightRatio: 0.5,
};

export const ALIGN_TYPE_CENTER = 'center';
export const ALIGN_TYPE_TOP = 'top';
export const ALIGN_TYPE_BOTTOM = 'bottom';
export const ALIGN_TYPE_LEFT = 'left';
export const ALIGN_TYPE_RIGHT = 'right';

export const TEXT_MASK_DATA_TYPE = 'TextFxData';
