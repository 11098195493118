const getAttributeTypeTranslations = () => ({
  label: 'aria-label',
  relevant: 'aria-relevant',
  busy: 'aria-busy',
  owns: 'aria-owns',
  tabindex: 'tabindex',
  live: 'aria-live',
  level: 'aria-level',
  role: 'role',
  atomic: 'aria-atomic',
  hidden: 'aria-hidden',
  current: 'aria-current',
  invalid: 'aria-invalid',
  multiline: 'aria-multiline',
  autocomplete: 'aria-autocomplete',
  expanded: 'aria-expanded',
  pressed: 'aria-pressed',
  haspopup: 'aria-haspopup',
  disabled: 'aria-disabled',
  roledescription: 'roledescription',
  activedescendant: 'aria-activedescendant',
  braillelabel: 'aria-braillelabel',
  brailleroledescription: 'aria-brailleroledescription',
  checked: 'aria-checked',
  colcount: 'aria-colcount',
  colindex: 'aria-colindex',
  colindextext: 'aria-colindextext',
  colspan: 'aria-colspan',
  controls: 'aria-controls',
  describedby: 'aria-describedby',
  description: 'aria-description',
  details: 'aria-details',
  dropeffect: 'aria-dropeffect',
  errormessage: 'aria-errormessage',
  flowto: 'aria-flowto',
  grabbed: 'aria-grabbed',
  keyshortcuts: 'aria-keyshortcuts',
  labelledby: 'aria-labelledby',
  multiselectable: 'aria-multiselectable',
  orientation: 'aria-orientation',
  placeholder: 'aria-placeholder',
  posinset: 'aria-posinset',
  readonly: 'aria-readonly',
  required: 'aria-required',
  rowcount: 'aria-rowcount',
  rowindex: 'aria-rowindex',
  rowindextext: 'aria-rowindextext',
  rowspan: 'aria-rowspan',
  selected: 'aria-selected',
  setsize: 'aria-setsize',
  sort: 'aria-sort',
  valuemax: 'aria-valuemax',
  valuemin: 'aria-valuemin',
  valuenow: 'aria-valuenow',
  valuetext: 'aria-valuetext',
});

export { getAttributeTypeTranslations };
