import events from './events';

const CONTROLS_TO_EVENTS = {
  boolean: events.commonControls.Toggle_Click,
  mandatory: events.commonControls.Toggle_Click,
  select: events.commonControls.DropDown_Selection,
  combobox: events.commonControls.DropDown_Selection,
  slider: events.commonControls.Slider_ValueChange,
  RadioButtons: events.commonControls.RadioButtonsList_Selection,
  Thumbnails: events.commonControls.Thumbnails_Selection_List,
  accordion: events.commonControls.Toggle_Click,
  textEffects: events.commonControls.RadioButtonsList_Selection,
  textOutdentLeft: events.textControls.Text_Panel_Indentation_Click,
  textOutdentRight: events.textControls.Text_Panel_Indentation_Click,
  textIndentLeft: events.textControls.Text_Panel_Indentation_Click,
  textIndentRight: events.textControls.Text_Panel_Indentation_Click,
  textWriteDirectionLeft: events.textControls.Text_Panel_Text_Orientation_Click,
  textWriteDirectionRight:
    events.textControls.Text_Panel_Text_Orientation_Click,
  textColor: events.textControls.Text_Panel_Color_Click,
  textHighlight: events.textControls.Text_Panel_Highlight_Click,
  paletteDisplayer: events.colorPicker.SITE_COLOR_SELECT,
  thumbnailsWithIcons: events.commonControls.RadioButtonsList_Selection,
  CheckboxWithButton: events.commonControls.CheckboxWithButton_Toggle,
} as const;

export default CONTROLS_TO_EVENTS;
