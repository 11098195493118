import constants from '#packages/constants';
import { sections } from '#packages/util';
import * as appStudioUtils from '../appStudio/appStudioUtils';
import * as localModeUtils from '../localMode/localModeUtils';

const isNewTopBarEnabled = (): boolean =>
  !appStudioUtils.isAppStudio() && !localModeUtils.isLocalModeEnabled();

const getNewTopBarHeight = () => {
  return sections.isSectionsMigrationBannerEnabled()
    ? constants.UI.TOPBAR_HEIGHT_WITH_SECTIONS_MIGRATION_BANNER
    : constants.UI.TOPBAR_HEIGHT;
};

const getHeightConst = (): number => {
  return isNewTopBarEnabled()
    ? getNewTopBarHeight()
    : constants.UI.OLD_TOPBAR_HEIGHT;
};

const getUpperDeckHeightConst = (): number =>
  Math.floor(
    getHeightConst() /
      (sections.isSectionsMigrationBannerEnabled()
        ? constants.UI.TOPBAR_DECKS_WITH_BANNER
        : constants.UI.TOPBAR_DECKS),
  );

// 37 - based on g-zoom-mode-preview-top scss variable
const getZoomModeHeightConst = (): number => {
  if (sections.isSectionsEnabled()) {
    return getHeightConst();
  }

  return isNewTopBarEnabled() ? getUpperDeckHeightConst() : 37;
};

const getOffsetTopWhenToolsHidden = (): number =>
  isNewTopBarEnabled()
    ? constants.UI.OFFSET_TOP_WHEN_TOOLS_HIDDEN
    : constants.UI.OLD_OFFSET_TOP_WHEN_TOOLS_HIDDEN;

export {
  isNewTopBarEnabled,
  getHeightConst,
  getUpperDeckHeightConst,
  getZoomModeHeightConst,
  getOffsetTopWhenToolsHidden,
};
