export default {
  CUSTOMIZE_CLICK: {
    evid: 1062,
    fields: {
      category: 'string',
    },
  },
  /** Navigation clicks */
  INSIDE_CLICK: {
    evid: 1063,
    fields: {
      category: 'string',
    },
  },

  THEME_SELECTED: {
    evid: 1195,
    fields: {
      value: 'string',
      selection: 'string',
    },
  },

  HOVER: {
    evid: 1438,
    fields: {
      category: 'string',
    },
  },

  /**Old design panel*/
  DESIGN_PANEL_SELECT_PRESET_CLICK: {
    evid: 275,
    fields: {
      style_id: 'string',
      preset_id: 'string',
      component_type: 'string',
      component_id: 'string',
      preset_data_skin: 'string',
      preset_data_tags: 'string',
      section: 'string',
    },
  },
  DESIGN_PANEL_CUSTOMIZE_BUTTON_CLICK: {
    evid: 276,
    fields: {
      was_preset_clicked_current_session: 'boolean',
      component_type: 'string',
      component_id: 'string',
      chosen_style_id: 'string',
    },
  },
  BOX_DESIGN_SETTINGS: {
    evid: 1512,
    fields: {
      component_type: 'string',
      component_id: 'string',
      field_name: 'string',
      field_value: 'string',
      panel_name: 'string',
    },
  },
} as const;
