export default {
  isShown: {
    evid: 529,
    src: 105,
    fields: {
      origin: 'string',
      site_branch_name: 'string',
      site_branch_id: 'string',
      dont_show_again: 'boolean',
    },
  },
  popupAction: {
    evid: 530,
    src: 105,
    fields: {
      action: 'string',
      site_branch_name: 'string',
      site_branch_id: 'string',
      dont_show_again: 'boolean',
    },
  },
} as const;
