// @ts-nocheck
import _ from 'lodash';

function getSvgV1SkinName(url) {
  const split = url.replace('_', '.').replace('/', '.').split('.');
  return [split[2], split[3], `svg_${split[1]}`, split[4]].join('.');
}

function getSvgV2SkinName(url) {
  //Example fileName: shapes/083ab0e246b24019a44c3d1e7096147f.svg
  //Example skinName: svgshape.v2.Svg_083ab0e246b24019a44c3d1e7096147f
  const regEx = /^shapes\/([a-z0-9]{32})\.svg$/i;
  const partsArr = regEx.exec(url);
  const md5Hash = partsArr[1];
  return `svgshape.v2.Svg_${md5Hash}`;
}

export default {
  parseSvgSkinFromUrl: function parseSvgSkinFromUrl(url) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    return _.includes(url, '.v1.')
      ? getSvgV1SkinName(url)
      : getSvgV2SkinName(url);
  },
};
