import type { AddPanelSectionTypes } from './sectionTypes';

export interface AddMenuSection {
  type: AddPanelSectionTypes;
  hide: boolean;
  title: string;
  sectionName?: string;
  subNavigationTitle: string;
  presetTitle: string;
  tooltipTitle: string;
  subNavigationHide: boolean;
  showSectionHeader: boolean;
  additionalBehaviours: any; // TODO: subtypes
  props: {
    image: string;
    onClick?: any;
    onDrop?: any;
    items: SectionItem[];
  };
  help: {
    hide: boolean;
    text: string;
  };
}

export interface SectionItem {
  id: string;
  structure: {
    type: string;
    layout: any;
    componentType: string;
    skin: string;
    data: any;
    props?: any;
    style: any;
    activeModes: any;
  };
  preset: any;
}
