import {
  getUpdatedDesignData,
  getColorValue,
  getOpacityValue,
} from './advancedColorPickerUtils';
import { initialDesignItemBackground } from './constants';
import _ from 'lodash';

import type { CompRef } from 'types/documentServices';
import type { EditorAPI } from '#packages/editorAPI';
import type { ColorPickerValues } from './advancedColorPickerUtils';

export const getBackgroundDesignValuesForColorpicker = (
  editorAPI: EditorAPI,
  compRef?: CompRef,
) => {
  const backdroundData =
    editorAPI.components.design.get(compRef)?.background ||
    initialDesignItemBackground;
  let color = getColorValue(backdroundData);
  if (typeof color === 'string') {
    color = color.replace(/[\{\}]/g, '');
  }

  return {
    color,
    alpha: getOpacityValue(backdroundData),
  };
};

export const updateComponentDesign = (
  editorAPI: EditorAPI,
  selectedComponent: CompRef,
  newValue: ColorPickerValues,
) => {
  const oldBackgroundData =
    editorAPI.components.design.get(selectedComponent)?.background;
  const newData = getUpdatedDesignData(
    oldBackgroundData || initialDesignItemBackground,
    newValue,
  );
  editorAPI.components.design.update(
    selectedComponent,
    {
      type: 'MediaContainerWithDividers',
      background: newData,
    },
    true,
  );
  const oldBgValue = oldBackgroundData?.colorLayers?.[0]?.fill;
  const newBgValue = newData?.colorLayers?.[0]?.fill;
  const hasBgChanged = !_.isEqual(oldBgValue, newBgValue);
  const valueChanged = hasBgChanged
    ? { param: 'bg', value: newValue.color }
    : { param: 'alpha-bg', value: newValue.alpha };

  return valueChanged;
};
