import _ from 'lodash';
import type { EditorAPI } from '#packages/editorAPI';
import type { StageRect } from '#packages/stateManagement';
import type { CompLayout, Rect } from 'types/documentServices';
import * as candidatesUtil from './candidatesUtil';
import { getRectanglesDrawData } from './snapDataUtils';
import type {
  AlignmentLine,
  RectangleDrawData,
  SnapCandidate,
} from './snapTo.types';

const LINES_DIFFERENCE = 26;
const DIFF_FROM_COMPONENT = 2;
const EDGE_LENGTH = 3;

type Axis = 'x' | 'y';

interface CompWithAxisValue {
  layout: Rect;
  axisValue?: number;
}

function getEqualDistancesData(
  editorAPI: EditorAPI,
  selectedComponent: CompLayout,
  candidates: SnapCandidate[],
  stageRect: StageRect,
  axis: Axis,
) {
  let compLayoutInEqualDistanceFound;
  let isVerticalEqualDistanceFound = false;
  let isHorizontalEqualDistanceFound = false;
  const currentPageId = editorAPI.dsRead.pages.getCurrentPageId();
  const equalDistanceCandidates = candidatesUtil.getEqualDistanceCandidates(
    currentPageId,
    stageRect,
    selectedComponent,
    candidates,
    axis,
  );
  const lineRectanglesDrawData = getRectanglesDrawData(
    editorAPI,
    equalDistanceCandidates,
  );
  const selectedCompLayout: CompWithAxisValue = {
    layout: selectedComponent.bounding,
  };
  const allComponents: CompWithAxisValue[] = [selectedCompLayout].concat(
    equalDistanceCandidates.map(
      (candidate) =>
        _.pick(candidate, ['layout', 'axisValue']) as CompWithAxisValue,
    ),
  );
  if (axis === 'x') {
    const leftComponents = _(allComponents)
      .sortBy('layout.x')
      .filter((e) => e.layout.x <= selectedCompLayout.layout.x)
      .reverse()
      .value();
    const rightComponents = _(allComponents)
      .sortBy('layout.x')
      .filter((e) => e.layout.x >= selectedCompLayout.layout.x)
      .value();
    const leftComponentsLayouts = leftComponents.map((comp) => comp.layout);
    const rightComponentsLayouts = rightComponents.map((comp) => comp.layout);
    const [leftDistTable, rightDistTable] = getHorizontalDistanceTables(
      leftComponentsLayouts,
      rightComponentsLayouts,
    );
    const horizontalEqualDistanceLines = getHorizontalEqualDistLines(
      lineRectanglesDrawData,
      leftComponents,
      rightComponents,
      leftDistTable,
      rightDistTable,
    );
    if (horizontalEqualDistanceLines.length > 1) {
      compLayoutInEqualDistanceFound = selectedComponent.bounding;
      isHorizontalEqualDistanceFound = true;
    } else {
      isHorizontalEqualDistanceFound = false;
    }
    return {
      isHorizontalEqualDistanceFound,
      compLayoutInEqualDistanceFound,
      horizontalEqualDistanceLines,
    };
  }
  const upperComponents = _(allComponents)
    .sortBy('layout.y')
    .filter((e) => e.layout.y <= selectedCompLayout.layout.y)
    .reverse()
    .value();
  const lowerComponents = _(allComponents)
    .sortBy('layout.y')
    .filter((e) => e.layout.y >= selectedCompLayout.layout.y)
    .value();
  const upperComponentsLayouts = upperComponents.map((comp) => comp.layout);
  const lowerComponentsLayouts = lowerComponents.map((comp) => comp.layout);
  const [upperDistTable, lowerDistTable] = getVerticalDistanceTables(
    upperComponentsLayouts,
    lowerComponentsLayouts,
  );
  const verticalEqualDistanceLines = getVerticalEqualDistLines(
    lineRectanglesDrawData,
    upperComponents,
    lowerComponents,
    upperDistTable,
    lowerDistTable,
  );
  if (verticalEqualDistanceLines.length > 1) {
    compLayoutInEqualDistanceFound = selectedComponent.bounding;
    isVerticalEqualDistanceFound = true;
  } else {
    isVerticalEqualDistanceFound = false;
  }
  return {
    isVerticalEqualDistanceFound,
    compLayoutInEqualDistanceFound,
    verticalEqualDistanceLines,
  };
}

function getVerticalDistanceTables(
  upperComponents: CompLayout[],
  lowerComponents: CompLayout[],
) {
  const upperCompsDistMatrix = upperComponents
    ? getDistanceTable(upperComponents, 'up', 'y', 'height')
    : [];
  const lowerCompsDistMatrix = lowerComponents
    ? getDistanceTable(lowerComponents, 'down', 'y', 'height')
    : [];
  return [upperCompsDistMatrix, lowerCompsDistMatrix];
}

function getHorizontalDistanceTables(
  leftComponents: CompLayout[],
  rightComponents: CompLayout[],
) {
  const leftCompsDistMatrix = leftComponents
    ? getDistanceTable(leftComponents, 'left', 'x', 'width')
    : [];
  const rightCompsDistMatrix = rightComponents
    ? getDistanceTable(rightComponents, 'right', 'x', 'width')
    : [];
  return [leftCompsDistMatrix, rightCompsDistMatrix];
}

function getDistanceTable(
  componentsArray: CompLayout[],
  direction: 'up' | 'down' | 'left' | 'right',
  axis: Axis,
  dimension: 'height' | 'width',
) {
  return componentsArray.reduce((compsDistMatrix, value, i) => {
    compsDistMatrix[i] = componentsArray.reduce((matrixRow, val, j) => {
      if (j <= i) {
        matrixRow.push(0);
      } else {
        const curComp = componentsArray[j];
        const prevComp = componentsArray[j - 1];
        if (axis === 'y') {
          matrixRow.push(
            direction === 'up'
              ? matrixRow[j - 1] +
                  prevComp[axis] -
                  (curComp[axis] + curComp[dimension])
              : matrixRow[j - 1] +
                  curComp[axis] -
                  (prevComp[axis] + prevComp[dimension]),
          );
        } else {
          matrixRow.push(
            direction === 'left'
              ? matrixRow[j - 1] +
                  prevComp[axis] -
                  (curComp[axis] + curComp[dimension])
              : matrixRow[j - 1] +
                  curComp[axis] -
                  (prevComp[axis] + prevComp[dimension]),
          );
        }
        if (j > i + 1) {
          matrixRow[j] += prevComp[dimension];
        }
      }
      return matrixRow;
    }, []);
    return compsDistMatrix;
  }, []);
}

function getVerticalEqualDistLines(
  lineRectanglesDrawData: RectangleDrawData[],
  upperComponents: CompWithAxisValue[],
  lowerComponents: CompWithAxisValue[],
  upperDistTable: number[][],
  lowerDistTable: number[][],
) {
  let curDist;
  let lines: AlignmentLine[] = [];
  const distanceArray: number[] = _.sortBy(
    _.union(upperDistTable[0], lowerDistTable[0]),
  );

  for (let i = 0; i < distanceArray.length; i++) {
    curDist = distanceArray[i];
    lines = findMatchesInVerticalDistTable(
      upperComponents,
      upperDistTable,
      0,
      0,
      curDist,
      lines,
      lineRectanglesDrawData,
    );
    lines = findMatchesInVerticalDistTable(
      lowerComponents,
      lowerDistTable,
      0,
      0,
      curDist,
      lines,
      lineRectanglesDrawData,
    );
    if (lines.length > 1) {
      return addEdgeLines(lines, 'vertical');
    }
    lines = [];
  }
  return [];
}

//todo lodash tricks for nested for loop with additional stop conditions
function findMatchesInVerticalDistTable(
  compArr: CompWithAxisValue[],
  distTable: number[][],
  row: number,
  col: number,
  curDist: number,
  lines: AlignmentLine[],
  lineRectanglesDrawData: RectangleDrawData[],
) {
  for (; row < distTable.length; row++) {
    for (; col < distTable.length && distTable[row][col] <= curDist; col++) {
      if (curDist !== 0 && curDist === distTable[row][col]) {
        const [topLayout, bottomLayout] =
          compArr[row].layout.y < compArr[col].layout.y
            ? [compArr[row], compArr[col]]
            : [compArr[col], compArr[row]];
        const xValue = isSelectedComponent(topLayout)
          ? bottomLayout.axisValue
          : topLayout.axisValue;
        lines.push({
          type: 'alignmentLine',
          line: [
            {
              x: xValue + LINES_DIFFERENCE,
              y:
                topLayout.layout.y +
                topLayout.layout.height +
                DIFF_FROM_COMPONENT,
            },
            {
              x: xValue + LINES_DIFFERENCE,
              y: bottomLayout.layout.y - DIFF_FROM_COMPONENT,
            },
          ],
          rectanglesDrawData: lineRectanglesDrawData,
        });
        row = col;
      }
    }
  }

  return lines;
}

function getHorizontalEqualDistLines(
  lineRectanglesDrawData: RectangleDrawData[],
  leftComponents: CompWithAxisValue[],
  rightComponents: CompWithAxisValue[],
  leftDistTable: number[][],
  rightDistTable: number[][],
) {
  let curDist: number;
  let lines: AlignmentLine[] = [];
  const distanceArray = _.sortBy(_.union(leftDistTable[0], rightDistTable[0]));

  for (let i = 0; i < distanceArray.length; i++) {
    curDist = distanceArray[i];
    lines = findMatchesInHorizontalDistanceTable(
      leftComponents,
      leftDistTable,
      0,
      0,
      curDist,
      lines,
      lineRectanglesDrawData,
    );
    lines = findMatchesInHorizontalDistanceTable(
      rightComponents,
      rightDistTable,
      0,
      0,
      curDist,
      lines,
      lineRectanglesDrawData,
    );
    if (lines.length > 1) {
      return addEdgeLines(lines, 'horizontal');
    }
    lines = [];
  }
  return [];
}

//todo lodash tricks for nested for loop with additional stop conditions
function findMatchesInHorizontalDistanceTable(
  compArr: CompWithAxisValue[],
  distTable: number[][],
  row: number,
  col: number,
  curDist: number,
  lines: AlignmentLine[],
  lineRectanglesDrawData: RectangleDrawData[],
) {
  for (; row < distTable.length; row++) {
    for (; col < distTable.length && distTable[row][col] <= curDist; col++) {
      if (curDist !== 0 && curDist === distTable[row][col]) {
        const [leftLayout, rightLayout] =
          compArr[row].layout.x < compArr[col].layout.x
            ? [compArr[row], compArr[col]]
            : [compArr[col], compArr[row]];
        const yValue = isSelectedComponent(leftLayout)
          ? rightLayout.axisValue
          : leftLayout.axisValue;
        lines.push({
          type: 'alignmentLine',
          line: [
            {
              x:
                leftLayout.layout.x +
                leftLayout.layout.width +
                DIFF_FROM_COMPONENT,
              y: yValue - LINES_DIFFERENCE,
            },
            {
              x: rightLayout.layout.x - DIFF_FROM_COMPONENT,
              y: yValue - LINES_DIFFERENCE,
            },
          ],
          rectanglesDrawData: lineRectanglesDrawData,
        });
        row = col;
      }
    }
  }

  return lines;
}

function addEdgeLines(
  snapData: AlignmentLine[],
  direction: 'horizontal' | 'vertical',
) {
  if (_.isEmpty(snapData)) {
    return [];
  }
  const linesWithEdges: AlignmentLine[] = [];
  for (let i = 0; i < snapData.length; i++) {
    const { line } = snapData[i];
    if (direction === 'horizontal') {
      linesWithEdges.push({
        type: 'alignmentLine',
        line: [
          { x: line[0].x, y: line[0].y - EDGE_LENGTH },
          { x: line[0].x, y: line[0].y + EDGE_LENGTH },
        ],
        rectanglesDrawData: [],
      });
      linesWithEdges.push({
        type: 'alignmentLine',
        line: [
          { x: line[1].x, y: line[1].y - EDGE_LENGTH },
          { x: line[1].x, y: line[1].y + EDGE_LENGTH },
        ],
        rectanglesDrawData: [],
      });
    }
    if (direction === 'vertical') {
      linesWithEdges.push({
        type: 'alignmentLine',
        line: [
          { x: line[0].x - EDGE_LENGTH, y: line[0].y },
          { x: line[0].x + EDGE_LENGTH, y: line[0].y },
        ],
        rectanglesDrawData: [],
      });
      linesWithEdges.push({
        type: 'alignmentLine',
        line: [
          { x: line[1].x - EDGE_LENGTH, y: line[1].y },
          { x: line[1].x + EDGE_LENGTH, y: line[1].y },
        ],
        rectanglesDrawData: [],
      });
    }
    linesWithEdges.push(snapData[i]);
  }
  return linesWithEdges;
}

function isSelectedComponent(comp: CompWithAxisValue) {
  return !comp.axisValue;
}

export { getEqualDistancesData };
