import { CeType } from '@wix/adi-core-types';
import {
  APP_ID_TO_CE_TYPE,
  APPS_PRIORITY_MAP,
  BLOG_AS_HOMEPAGE_STRUCTURE_IDS,
  EVENTS_AS_HOMEPAGE_STRUCTURE_IDS,
} from './sitemapRulesConsts';
import {
  BANDSINTOWN,
  INSTAGRAM_FEED,
  PRICING_PLANS,
  WIX_BLOG,
  WIX_BOOKINGS,
  WIX_EVENTS,
  WIX_MUSIC,
  WIX_NEW_STORES,
  WIX_RESERVATIONS,
  WIX_VIDEO,
} from '@wix/app-definition-ids';
import { PageSectionContent } from '../types';
import { Volume } from '@wix/adi-content-api';
import { createSectionContent } from './caasUtils';
import { PresetContentCategory } from './caasConsts';

export interface CeTypeToSection {
  [ceType: string]: number;
}

export interface PositionCfg {
  appCeType?: CeType;
  positionCeType?: CeType;
  replaceContentCategory?: PresetContentCategory;
  position?: POSITION;
  removeSection?: CeType[];
  blockingApps?: string[];
  blockingStructureIds?: string[];
}

interface AppIdToPositionCfg {
  [appId: string]: PositionCfg;
}

export enum POSITION {
  AFTER = 'after',
  BEFORE = 'before',
  LAST = 'last',
}

export const sortAppDefIdsByPriority = (appDefIds: string[]): string[] => {
  return [...appDefIds].sort((a, b) => {
    const positionCfg = getWidgetPositionCfg(a);
    if (positionCfg?.position === POSITION.BEFORE) {
      return APPS_PRIORITY_MAP[a] - APPS_PRIORITY_MAP[b];
    }
    return APPS_PRIORITY_MAP[b] - APPS_PRIORITY_MAP[a];
  });
};

export const getCeTypeToSectionIndex = (
  sections: PageSectionContent[],
): CeTypeToSection => {
  return sections.reduce((acc, section, index) => {
    if (!section.ceType) return acc;
    acc[section.ceType] = index;
    return acc;
  }, {} as CeTypeToSection);
};

export const getSectionIndex = (
  positionCfg: PositionCfg,
  ceTypeToSectionIndex: CeTypeToSection,
  numberOfSections: number,
): number => {
  if (positionCfg.position === POSITION.LAST) {
    return numberOfSections;
  }
  const sectionIndex = positionCfg.positionCeType
    ? ceTypeToSectionIndex[positionCfg.positionCeType]
    : undefined;
  if (sectionIndex !== undefined) {
    if (positionCfg.position === POSITION.AFTER) {
      return sectionIndex + 1;
    }
    return sectionIndex;
  }
  return 1;
};

export const addNewSection = (
  appDefId: string,
  appCeType: CeType,
  sections: PageSectionContent[],
  sectionIndex: number,
  ceTypesNotToPersonalize: Set<CeType>,
  sourceTemplateIdsByCeType: Record<string, string>,
): PageSectionContent[] => {
  const alreadyExists = sections.some(
    (section) => APP_ID_TO_CE_TYPE[appDefId]?.includes(section.ceType),
  );
  if (!alreadyExists) {
    const newSection = createSectionContent(
      '',
      appCeType,
      Volume.BuiltIn,
      ceTypesNotToPersonalize,
      sourceTemplateIdsByCeType,
    );
    return [
      ...sections.slice(0, sectionIndex),
      newSection,
      ...sections.slice(sectionIndex),
    ];
  }
  return [...sections];
};

export const isSectionBlocked = (
  structureId: string,
  positionCfg: PositionCfg,
  installedApps: string[],
) => {
  if (positionCfg.blockingStructureIds?.includes(structureId)) {
    return true;
  }
  if (
    positionCfg.blockingApps?.some((appId) => installedApps.includes(appId))
  ) {
    return true;
  }
  return false;
};

export const replaceContentCategory = (
  sections: PageSectionContent[],
  appCeType: CeType,
  contentCategory: PresetContentCategory,
): PageSectionContent[] => {
  return sections.map((section) => ({
    ...section,
    contentCategory:
      appCeType === section.ceType ? contentCategory : section.contentCategory,
  }));
};

export const removeSectionsIfNeeded = (
  sections: PageSectionContent[],
  positionCfg: PositionCfg,
  ceTypeToSectionIndex: CeTypeToSection,
): PageSectionContent[] => {
  let resultSections = [...sections];
  positionCfg.removeSection?.forEach((ceType) => {
    const sectionIndex = ceTypeToSectionIndex[ceType];
    if (sectionIndex) {
      resultSections = [
        ...sections.slice(0, sectionIndex),
        ...sections.slice(sectionIndex + 1),
      ];
    }
  });
  return resultSections;
};

export const getWidgetPositionCfg = (
  appDefId: string,
  structureId: string = '',
): PositionCfg => {
  const isBlogAsHomepage = BLOG_AS_HOMEPAGE_STRUCTURE_IDS.includes(structureId);
  const isEventsAsHomepage =
    EVENTS_AS_HOMEPAGE_STRUCTURE_IDS.includes(structureId);

  const widgetsPositionsCfg: AppIdToPositionCfg = {
    [WIX_NEW_STORES]: {
      appCeType: CeType.StoresGridGalleryWidget,
      positionCeType: CeType.Welcome,
      position: POSITION.AFTER,
      removeSection: [CeType.Product, CeType.Price],
      blockingStructureIds: BLOG_AS_HOMEPAGE_STRUCTURE_IDS,
    },
    [WIX_BOOKINGS]: {
      appCeType: CeType.BookingsWidget,
      positionCeType: CeType.Welcome,
      removeSection: [CeType.Service, CeType.Price],
      position: POSITION.AFTER,
    },
    [WIX_BLOG]: {
      appCeType: CeType.NewBlogCustomFeed,
      positionCeType: CeType.Contact,
      position: POSITION.BEFORE,
      removeSection: [CeType.News],
      blockingApps: isBlogAsHomepage ? [] : [WIX_NEW_STORES],
    },
    [INSTAGRAM_FEED]: {
      appCeType: CeType.Instagram_Widget,
      position: POSITION.LAST,
    },
    [WIX_RESERVATIONS]: {
      appCeType: CeType.RestaurantsReservationsWidget,
      position: POSITION.LAST,
    },
    [BANDSINTOWN]: {
      appCeType: CeType.BandsInTownWidget,
      positionCeType: CeType.Contact,
      position: POSITION.BEFORE,
    },
    [WIX_VIDEO]: {
      appCeType: CeType.VideoWidget,
      position: POSITION.LAST,
    },
    [WIX_EVENTS]: {
      appCeType: CeType.EventsSingle,
      positionCeType: CeType.Contact,
      position: POSITION.BEFORE,
      replaceContentCategory: isEventsAsHomepage
        ? PresetContentCategory.EventsWelcome
        : undefined,
    },
    [WIX_MUSIC]: {
      appCeType: CeType.MusicWidget,
      positionCeType: CeType.Contact,
      position: POSITION.BEFORE,
    },
    [PRICING_PLANS]: {
      removeSection: [CeType.Price],
    },
  };

  return widgetsPositionsCfg[appDefId];
};
