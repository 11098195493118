// @ts-nocheck
import _ from 'lodash';

export default {
  getInitialState() {
    return { checkedItems: {} };
  },

  clearSelection() {
    this.setState({ checkedItems: {} });
  },

  toggle(id) {
    const { checkedItems } = this.state;
    checkedItems[id] = !checkedItems[id];
    this.setState({ checkedItems });
  },

  toggleAll(allIds) {
    if (this.getSelectedIds().length === 0) {
      const checkedItems = _.transform(
        allIds,
        function (acc, id) {
          acc[id] = true;
        },
        {},
      );
      this.setState({ checkedItems });
    } else {
      this.setState({ checkedItems: {} });
    }
  },

  isSelected(id) {
    return !!this.state.checkedItems[id];
  },

  getSelectedIds() {
    return _(this.state.checkedItems).pickBy().keys().value();
  },
};
