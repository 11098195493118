export default {
  PUBLISH_CLICK: {
    evid: 222,
    src: 38,
    fields: {
      origin: 'string',
      viewModeSource: 'string',
      is_first_manual_publish: 'boolean',
      numberOfManualPublish: 'numeric',
    },
  },
  PUBLISH_DROPDOWN_CLICKED: {
    evid: 905,
    src: 38,
    fields: {
      origin: 'string',
    },
  },
  PUBLISH_ALL: {
    evid: 918,
    src: 38,
    fields: {
      origin: 'string',
    },
  },
  First_Publish_connect_domain_click: {
    evid: 379,
    fields: {
      user_status: 'string',
      message: 'string',
    },
  },
  Second_Publish_connect_domain_click: {
    evid: 443,
    fields: {
      user_status: 'string',
      message: 'string',
    },
  },
  First_Publish_Done_click: {
    evid: 380,
    fields: {
      user_status: 'string',
      message: 'string',
    },
  },
  Second_Publish_Done_click: {
    evid: 394,
    fields: {
      user_status: 'string',
      message: 'string',
    },
  },
  First_Publish_dont_show_again_click: {
    evid: 381,
    fields: {
      toggle: 'bool',
      user_status: 'string',
    },
  },
  First_Publish_try_to_edit_site_name_click: {
    evid: 382,
    fields: {
      user_status: 'string',
    },
  },
  Second_Publish_dont_show_again_click: {
    evid: 395,
    fields: {
      toggle: 'bool',
    },
  },
  First_Publish_Send_ShoutOut_link_click: {
    evid: 383,
    fields: {
      user_status: 'string',
    },
  },
  First_Publish_get_own_Mailbox_link_click: {
    evid: 384,
    fields: {
      user_status: 'string',
    },
  },
  First_Publish_Traffic_Apps_link_click: {
    evid: 385,
    fields: {
      user_status: 'string',
    },
  },
  Second_Publish_View_Site_click: {
    evid: 388,
    fields: {
      user_status: 'string',
    },
  },
  Second_Publish_facebook_Share_click: {
    evid: 389,
    fields: {
      user_status: 'string',
    },
  },
  Second_Publish_Twitter_share_click: {
    evid: 390,
    fields: {
      user_status: 'string',
    },
  },
  Second_Publish_ShoutOut_Share_click: {
    evid: 391,
    fields: {
      user_status: 'string',
    },
  },
  Second_Publish_Seo_Wizard_click: {
    evid: 684,
    fields: {
      user_status: 'string',
    },
  },
  Second_Publish_get_own_Mailbox_click: {
    evid: 392,
    fields: {
      user_status: 'string',
    },
  },
  Second_Publish_Traffic_Apps_click: {
    evid: 393,
    fields: {
      user_status: 'string',
    },
  },
  Publish_Work_not_Published_Error_ok_Click: {
    evid: 396,
    fields: {},
  },
  Publish_Work_not_Published_Error_Help_Center_Link_Click: {
    evid: 397,
    fields: {},
  },
  publish_view_your_site_click: {
    evid: 449,
    fields: {
      is_first_publish: 'bool',
      panel_name: 'string',
      user_status: 'string',
    },
  },
  publish_flow_upgrade_now_button_click: {
    evid: 272,
    fields: {
      is_first_publish: 'bool',
      user_status: 'string',
      message: 'string',
    },
  },
  publish_flow_upgrade_later_button_click: {
    evid: 273,
    fields: {
      is_first_publish: 'bool',
      user_status: 'string',
      message: 'string',
    },
  },
  publish_flow_connect_domain_now_button_click: {
    evid: 297,
    fields: {
      is_first_publish: 'bool',
      user_status: 'string',
      message: 'string',
    },
  },
  publish_flow_connect_domain_later_button_click: {
    evid: 305,
    fields: {
      is_first_publish: 'bool',
      user_status: 'string',
      message: 'string',
    },
  },
  publish_success_panel_opened_with_content: {
    evid: 469,
    fields: {
      is_first_publish: 'bool',
      message: 'string',
      type: 'string',
    },
  },
  publish_rc_view_your_site_click: {
    evid: 893,
    fields: {
      revision: 'numeric',
    },
  },
  publish_rc_goto_release_manager_click: {
    evid: 895,
    fields: {
      origin: 'string',
    },
  },
  publish_rc_success: {
    evid: 915,
    fields: {
      origin: 'string',
    },
  },
  publish_rc_override_confirmation: {
    evid: 1118,
    fields: {
      origin: 'string',
      action_name: 'string',
      do_not_show_again: 'bool',
      branchId: 'string',
    },
  },
  publish_rc_prepare_modal_confirmation: {
    evid: 1470,
    src: 38,
    fields: {
      panel_name: 'string',
      element_name: 'string',
      actionName: 'string',
      dont_show_this_again: 'bool',
    },
  },
  rc_deleted: {
    evid: 106,
    src: 105,
    fields: {
      revision: 'string',
    },
  },
  START_PUBLISH: {
    evid: 466,
    fields: {
      publishType: 'string',
    },
  },
  SUCCESS_PUBLISH: {
    evid: 467,
    fields: {
      publishType: 'string',
    },
  },
  Create_RC_Clicked: {
    evid: 894,
    fields: {
      revision: 'numeric',
    },
  },
} as const;
