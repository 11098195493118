export default {
  REMOVE_COMPONENT_SECOND_STEP_CLICK: {
    evid: 280,
    src: 38,
    endpoint: 'editor',
    fields: {
      app_id: 'string',
      app_site_id: 'string',
      instance_id: 'string',
      is_last_tpa: 'number',
      component_id: 'string',
      component_type: 'string',
      removal_method: 'string',
    },
  },
  OPEN_MARKET_CLICK: {
    evid: 164,
    src: 38,
    endpoint: 'editor',
    fields: {
      is_first: 'boolean',
      num_pending_apps: 'number',
      referral_info_category: 'string',
      referral_info: 'string',
    },
  },
  COMPONENT_REMOVED: {
    evid: 170,
    fields: {
      app_id: 'guid',
      app_site_id: 'guid',
      component_id: 'string',
      widget_id: 'string',
      component_type: 'string',
      instance_id: 'guid',
      is_last_tpa: 'bool',
      removal_method: 'string',
      origin: 'string',
      out_of_grid_previously: 'boolean',
    },
  },
} as const;
