export default {
  TRANSLATION_ERROR: {
    errorCode: 200,
    errorName: 'TRANSLATION_ERROR',
    desc: 'error translating text with parameters',
    severity: 'warning',
    params: {
      p1: 'key',
      p2: 'translated',
    },
  },
  NEW_ADD_PANEL: {
    GENERAL: {
      errorCode: 2600,
      errorName: 'NEW_ADD_PANEL_GENERAL',
      desc: 'Error happened in New Add Panel component',
      severity: 'error',
      params: {},
    },
    DATA_LOADING: {
      errorCode: 2601,
      errorName: 'NEW_ADD_PANEL_DATA_LOADING',
      desc: 'Error happened in New Add Panel API service while data loading',
      severity: 'error',
      params: {},
    },
    PANEL_DESIGN_LOADING: {
      errorCode: 2602,
      errorName: 'NEW_ADD_PANEL_DESIGN_DATA_LOADING',
      desc: 'Error happened in New Add Panel API service while Panel Design data loading',
      severity: 'error',
      params: {},
    },
  },
  MEDIA_PANEL: {
    PANEL_FAILED_TO_LOAD: {
      errorCode: 2500,
      errorName: 'MEDIA_PANEL_FAILED_TO_LOAD',
      desc: 'Panel is failed to load for some reason',
      severity: 'error',
      params: {
        p1: 'errorMessage',
      },
    },
    SECTION_FAILED_TO_LOAD: {
      errorCode: 2501,
      errorName: 'MEDIA_PANEL_SECTION_FAILED_TO_LOAD',
      desc: 'Section is failed to load for some reason',
      severity: 'error',
      params: {
        p1: 'sectionId',
        p2: 'errorMessage',
      },
    },
  },
} as const;
