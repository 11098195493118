._container_7emtn_1 {
  background: white;
  opacity: 0.35;
  pointer-events: none;
  overflow-y: scroll;
}

._container_7emtn_1:hover {
  opacity: 1;
  pointer-events: auto;
}

 ._list_7emtn_13 {
   display: flex;
   flex-direction: column;
   padding: 0;
   margin: 0;
   list-style: none;
 }

._list_7emtn_13 ._listItem_7emtn_21 {
  display: flex;
  justify-content: space-between;
}

._list_7emtn_13 ._listItem_7emtn_21:hover {
  background: #eee;
  color: blue;
}

._list_7emtn_13 ._listItem_7emtn_21 input[type="checkbox"] {
  pointer-events: all;
}
