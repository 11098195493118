import experiment from 'experiment';

export const isAdvancedMenuExperimentOpen =
  experiment.isOpen('se_advancedMenu');

export const isAdvancedMenuOpen = () =>
  (window as any).___advanced_menus_open___ ||
  (isAdvancedMenuExperimentOpen && !(window as any).___custom_menus_open___);

export const isAdvancedMenusMigrationOpen = () =>
  experiment.isOpen('se_advancedMenusMigration');

export const isCustomMenusEnabled = () => {
  if (experiment.isOpen('se_revertCustomMenus') || isAdvancedMenuOpen()) {
    return false;
  }

  return !!(
    experiment.isOpen('se_customMenus') ||
    experiment.isOpen('se_updatedCustomMenus') ||
    experiment.isOpen('se_customMenus_newPagesPanel') ||
    (window as any).___custom_menus_open___
  );
};

export const isMainMenuFlowEnabled = () => {
  if (!isCustomMenusEnabled() || isAdvancedMenuOpen()) {
    return false;
  }

  return (
    experiment.isOpen('se_updatedCustomMenus') ||
    experiment.isOpen('se_customMenus_newPagesPanel')
  );
};

export const isNewPagesPanelEnabled = () =>
  experiment.isOpen('se_customMenus_newPagesPanel') && !isAdvancedMenuOpen();

export const isCustomOrAdvancedMenuEnabled = () =>
  isCustomMenusEnabled() || isAdvancedMenuOpen();
