export default {
  help_home_panel_open: {
    evid: 1089,
    src: 38,
    fields: {
      origin: 'string',
      visible_sections: 'string',
      loading_time: 'numeric',
    },
  },
  help_home_icon_hover: {
    evid: 1090,
    src: 38,
    fields: {},
  },
  help_home_article_click: {
    evid: 1092,
    src: 38,
    fields: {
      article_id: 'guid',
    },
  },
  help_home_help_center_click: {
    evid: 1093,
    src: 38,
    fields: {},
  },
  help_home_video_click: {
    evid: 1094,
    src: 38,
    fields: {
      video_id: 'string',
    },
  },
  help_home_video_scroll_click: {
    evid: 1095,
    src: 38,
    fields: {},
  },
  help_home_tour_click: {
    evid: 1096,
    src: 38,
    fields: {},
  },
  help_home_search_click: {
    evid: 1097,
    src: 38,
    fields: {},
  },
};
