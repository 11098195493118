const getPublishViewMode = (
  isMobileEditor: boolean,
  isInPreviewMode: boolean,
): string => {
  if (isMobileEditor) {
    return isInPreviewMode ? 'mobile_editor_preview' : 'mobile_editor';
  }
  return isInPreviewMode ? 'editor_preview' : 'editor';
};

export { getPublishViewMode };
