import type { ListContentItem, MediaItem } from '@wix/adi-content-api';
import type { ExtraContentItem, ProviderContent } from '../types';
import { ContentKind } from '../consts';
import { isListResult } from './caasUtils';

export const filterUnsupportedMedia = (media: MediaItem[]) => {
  return media?.filter(
    (media) => media.mediaType === 'video' || media.mediaType === 'image',
  );
};

const createContentObject = (
  item: ExtraContentItem,
  contentKind: ContentKind,
): ProviderContent => {
  item.media = filterUnsupportedMedia(item.media ?? []);
  item.extraMedia = filterUnsupportedMedia(item.extraMedia ?? []);
  if (contentKind === ContentKind.MEDIA) {
    return {
      media: item.media ?? [],
      extraMedia: item.extraMedia ?? [],
    };
  }
  if (contentKind === ContentKind.TEXT) {
    return {
      title: item.title ?? '',
      subTitle: item.subTitle ?? '',
      paragraph: item.longText ?? '',
    };
  }
  if (contentKind !== ContentKind.ALL) {
    return {};
  }
  return {
    title: item.title ?? '',
    subTitle: item.subTitle ?? '',
    paragraph: item.longText ?? '',
    media: item.media ?? [],
    extraMedia: item.extraMedia ?? [],
  };
};

const createListContent = (
  contentItem: ListContentItem,
  contentKind: ContentKind,
): ProviderContent[] => {
  const headerItem = createContentObject(contentItem.header, contentKind);
  const listItems = contentItem.items.map(
    (item, listItemId): ProviderContent => {
      return {
        ...createContentObject(item, contentKind),
        listItemId,
      };
    },
  );
  return [headerItem, ...listItems];
};

export const caasContentAdapter = (
  caasContentItems: ListContentItem[] | ExtraContentItem[] | undefined,
  contentKind: ContentKind,
): ProviderContent[] => {
  if (!caasContentItems) {
    return [];
  }

  return caasContentItems.flatMap(
    (contentItem: ListContentItem | ExtraContentItem) => {
      if (isListResult(contentItem)) {
        return createListContent(contentItem, contentKind);
      }
      return createContentObject(contentItem, contentKind);
    },
  );
};
