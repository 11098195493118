import type {
  DocumentServicesObject,
  CompRef,
  CompStructure,
} from 'types/documentServices';

export const isSectionableByStructure = (
  compStructure: CompStructure,
): boolean => {
  const isFixedPosition = compStructure.layout.fixedPosition;

  return !isFixedPosition;
};

export const isSectionableComponent = (
  documentServices: DocumentServicesObject,
  compRef: CompRef,
) => {
  const isFixedPosition =
    documentServices.components.layout.isFixedPosition(compRef);

  return !isFixedPosition;
};
