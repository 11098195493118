import _ from 'lodash';
import * as bi from '../bi/bi';
import { t } from '#packages/i18n';

interface CreateTranslationUtilConfig {
  translate?: (key: string) => string;
}

const createTranslationUtil = (config: CreateTranslationUtilConfig = {}) => {
  const translateDep = config.translate || t;

  return {
    translate: translateDep,
    translateTemplate(
      key: string,
      params: object,
      reportOnError?: Function,
      useKeyAsTranslationValue?: boolean,
    ) {
      if (!useKeyAsTranslationValue) {
        return t(key, params);
      }

      let translated = key;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/every
      const isValid = _.every(params, function (val, objKey) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line you-dont-need-lodash-underscore/includes
        return _.includes(key, objKey);
      });

      if (isValid) {
        try {
          translated = _.template(translated)(params);
        } catch (err) {
          if (_.isFunction(reportOnError)) {
            reportOnError(bi.errors.TRANSLATION_ERROR, { key, translated });
          }
        }
      }

      return translated;
    },
  };
};

// allow tests to inject dependencies overrides, despite the use of globals
export default {
  ...createTranslationUtil(),
  create: createTranslationUtil,
};
