// @ts-nocheck
import * as utils from '@wix/santa-editor-utils';

export default {
  onPreviewReady(editorAPI) {
    if (utils.url.isTrue('isqa')) {
      editorAPI.registerSitePublishedCallbacks(function () {
        window.testApi.publishState.isPublishedInSession = true;
      });
    }
  },
};
