import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from '@wix/document-services-types';

export function getTopPositionByStageEntryIndex(
  editorAPI: EditorAPI,
  stageEntryIndex: number,
  pageRef: CompRef,
): number {
  const pageSections =
    editorAPI.sections.getPageSectionsWithLayoutSortedByStageOrder(pageRef);

  let topPosition = 0;

  for (let i = 0; i < stageEntryIndex; i++) {
    topPosition += pageSections[i].layout.height;
  }
  return topPosition;
}
