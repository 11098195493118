// @ts-nocheck
import * as utils from '@wix/santa-editor-utils';

export default {
  start() {
    window._kiq = window._kiq || [];
    window.setTimeout(function () {
      const existingScriptElm =
        window.document.getElementsByTagName('script')[0];
      const scriptElm = window.document.createElement('script');
      scriptElm.type = 'text/javascript';
      scriptElm.async = true;
      scriptElm.src = '//s3.amazonaws.com/ki.js/59166/dei.js';
      existingScriptElm.parentNode.insertBefore(scriptElm, existingScriptElm);
    }, 1);
    window._kiq.push([
      'identify',
      utils.editorModel.permissionsInfo.loggedInUserId,
    ]);
  },
};
