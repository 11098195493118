import constants from '#packages/constants';
import { objectToEntries } from '../object/object';
import {
  MAGNET_SNAP_TRESHOLD,
  getContainerMargins,
  getIsCloseEnough,
} from './snapToUtils';

import {
  type AlignmentLine,
  type RectangleDrawData,
  type SnapCandidate,
  type SnapData,
  type SnapDataStyles,
  type SnapTextLabel,
  type Margin,
  RectTypes,
} from './snapTo.types';
import type { EditorAPI } from '#packages/editorAPI';
import type { CompLayout, CompRef, Point } from 'types/documentServices';

interface StageConstraint {
  left: number;
  right: number;
}

const BORDER_WIDTH = 3;

const getRectangleStyle = (
  editorAPI: EditorAPI,
  rectType?: RectTypes,
  compRef?: CompRef,
) => {
  if (rectType === RectTypes.BorderMagnet) {
    return {
      fill: '#80b1ff',
      stroke: '#80b1ff',
    };
  }

  if (rectType === RectTypes.SnappedToBorder) {
    return {
      fill: '#116dff',
      stroke: '#116dff',
    };
  }

  if (rectType === RectTypes.MarginIndicatorsCompBorder) {
    return {
      stroke: '#116dff',
      fill: 'none',
    };
  }

  const isOnMasterPage =
    compRef &&
    (editorAPI.components.isShowOnAllPages(compRef) ||
      editorAPI.components.isShowOnSomePages(compRef));

  if (isOnMasterPage) {
    return {
      fill: 'none',
      stroke: '#fe620f',
    };
  }

  return {
    stroke: '#92fafe',
    fill: 'none',
  };
};

function getRectanglesDrawData(
  editorAPI: EditorAPI,
  compsPointersAndLayouts: SnapCandidate[],
  rectType?: RectTypes,
): RectangleDrawData[] {
  return compsPointersAndLayouts.map((compPointerAndLayout) => {
    const { x, y, width, height } = compPointerAndLayout.layout;
    return {
      x,
      y,
      width,
      height,
      style: getRectangleStyle(editorAPI, rectType, compPointerAndLayout.comp),
    };
  });
}

function getBorderSnapData(
  editorAPI: EditorAPI,
  layout: CompLayout,
  containerCompLayout: CompLayout,
) {
  let snapData: AlignmentLine[] = [];
  const containerMargins = getContainerMargins(layout, containerCompLayout);
  const isCloseEnough = getIsCloseEnough(
    containerMargins,
    MAGNET_SNAP_TRESHOLD,
  );
  const { top, right, left, bottom } = containerMargins;

  if (isCloseEnough.left) {
    const isSnappedToBorder = left === 0;
    const borderLayout = {
      width: isSnappedToBorder ? BORDER_WIDTH : left,
      height: layout.height,
      x: containerCompLayout.x,
      y: layout.y,
    };
    const border = getRectanglesDrawData(
      editorAPI,
      [{ layout: borderLayout, isDynamicLayout: false }],
      isSnappedToBorder ? RectTypes.SnappedToBorder : RectTypes.BorderMagnet,
    );

    snapData = snapData.concat([
      { type: 'alignmentLine', rectanglesDrawData: border },
    ]);
  }

  if (isCloseEnough.right) {
    const isSnappedToBorder = right === 0;
    const borderLayout = {
      width: isSnappedToBorder ? BORDER_WIDTH : Math.abs(right),
      height: layout.height,
      x: isSnappedToBorder
        ? layout.x + layout.width - BORDER_WIDTH
        : layout.x + layout.width,
      y: layout.y,
    };
    const border = getRectanglesDrawData(
      editorAPI,
      [{ layout: borderLayout, isDynamicLayout: false }],
      isSnappedToBorder ? RectTypes.SnappedToBorder : RectTypes.BorderMagnet,
    );

    snapData = snapData.concat([
      { type: 'alignmentLine', rectanglesDrawData: border },
    ]);
  }

  if (isCloseEnough.top) {
    const isSnappedToBorder = top === 0;
    let { width, x } = layout;

    if (isCloseEnough.left) {
      width = layout.x + layout.width - containerCompLayout.x;
      x = containerCompLayout.x;
    }

    if (isCloseEnough.right) {
      width = containerCompLayout.x + containerCompLayout.width - layout.x;
      x = layout.x;
    }

    const borderLayout = {
      width,
      height: isSnappedToBorder ? BORDER_WIDTH : top,
      x,
      y: containerCompLayout.y,
    };
    const border = getRectanglesDrawData(
      editorAPI,
      [{ layout: borderLayout, isDynamicLayout: false }],
      isSnappedToBorder ? RectTypes.SnappedToBorder : RectTypes.BorderMagnet,
    );
    snapData = snapData.concat([
      { type: 'alignmentLine', rectanglesDrawData: border },
    ]);
  }

  if (isCloseEnough.bottom) {
    const isSnappedToBorder = bottom === 0;
    let { width, x } = layout;

    if (isCloseEnough.left) {
      width = layout.x + layout.width - containerCompLayout.x;
      x = containerCompLayout.x;
    }

    if (isCloseEnough.right) {
      width = containerCompLayout.x + containerCompLayout.width - layout.x;
      x = layout.x;
    }

    const borderLayout = {
      width,
      height: isSnappedToBorder ? BORDER_WIDTH : Math.abs(bottom),
      x,
      y: isSnappedToBorder
        ? layout.y + layout.height - BORDER_WIDTH
        : layout.y + layout.height,
    };
    const border = getRectanglesDrawData(
      editorAPI,
      [{ layout: borderLayout, isDynamicLayout: false }],
      isSnappedToBorder ? RectTypes.SnappedToBorder : RectTypes.BorderMagnet,
    );

    snapData = snapData.concat([
      { type: 'alignmentLine', rectanglesDrawData: border },
    ]);
  }

  return snapData;
}

const getLine = (
  p1: Point,
  p2: Point,
  style?: SnapDataStyles,
): AlignmentLine => ({
  type: 'alignmentLine',
  line: [p1, p2],
  rectanglesDrawData: [],
  style,
});

const getMarginLabel = (value: number, position: Point): SnapTextLabel => ({
  type: 'textLabel',
  value,
  position,
});

const MARGIN_LABEL_MIN_SPACE = 20;

const getLeftElemMarginX = (
  leftCompLayout: CompLayout,
  margin: number,
  stageConstraint: StageConstraint,
) => {
  const shouldCenter = margin >= MARGIN_LABEL_MIN_SPACE;

  if (shouldCenter) return leftCompLayout.x - margin / 2;

  if (!stageConstraint)
    return leftCompLayout.x - margin - MARGIN_LABEL_MIN_SPACE / 2;

  if (
    leftCompLayout.x - margin - MARGIN_LABEL_MIN_SPACE >=
    stageConstraint.left
  )
    return leftCompLayout.x - margin - MARGIN_LABEL_MIN_SPACE / 2;

  return leftCompLayout.x + MARGIN_LABEL_MIN_SPACE / 2;
};

const getRightElemMarginX = (
  rightCompLayout: CompLayout,
  margin: number,
  stageConstraint: StageConstraint,
) => {
  const shouldCenter = margin >= MARGIN_LABEL_MIN_SPACE;

  if (shouldCenter)
    return rightCompLayout.x + rightCompLayout.width + margin / 2;

  if (!stageConstraint)
    return (
      rightCompLayout.x +
      rightCompLayout.width +
      margin +
      MARGIN_LABEL_MIN_SPACE / 2
    );

  if (
    rightCompLayout.x +
      rightCompLayout.width +
      margin +
      MARGIN_LABEL_MIN_SPACE <=
    stageConstraint.right
  )
    return (
      rightCompLayout.x +
      rightCompLayout.width +
      margin +
      MARGIN_LABEL_MIN_SPACE / 2
    );

  return rightCompLayout.x + rightCompLayout.width - MARGIN_LABEL_MIN_SPACE / 2;
};

const getBottomElemMarginY = (bottomCompLayout: CompLayout, margin: number) => {
  if (margin > MARGIN_LABEL_MIN_SPACE) {
    return bottomCompLayout.y + bottomCompLayout.height + margin / 2;
  }

  return (
    bottomCompLayout.y +
    bottomCompLayout.height +
    margin +
    MARGIN_LABEL_MIN_SPACE / 2
  );
};

const getTopElemMarginY = (topCompLayout: CompLayout, margin: number) => {
  if (margin > MARGIN_LABEL_MIN_SPACE) {
    return topCompLayout.y - margin / 2;
  }

  return topCompLayout.y - margin - MARGIN_LABEL_MIN_SPACE / 2;
};

const SIDE_GUTTER = 3; // px
const marginLineStyle = { stroke: '#a107ff' };

function getLeftMarginLines(
  compLayout: CompLayout,
  containerCompLayout: CompLayout,
  stageConstraint: StageConstraint,
): SnapData[] {
  const { x, y, height } = compLayout;
  const lineY = y + height / 2;
  const l1 = getLine(
    { x: containerCompLayout.x + 1, y: lineY },
    { x: x - 1, y: lineY },
    marginLineStyle,
  );
  const l2 = getLine(
    { x: containerCompLayout.x + 1, y: lineY - SIDE_GUTTER },
    { x: containerCompLayout.x + 1, y: lineY + SIDE_GUTTER },
    marginLineStyle,
  );
  const l3 = getLine(
    { x: x - 1, y: lineY - SIDE_GUTTER },
    { x: x - 1, y: lineY + SIDE_GUTTER },
    marginLineStyle,
  );
  const marginValue = Math.round(x - containerCompLayout.x);
  const marginLabel = getMarginLabel(marginValue, {
    x: getLeftElemMarginX(compLayout, marginValue, stageConstraint),
    y: lineY,
  });

  return [l1, l2, l3, marginLabel];
}

function getTopMarginLines(
  compLayout: CompLayout,
  containerCompLayout: CompLayout,
): SnapData[] {
  const { x, y, width } = compLayout;
  const lineX = x + width / 2;
  const l1 = getLine(
    { x: lineX, y: containerCompLayout.y + 1 },
    { x: lineX, y: y - 1 },
    marginLineStyle,
  );
  const l2 = getLine(
    {
      x: lineX - SIDE_GUTTER,
      y: containerCompLayout.y + 1,
    },
    {
      x: lineX + SIDE_GUTTER,
      y: containerCompLayout.y + 1,
    },
    marginLineStyle,
  );
  const l3 = getLine(
    { x: lineX - SIDE_GUTTER, y: y - 1 },
    { x: lineX + SIDE_GUTTER, y: y - 1 },
    marginLineStyle,
  );
  const marginValue = Math.round(y - containerCompLayout.y);
  const marginLabel = getMarginLabel(marginValue, {
    x: lineX,
    y: getTopElemMarginY(compLayout, marginValue),
  });

  return [l1, l2, l3, marginLabel];
}

function getRightMarginLines(
  compLayout: CompLayout,
  containerCompLayout: CompLayout,
  stageConstraint: StageConstraint,
): SnapData[] {
  const { x, y, height, width } = compLayout;
  const lineY = y + height / 2;
  const l1 = getLine(
    { x: x + width + 1, y: lineY },
    {
      x: containerCompLayout.x + containerCompLayout.width - 1,
      y: lineY,
    },
    marginLineStyle,
  );
  const l2 = getLine(
    {
      x: containerCompLayout.x + containerCompLayout.width - 1,
      y: lineY - SIDE_GUTTER,
    },
    {
      x: containerCompLayout.x + containerCompLayout.width - 1,
      y: lineY + SIDE_GUTTER,
    },
    marginLineStyle,
  );
  const l3 = getLine(
    { x: x + width + 1, y: lineY - SIDE_GUTTER },
    { x: x + width + 1, y: lineY + SIDE_GUTTER },
    marginLineStyle,
  );
  const marginValue = Math.round(
    containerCompLayout.x + containerCompLayout.width - (x + width),
  );
  const marginLabel = getMarginLabel(marginValue, {
    x: getRightElemMarginX(compLayout, marginValue, stageConstraint),
    y: lineY,
  });

  return [l1, l2, l3, marginLabel];
}

function getBottomMarginLines(
  compLayout: CompLayout,
  containerCompLayout: CompLayout,
): SnapData[] {
  const { x, y, height, width } = compLayout;
  const lineX = x + width / 2;
  const l1 = getLine(
    { x: lineX, y: y + height + 1 },
    {
      x: lineX,
      y: containerCompLayout.y + containerCompLayout.height - 1,
    },
    marginLineStyle,
  );
  const l2 = getLine(
    {
      x: lineX - SIDE_GUTTER,
      y: y + height + 1,
    },
    {
      x: lineX + SIDE_GUTTER,
      y: y + height + 1,
    },
    marginLineStyle,
  );
  const l3 = getLine(
    {
      x: lineX - SIDE_GUTTER,
      y: containerCompLayout.y + containerCompLayout.height - 1,
    },
    {
      x: lineX + SIDE_GUTTER,
      y: containerCompLayout.y + containerCompLayout.height - 1,
    },
    marginLineStyle,
  );
  const marginValue = Math.round(
    containerCompLayout.y + containerCompLayout.height - (y + height),
  );
  const marginLabel = getMarginLabel(marginValue, {
    x: lineX,
    y: getBottomElemMarginY(compLayout, marginValue),
  });

  return [l1, l2, l3, marginLabel];
}

const getMaxMarginIndicatorDistance = (containerWidth: number) =>
  Math.min(containerWidth * 0.2, 99);

const getAllowedMargins = (
  compLayout: CompLayout,
  containerLayout: CompLayout,
): Partial<Margin> | null => {
  const maxMarginDistance = getMaxMarginIndicatorDistance(
    containerLayout.width,
  );
  const margins = getContainerMargins(compLayout, containerLayout);
  const allowedMarginsEntries = objectToEntries(margins).filter(
    ([_, distance]) => distance <= maxMarginDistance && distance > 0,
  );

  return allowedMarginsEntries.length === 0
    ? null
    : Object.fromEntries(allowedMarginsEntries);
};

const buildMarginIndicatorsSnapData = (
  marginMap: Partial<Margin>,
  compLayout: CompLayout,
  containerLayout: CompLayout,
  stageConstraint: StageConstraint,
) => {
  let result: SnapData[] = [];

  if (marginMap.top) {
    result = result.concat(getTopMarginLines(compLayout, containerLayout));
  }

  if (marginMap.right) {
    result = result.concat(
      getRightMarginLines(compLayout, containerLayout, stageConstraint),
    );
  }

  if (marginMap.bottom) {
    result = result.concat(getBottomMarginLines(compLayout, containerLayout));
  }

  if (marginMap.left) {
    result = result.concat(
      getLeftMarginLines(compLayout, containerLayout, stageConstraint),
    );
  }

  return result;
};

const getSameDistanceMargins = (margin: Partial<Margin>) => {
  const sameDistanceMargins: Partial<Margin> = {};
  const marginEntries = objectToEntries(margin);
  const distanceMap = marginEntries.reduce<Record<string, (keyof Margin)[]>>(
    (acc, [direction, distance]) => {
      if (acc[distance]) {
        acc[distance].push(direction);
      } else {
        acc[distance] = [direction];
      }

      return acc;
    },
    {},
  );

  // eslint-disable-next-line guard-for-in
  for (const distance in distanceMap) {
    const directions = distanceMap[distance];
    if (directions.length > 1) {
      directions.forEach((direction) => {
        sameDistanceMargins[direction] = Number(distance);
      });
    }
  }

  return Object.keys(sameDistanceMargins).length === 0
    ? null
    : sameDistanceMargins;
};

const getMarginIndicatorsWithSameDistances = (
  selectedCompMargin: Partial<Margin>,
  compLayout: CompLayout,
  containerLayout: CompLayout,
  stageConsraintRect: StageConstraint,
) => {
  const sameDistanceMargins = getSameDistanceMargins(selectedCompMargin);

  if (!sameDistanceMargins) return [];

  const snapData = buildMarginIndicatorsSnapData(
    sameDistanceMargins,
    compLayout,
    containerLayout,
    stageConsraintRect,
  );

  return snapData;
};

const getMarginsSnapIndicators = (
  editorAPI: EditorAPI,
  compRef: CompRef,
  container: CompRef,
) => {
  const xAxisDirection = ['left', 'right'];
  const compTypesToWrapWithBorder = [
    constants.COMP_TYPES.MEDIA_PLAYER,
    constants.COMP_TYPES.TEXT,
  ];
  const selectedCompLayout =
    editorAPI.components.layout.getRelativeToScreen_rect(compRef);
  const containerLayout =
    editorAPI.components.layout.getRelativeToScreen_rect(container);
  const selectedCompAllowedMarginMap = getAllowedMargins(
    selectedCompLayout,
    containerLayout,
  );

  if (!selectedCompAllowedMarginMap) return [];

  const siblings = editorAPI.components
    .getChildren(container)
    .filter((comp) => !editorAPI.utils.isSameRef(comp, compRef));
  const stageConstraint = editorAPI.isMobileEditor()
    ? null
    : { left: editorAPI.site.getSiteX(), right: editorAPI.site.getWidth() };

  if (siblings.length === 0) {
    return getMarginIndicatorsWithSameDistances(
      selectedCompAllowedMarginMap,
      selectedCompLayout,
      containerLayout,
      stageConstraint,
    );
  }

  const xAxisDistances = [
    selectedCompAllowedMarginMap.left,
    selectedCompAllowedMarginMap.right,
  ].filter(Boolean);
  const yAxisDistances = [
    selectedCompAllowedMarginMap.top,
    selectedCompAllowedMarginMap.bottom,
  ].filter(Boolean);

  const sameAxisDistanceSiblingsMargins = siblings
    .map((sibling) => {
      const siblingLayout =
        editorAPI.components.layout.getRelativeToScreen_rect(sibling);
      const siblingMargin = getContainerMargins(siblingLayout, containerLayout);

      const sameAxisDistanceMarginEntries = objectToEntries(
        siblingMargin,
      ).filter(([direction, distance]) => {
        return xAxisDirection.includes(direction)
          ? xAxisDistances.includes(distance)
          : yAxisDistances.includes(distance);
      });

      const margin =
        sameAxisDistanceMarginEntries.length === 0
          ? null
          : (Object.fromEntries(
              sameAxisDistanceMarginEntries,
            ) as Partial<Margin>);

      return {
        margin,
        layout: siblingLayout,
        comp: sibling,
      };
    })
    .filter(({ margin }) => margin);

  if (sameAxisDistanceSiblingsMargins.length === 0) {
    return getMarginIndicatorsWithSameDistances(
      selectedCompAllowedMarginMap,
      selectedCompLayout,
      containerLayout,
      stageConstraint,
    );
  }

  const selectedCompMargins = objectToEntries(
    selectedCompAllowedMarginMap,
  ).reduce<{ layout: CompLayout; margin: Partial<Margin>; comp: CompRef }>(
    (acc, [direction, distance]) => {
      const hasAxisDirectionMatches = sameAxisDistanceSiblingsMargins.some(
        ({ margin }) =>
          xAxisDirection.includes(direction)
            ? margin?.left === distance || margin?.right === distance
            : margin?.top === distance || margin?.bottom === distance,
      );

      if (hasAxisDirectionMatches) {
        acc.margin[direction] = distance;
      }

      return acc;
    },
    { layout: selectedCompLayout, margin: {}, comp: compRef },
  );

  return [selectedCompMargins, ...sameAxisDistanceSiblingsMargins].flatMap(
    ({ margin, layout, comp }) => {
      const marginIndicator = buildMarginIndicatorsSnapData(
        margin,
        layout,
        containerLayout,
        stageConstraint,
      );

      const componentType = editorAPI.components.getType(comp);
      const shouldDrawCompBorder =
        !editorAPI.utils.isSameRef(compRef, comp) &&
        compTypesToWrapWithBorder.some((type) => type === componentType);

      if (shouldDrawCompBorder) {
        const border = getRectanglesDrawData(
          editorAPI,
          [{ layout, isDynamicLayout: false }],
          RectTypes.MarginIndicatorsCompBorder,
        );

        return [
          ...marginIndicator,
          ...[
            {
              type: 'alignmentLine',
              rectanglesDrawData: border,
            } as AlignmentLine,
          ],
        ];
      }

      return marginIndicator;
    },
  );
};

function getWidthPercentageLine(snapCandidate: SnapCandidate): AlignmentLine {
  const { x, y, height } = snapCandidate.layout;

  return {
    type: 'alignmentLine',
    line: [
      { x, y },
      { x, y: y + height },
    ],
    rectanglesDrawData: [],
    style: snapCandidate.style,
  };
}

export {
  getLine,
  getRectanglesDrawData,
  getBorderSnapData,
  getMarginsSnapIndicators,
  getWidthPercentageLine,
  getMaxMarginIndicatorDistance,
  getAllowedMargins,
};
