// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { EntryPoint, Shell, SlotKey } from 'repluggable';
import { ComponentSlot } from './componentSlot';
import { ScopeRefMapping } from './connect';
import {
  callApiFactory,
  type FunctionApiFactory,
  type PublicApiFactory,
} from './publicApi';
import type { Reducer, ReduxStore } from './store';
import type { ApiFactory, PublicSlotKey } from './types';
import { memo } from './utils';
const PrivateDataSymbol = Symbol('__private');

const IsGetterSymbol = Symbol('isGetter');

export const ShellDontUseSymbol = Symbol('shell_dont_use');

type GetterFactory<T> = (data: {
  entryName: string;
  propName: string;
}) => () => T;

function isGetterFactory(value: any): value is GetterFactory<unknown> {
  return value[IsGetterSymbol] === true;
}

function makeGetter<T>(factory: GetterFactory<T>) {
  (factory as AnyFixMe)[IsGetterSymbol] = true;
  return factory;
}

function replaceGettersSymbolsWithActualGetters(
  scope: BaseEntryScope,
  entryName: string,
) {
  for (const key of Object.getOwnPropertyNames(scope)) {
    const descriptor = Object.getOwnPropertyDescriptor(scope, key);
    const { value } = descriptor;
    if (descriptor.enumerable && value) {
      if (typeof value === 'function' && isGetterFactory(value)) {
        const getter = value({ entryName, propName: key });
        Object.defineProperty(scope, key, {
          get: getter,
          configurable: false,
        });
      } else if (typeof value === 'object') {
        replaceGettersSymbolsWithActualGetters(value, entryName);
      }
    }
  }
}

export abstract class BaseEntryScope {
  private [PrivateDataSymbol]: {
    dependencies: Array<SlotKey<unknown>>;
    slotKeys: Array<SlotKey<unknown>>;
    reducers: Array<{ reducerName: string; reducer: Reducer<any> }>;
  } = {
    dependencies: [],
    slotKeys: [],
    reducers: [],
  };

  private [ShellDontUseSymbol]: Shell;

  /**
   * Declare a dependency API
   */
  protected useDependency<T>(apiKey: SlotKey<T>): T {
    this[PrivateDataSymbol].dependencies.push(apiKey);
    return makeGetter(
      ({}) =>
        () =>
          this[ShellDontUseSymbol].getAPI(apiKey),
    ) as any;
  }

  /**
   * Declare a private API, that could be used inside the entry.
   * Here, you should declare "services", which you need to share across your package.
   */
  protected declareApi<T>(factory: ApiFactory<any, T>): T {
    //any to avoid circular types error
    return makeGetter(({}) => {
      let memoizedApiValue: AnyFixMe;
      return () => {
        if (!memoizedApiValue) {
          memoizedApiValue = factory(this);
        }
        return memoizedApiValue;
      };
    }) as any;
  }

  // /**
  //  * Declare a private API, that could be used inside the entry.
  //  * Here, you should declare "services", which you need to share across your package.
  //  */
  //  protected declareApi<T>(factory: ApiFactory<any, T>): T { //left it for future, mb will be needed
  //   return makeGetter(({ propName, entryName }) => {
  //     const apiKey: SlotKey<unknown> = {
  //       name: `${entryName}_${propName}ApiKey`,
  //     };
  //     this[PrivateDataSymbol].declaredApis.push(apiKey);
  //     this[PrivateDataSymbol].attachFns.push((scope) => {
  //       scope.shell.contributeAPI(apiKey, () => factory(scope));
  //     });
  //     return () => this.shell.getAPI(apiKey);
  //   }) as any;
  // }

  /**
   * Declare repluggable extension slot (shell.declareSlot).
   * Type is React.ComponentType<T>
   */
  protected declareComponentSlot<T = {}>(): ComponentSlot<T> {
    return makeGetter(({ propName, entryName }) => {
      const apiKey: SlotKey<any> = {
        name: `${entryName}_${propName}_${this[PrivateDataSymbol].slotKeys.length}_SlotKey`,
      };
      this[PrivateDataSymbol].slotKeys.push(apiKey);
      return () =>
        new ComponentSlot(
          this[ShellDontUseSymbol].getSlot(apiKey),
          this[ShellDontUseSymbol],
        );
    }) as any;
  }

  /**
   * Declare redux store
   * Returns "api" of that store with actions and selectors.
   */
  protected declareStore<S, T>(storeFactory: ReduxStore<S, T>): T {
    return makeGetter(({ propName }) => {
      const reducerName = propName;
      const store = storeFactory({ reducerName });

      this[PrivateDataSymbol].reducers.push({
        reducer: store.reducer,
        reducerName,
      });
      let memoizedStoreApi: AnyFixMe;

      return () => {
        if (!memoizedStoreApi) {
          memoizedStoreApi = store.apiFactory(this[ShellDontUseSymbol]);
        }
        return memoizedStoreApi;
      };
    }) as any;
  }

  contributeDeferred: ContributePublicApi<any> = (apiKey, factory) => {
    this[ShellDontUseSymbol].contributeAPI(apiKey, () =>
      callApiFactory(factory, this as any),
    );
  };
}

type ContributePublicApi<SC> = <T>(
  _apiKey: PublicSlotKey<T>,
  factory: PublicApiFactory<SC, T> | FunctionApiFactory<SC, T>,
) => void;

type DeclareApiFn = (apiKey: SlotKey<any>) => void;

interface EntryPointObject<SC extends BaseEntryScope> {
  Scope: { new (): SC };
  name: string;
  layer?: string;
  publicApi?: (args: {
    contributeApi: ContributePublicApi<SC>;
    declareApi: DeclareApiFn;
  }) => void;
  initialize?: (scope: SC) => void;
}

function throwIfDublicateStrings(strings: string[]) {
  const set = new Set<string>();
  for (const str of strings) {
    if (set.has(str)) {
      throw new Error(
        `dublicate apiKey: ${str}, please change propName in scope declaration`,
      );
    }
  }
  return strings;
}

function validateScope(scope: BaseEntryScope) {
  const privateData = scope[PrivateDataSymbol];
  throwIfDublicateStrings(privateData.slotKeys.map((api) => api.name));
  throwIfDublicateStrings(
    privateData.reducers.map((reducer) => reducer.reducerName),
  );
}

export function createEntryPoint<SC extends BaseEntryScope>(
  entrypointObject: EntryPointObject<SC>,
): () => EntryPoint {
  return () => {
    // to cleanup memo between tests
    const { Scope: ScopeFactory } = entrypointObject;

    const getScope = memo(() => {
      //because should be lazy
      const _scope = new ScopeFactory();
      replaceGettersSymbolsWithActualGetters(_scope, entrypointObject.name);
      validateScope(_scope);

      return _scope;
    });

    const getPublicApiData = memo(() => {
      //because should be lazy
      const declaredApis: Array<SlotKey<unknown>> = [];
      const attachFns: Array<(scope: BaseEntryScope) => void> = [];

      const declareApi: DeclareApiFn = (apiKey) => {
        if (!apiKey.public) {
          throw new Error('Api key should have "public: true"');
        }
        declaredApis.push(apiKey);
      };

      const contributePublicApi: ContributePublicApi<SC> = (
        apiKey,
        factory,
      ) => {
        declareApi(apiKey);
        attachFns.push((scope) => {
          scope[ShellDontUseSymbol].contributeAPI(apiKey, () =>
            callApiFactory(factory, scope as any),
          );
        });
      };

      entrypointObject.publicApi?.({
        contributeApi: contributePublicApi,
        declareApi,
      });

      return { declaredApis, attachFns };
    });

    const EntryPoint: EntryPoint = {
      name: entrypointObject.name,
      layer: entrypointObject.layer,
      declareAPIs: () => {
        return [...getPublicApiData().declaredApis];
      },
      getDependencyAPIs: () => getScope()[PrivateDataSymbol].dependencies,
      attach: (shell) => {
        const scope = getScope();
        scope[ShellDontUseSymbol] = shell;
        const { reducers, slotKeys } = scope[PrivateDataSymbol];

        if (reducers.length) {
          shell.contributeState(() => {
            const reducersObj = Object.fromEntries(
              reducers.map(({ reducer, reducerName }) => [
                reducerName,
                reducer as any,
              ]),
            );
            return reducersObj;
          });
        }

        slotKeys.forEach((sl) => {
          shell.declareSlot(sl);
        });
        ScopeRefMapping.set(ScopeFactory, scope);

        for (const fn of getPublicApiData().attachFns) {
          fn(scope);
        }
      },
      extend: () => {
        const scope = getScope();
        return entrypointObject.initialize?.(scope);
      },
    };

    return EntryPoint;
  };
}
