import _ from 'lodash';
import * as youtubeVideoUtils from './youtubeVideoUtils';
import * as vimeoVideoUtils from './vimeoVideoUtils';
import * as dailymotionVideoUtils from './dailymotionVideoUtils';
import * as facebookVideoUtils from './facebookVideoUtils';

// TODO: unite with santa viewer

const VIDEO_UTILS_PROVIDERS: AnyFixMe = {
  YOUTUBE: youtubeVideoUtils,
  VIMEO: vimeoVideoUtils,
  DAILYMOTION: dailymotionVideoUtils,
  FACEBOOK: facebookVideoUtils,
};

function getMethodForType(videoType: AnyFixMe, methodName: AnyFixMe) {
  return (
    VIDEO_UTILS_PROVIDERS[videoType.toUpperCase()] &&
    VIDEO_UTILS_PROVIDERS[videoType][methodName]
  );
}

/**
 * @param {{videoType: string, videoId: string}} videoData
 * @returns {string}
 */
function getVideoUrl(videoData: AnyFixMe) {
  if (videoData?.videoType && videoData.videoId) {
    const videoUrlGetter = getMethodForType(videoData.videoType, 'getVideoUrl');
    return (videoUrlGetter && videoUrlGetter(videoData.videoId)) || '';
  }

  return '';
}

/**
 * @param {string} videoUrl
 * @returns {videoData}
 */
function getVideoDataFromUrl(videoUrl: AnyFixMe): AnyFixMe {
  let videoData;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
  _.forEach(VIDEO_UTILS_PROVIDERS, function (methods, videoType) {
    const videoIdGetter = getMethodForType(videoType, 'getVideoIdFromUrl');
    const videoId = videoIdGetter && videoIdGetter(videoUrl);
    if (!_.isEmpty(videoId)) {
      videoData = {
        videoType,
        videoId,
      };
      return false; // break forEach
    }
  });
  return videoData;
}

/**
 * An async method that will call a success callback if the given video URL is valid, or the error callback if it is not
 * @param videoUrl
 * @param onSuccess will be called with the url as its only attribute
 * @param onError will be called in case of an error
 */
function verifyVideoUrl(
  videoUrl: AnyFixMe,
  onSuccess: AnyFixMe,
  onError: AnyFixMe,
) {
  const videoData = getVideoDataFromUrl(videoUrl);
  if (videoData?.videoType && videoData.videoId) {
    const urlVerifier = getMethodForType(videoData.videoType, 'verifyVideoUrl');
    if (urlVerifier) {
      urlVerifier(videoUrl, onSuccess, onError);
    } else {
      onError();
    }
  } else {
    onError();
  }
}

const videoTypes = {
  YOUTUBE: 'YOUTUBE',
  VIMEO: 'VIMEO',
  DAILYMOTION: 'DAILYMOTION',
  FACEBOOK: 'FACEBOOK',
};

export { verifyVideoUrl, getVideoUrl, getVideoDataFromUrl, videoTypes };
