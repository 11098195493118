export default {
  PANEL_OPENED: {
    evid: 309,
    src: 38,
    fields: {
      panel_name: 'string',
      component_type: 'string',
      origin: 'string',
      component_id: 'string',
      element_name: 'string',
      panelProps: 'string',
    },
  },
  PANEL_CLOSED: {
    evid: 345,
    src: 38,
    fields: {
      panel_name: 'string',
      origin: 'string',
      component_type: 'string',
      component_id: 'string',
      element_name: 'string',
    },
  },
  PANEL_CLICK: {
    evid: 949,
    src: 38,
    fields: {
      action_name: 'string',
      component_id: 'string',
      component_type: 'string',
      esi: 'guid',
      panel_name: 'string',
      site_id: 'guid',
    },
  },
  login_related_error_message_show: {
    evid: 1149,
    src: 38,
    fields: {},
  },
  login_releted_error_popup_click: {
    evid: 1136,
    src: 38,
    fields: {
      ctaName: 'string',
      actionsType: 'string',
      action: 'string',
    },
  },
  CLICK_OUTSIDE_PANEL: {
    evid: 1372,
    src: 38,
    fields: {
      panel_name: 'string',
      origin: 'string',
    },
  },
} as const;
