import constants from '#packages/constants';

import type { EditorAPI } from '#packages/editorAPI';
import type { CompRef } from 'types/documentServices';

function isStyleOnlyModeEnabled(
  editorAPI: EditorAPI,
  compRefs: CompRef[] | CompRef,
) {
  const isStyleOnlyMode = (compRef: CompRef) =>
    editorAPI.components.is.getDataEditOptions(compRef) ===
    constants.META_DATA.DATA_EDIT_OPTIONS.TEXT.STYLE_ONLY;
  if (Array.isArray(compRefs)) {
    return compRefs.some((compRef) => isStyleOnlyMode(compRef));
  }
  return isStyleOnlyMode(compRefs);
}

export { isStyleOnlyModeEnabled };
