const outerClassName = 'scrollBarWidthMeasurerOuter';
const innerClassName = 'scrollBarWidthMeasurerInner';
const width = 100;

let scrollBarWidthMeasurerAdded = false;

export const getScrollbarWidth = () => {
  if (!scrollBarWidthMeasurerAdded) {
    const outer = document.createElement('div');
    outer.classList.add(outerClassName);
    outer.style.visibility = 'hidden';
    outer.style.width = `${width}px`;
    outer.style.height = '0px';
    outer.style.overflow = 'scroll';
    document.body.appendChild(outer);

    const inner = document.createElement('div');
    inner.style.width = `${width}%`;
    inner.classList.add(innerClassName);
    outer.appendChild(inner);

    scrollBarWidthMeasurerAdded = true;
  }

  const inner = document.querySelector(`.${innerClassName}`);
  return inner ? width - inner.clientWidth : 0;
};

export const waitForImagesToLoad = (container: Element) => {
  const imageLoadPromises = Array.from(
    container.querySelectorAll('img'),
    (image) => {
      return new Promise<void>((resolve) => {
        if (image.complete) {
          resolve();
          return;
        }

        image.addEventListener('load', resolve as () => void, { once: true });
      });
    },
  );

  return Promise.all(imageLoadPromises);
};
