import constants from '#packages/constants';
import type { DSRead, CompRef } from 'types/documentServices';

export function isPageComponentByType(
  dsRead: DSRead,
  compType: string,
): compType is typeof constants.COMP_TYPES.PAGE {
  return compType === constants.COMP_TYPES.PAGE;
}

export function isRefComponentByType(
  dsRead: DSRead,
  compType: string,
): compType is typeof constants.COMP_TYPES.REF_COMPONENT {
  return compType === constants.COMP_TYPES.REF_COMPONENT;
}

export function isAppWidgetByType(
  dsRead: DSRead,
  compType: string,
): compType is typeof constants.COMP_TYPES.APP_WIDGET {
  return compType === constants.COMP_TYPES.APP_WIDGET;
}

export function isRepeaterByType(
  dsRead: DSRead,
  compType: string,
): compType is typeof constants.COMP_TYPES.REPEATER {
  return compType === constants.COMP_TYPES.REPEATER;
}

export function isGhostRefComponentByType(
  dsRead: DSRead,
  compType: string,
): compType is typeof constants.GHOST_REF_COMP {
  return compType === constants.GHOST_REF_COMP;
}

export function isStripColumnContainerByType(
  dsRead: DSRead,
  compType: string,
): compType is typeof constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER {
  return compType === constants.COMP_TYPES.STRIP_COLUMNS_CONTAINER;
}

export function isPopupContainerByType(
  dsRead: DSRead,
  compType: string,
): compType is typeof constants.COMP_TYPES.POPUP_CONTAINER {
  return compType === constants.COMP_TYPES.POPUP_CONTAINER;
}

function getComponentType(dsRead: DSRead, compRef: CompRef) {
  return dsRead.components.is.exist(compRef)
    ? dsRead.components.getType(compRef)
    : undefined;
}

export function isPageComponent(dsRead: DSRead, compRef: CompRef): boolean {
  return isPageComponentByType(dsRead, getComponentType(dsRead, compRef));
}

export function isRefComponent(dsRead: DSRead, compRef: CompRef): boolean {
  return isRefComponentByType(dsRead, getComponentType(dsRead, compRef));
}

export function isAppWidget(dsRead: DSRead, compRef: CompRef): boolean {
  return isAppWidgetByType(dsRead, getComponentType(dsRead, compRef));
}

export function isRepeater(dsRead: DSRead, compRef: CompRef): boolean {
  return isRepeaterByType(dsRead, getComponentType(dsRead, compRef));
}

export function isGhostRefComponent(dsRead: DSRead, compRef: CompRef): boolean {
  return isGhostRefComponentByType(dsRead, getComponentType(dsRead, compRef));
}

export function isStripColumnContainer(
  dsRead: DSRead,
  compRef: CompRef,
): boolean {
  return isStripColumnContainerByType(
    dsRead,
    getComponentType(dsRead, compRef),
  );
}

export function isPopupContainer(dsRead: DSRead, compRef: CompRef): boolean {
  return isPopupContainerByType(dsRead, getComponentType(dsRead, compRef));
}
