export default {
  FONT_EVENTS_NAMES: {
    LIST_CHANGED: 'LIST_CHANGED',
    QUEUE_CHANGED: 'QUEUE_CHANGED',
    FILE_CHANGED: 'FILE_CHANGED',
  },
  FONT_STATUS: {
    WAITING: 'waiting',
    SUCCEEDED: 'succeeded',
    FAILED: 'failed',
    FINISHED: 'finished',
    PROCESSING: 'processing',
    UPLOADING: 'uploading',
  },
  FONT_STATUS_MSG: {
    waiting: 'Upload_Fonts_Status_Awaiting_Upload',
    uploading: 'Upload_Fonts_Status_Awaiting_Upload',
    processing: 'Upload_Fonts_Status_Uploading',
    finished: 'Upload_Fonts_Status_Upload_Successful',
  },
  WARNING_FONT_UPLOAD_SIZE: 4000000,

  FONT_ERROR: {
    '-7751': {
      bi_msg: 'File Format warning',
      translate_msg: 'Upload_Fonts_Errors_File_Format',
    },
    '999999': {
      bi_msg: 'File Size',
      translate_msg: 'Upload_Fonts_Errors_File_Size',
    },
    '999998': {
      bi_msg: 'Folder Upload',
      translate_msg: 'Upload_Fonts_Errors_Folder',
    },
    unknown: {
      bi_msg: 'Failed',
      translate_msg: 'Upload_Fonts_Errors_Upload_Failed',
    },
  },

  ORIGINAL_FONT_PREFIX: 'orig_',
  FONTS_MEDIA_TYPE: 'ufont',
  CONVERTED_EXTENTIONS: ['ttf', 'woff', 'woff2'],
  ACCEPTABLE_EXTENTIONS: ['ttf', 'woff', 'woff2', 'otf'],
  CJK_LANGUAGES: ['ko', 'ja', 'zh'],
  CJK_COUNTRIES: ['JPN', 'PRK', 'KOR', 'TWN', 'CHN', 'HKG', 'SGP'],
  MAX_FILE_SIZE: 1500000,
};
