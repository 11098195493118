import { TEMP_USER_DATA } from '@wix/editor-content-provider';
import { injectPresetStructure } from './injectors';

import type { UserData, ProviderContent } from '@wix/editor-content-provider';
import type { CompStructure } from '@wix/document-services-types';
import type { MediaItem } from '@wix/adi-content-api';

export class ContentInjector {
  private _userData: UserData = TEMP_USER_DATA;

  setUserData(userData: UserData) {
    this._userData = userData;
  }

  injectPresetStructure(
    presetStructure: CompStructure,
    content: ProviderContent[],
    usedMediaGlobal: MediaItem[],
    isDebugMode?: boolean,
  ): CompStructure {
    return injectPresetStructure(
      presetStructure,
      content,
      this._userData,
      usedMediaGlobal,
      isDebugMode,
    );
  }
}
