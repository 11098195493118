// @ts-nocheck

/**
 * @param  {string} splitPosition
 * @param  {string} collapseDirection
 * @return {object} splitterState
 */
function getInitialState(splitPosition, collapseDirection) {
  return {
    splitPosition: splitPosition || '50%',
    collapsed: collapseDirection || false,
  };
}

/**
 * @param  {object} splitterState
 * @return {string} splitt position
 */
function getSplitPosition(splitterState) {
  switch (splitterState.collapsed) {
    case 'top':
    case 'left':
      return '0';
    case 'bottom':
    case 'right':
      return '100%';
    default:
      return splitterState.splitPosition;
  }
}

/**
 * @param  {object} splitterState
 * @param  {string} direction
 * @return {object} new state
 */
function collapse(splitterState, direction) {
  return { ...splitterState, collapsed: direction };
}

/**
 * revert to previous non-collapsed pane sizes. if direction is given, will only apply if currently collapsed to the given direction.
 * @param {object} splitterState
 * @param {string} [direction]
 * @return {object} new state
 */
function unCollapse(splitterState, direction) {
  if (!direction || direction === splitterState.collapsed) {
    return { ...splitterState, collapsed: false };
  }
  return splitterState;
}

/**
 * toggle collapse to the given direction.
 * @param {object} splitterState
 * @param {string} direction
 * @return {object} new state
 */
function toggleCollapse(splitterState, direction) {
  if (!direction) {
    return splitterState;
  }
  return splitterState.collapsed === direction
    ? unCollapse(splitterState, direction)
    : collapse(splitterState, direction);
}

/**
 * @param {object} splitterState
 * @param {string} direction
 * @returns {boolean}
 */
function isCollapsed(splitterState, direction) {
  return direction
    ? splitterState.collapsed === direction
    : splitterState.collapsed;
}

function setSplitPosition(splitterState, newSplitPosition) {
  return {
    ...splitterState,
    splitPosition: newSplitPosition,
    collapsed: false,
  };
}

export {
  getInitialState,
  getSplitPosition,
  setSplitPosition,
  collapse,
  unCollapse,
  toggleCollapse,
  isCollapsed,
};
