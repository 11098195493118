// @ts-nocheck

export default {
  reloadPageWithoutQuestion() {
    window.onbeforeunload = null;
    window.location.reload(true);
  },
  replaceState() {
    if (window.history?.replaceState) {
      window.history.replaceState.apply(window.history, arguments);
    } else {
      console.error('window.history is not supported in this OLD browser!');
    }
  },
};
