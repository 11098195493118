import _ from 'lodash';
import events from './events';
import errors from './errors';

import {
  biLogger,
  biLoggerLegacy,
  biLoggerUpdateDefaults,
} from './loggers/biLogger';

const registerEvents = _.noop;

const getDateInStringFormat = () => {
  const date = new Date();
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString();
  const day = date.getDate().toString();
  const hour = date.getHours().toString();
  const minutes = date.getMinutes().toString();
  const seconds = date.getSeconds().toString();

  return `${year}-${month}-${day}T${hour}:${minutes}:${seconds}`;
};

export { getBILoggerDSParams } from './getBILoggerDSParams';

export {
  events,
  errors,
  registerEvents,
  biLogger,
  biLoggerLegacy,
  biLoggerUpdateDefaults,
  getDateInStringFormat,
};
