export * from "./wrapAPIFunctions";
export * from "./apiCallsDebugger/apiCallsDebugger";

// const debuggger = createApiCallsDebugger();
//
// wrapAPIFunctions(window.ds, {
//   wrapper: (originalFunction, path) => {
//     return function (this: any) {
//       debuggger.reportPathCall(path);
//
//       try {
//         return originalFunction.apply(this, arguments);
//       } catch (e) {
//         throw new Error('ds error' + e.toString());
//       }
//     };
//   }
// })
