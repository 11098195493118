import { LinkTypes, linkTypeValidators } from '../link/link';
import { isSectionsEnabled } from '../sections';
import { compIcon } from '#packages/util';

import type { Link } from 'types/documentServices';

type LinkType = Link['type'];

const LINK_SYMBOLS_MAP: Record<LinkType, (link: Link) => string> = {
  [LinkTypes.ANCHOR_LINK]: (link) => {
    if (isSectionsEnabled() && linkTypeValidators.isSectionLink(link)) {
      return compIcon.toWBU('Section');
    }

    return 'anchorPageType';
  },
  [LinkTypes.EXTERNAL_LINK]: () => 'urlPageType',
  [LinkTypes.EMAIL_LINK]: () => 'emailPageType',
  [LinkTypes.PHONE_LINK]: () => 'phonePageType',
  [LinkTypes.DOCUMENT_LINK]: () => 'documentPageType',
};

const DEFAULT_SYMBOL = 'linkPageType';

export const getLinkSymbolName = (link: Link): string => {
  return LINK_SYMBOLS_MAP[link?.type]?.(link) || DEFAULT_SYMBOL;
};
