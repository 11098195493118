// @ts-nocheck
import _ from 'lodash';
import * as utils from '@wix/santa-editor-utils';

const START_MARKER = ' start';
const FINISH_MARKER = ' finish';

// copied from window.performance.getEntriesByType(type) return type
interface PerformanceEntry {
  readonly duration: number;
  readonly entryType: string;
  readonly name: string;
  readonly startTime: number;
  toJSON(): any;
}

type PerformanceExtended = typeof window.performance & {
  getMark(name: string): PerformanceEntry;
  getMeasure(name: string): PerformanceEntry;
};

function getMockPerformanceApi(): PerformanceExtended {
  return {
    mark: _.noop,
    measure: _.noop,

    start: _.noop,
    startOnce: _.noop,
    finish: _.noop,

    time(name, code) {
      return code.apply(this, Array.prototype.slice.call(arguments, 2));
    },

    getMark: _.noop,
    getMeasure: _.noop,

    clearMarks: _.noop,
    clearMeasures: _.noop,

    now: _.now,
  };
}

function getPerformanceApi(): PerformanceExtended {
  let now;
  const { performance } = window;
  if (performance?.now) {
    now = performance.now.bind(performance);
  } else {
    const initialTimestamp = window.mainLoaded || 0;
    now = function () {
      return _.now() - initialTimestamp;
    };
  }

  let api: typeof window.performance;
  if (performance?.measure) {
    const getEntry = function (type, name) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/find
      return _.find(performance.getEntriesByType(type), { name });
    };

    api = {
      mark: performance.mark.bind(performance),
      measure(name: string, startMark?: string, endMark?: string) {
        try {
          performance.measure(name, startMark, endMark);
        } catch (e) {
          // Do nothing
        }
      },
      getMark: getEntry.bind(this, 'mark'),
      getMeasure: getEntry.bind(this, 'measure'),
      clearMarks: performance.clearMarks.bind(performance),
      clearMeasures: performance.clearMeasures.bind(performance),
    };
  } else {
    const marks = {
      domLoading: {
        name: 'domLoading',
        startTime: 0,
      },
    };
    const measures = {};
    const clear = function (map, name) {
      if (name) {
        delete map[name];
      }
    };

    api = {
      mark(name: string) {
        marks[name] = {
          name,
          startTime: now(),
        };
      },
      measure(name: string, startMark?: string, endMark?: string) {
        const s = api.getMark(startMark);
        const e = api.getMark(endMark);
        if (s && e) {
          const m = {
            name,
            startTime: s.startTime,
            duration: e.startTime - s.startTime,
          };
          if (!isNaN(m.duration)) {
            measures[name] = m;
          }
        }
      },
      getMark(name: string) {
        return marks[name];
      },
      getMeasure(name: string) {
        return measures[name];
      },
      clearMarks: clear.bind(this, marks),
      clearMeasures: clear.bind(this, measures),
    };
  }

  api.start = function (name) {
    api.mark(name + START_MARKER);
  };
  api.startOnce = function (name) {
    if (!api.getMark(name + START_MARKER)) {
      api.start(name);
    }
  };
  api.finish = function (name, report, attributes) {
    const nameStart = name + START_MARKER;
    if (api.getMark(nameStart)) {
      const nameFinish = name + FINISH_MARKER;
      api.mark(nameFinish);
      api.measure(name, nameStart, nameFinish, report, attributes);
      api.clearMarks(nameStart);
      api.clearMarks(nameFinish);
    }
  };
  api.time = function (name, code, report, attributes) {
    try {
      api.start(name);
      return code.apply(this, Array.prototype.slice.call(arguments, 4));
    } finally {
      api.finish(name, report, attributes);
    }
  };
  api.now = now;
  return api;
}

export default typeof window === 'undefined' ||
utils.url.isTrue('suppressperformance')
  ? getMockPerformanceApi()
  : getPerformanceApi();
