// @ts-nocheck
import _ from 'lodash';

export default {
  registerToComponentDidUpdate(listener) {
    this.componentDidUpdateListeners = this.componentDidUpdateListeners || [];
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/includes
    if (!_.includes(this.componentDidUpdateListeners, listener)) {
      this.componentDidUpdateListeners.push(listener);
    }
  },
  unregisterToComponentDidUpdate(listener) {
    this.componentDidUpdateListeners =
      _.without(this.componentDidUpdateListeners, listener) || [];
  },
  componentDidUpdate(prevProps, prevState) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(
      this.componentDidUpdateListeners,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line you-dont-need-lodash-underscore/bind
      _.bind(function (listener) {
        listener(prevProps, prevState, this.state);
      }, this),
    );
  },
};
