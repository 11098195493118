//@ts-nocheck
import _ from 'lodash';

export const timeoutsMixin = {
  componentWillMount() {
    this.timeouts = {};
  },
  componentWillUnmount() {
    //eslint-disable-next-line you-dont-need-lodash-underscore/for-each
    _.forEach(this.timeouts, (timerId, timerName) => {
      this.clearTimeoutNamed(timerName);
    });
  },
  setTimeoutNamed(timerName, callback, timeout) {
    if (this.timeouts.hasOwnProperty(timerName)) {
      this.clearTimeoutNamed(timerName);
    }
    this.timeouts[timerName] = setTimeout(() => {
      delete this.timeouts[timerName];
      callback();
    }, timeout);
  },
  setTimeout(callback, timeout) {
    this.setTimeoutNamed('default', callback, timeout);
  },
  clearTimeoutNamed(timerName) {
    if (this.timeouts[timerName]) {
      clearTimeout(this.timeouts[timerName]);
      delete this.timeouts[timerName];
    }
  },
  clearTimeout() {
    this.clearTimeoutNamed('default');
  },
};
