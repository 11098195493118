export default {
  titleKey(sectionKey: AnyFixMe) {
    return `${sectionKey}_tooltip_title`;
  },
  descriptionKey(sectionKey: AnyFixMe) {
    return `${sectionKey}_tooltip_description`;
  },
  thumbnailKey(sectionKey: AnyFixMe) {
    return `${sectionKey}_tooltip_thumbnail`;
  },
  videoIdKey(sectionKey: AnyFixMe) {
    return `${sectionKey}_tooltip_video_id`;
  },
};
