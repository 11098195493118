import _ from 'lodash';
import controlsToEvents from './bi/controlsToEvents';
import events from './bi/events';
import errors from './bi/errors';

const registerEvents = _.noop;

export {
  events,
  events as biEvents,
  errors,
  registerEvents,
  controlsToEvents as CONTROLS_TO_EVENTS,
};
