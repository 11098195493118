// @ts-nocheck
import _ from 'lodash';
import * as coreBi from '#packages/coreBi';

const { CONTROLS_TO_EVENTS } = coreBi;

function getEventDefinition(controlName) {
  return CONTROLS_TO_EVENTS[controlName];
}

function getParams(controlName, changeEvent) {
  // eslint-disable-next-line you-dont-need-lodash-underscore/keys
  const supportedParams = _(CONTROLS_TO_EVENTS[controlName].fields)
    .keys()
    .union(['value'])
    .value();

  return _(changeEvent)
    .pick(supportedParams)
    .mapValues(function (val) {
      return typeof val === 'boolean' ? val.toString() : val;
    })
    .value();
}

export { getEventDefinition, getParams };
