// @ts-nocheck
import _ from 'lodash';
import keyboardMaster from 'keyboardMaster';

// Add shared contexts names in the Editor here, if you think they could be extended.
const SHARED_CONTEXTS = {
  TOP_CONTEXT: 'all',
  EDITOR: 'EDITOR',
  BASE_EDITOR: 'BASE_EDITOR',
  INTERACTIONS: 'INTERACTIONS',
  COMPONENT_FOCUS_MODE: 'COMPONENT_FOCUS_MODE',
  PIN_MODE: 'PIN_MODE',
  IMAGE_CROP: 'IMAGE_CROP',
  ZOOM_MODE: 'ZOOM_MODE',
  PREVIEW: 'PREVIEW',
  TOP_BAR: 'TOP_BAR',
  MENU_BAR: 'MENU_BAR',
  PAGES_MENU_PANEL: 'PAGES_MENU_PANEL',
  ADD_PAGE_PANEL: 'ADD_PAGE_PANEL',
  ADD_SECTION_PANEL: 'ADD_SECTION_PANEL',
  AI_WRITER_PANEL: 'AI_WRITER_PANEL',
  SWITCH_LAYOUT_PANEL: 'SWITCH_LAYOUT_PANEL',
  MODAL_PANEL: 'MODAL_PANEL',
  AUTOMATIC: 'AUTOMATIC',
  RULERS: 'RULERS',
  DRAG: 'DRAG',
  ROTATE: 'ROTATE',
  RESIZE: 'RESIZE',
  LIGHTBOX_MODE: 'LIGHTBOX_MODE',
  MULTILINGUAL_MODE: 'MULTILINGUAL_MODE',
  MOBILE_EDITOR: 'MOBILE_EDITOR',
  QUICK_EDIT: 'QUICK_EDIT',
  WORKSPACE_MODE_LITE: 'WORKSPACE_MODE_LITE',
  DELETE_APP_MODAL: 'DELETE_APP_MODAL',
};

const SHARED_CONTEXTS_KEYBOARD_ACTIONS = {
  TOP_CONTEXT: {},
  EDITOR: {},
  PREVIEW: {},
  TOP_BAR: {},
};

let lastEnabledContext = null;
const DISABLED_CONTEXT = '!DISABLED!';
const DEFAULT_CONTEXT = SHARED_CONTEXTS.TOP_CONTEXT;

let currentContext = DEFAULT_CONTEXT;

const keyMaster = keyboardMaster.noConflict();

const specialKeys = {
  space: 'space',
  up: 'up',
  down: 'down',
  left: 'left',
  right: 'right',
  home: 'home',
  end: 'end',
  pageUp: 'pageup',
  pageDown: 'pagedown',
  del: 'del',
  esc: 'esc',
  enter: 'enter',
  backspace: 'backspace',
  tab: 'tab',
  alt: 'alt',
  option: 'option',
  shift: 'shift',
  ctrl: 'ctrl',
  command: 'command',
};

function filter(event) {
  const target = event.target || event.srcElement;
  const { tagName } = target;
  const isContenteditable = target.getAttribute('contenteditable');

  if (tagName === 'INPUT') {
    const isCtrlOrCmd = event.metaKey || event.ctrlKey;
    const isZorY = event.keyCode === 90 || event.keyCode === 89;

    // allow undo/redo in inputs
    if (isCtrlOrCmd && isZorY) {
      event.preventDefault();
      return true;
    }

    // filter all other keyboard shortcuts
    return false;
  }

  // ignore keypressed in any elements that support keyboard data input
  return !(tagName === 'SELECT' || tagName === 'TEXTAREA' || isContenteditable);
}

function disable() {
  if (areKeyboardShortcutsEnabled()) {
    lastEnabledContext = currentContext;
  }
  setCurrentContext(DISABLED_CONTEXT);
}

function enable() {
  if (!areKeyboardShortcutsEnabled()) {
    setContext(lastEnabledContext);
    lastEnabledContext = null;
  }
}

function areKeyboardShortcutsEnabled() {
  return getCurrentContext() !== DISABLED_CONTEXT;
}

function registerContext(context, shortcuts) {
  if (isValidContext(context) && shortcuts) {
    if (SHARED_CONTEXTS_KEYBOARD_ACTIONS[context]) {
      unregisterContext(context);
    }
    SHARED_CONTEXTS_KEYBOARD_ACTIONS[context] = shortcuts;
    _.forOwn(shortcuts, function (shortcutAction, shortCutCombo) {
      registerShortcut(shortCutCombo, context, shortcutAction);
    });
  }
}

function unregisterContext(context) {
  if (isValidContext(context)) {
    keyMaster.deleteScope(context);
    SHARED_CONTEXTS_KEYBOARD_ACTIONS[context] = {};
  }
}

function registerShortcut(shortcut, context, action) {
  // Checking isFunction for JIRA SE-14904 - Relic throwing error on this line.
  if (
    !_.isEmpty(shortcut) &&
    action &&
    isValidContext(context) &&
    _.isFunction(keyMaster)
  ) {
    keyMaster(shortcut, context, action);
  }
}

function isValidContext(context) {
  return !_.isEmpty(context);
}

function clearContext() {
  setCurrentContext(DEFAULT_CONTEXT);
}

function getCurrentContext() {
  return currentContext;
}

function setCurrentContext(contextName) {
  if (areKeyboardShortcutsEnabled()) {
    setContext(contextName);
  } else if (contextName !== DISABLED_CONTEXT) {
    lastEnabledContext = contextName; // update lastEnabledContext when current context is disabled
  }
}

function setContext(contextName) {
  currentContext = contextName;
  keyMaster.setScope(contextName);
}

function extendContext(contextNameToExtend, extension) {
  const extendedContext =
    SHARED_CONTEXTS_KEYBOARD_ACTIONS[contextNameToExtend] || {};
  return { ...extendedContext, ...extension };
}

keyMaster.filter = filter;

const isPressed = {
  command: () => keyMaster.command || keyMaster.control,
  alt: () => keyMaster.alt || keyMaster.option,
};

export {
  SHARED_CONTEXTS as CONTEXTS,
  disable,
  enable,
  areKeyboardShortcutsEnabled as isEnabled,
  specialKeys,
  extendContext,
  getCurrentContext as getContext,
  setCurrentContext as setContext,
  clearContext,
  registerContext,
  unregisterContext,
  isPressed,
};
