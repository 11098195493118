import { ContentKind, TEMP_USER_DATA, Providers } from './consts';
import { CaasProvider } from './caas/caasProvider';

import type {
  GetContentOptions,
  PageData,
  ProviderContent,
  UserData,
  IndustryProfileData,
  SuggestedKit,
} from './types';
import type { CompStructure } from '@wix/document-services-types';
import type { AppInstallOption } from '@wix/editor-platform-host-integration-apis';
import { BusinessTypeInfo } from './types';

export class ContentProvider {
  private _userData: UserData = TEMP_USER_DATA;
  private readonly contentProvider: CaasProvider; // can be other providers
  private readonly _provider: Providers;
  private initPromise: Promise<void> | null = null;

  constructor({
    provider,
    origin = 'contentProvider',
    locale,
    contentVersion,
  }: {
    provider: Providers;
    origin?: string;
    locale?: string;
    contentVersion?: string;
  }) {
    this._provider = provider;
    switch (this._provider) {
      case Providers.CAAS:
        this.contentProvider = new CaasProvider({
          origin,
          locale,
          contentVersion,
        });
        break;
      default:
        throw new Error('Provider not supported');
    }
  }

  async initContent(structureId?: string, industryId?: string): Promise<void> {
    this.initPromise = this.contentProvider.initContent(
      structureId,
      industryId,
    );
    await this.initPromise;
  }

  setUserData(userData: UserData) {
    this._userData = userData;
    this.contentProvider.setReplacements(this._userData); // TODO init even if there is no user data
  }

  async getContent(
    options: GetContentOptions,
    compStructure: CompStructure,
    contentKind: ContentKind,
  ): Promise<ProviderContent[]> {
    await this.initPromise;
    return this.contentProvider.getContent(options, compStructure, contentKind);
  }

  async getAdditionalPagesData(): Promise<PageData[]> {
    await this.initPromise;
    return this.contentProvider.getAdditionalPagesData();
  }

  async getHomepageData(
    appDefIds: string[],
    appDefIdsNotToPersonalize: string[],
  ): Promise<PageData> {
    await this.initPromise;
    return this.contentProvider.getHomepageData(
      appDefIds,
      appDefIdsNotToPersonalize,
    );
  }

  async getIndustryProfiles(): Promise<IndustryProfileData[]> {
    await this.initPromise;
    return this.contentProvider.getIndustryProfiles();
  }

  setProfileId(profileId: string) {
    this.contentProvider.setProfileId(profileId);
  }

  getCurrentProfile(): IndustryProfileData | null {
    return this.contentProvider.getCurrentProfile();
  }

  async getLayoutFamilyBlacklist(): Promise<string[]> {
    await this.initPromise;
    return this.contentProvider.getLayoutFamilyBlacklist();
  }

  async getSuggestedKits(): Promise<SuggestedKit[]> {
    await this.initPromise;
    return this.contentProvider.getSuggestedKits();
  }

  async getBusinessTypeByTerm(term: string): Promise<BusinessTypeInfo | null> {
    await this.initPromise;
    return this.contentProvider.getBusinessTypeByTerm(term);
  }

  getAppsInstallOptions(appDefIds: string[]): Record<string, AppInstallOption> {
    return this.contentProvider.getAppsInstallOptions(appDefIds);
  }
}
