import {
  getComponentChildrenOrScopedChildren,
  traverseMatchesCondition,
} from '../componentSelectors';
import type {
  DSRead,
  CompRef,
  ScopePointer,
  ScopeMetaData,
  ConnectionStageData,
  AppData,
  AppDataComponent,
} from 'types/documentServices';
import { isAppWidgetByType, isRefComponent } from '../componentTypeSelectors';

function isComponentScopeMetaDataReady(
  dsRead: DSRead,
  compRef: CompRef,
): boolean {
  const [scopeRef] = dsRead.components.scopes.getDefinedScopes(compRef);

  return scopeRef && dsRead.components.scopes.isLoaded(scopeRef, compRef.type);
}

function isComponentScopeChildrenReady(
  dsRead: DSRead,
  compRef: CompRef,
): boolean {
  const [scopeRef] = dsRead.components.scopes.getDefinedScopes(compRef);

  return scopeRef && dsRead.components.scopes.isLoaded(scopeRef, compRef.type);
}

export function getComponentDefinedScopes(
  dsRead: DSRead,
  compRef: CompRef,
): ScopePointer[] {
  return dsRead.components.scopes.getDefinedScopes(compRef);
}

export function getComponentDefinedScopesAndScopesList(
  dsRead: DSRead,
  compRef: CompRef,
): ScopePointer[] {
  const compDefinedScopes = getComponentDefinedScopes(dsRead, compRef);
  const compScopeRef =
    dsRead.components.scopes.extractScopeFromPointer(compRef);

  if (!compScopeRef) {
    return compDefinedScopes;
  }

  return compDefinedScopes.concat(
    compScopeRef,
    dsRead.components.scopes.getScopesList(compScopeRef),
  );
}

export function isComponentScoped(dsRead: DSRead, compRef: CompRef): boolean {
  return !!(
    compRef && dsRead.components.scopes.extractScopeFromPointer(compRef)
  );
}

export function getComponentScopeOwner(
  dsRead: DSRead,
  compRef: CompRef,
): CompRef {
  const scopeRef = dsRead.components.scopes.extractScopeFromPointer(compRef);
  return (
    scopeRef && dsRead.components.scopes.getScopeOwner(scopeRef, compRef.type)
  );
}

export function findComponentScopeOwner(
  dsRead: DSRead,
  compRef: CompRef,
  condition: (scopeOwnerRef: CompRef) => boolean,
): CompRef | null {
  return traverseMatchesCondition(
    compRef,
    (compRef) => getComponentScopeOwner(dsRead, compRef),
    condition,
  );
}

export function getComponentScopeOwnerAncestors(
  dsRead: DSRead,
  compRef: CompRef,
): CompRef[] {
  const scopeOwnerRef = getComponentScopeOwner(dsRead, compRef);
  return scopeOwnerRef ? dsRead.components.getAncestors(scopeOwnerRef) : [];
}

export function getComponentScopeChildren(
  dsRead: DSRead,
  compRef: CompRef,
): CompRef[] {
  if (
    !isRefComponent(dsRead, compRef) ||
    !isComponentScopeChildrenReady(dsRead, compRef)
  ) {
    return [];
  }

  return getComponentDefinedScopes(dsRead, compRef).map((scopeRef) =>
    dsRead.components.scopes.getRootComponent(scopeRef, compRef.type),
  );
}

export function hasComponentScopeChildren(
  dsRead: DSRead,
  compRef: CompRef,
): boolean {
  if (
    !isRefComponent(dsRead, compRef) ||
    !isComponentScopeChildrenReady(dsRead, compRef)
  ) {
    return false;
  }

  return getComponentDefinedScopes(dsRead, compRef).some(
    (scopeRef) =>
      !!dsRead.components.scopes.getRootComponent(scopeRef, compRef.type),
  );
}

function getRefComponentScopeMetaData(
  dsRead: DSRead,
  compRef: CompRef,
): ScopeMetaData {
  if (
    !isRefComponent(dsRead, compRef) ||
    !isComponentScopeMetaDataReady(dsRead, compRef)
  ) {
    return;
  }

  const [scopeRef] = dsRead.components.scopes.getDefinedScopes(compRef);

  try {
    return (
      scopeRef && dsRead.scopes.metaData.getMetaData(scopeRef, compRef.type)
    );
  } catch (error) {
    const errorMessage = `Failed to get scope meta data of the RefComponent: \n${JSON.stringify(
      compRef,
    )}`;

    if (
      error instanceof Error &&
      error.message.includes('Unable to get scope owner data')
    ) {
      // TODO: discuss with DS this error type
      // https://github.com/wix-private/document-management/blob/e5a1f2429e3746737ca21c891c4471cad559b31c/document-manager-extensions/src/extensions/scopesMetaData.ts#LL120C37-L120C37
      // eslint-disable-next-line no-console
      console.error(
        `${errorMessage}\n> SCOPES_METADATA_MISSING_SCOPE_OWNER_DATA`,
      );
      return;
    }

    throw new Error(errorMessage, { cause: error });
  }
}

export function getRefComponentRootChildType(
  dsRead: DSRead,
  compRef: CompRef,
): string | undefined {
  if (!isRefComponent(dsRead, compRef)) {
    return;
  }

  const metaData = getRefComponentScopeMetaData(dsRead, compRef);
  return metaData?.rootType;
}

export function getRefComponentStageData(
  dsRead: DSRead,
  compRef: CompRef,
): ConnectionStageData | undefined {
  if (!isRefComponent(dsRead, compRef)) {
    return;
  }

  const metaData = getRefComponentScopeMetaData(dsRead, compRef);
  return (
    metaData?.platform &&
    (dsRead.platform.controllers.getStageDataByStateAndType(
      metaData.platform.controllerStatePointer,
      metaData.platform.controllerType,
      metaData.platform.appDefinitionId,
    ) as ConnectionStageData)
  );
}

export function getRefComponentAppData(dsRead: DSRead, compRef: CompRef) {
  if (!isRefComponent(dsRead, compRef)) {
    return;
  }

  const scopeMetaData = getRefComponentScopeMetaData(dsRead, compRef);
  const appDefinitionId = scopeMetaData?.platform?.appDefinitionId;
  return (
    appDefinitionId &&
    dsRead.platform.getAppDataByApplicationId(appDefinitionId)
  );
}

export function getRefComponentAppDefinitionName(
  dsRead: DSRead,
  compRef: CompRef,
) {
  return getRefComponentAppData(dsRead, compRef)?.appDefinitionName;
}

export function getRefComponentDisplayName(
  dsRead: DSRead,
  compRef: CompRef,
): string | undefined {
  return getRefComponentStageData(dsRead, compRef)?.displayName;
}

export function getRefComponentDefaultWidgetName(
  dsRead: DSRead,
  compRef: CompRef,
): string | undefined {
  const { appDefinitionId, widgetId } = dsRead.components.data.get(compRef);

  const appComponents = (
    dsRead.platform.getInstalledEditorApps() as AppData[]
  ).find((app) => app.appDefinitionId === appDefinitionId).components;

  const currentWidget = appComponents.find(
    (widget: AppDataComponent) => widget.componentId === widgetId,
  );

  return currentWidget.name;
}

export function getRefComponentCodeNickname(
  dsRead: DSRead,
  compRef: CompRef,
): string | undefined {
  if (
    !isRefComponent(dsRead, compRef) ||
    !isAppWidgetByType(dsRead, getRefComponentRootChildType(dsRead, compRef))
  ) {
    return;
  }

  return getRefComponentStageData(dsRead, compRef)?.nickname;
}

export function getRefComponentRootChild(
  dsRead: DSRead,
  refComponent: CompRef,
) {
  if (!isRefComponent(dsRead, refComponent)) {
    return;
  }

  const childRef = getComponentChildrenOrScopedChildren(
    dsRead,
    refComponent,
  )[0];
  return childRef && dsRead.components.is.exist(childRef)
    ? childRef
    : undefined;
}
