export interface ValidationErrorMessage {
  message: string;
}

export interface ValidationErrorMessageWithData<AdditionalData = void>
  extends ValidationErrorMessage {
  additionalData?: AdditionalData;
}

export type ValidationResult = ValidationErrorMessage | null;
export type ValidationResultWithMessage<AdditionalData> =
  ValidationErrorMessageWithData<AdditionalData> | null;

export type ValidatorFn<ValueType, ErrorAdditionalData = void> = (
  value: ValueType,
) => ValidationResult | ValidationErrorMessageWithData<ErrorAdditionalData>;

export type ValidatorWithConfig<ValueType, Config, ErrorAdditionalData> = (
  config?: Config,
) => ValidatorFn<ValueType, ErrorAdditionalData>;
