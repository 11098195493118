import { CeType } from '@wix/adi-core-types';
import {
  BANDSINTOWN,
  INSTAGRAM_FEED,
  WIX_BLOG,
  WIX_BOOKINGS,
  WIX_EVENTS,
  WIX_FORMS,
  WIX_GROUPS,
  WIX_MUSIC,
  WIX_PRO_GALLERY,
  WIX_RESERVATIONS,
  WIX_STORES,
  WIX_VIDEO,
  WIX_NEW_STORES,
  PRICING_PLANS,
  WIX_PORTFOLIO,
  WIX_RESTAURANTS_MENUS,
  WIX_RESTAURANTS_ORDERS,
  WIX_RESTAURANTS_MENUS_NEW,
  WIX_RESTAURANTS_ORDERS_NEW,
  WIX_RESTAURANTS_TABLE_RESERVATIONS,
} from '@wix/app-definition-ids';

export const BLOG_AS_HOMEPAGE_STRUCTURE_IDS = [
  'b0a520919b99728bae200018',
  '60ebe220e0aacf0015cecf8c',
  '613efcf07b3e9c0016571b2a',
  '611507cbcb41440015253611',
  '6107f122eec48300158889ec',
  '6140a65e7d886100167a7fb5',
  '61a884000d569e0016fd1b6b',
  '61712509276d2b00161abefd',
  '6190c09ea9d7560016809730',
  '61c464e016d3210017c9950b',
];

export const EVENTS_AS_HOMEPAGE_STRUCTURE_IDS = [
  '77cb9f8b7e6e46e77384199b',
  '5ef99cac71ae2c001796e89f',
  '5ebd5133b989ad0017a18d9a',
  'd92c36de57e30d8ba567d7de',
  '86614cf07e4b4b958c17799e',
  'd0a23df9f1f222e74ee14792',
  '4115b4b3648e14a3d30fc868',
  '82e7dd0419f621c370d802cd',
  '6366bb29a125a48254d64d8f',
  '039e2da6322d72456c116493',
  '8f92fe0b3e5ac39b968ed30a',
  '9f963f4ae9923320f3d0df74',
  '66ab3cd9ab82187cba6d438a',
  '64019cca29a74c24d67ceb8e',
  'befcaf30ac3c975eff7c8018',
  '2634908fb29b65cc063198ae',
  '59922f2d4d214b6def6bdb1c',
  '3118118a1e2e5ef4539a51f3',
  '53ccd6d3ed28056652523bf8',
  '8ad09c40cd032d6f157c3061',
  '93762d8e11ff81ded43e0d01',
  'a1b4f8164a1a626a7eab7242',
  '2b2732a277096b81176a53e0',
  '2597ed3258e7803bf2df1d3a',
  'd24103ac38dad574c915c6d4',
  'c3315453c35066966a2a2b6e',
  '3edc74db8e5c9df30f410797',
  'ff34af892529b6245eee17ef',
  '1ab0758e82c7e024d6bc00fb',
  '2ccd9638ebbeffef7087609f',
  '96752dbe194b85d9648d5b49',
  '661faae4694aff347897a3c6',
  '7f46632f92b9f8f5c1afa658',
  '5bc45e059dd89e0014102a50',
];

const APPS_PRIORITY: string[] = [
  WIX_NEW_STORES,
  WIX_STORES,
  WIX_BOOKINGS,
  WIX_BLOG,
  WIX_FORMS,
  WIX_RESERVATIONS,
  INSTAGRAM_FEED,
  BANDSINTOWN,
  WIX_GROUPS,
  WIX_VIDEO,
  WIX_EVENTS,
  WIX_MUSIC,
  WIX_PRO_GALLERY,
];

export const APP_ID_TO_CE_TYPE: { [appId: string]: CeType[] } = {
  [WIX_NEW_STORES]: [
    CeType.Stores,
    CeType.StoresGridGalleryWidget,
    CeType.StoresProductSpecificWidget,
    CeType.StoresSliderGalleryWidget,
  ],
  [WIX_STORES]: [
    CeType.Stores,
    CeType.StoresGridGalleryWidget,
    CeType.StoresProductSpecificWidget,
    CeType.StoresSliderGalleryWidget,
  ],
  [WIX_BOOKINGS]: [
    CeType.Bookings,
    CeType.BookingsTimetable,
    CeType.BookingsWidget,
  ],
  [WIX_BLOG]: [
    CeType.Blog,
    CeType.BlogPostGallery,
    CeType.NewBlog,
    CeType.NewBlogCustomFeed,
    CeType.NewBlogRecentPosts,
  ],
  [WIX_PORTFOLIO]: [CeType.Portfolio_Widget],
  [INSTAGRAM_FEED]: [CeType.Instagram_Widget],
  [WIX_RESTAURANTS_MENUS]: [CeType.RestaurantsMenus],
  [WIX_RESTAURANTS_ORDERS]: [CeType.RestaurantsOrders],
  [WIX_RESERVATIONS]: [CeType.RestaurantsReservationsWidget],
  [WIX_RESTAURANTS_MENUS_NEW]: [CeType.RestaurantsMenus],
  [WIX_RESTAURANTS_ORDERS_NEW]: [CeType.RestaurantsOrders],
  [WIX_RESTAURANTS_TABLE_RESERVATIONS]: [CeType.RestaurantsReservationsWidget],
  [BANDSINTOWN]: [CeType.BandsInTownWidget],
  [WIX_VIDEO]: [CeType.Video, CeType.VideoWidget],
  [WIX_EVENTS]: [CeType.Event, CeType.EventsMultiple, CeType.EventsSingle],
  [WIX_MUSIC]: [CeType.MusicWidget],
  [PRICING_PLANS]: [CeType.Price],
};

type PartialRecord<K extends string | number | symbol, T> = { [P in K]?: T };

export const getCeTypeToAppDefIdsMap = (): PartialRecord<CeType, string[]> => {
  return Object.entries(APP_ID_TO_CE_TYPE).reduce(
    (acc, [appDefId, ceTypes]) => {
      ceTypes.forEach((ceType) => {
        if (!acc[ceType]) {
          acc[ceType] = [];
        }
        acc[ceType]!.push(appDefId);
      });
      return acc;
    },
    {} as PartialRecord<CeType, string[]>,
  );
};

interface AppsPriorityMap {
  [appDefId: string]: number;
}
export const APPS_PRIORITY_MAP: AppsPriorityMap = APPS_PRIORITY.reduce(
  (acc, val, index) => {
    acc[val] = index + 1;
    return acc;
  },
  {} as AppsPriorityMap,
);
