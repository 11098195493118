import {
  boxesLogic,
  ConversionUnits,
  unitsConverter,
} from '@wix/editor-utility-layout-transforms';
import type {
  BaseImageData,
  BoundingBox,
  CompMeasurement,
  ImageFace,
  Point,
  Rect,
} from './types';
import { ImageMediaItem } from '@wix/adi-content-api';
import {
  ALIGN_TYPE_BOTTOM,
  ALIGN_TYPE_CENTER,
  ALIGN_TYPE_LEFT,
  ALIGN_TYPE_RIGHT,
  ALIGN_TYPE_TOP,
  SmartFocalPointConfig,
} from './consts';
import { PointInPercents } from './types';

export const getFocalPointFromFaces = (image: any): PointInPercents | null => {
  const faces = image.file_input?.face || image.fileInput?.face || [];
  const validFaces = faces.filter(
    (face: any) => isValidFaceConfidence(face) && isValidFaceSize(face, image),
  );
  const facesMinHeight = getMinFacesHeight(validFaces);
  const bigEnoughFaces = validFaces.filter(
    (face: any) => face.height >= facesMinHeight,
  );
  const faceBoundingBoxes = bigEnoughFaces.map((face: any) =>
    boxesLogic.rectToClientRect(face),
  );

  if (faceBoundingBoxes.length === 0) {
    return null;
  }

  const facesSnug = measureSnug(faceBoundingBoxes);
  if (!isValidFacesSnug(facesSnug, image)) {
    return null;
  }
  const snugCenter = boxesLogic.getClientRectCenter(facesSnug) as Point;
  return convertPositionToFocalPoint(snugCenter, image);
};

const isValidFaceConfidence = (face: any): boolean =>
  !face.confidence ||
  face.confidence >= SmartFocalPointConfig.MinFaceConfidence;

const getMinFacesHeight = (faces: ImageFace[]): number => {
  const facesMaxHeight = Math.max(...faces.map((face) => face.height));
  return facesMaxHeight * SmartFocalPointConfig.MaxFacesHeightRatio;
};

const isValidFacesSnug = (
  facesSnug: BoundingBox,
  image: BaseImageData,
): boolean => {
  const facesArea = boxesLogic.calculateArea(facesSnug);
  const imageArea = boxesLogic.calculateArea(image);

  return facesArea <= imageArea * SmartFocalPointConfig.MaxFaceAreaCoverage;
};

const isValidFaceSize = (face: Rect, image: BaseImageData): boolean =>
  face.x >= 0 &&
  face.x + face.width <= image.width &&
  face.y >= 0 &&
  face.y + face.height <= image.height;

const measureSnug = (
  compMeasurements: CompMeasurement['boundingBox'][],
): CompMeasurement['boundingBox'] => {
  const left =
    Math.min.apply(
      Math,
      compMeasurements.map((comp) => comp.left),
    ) || 0;
  const top =
    Math.min.apply(
      Math,
      compMeasurements.map((comp) => comp.top),
    ) || 0;
  const right =
    Math.max.apply(
      Math,
      compMeasurements.map((comp) => comp.left + comp.width),
    ) || 0;
  const bottom =
    Math.max.apply(
      Math,
      compMeasurements.map((comp) => comp.top + comp.height),
    ) || 0;
  return { left, top, width: right - left, height: bottom - top };
};

const convertPositionToFocalPoint = (
  point: Point,
  containerSize: { width: number; height: number },
): any => {
  return convertFocalPoint(
    point,
    ConversionUnits.px,
    ConversionUnits.percentage,
    containerSize,
  );
};

const convertFocalPoint = (
  point: Point,
  sourceUnit: ConversionUnits,
  targetUnit: ConversionUnits,
  containerSize: { width: number; height: number },
): any => {
  return {
    x: unitsConverter.convert(point.x, sourceUnit, targetUnit, {
      containerSizeInPixels: containerSize.width,
    }),
    y: unitsConverter.convert(point.y, sourceUnit, targetUnit, {
      containerSizeInPixels: containerSize.height,
    }),
  };
};

const getAlignTypeFromFocalPoint = (point: PointInPercents): string => {
  let horizontalPosition, verticalPosition;
  const ONE_THIRD_PERCENTAGE = 100 / 3;

  if (point.x < ONE_THIRD_PERCENTAGE) {
    horizontalPosition = ALIGN_TYPE_LEFT;
  } else if (point.x < 2 * ONE_THIRD_PERCENTAGE) {
    horizontalPosition = '';
  } else {
    horizontalPosition = ALIGN_TYPE_RIGHT;
  }

  if (point.y < ONE_THIRD_PERCENTAGE) {
    verticalPosition = ALIGN_TYPE_TOP;
  } else if (point.x < 2 * ONE_THIRD_PERCENTAGE) {
    verticalPosition = '';
  } else {
    verticalPosition = ALIGN_TYPE_BOTTOM;
  }

  const alignType = `${verticalPosition}${
    verticalPosition && horizontalPosition ? '_' : ''
  }${horizontalPosition}`;
  return alignType ? alignType : ALIGN_TYPE_CENTER;
};

export const getAlignTypeFromFaces = (image: ImageMediaItem): string => {
  const focalPoint = getFocalPointFromFaces(image);
  return !focalPoint
    ? ALIGN_TYPE_CENTER
    : getAlignTypeFromFocalPoint(focalPoint);
};
